import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '@material-ui/core/Select';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font
    '&:focus': {
      borderRadius: 4,
      borderColor: '#944DFF',
      boxShadow: '#944DFF',
    },
    '&:hover ': {
      opacity: 1,
      borderColor: '#944DFF',

    },
  },
}))(InputBase);

const styles = (theme) => ({
  root: {
    '&:hover ': {
      opacity: 1,
      borderColor: '#944DFF',

    },
  },

  input: {
    width: 435,
    height: 40,
    marginBottom: 10,
  },
  select: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  error: {
    color: 'red',
    margin: 0,
  },
  formControl: {
    width: '100%',
    // paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  gray: {
    color: '#828282',
  },
  inputLabel: {
    Padding: theme.spacing(3)
  },
  inputLabel1: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  fieldsetPadding: {
    paddingLeft: theme.spacing(2),
  },
  select: {
    borderWidth: 10,
  },
  icon: {
    fill: 'rgba(0, 0, 0, 0.23)',
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: '10px'
      }
    },
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        '& $notchedOutline': {
          borderColor: 'yellow',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'Yellow',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: 'rgba(16, 230, 112, 0.23)',
          },
        },
        '&$focused $notchedOutline': {
          borderColor: '#4A90E2',
          borderWidth: 1,
        },
      },
    },
  },
});

@inject('rootStore')
@observer
class UtaSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => { };

  render() {
    const {
      rootStore,
      form,
      fieldName,
      classes,
      label,
      menuItems,
      core,
      onClose,
      firstOptionLabel,
    } = this.props;
    const { apiStore, uiStore } = rootStore;
    const finalFirstOptionLabel = _.isEmpty(this.props.firstOptionLabel)
      ? `Select ${label}`
      : this.props.firstOptionLabel;
    const finalLabel = !form.$(fieldName).rules.includes('required')
      ? `(Optional) ${label}`
      : label;

    return (
      <fieldset
        id={`fieldset-${fieldName}`}
        className={classes.fieldsetPadding}
      >
        <Typography
          className={classes.inputLabel}
          htmlFor={form.$(fieldName).id}
        >
          {finalLabel}
        </Typography>
        <FormControl id={`formControl-${core}`} className={classes.formControl}>
          <Select
            variant="outlined"
            className={classes.input}
            id={`select-${core}`}
            native={false}
            onClose={onClose}
            clearable="true"

            notched={false}
            displayEmpty={true}
            // input={<BootstrapInput />}
            inputProps={{
              classes: {
                iconOutlined: classes.icon,
                selectMenu: classes.inputLabel1
              },
            }}
            {...form.$(fieldName).bind()}
          >

            <MenuItem role="option" disabled style={{ color: 'gray', paddingTop: 15, paddingBottom: 15 }} className={classes.root} value="">
              <span className={classes.gray}>Select {label}</span>
            </MenuItem>
            {menuItems}

          </Select>
          <div className={classes.error}>{form.$(fieldName).error}</div>
        </FormControl>
      </fieldset >
    );
  }
}

UtaSelect.wrappedComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UtaSelect);
