
import { Button } from 'primereact/button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import RootStore from '../../store/RootStore';
import Tooltip from '@material-ui/core/Tooltip';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import UccToolbarButton from './UccToolbarButton';

@inject('rootStore')
@observer
class UccToolbarEdit extends React.Component {
  constructor(props) {
    super(props);
    this.model = this.props.rootStore.channel.model;
  }

  exportClick = (event) => {
    this.props.model.crudStore.setCopyExportEl(event.currentTarget, 'bulk_edit');
  };

  onClick = (e) => {
    const crudStore = this.props.model.crudStore;
    if (this.props.model.crudStore.selectedRowIds.length == 1) {
      crudStore.setToolbarAction('edit');
      crudStore.onEdit();
    } else if (this.disableEditForBulk()) {
      alert("Cannot be bulk edited");
    } else {
      crudStore.setToolbarAction('bulk_edit');
      crudStore.renderBulkForm(e, 'bulk_edit');
    }
  }


  disableEditForBulk = () => {

    const isAdmin = this.props.rootStore.channel.adminChannel;
    const hasStatus = this.props.rootStore.channel.hasStatus == undefined ? (this.props.rootStore.channel.model.hasStatus == undefined ? true : this.props.rootStore.channel.model.hasStatus) : this.props.rootStore.channel.hasStatus;
    const selectedRows = _.compact(this.props.model.crudStore.selectedRows);
    const hasActiveRecord = (hasStatus && selectedRows.length > 0) ? (selectedRows.filter(m => m.status.name == 'Active').length > 0) : false
    const hasArchivedRecord = (hasStatus && selectedRows.length > 0) ? (selectedRows.filter(m => m.status.name == 'Archived').length > 0) : false

    if (!isAdmin && hasActiveRecord) return true;
    if (isAdmin && hasArchivedRecord) return true;
    return this.props.model.crudStore.selectedRowIds.length > 1 && this.props.model.disableBulkEdit
  }

  render() {
    const { rootStore, model, classes, anchorEl, disable, id } = this.props;
    const channel = rootStore.channel;
    // disable editing if it should archived instead
    if (rootStore.apiStore.currentUser.pll < model.permCreateAndEdit) return (<></>);
    const selectedRowIds = model.crudStore.selectedRowIds;

    const crudStore = model.crudStore;
    const deleteCount = this.deleteCount;
    const canEditUserPerm = model.permCreateAndEdit <= rootStore.apiStore.currentUser.pll;

    let disabled = canEditUserPerm ? !canEditUserPerm : this.props.disable;
    disabled = model.crudStore.selectedRowIds.length == 0 ? true : (this.disableEditForBulk()) ? this.disableEditForBulk() : disabled;
    if (model.crudStore.selectedRowIds.length > 0 && rootStore.apiStore.currentUser.pll > 90) disabled = false;
    return (
      <>
        <UccToolbarButton id={id} disable={disabled} selectedRowIds={selectedRowIds} model={model} label="Edit" icon="pi pi-pencil" onClick={(e) => this.onClick(e)} />
      </>
    )
  }
}


export default UccToolbarEdit;
