
import AdminUserManagement from '../../../../../public/images/admin-user-management.svg';
import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');

const abbrev_regex = 'regex:/^\\w+$/';
const extra_regex = 'regex:/^[a-zA-Z0-9_]+$/';

export const user_management = {
  name: 'User Management',
  abbrev: 'UserManagement',
  codename: 'userManagement',
  endpoint: 'user-management',
  icon: AdminUserManagement,
  adminChannel: true,
  showViewIcon: true,
  subtitle: 'Manage Users, Groups, Brands and adjust permissions',
  models: [
    {
      allFilterData: (apiStore) => apiStore['usersFilterOptions'],
      childModelEndpoints: ['groups'], // set to endpoints
      childModelFilter: (root) => childModelFilter(root, 'user_id'),
      codename: 'AdminUser',
      controller: 'user',
      endpoint: 'users',
      form: (rootStore) => <GenericForm numButtons={2} />,
      genericModelPromiseEndpoints: [
        'areas',
        'brands',
        'brand-channels',
        'business-units',
        'channels',
        'groups',
        'permission-levels',
        'users',
      ],
      modelPromiseEndpoints: (apiStore) => ([
        apiStore.brandsCrud.getFilterOptions('', ''),
        apiStore.usersCrud.getFilterOptions('', ''),
        apiStore.groupsCrud.getFilterOptions('', ''),
        apiStore.channelsCrud.getFilterOptions('', ''),
      ]),
      modelFilter: (root) => { return {}; },
      name: 'Users',
      onCreateCallback: (root) => root.apiStore.sendWelcomeEmail(root.channel.model.crudStore.form),
      permArchive: 60,
      permCreateAndEdit: 20,
      permDelete: 60,
      permListAndView: 20,
      route: 'users',
      send2domo: false,
      singular: 'user',
      sortField: 'name',
      tabIndex: 0,
      tabLabel: (m) => m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults('admin'),
      crud: (rootStore) => rootStore.usersCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 8,
          form: 'name',
          formField: (rootStore) => (
            <UccInputText
              core="name"
              fieldName="name"
              form={rootStore.channel.model.crudStore.form}
              label="Name"
              onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
            />
          ),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 20,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Name',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
          utaType: 'string',
        },
        {
          colSize: 12,
          form: 'email',
          formField: (rootStore) => (
            <UccInputText
              core="email"
              fieldName="email"
              form={rootStore.channel.model.crudStore.form}
              label="Email"
              onInput={(event) => rootStore.channel.model.crudStore.storeData('email', event)}
            />
          ),
          grid: true,
          grid_link: 'email',
          grid_order: 30,
          grid_value: (row) => row.email,
          id: 'email',
          label: 'Email',
          name: 'email',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          show: true,
          show_value: (row) => row.email,
          show_order: 30,
          utaType: 'string',
        },

        {
          belongsTo: ['permission_level', 'permissionLevels'],
          copyEditable: true,
          formOrder: 70,
          default: 3,
          form: 'permission_level_id',
          formField: (rootStore) => (
            <UccSelect
              core="permission_level_id"
              fieldName="permission_level_id"
              form={rootStore.channel.model.crudStore.form}
              label="Permission Level"
              menuItems={rootStore.apiStore.permissionLevels.filter(p => p.id != 1 && p.id != 2).filter(p => p.level <= rootStore.apiStore.currentUser.pll).map((p) => ({
                label: p.level_name,
                value: `${p.id}`
              }))}
              onChange={(event) => rootStore.channel.model.crudStore.storeData('permission_level_id', event)}
            />
          ),
          label: 'Permission Level',

          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.permission_level, 'level_name'),
          show_order: 70,
          utaType: 'fk',
        },
        {
          colSize: 8,
          grid: true,
          grid_order: 70,
          grid_value: (row) => property(row.permission_level, 'level_name'),
          id: 'permission_level',
          label: 'Permission Level',
          filter_label: 'level_name',
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'permission_level',
          rules: 'required',
          options: (apiStore) => apiStore.permissionLevels,
          utaType: 'other',
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 90,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 90,
          utaType: 'status',
        },

        // {
        //   form: 'admin',
        //   grid: true,
        //   grid_order: 40,
        //   grid_value: (row) => (row.admin ? 'Admin' : 'Standard'),
        //   id: 'admin',
        //   label: 'Account Type',
        //   name: 'admin',
        //   rules: 'boolean',
        //   show: true,
        //   show_value: (row) => (row.admin ? 'Admin' : 'Standard'),
        //   show_order: 40,
        //   utaType: 'boolean',
        // },
        {
          belongsTo: ['groups', 'groups'],
          form: 'group_ids',
          copyEditable: true,
          formField: (rootStore) => (
            <UccMultiSelect
              core="groups"
              fieldName="group_ids"
              form={rootStore.channel.model.crudStore.form}
              filter={true}
              label="Groups"
              menuItems={_.sortBy(rootStore.apiStore.groups, ['name']).map((p) => ({
                label: p.name,
                value: p.id,
              }))}
              onChange={(event) => rootStore.channel.model.crudStore.storeData('group_ids', event)}
            />
          ),
          label: 'Group',
          multiple: [],
          rules: 'array',
          utaType: 'array',
        },
        {
          grid: true,
          grid_order: 50,
          grid_value: (row) => row.group_ids.length,
          id: 'groups',
          label: 'Groups',
          name: 'groups',
          rules: 'array',
          show: true,
          show_value: (row) => row.group_ids.length,
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          form: 'password',
          label: 'password',
          rules: 'string',
          utaType: 'string',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['brandsFilterOptions'],
      childModelEndpoints: ['groups'], // set to endpoints
      childModelFilter: (root) => childModelFilter(root, 'brand_id'),
      codename: 'AdminBrand',
      controller: 'brand',
      endpoint: 'brands',
      form: (rootStore) => <GenericForm numButtons="approve" />,
      genericModelPromiseEndpoints: [
        'areas',
        'brands',
        'brand-channels',
        'business-units',
        'channels',
        'groups',
        'permission-levels',
        'users',
      ],
      modelPromiseEndpoints: (apiStore) => ([
        apiStore.brandsCrud.getFilterOptions('', ''),
        apiStore.usersCrud.getFilterOptions('', ''),
        apiStore.groupsCrud.getFilterOptions('', ''),
        apiStore.channelsCrud.getFilterOptions('', ''),
      ]),
      modelFilter: (root) => { },
      name: 'Brands',
      permArchive: 60,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      send2domo: false,
      singular: 'brand',
      sortField: 'name',
      permRequest: 20,
      route: 'brands',
      tabIndex: 1,
      tabLabel: (m) => m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults('admin'),
      crud: (rootStore) => rootStore.brandsCrud,
      columns: [
        {
          colSize: 6,
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'id',
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 20,
          grid_value: (row) => row.id,
          id: 'id',
          label: 'id',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          show: true,
          show_order: 20,
          show_value: (row) => row.id,
          type: 'hidden',
        },
        {
          colSize: 8,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_value: (row) => row.name,
          grid_link: 'draft_edit',
          id: 'name',
          label: 'Brand',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 6,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
          // disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          form: 'path',
          formField: (rootStore) => (<UccInputText
            core="path"
            fieldName="path"
            form={rootStore.channel.model.crudStore.form}
            label="Path"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('path', event)}
          />),
          formOrder: 33,
          grid: true,
          grid_order: 33,
          grid_value: (row) => row.path,
          id: 'path',
          label: 'Path',
          name: 'path',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'string',
          show: true,
          show_value: (row) => row.path,
          show_order: 33,
        },
        {
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.group_ids.length,
          id: 'groups',
          label: 'Groups',
          name: 'groups',
          rules: 'array',
          show: true,
          show_value: (row) => row.group_ids.length,
          show_order: 40,
          utaType: 'array',
          sortable: false
        },

        {
          belongsTo: ['channels', 'channels'],
          copyEditable: true,
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={rootStore.apiStore.sortedChannels.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 36,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          grid: true,
          grid_order: 50,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channel_ids.length,
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 90,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 90,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['groupsFilterOptions'],
      childModelEndpoints: ['users', 'brands'], // set to endpoints
      childModelFilter: (root) => childModelFilter(root, 'group_id'),
      codename: 'AdminGroup',
      controller: 'group',
      disableBulkEdit: false,
      endpoint: 'groups',
      form: (rootStore) => <GenericForm numButtons={2} />,
      genericModelPromiseEndpoints: [
        'areas',
        'brands',
        'brand-channels',
        'business-units',
        'channels',
        'groups',
        'permission-levels',
        'users',
      ],
      modelPromiseEndpoints: (apiStore) => ([
        apiStore.brandsCrud.getFilterOptions('', ''),
        apiStore.usersCrud.getFilterOptions('', ''),
        apiStore.groupsCrud.getFilterOptions('', ''),
        apiStore.channelsCrud.getFilterOptions('', ''),
      ]),
      modelFilter: (root) => { return {}; },
      name: 'Groups',
      // permArchive: 60,
      permCreateAndEdit: 40,
      permDelete: 60,
      permListAndView: 20,
      // permRequest: 20,
      route: 'groups',
      send2domo: false,
      singular: 'group',
      sortField: 'name',
      tabIndex: 2,
      tabLabel: (m) => m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults('admin'),
      crud: (rootStore) => rootStore.groupsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 8,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Group Name"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          grid: true,
          grid_order: 20,
          grid_value: (row) => row.name,
          grid_link: 'draft_edit',
          id: 'name',
          label: 'Name',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 6,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
          // disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          belongsTo: ['users', 'users'],
          copyEditable: true,
          form: 'user_ids',
          formField: (rootStore) => (
            <UccMultiSelect
              core="users"
              fieldName="user_ids"
              form={rootStore.channel.model.crudStore.form}
              label="User(s)"
              filter={true}
              menuItems={rootStore.apiStore.users.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
              onChange={(event) => rootStore.channel.model.crudStore.storeData('user_ids', event)}
            />
          ),
          label: 'User',
          multiple: [],
          rules: 'array',
          utaType: 'array',
        },
        {
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.user_ids.length,
          id: 'users',
          label: 'Users',
          multiple: [],
          name: 'users',
          rules: 'array',
          show: true,
          show_value: (row) => row.user_ids.length,
          show_order: 40,
          utaType: 'array',
          sortable: false

        },
        {
          belongsTo: ['brands', 'brands'],
          form: 'brand_ids',
          label: 'Brand',
          multiple: [],
          rules: 'array',
          utaType: 'array',
        },
        {
          belongsTo: ['brand_channels', 'brandChannels'],
          form: 'brand_channel_ids',
          formField: (rootStore) => (
            <UccBrandChannelGroupDataTable
              model={rootStore.channel.model}
              core='acg'
            />
          ),
          label: 'BrandChannelId',
          multiple: [],
          rules: 'array',
          utaType: 'array',
        },
        {
          grid: true,
          grid_order: 50,
          grid_value: (row) => row.brand_ids.length + ' / ' + row.brand_channel_ids.length,
          id: 'brands_channels',
          label: 'Brands / Channels',
          multiple: [],
          name: 'brands_channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.brand_ids.length + ' / ' + row.brand_channel_ids.length,
          show_order: 50,
          utaType: 'array',
          sortable: false

        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 90,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 90,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['permissionLevels'],
      codename: 'AdminPermissionLevel',
      controller: 'permission_level',
      crud: (rootStore) => rootStore.permissionLevelsCrud,
      disableBulkEdit: true,
      endpoint: 'permission-levels',
      form: () => <GenericForm datePicker={2} numButtons={2} />,
      hasStatus: false,
      modelFilter: (root) => { return {}; },
      name: 'Permission Levels',
      noDataText: (root) => '',
      permArchive: 80,
      permCreateAndEdit: 80,
      permDelete: 80,
      permListAndView: 80,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults('admin'),
      permRequest: false,
      route: 'permission-levels',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Permission Level',
      sortField: 'level',
      tabDisabled: () => false,
      tabIndex: 0,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 6,
          form: 'id',
          label: 'id',
          rules: 'integer',
          type: 'hidden',
          show: true,
          show_value: (row) => row.id,
          show_order: 3,
        },
        {
          colSize: 15,
          form: 'level_name',
          formField: (rootStore) => (<UccInputText
            core="level_name"
            fieldName="level_name"
            form={rootStore.channel.model.crudStore.form}
            label="Level Name"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('level_name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_value: (row) => row.level_name,
          id: 'level_name',
          label: 'Level Name',
          name: 'level_name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.level_name,
          show_order: 20,
        },
        {
          colSize: 15,
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'level',
          formField: (rootStore) => (<UccSelect
            core="level"
            fieldName="level"
            form={rootStore.channel.model.crudStore.form}
            label="Level"
            menuItems={[
              { label: '0', value: '0' },
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '70', value: '70' },
              { label: '80', value: '80' },
              { label: '99', value: '99' }]}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('level', event)}
          />),
          formOrder: 40,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.level,
          id: 'level',
          label: 'Position',
          name: 'level',
          rules: 'required|integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.level,
          show_order: 40,
        },
      ],
    },
  ],
};