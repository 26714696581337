import AcknowledgeDialog from '../lib/AcknowledgeDialog';
import CrudAdmin from './CrudAdmin';
import CrudIndex from './CrudIndex';
import CircularProgress from '@material-ui/core/CircularProgress';
import Model from '../../store/Model';
import PropTypes from 'prop-types';
import React from 'react';
import routes from '../Layout/Routes';
import Typography from '@material-ui/core/Typography';
import UiStore from '../../store/UiStore';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { observable, action } from 'mobx';
import { TabView, TabPanel } from 'primereact/tabview';


const styles = theme => ({
  tabs: {
    paddingTop: theme.spacing(1),
    marginLeft: '50px',
    marginBottom: theme.spacing(3)
  },
  tab: {
    fontWeight: 'bold'
  },
});


@inject('uiStore', 'rootStore')
@observer
class CrudChildTabs extends React.Component {

  constructor(props) {
    super(props);
    const { rootStore, uiStore, model } = this.props;
    const crudStore = model.crudStore;
    model.children.forEach((childModel, index) => childModel.childTabIndex = index);
  }

  @action.bound
  componentDidMount = async () => {
    const rootStore = this.props.rootStore;
    const crudStore = this.props.model.crudStore;
    const model = this.props.model
    model.children[0].crudStore.fetchDisplayedRows(model.childModelFilter(rootStore));
    model.children[0].crudStore.fetchTotalRecords(model.childModelFilter(rootStore));
  }

  componentDidUpdate = () => {
    const { rootStore, model } = this.props;
    model.children.forEach((childModel, index) => childModel.childTabIndex = index);
  }

  handleChange = (event, newValue) => {
    const { rootStore, uiStore, model } = this.props;
    const channel = rootStore.channel;
    const crudStore = model.crudStore;
    crudStore.setChildModel(model.children.find(cm => cm.childTabIndex == event.index));
    if (channel.adminChannel) {
      rootStore.uiStore.router.goTo(
        routes.adminChannelModelChild,
        {
          channel: rootStore.channel.endpoint,
          model: model.endpoint,
          id: model.crudStore.selectedRowId,
          childModel: model.crudStore.childModel.endpoint
        },
        rootStore.channel[model.codename].crudStore,
      );
    }
  }

  render() {
    const { classes, rootStore, model } = this.props;
    const channel = rootStore.channel;
    if (rootStore.apiStore.fetchingBrands) {
      return (<div style={{ textAlign: 'center' }}><CircularProgress /></div>);
    }
    if (!rootStore.apiStore.channelPerm(channel)) return (<div>This is not your brand</div>);
    const areaname = rootStore.apiStore.currentBrand ? rootStore.apiStore.currentBrand.name : 'Admin';
    const value = model.children.findIndex(cm => cm.endpoint == model.childModel.endpoint)
    return (
      <div data-qa="child-tabs">
        <TabView
          className="tab-view-style"
          activeIndex={value}
          onTabChange={this.handleChange}
        >
          {model.children.map((childModel, index) => {

            childModel.crudStore.storeData('isChild', true);
            return (
              <TabPanel className="tab-panel-style" header={childModel.tabLabel(childModel)} key={model.name + model.childModel} >
                <CrudIndex model={model.childModel} childIndex={true}></CrudIndex>
              </TabPanel>
            );
          })}
        </TabView>

      </div>
    );
  }
}

CrudChildTabs.wrappedComponent.propTypes = {
  // uiStore: PropTypes.instanceOf(UiStore).isRequired,
  // crudAdminStore: PropTypes.instanceOf(CrudAdminStore).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CrudChildTabs);
