import GenericForm from '../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from '../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from '../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from '../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from '../../../components/PrimeReactComponent/UccInputText';
import UccSelect from '../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from '../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from '../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from '../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'
import { displayPlacementModelMetadata, displayAdModelMetadata, displayCreativeModelMetadata, displayPackageModelMetadata } from './display-channel'
var moment = require('moment');
const abbrev_regex = 'regex:/^\\w+$/';
const extra_regex = 'regex:/^[a-zA-Z0-9_]+$/';

export const display = {
    name: 'Display',
    endpoint: 'display',
    codename: 'display',
    abbrev: 'Display',
    icon: 'Display',
    id: 2,
    showViewIcon: true,
    models: [].concat(displayPlacementModelMetadata(2, 0))
        .concat(displayAdModelMetadata(2, 1))
        .concat(displayCreativeModelMetadata(2, 2))
        .concat(displayPackageModelMetadata(2, 3)),
};

