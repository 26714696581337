import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';

import ApiStore from '../../store/ApiStore';
import AppLayout from '../Layout/AppLayout';
import Signin from './Signin';
import ForgotPasswordForm from './PasswordForgotForm';
import PasswordChangeForm from './PasswordChangeForm';
import NewAccountForm from './NewAccountForm';

@inject('apiStore')
@observer
class AuthenticatedApp extends React.Component {
  componentDidMount = () => {
    this.props.apiStore.refreshCurrentUser();
  }

  render() {
    const { apiStore } = this.props;
    if (!apiStore.authenticated) {
      if (!apiStore.refreshingUser) {
        if (window.location.pathname === '/app/forgot-password') {
          return (<ForgotPasswordForm />);
        } else if (window.location.pathname === '/app/change-password') {
          return (<PasswordChangeForm />);
        } else if (window.location.pathname === '/users/password/edit') {
          return (<PasswordChangeForm />);
        } else if (window.location.pathname === '/users/new-account') {
          return (<NewAccountForm />);
        } else {
          return (<Signin />);
        }
      }
      return '';
    }
    return (<AppLayout />);
  }
}

AuthenticatedApp.wrappedComponent.propTypes = {
  apiStore: PropTypes.instanceOf(ApiStore).isRequired,
};

export default AuthenticatedApp;
