import React from 'react';
import routes from './Routes';
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { Link } from 'mobx-router';
import Breadcrumb from './Breadcrumb';

const styles = theme => ({

  breadcrumbs: {
    paddingLeft: 0,
    marginTop: '40px',
    paddingBottom: '25px'
  },
  Breadcrumbli: {
    padding: '5 5 5 5',
    position: 'relative',
    display: 'block',
    float: 'left',
    margin: '5 5 5 5',
    color: 'gray',
    listStyle: 'none',
  },
  BreadcrumbSeparator: {
    color: '#333',
    margin: 'auto 6px',
    display: 'inline-block',
    userSelect: 'none',
  },
  AdminBreadcrumb: {
    // paddingLeft: '44px'
  },
});

@inject('uiStore', 'apiStore')
@observer
class Breadcrumbs extends React.Component {
  render() {
    const { uiStore, classes } = this.props;
    let { breadcrumbs } = uiStore;
    breadcrumbs = _.reject(breadcrumbs, _.isEmpty);
    const arrLen = breadcrumbs ? breadcrumbs.length - 1 : 0;
    const separator = index => {
      if (index < arrLen) {
        return <i className="pi pi-chevron-right p-mr-2" style={{ 'fontSize': '20px', 'paddingLeft': '15px', 'paddingRight': '15px' }}></i>;
      }
      return <div></div>;
    };
    const breadcrumbsElement = () => {
      return breadcrumbs.map((breadcrumb, index) => {
        return (
          <div id={`div_${index}`} key={index} className={classes.Breadcrumbli}>
            <Breadcrumb breadcrumb={breadcrumb} /> {separator(index)}
          </div>
        );
      });
    };
    let classings = ["breadcrumb"];
    if (window.location.pathname.includes('legacy-admin')) classings.push(classes.AdminBreadcrumb);
    if (!breadcrumbs) {
      return <div></div>;
    }
    return <ul className={classes.breadcrumbs}>{breadcrumbsElement()}</ul>;
  }
}

export default withStyles(styles)(Breadcrumbs);
