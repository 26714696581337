import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';
import UccErrorHandling from './UccErrorHandling';

@inject('rootStore')
@observer
class UccInputText extends Component {
  render() {
    const {
      rootStore,
      fieldName,
      label,
      core,
      form,
      display
    } = this.props;
    // console.log('fieldName: ' + fieldName + ', display: ' + display);
    if (display === false) return (null);
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const finalFirstOptionLabel = _.isEmpty(this.props.firstOptionLabel) ? `Select ${label}` : this.props.firstOptionLabel
    const isError = !form ? !crudStore.displayError : (!!(form.$(fieldName).error) && crudStore.storedData[fieldName]?.toString() === '' ? false : true)
    const finalLabel = !form ? label :
      !form.$(fieldName).rules.includes('required') && !label == '' && this.props.callFrom != 'Days' && this.props.callFrom != 'targeting'
        ? `(Optional)  ${label}`
        : label;
    return (
      <fieldset className={this.props.callFrom == "Days" ? "fieldset-textfield-targeting" : "fieldset-textfield"} id={`fieldset-${fieldName}`} style={this.props.callFrom == "targeting" ? { paddingTop: '25px' } : {}} >
        <FormControl id={`formControl-${this.props.core}`}>
          <div className="container" style={this.props.callFrom == "targeting" ? { width: '415px' } : {}} >
            <span className={"fieldset-text p-float-label uccItems"} style={this.props.callFrom == "Days" ? { bottom: '8px' } : {}}>
              <InputText
                type="text"
                id={`textbox_id-${core}`}
                value={crudStore.storedData[fieldName]}

                onChange={this.props.onInput}
                className={this.props.className}
                disabled={!this.props.disabled ? "" : "disabled"}
                keyfilter={this.props.keyfilter}
                style={isError && !(form?.errors()[fieldName]) ? { border: '' } : { border: '2px solid #FF5656' }}
              />
              <label htmlFor={`textbox_id-${core}`}>{finalLabel}</label>
            </span>
            {this.props.callFrom == "Days" ? "" : <UccErrorHandling form={this.props.form} fieldName={fieldName} />}
          </div>
        </FormControl>
      </fieldset>
    );
  }
}

export default UccInputText;
