import React from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from 'primereact/button';
import AcknowledgeError from '../lib/AcknowledgeError';


const MobxRouterWithErrorHandlingFunc = ({ store, store: { router } }) =>
    router.currentView && router.currentView.component ? (
        router.currentView.component
    ) : (
      <AcknowledgeError>
        <h3>
          Page Not Found.
        </h3>
        <p>
          Click below to return Home.
        </p>
        <p>
          If the problem persists, please contact the UCC administrator.
        </p>
      </AcknowledgeError>
    );

const MobxRouterWithErrorHandling = inject('store')(observer(MobxRouterWithErrorHandlingFunc));

export default MobxRouterWithErrorHandling;