import GenericForm from '../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from '../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from '../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from '../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from '../../../components/PrimeReactComponent/UccInputText';
import UccSelect from '../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from '../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from '../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from '../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');
const abbrev_regex = 'regex:/^\\w+$/';
const extra_regex = 'regex:/^[a-zA-Z0-9_]+$/';



export const displayPlacementModelMetadata = (channel, tabIndex) => {
  // ______ _                                     _
  // | ___ \ |                                   | |
  // | |_/ / | __ _  ___ ___ _ __ ___   ___ _ __ | |_ ___
  // |  __/| |/ _` | / __/ _ \ '_ ` _ \ / _ \ '_ \| __ / __ |
  // | |   | | (_| | (_|  __/ | | | | |  __/ | | | |_\__ \
  // \_|   |_|\__,_|\___\___|_| |_| |_|\___|_| |_|\__|___/
  return [
    {
      allFilterData: (apiStore) => apiStore[apiStore.rootStore.channel.codename + 'PlacementsFilterOptions'],
      codename: 'DispPlace',
      controller: 'display_placement',
      crud: (rootStore) => rootStore.displayPlacementsCrud,
      domoNsType: (channel) => { return ((channel == 'display' || channel == 2) ? 'DisplayPlacement' : 'ProgrammaticPlacement') },
      endpoint: 'display-placements',
      filterOptionsCall: (apiStore) => (val) => apiStore[apiStore.rootStore.channel.codename + 'PlacementsFilterOptions'] = val,
      form: (rootStore) => <GenericForm type="Placement" datePicker={2} numButtons={3} extraFields={['size_id']} />,
      genericModelPromiseEndpoints: [
        'audiences',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-genders',
        'channel-objectives',
        'channel-tactics',
        'channel-targetings',
        'creative-types',
        'devices',
        'display-types',
        'display-type-channels',
        'genders',
        'geos',
        'languages',
        'marketing-campaigns',
        'objectives',
        'publishers',
        'sizes',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => {
        return ([
          apiStore[apiStore.channel.codename + 'PlacementsCrud'].getFilterOptions({ brand_id: brand_id, channel_id: channel }, ''),
          apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType(channel), brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
        ])
      },

      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, channel_id: channel }; },
      name: 'Placements',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'placements',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'placement',
      tabDisabled: () => false,
      tabIndex: tabIndex,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 13,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 10,
          grid_type: 'namestring',
          filter: false,
          id: 'namestring',
          filter: false,
          filterField: 'namestring',
          label: 'Namestring',
          rules: 'required'
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: (crudStore) => crudStore.channel.name + 'Placement',
          rules: 'required',
        },
        {
          default: channel,
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          form: 'channel_id',
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: () => 'hmm',
          rules: 'required',
          type: 'hidden',
          utaType: 'channel_id',
        },
        {
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 180,
          label: 'Created By',
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.Users
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 30,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          label: 'Group',
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          ns_order: 10,
          rules: 'required',
          utaType: 'group',
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          label: 'Brand',
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },

        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 1,
          label: 'Campaign Type',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 11,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
          grid: true,
          grid_order: 40,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          grid_value: (row) => property(row.campaign_type, 'name'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          ns_order: 30,
          show: true,
          show_value: (row) => property(row.campaign_type, "name"),
          show_order: 34,
          rules: 'required',
        },
        {
          colSize: 9,
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 50,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectives,
          grid_value: (row) => property(row.objective, 'name'),
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          ns: (crudStore) => abbrev(crudStore.storedData.objective),
          ns_order: 35,
          show: true,
          show_value: (row) => property(row.objective, "name"),
          show_order: 35,
          rules: 'required',
        },
        {
          belongsTo: ['objective', 'objectives'],
          form: 'objective_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            form={rootStore.channel.model.crudStore.form}
            label="Objective"
            menuItems={rootStore.uiStore.menuItems('objectives', 'makePrimeObjectiveOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 2,
          label: 'Objective',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 9,
          // domo_field: 'ad_name',
          // domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          // form: 'name',
          // copyEditable: true,
          // formField: (rootStore) => (
          //   <UccInputText
          //     core="name"
          //     fieldName="name"
          //     form={rootStore.channel.model.crudStore.form}
          //     label="Placement Description"
          //     onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          //   />),
          // formOrder: 3,
          grid: true,
          filter: true,
          grid_order: 60,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          filter_placeholder: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          name: 'name',
          // ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          // ns_order: 45,
          // rules: 'required|string',
          show: true,
          show_value: (row) => row.name,
          show_order: 45,
          utaType: 'name',
        },
        {
          colSize: 13,
          domo_field: (crudStore) => crudStore.channel.codename == 'programmatic' ? 'campaign_name' : 'ad_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 55,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Marketing Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 45,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.marketing_campaign, 'name'),
          show_order: 60,
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Marketing Campaign"
            menuItems={rootStore.uiStore.menuItems('marketingCampaigns', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 2,
          label: 'Marketing Campaign',
          rules: 'required|integer',
          utaType: 'fk',
        },

        {
          domo_field: 'publisher',
          domo_value: (crudStore) => property(crudStore.storedData.publisher, 'name'),
          grid: true,
          grid_order: 60,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.publishers,
          grid_value: (row) => property(row.publisher, 'name'),
          id: 'publisher',
          label: 'Publisher',
          name: 'publisher',
          ns: (crudStore) => abbrev(crudStore.storedData.publisher),
          ns_order: 50,
          show: true,
          show_value: (row) => property(row.publisher, "name"),
          show_order: 50,
          rules: 'required',
        },
        {
          belongsTo: ['publisher', 'publishers'],
          form: 'publisher_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="publisher"
            fieldName="publisher_id"
            form={rootStore.channel.model.crudStore.form}
            label="Publisher"
            menuItems={rootStore.uiStore.menuItems('publishers', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('publisher_id', event)}
          />),
          formOrder: 5,
          label: 'Publisher',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (<UccSelect
            core="tactic"
            fieldName="tactic_id"
            form={rootStore.channel.model.crudStore.form}
            label="Tactic"
            menuItems={rootStore.uiStore.menuItems('tactics', 'makePrimeChannelSpecificOption', 'channelTactics')}
            onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
          />),
          formOrder: 6,
          label: 'Tactic',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 70,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          grid_value: (row) => property(row.tactic, 'name'),
          id: 'tactic',
          label: 'Tactic',
          name: 'tactic',
          ns: (crudStore) => abbrev(crudStore.storedData.tactic),
          ns_order: 60,
          show: true,
          show_value: (row) => property(row.tactic, "name"),
          show_order: 60,
          rules: 'required|integer',
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          ns: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          ns_order: 75,
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 75,
          type: 'checkbox',
          utaType: 'boolean',
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          form: 'display_placement_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
          formOrder: 7,
          grid: true,
          grid_order: 80,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'display_placement_targetings_attributes',
          ns: (crudStore) => NameString.targetingString(crudStore),
          ns_order: 80,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "display_placement_targetings"),
          show_order: 80,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'display_placement_targetings_attributes[].id',
        },
        {
          form: 'display_placement_targetings_attributes[].display_placement_id',
        },
        {
          form: 'display_placement_targetings_attributes[].targeting_id',
        },
        {
          form: 'display_placement_targetings_attributes[].extra',
        },
        {
          form: 'display_placement_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'display_placement_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'display_placement_targetings_attributes[].on',
        },
        {
          belongsTo: ['device', 'devices'],
          form: 'device_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="device"
            fieldName="device_id"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            menuItems={rootStore.uiStore.menuItems('devices', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('device_id', event)}
          />),
          formOrder: 9,
          label: 'Device',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.device, 'name'),
          show_order: 90,
          utaType: 'fk',
        },
        {
          domo_field: 'device',
          domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
          grid: true,
          grid_order: 80,
          grid_value: (row) => property(row.device, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.devices,
          id: 'device',
          label: 'Device',
          name: 'device',
          ns: (crudStore) => abbrev(crudStore.storedData.device),
          ns_order: 90,
          rules: 'required|integer',
        },
        {
          belongsTo: ['display_types', 'displayTypes'],
          form: 'display_type_ids',
          copyEditable: true,
          formField: (rootStore) => (<UccMultiSelect
            core="display-types"
            fieldName="display_type_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Display Types"
            menuItems={rootStore.apiStore.displayTypes.map((p) => ({ label: p.name, value: p.id, }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('display_type_ids', event)}
          />),
          formOrder: 3,
          label: 'Display Type',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 12,
          domo_field: 'display_types',
          domo_value: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
          grid: true,
          grid_order: 56,
          grid_value: (row) => row.display_types.map(c => c.name).sort().join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.displayTypes,
          id: 'display_types',
          label: 'Display Types',
          multiple: [],
          name: 'display_types',
          ns: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
          ns_order: 46,
          rules: 'array',
          show: true,
          show_value: (row) => row.display_types.map(c => c.name).join(', '),
          show_order: 61,
          utaType: 'array',
          sortable: false
        },
        {
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          copyEditable: true,
          formField: (rootStore) => (
            <UccSelect
              core="geo"
              fieldName="geo_id"
              form={rootStore.channel.model.crudStore.form}
              label="Geo"
              menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeOption')}
              onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
            />
          ),
          formOrder: 10,
          label: 'Geo',
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 100,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          grid_order: 100,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          label: 'Geo',
          name: 'geo',
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          ns_order: 100,
          rules: 'required|integer',
        },
        {
          colSize: 15,
          domo_field: 'region',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.region),
          form: 'region',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="region"
            fieldName="region"
            form={rootStore.channel.model.crudStore.form}
            label="Region"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
          />),
          formOrder: 11,
          grid: true,
          grid_order: 105,
          grid_value: (row) => row.region,
          id: 'region',
          name: 'region',
          ns: (crudStore) => crudStore.storedData.region,
          ns_order: 103,
          label: 'Region',
          utaType: 'string',
          rules: ['string', abbrev_regex],
          // rules: ['string'],
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.region,
          show_order: 105,
        },
        {
          belongsTo: ['language', 'languages'],
          form: 'language_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="language"
            fieldName="language_id"
            form={rootStore.channel.model.crudStore.form}
            label="Language"
            menuItems={rootStore.uiStore.menuItems('languages', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('language_id', event)}
          />),
          formOrder: 12,
          label: 'Language',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 110,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 110,
          grid_value: (row) => property(row.language, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          id: 'language',
          label: 'Language',
          name: 'language',
          ns: (crudStore) => abbrev(crudStore.storedData.language),
          ns_order: 105,
          rules: 'integer',
        },
        {
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={rootStore.uiStore.menuItems('genders', 'makePrimeChannelSpecificOption', 'channelGenders')}
            onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 13,
          label: 'Gender',
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 120,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          grid_order: 105,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 110,
          rules: 'required|integer',
        },
        {
          belongsTo: ['size', 'sizes'],
          form: 'size_id',
          copyEditable: true,
          hideForm: true,
          formField: (rootStore) => (<UccSelect
            core="size"
            fieldName="size_id"
            form={rootStore.channel.model.crudStore.form}
            label="Size"
            menuItems={rootStore.uiStore.menuItems('sizes', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('size_id', event)}
          />),
          label: 'Size',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.size, 'name'),
          show_order: 130,
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'size',
          copyEditable: true,
          domo_value: (crudStore) => property(crudStore.storedData.size, 'name'),
          grid: true,
          grid_order: 130,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.sizes,
          grid_value: (row) => property(row.size, 'name'),
          id: 'size',
          label: 'Size',
          name: 'size',
          ns: (crudStore) => abbrev(crudStore.storedData.size),
          ns_order: 150,
          rules: 'required|integer',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 110, 130, 'S.', 130),
        dateField('end_date', 'End Date', 'blank', false, 120, 140, 'E.', 140),
      ),
    }
  ]
}


export const displayAdModelMetadata = (channel, tabIndex) => {
  // ___   _            _                 _       _
  // |   \ (_) ___ _ __ | | __ _  _  _    /_\   __| | ___
  // | |) || |(_-<| '_ \| |/ _` || || |  / _ \ / _` |(_-<
  // |___/ |_|/__/| .__/|_|\__,_| \_, | /_/ \_\\__,_|/__/
  //              |_|             |__/
  return [
    {
      allFilterData: (apiStore) => apiStore[apiStore.rootStore.channel.codename + 'AdsFilterOptions'],
      codename: 'DispAd',
      controller: 'display_ad',
      crud: (rootStore) => rootStore.displayAdsCrud,
      domoNsType: (channel) => { return ((channel == 'display' || channel == 2) ? 'DisplayAd' : 'ProgrammaticAd') },
      endpoint: 'display-ads',
      filterOptionsCall: (apiStore) => (val) => apiStore[apiStore.rootStore.channel.codename + 'AdsFilterOptions'] = val,
      form: (rootStore) => <GenericForm type="Display Ad" datePicker={2} numButtons={3} extraFields={['size_id']} />,
      genericModelPromiseEndpoints: [
        'audiences',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-genders',
        'channel-objectives',
        'channel-tactics',
        'channel-targetings',
        'creative-types',
        'devices',
        'display-types',
        'display-type-channels',
        'genders',
        'geos',
        'languages',
        'marketing-campaigns',
        'objectives',
        'publishers',
        'sizes',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore[apiStore.channel.codename + 'AdsCrud'].getFilterOptions({ brand_id: brand_id, channel_id: channel }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType(channel), brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, channel_id: channel }; },
      name: 'Ads',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'ads',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'ad',
      tabDisabled: () => false,
      tabIndex: tabIndex,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 13,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 5,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'

        },
        {
          domo_field: 'ns_type',
          domo_value: (crudStore) => crudStore.channel.name + 'Ad',
          rules: 'required',
        },

        {
          default: channel,
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          form: 'channel_id',
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: () => 'hmm',
          rules: 'required',
          type: 'hidden',
          utaType: 'channel_id',
        },
        {
          colSize: 10,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'created_by',
          label: 'Created By',
          id: 'created_by',
          options: (apiStore) => apiStore.Users,
          grid: true,
          grid_order: 190,
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 15,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          ns_order: 10,
          utaType: 'group',
          rules: 'required',
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 10,
          label: 'Campaign Type',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 12,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
          grid: true,
          grid_order: 25,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          grid_value: (row) => property(row.campaign_type, 'name'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          ns_order: 30,
          show: true,
          show_value: (row) => property(row.campaign_type, "name"),
          show_order: 10,
        },
        {
          colSize: 9,
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 30,
          grid_value: (row) => property(row.objective, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectives,
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          ns: (crudStore) => abbrev(crudStore.storedData.objective),
          show: true,
          show_value: (row) => property(row.objective, "name"),
          show_order: 20,
          ns_order: 40,
        },
        {
          belongsTo: ['objective', 'objectives'],
          form: 'objective_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            form={rootStore.channel.model.crudStore.form}
            label="Objective"
            menuItems={rootStore.uiStore.menuItems('objectives', 'makePrimeObjectiveOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 20,
          label: 'Objective',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 13,
          domo_field: (crudStore) => crudStore.channel.codename == 'programmatic' ? 'campaign_name' : 'ad_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 27,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Marketing Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 45,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.marketing_campaign, "name"),
          show_order: 15,
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Marketing Campaign"
            menuItems={rootStore.uiStore.menuItems('marketingCampaigns', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 30,
          label: 'Marketing Campaign',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 9,
          grid: true,
          grid_order: 28,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          name: 'name',
          rules: 'string',
          utaType: 'name',
        },
        {
          belongsTo: ['display_types', 'displayTypes'],
          form: 'display_type_ids',
          copyEditable: true,
          formField: (rootStore) => (<UccMultiSelect
            core="display-types"
            fieldName="display_type_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Display Types"
            menuItems={rootStore.apiStore.displayTypes.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('display_type_ids', event)}
          />),
          formOrder: 40,
          label: 'Display Type',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          domo_field: 'display_types',
          domo_value: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
          grid: true,
          grid_order: 56,
          grid_value: (row) => row.display_types.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.displayTypes,
          id: 'display_types',
          label: 'Display Types',
          multiple: [],
          name: 'display_types',
          ns: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
          ns_order: 46,
          rules: 'array',
          show: true,
          show_value: (row) => row.display_types.map(c => c.name).join(', '),
          show_order: 61,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 9,
          domo_field: 'publisher',
          domo_value: (crudStore) => property(crudStore.storedData.publisher, 'name'),
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.publisher, 'name'),
          id: 'publisher',
          label: 'Publisher',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.publishers,
          name: 'publisher',
          ns: (crudStore) => abbrev(crudStore.storedData.publisher),
          ns_order: 60,
          show: true,
          show_value: (row) => property(row.publisher, "name"),
          show_order: 40,
        },
        {
          belongsTo: ['publisher', 'publishers'],
          form: 'publisher_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="publisher"
            fieldName="publisher_id"
            form={rootStore.channel.model.crudStore.form}
            label="Publisher"
            menuItems={rootStore.uiStore.menuItems('publishers', 'makePrimeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('publisher_id', event)}
          />),
          formOrder: 45,
          label: 'Publisher',
          rules: 'integer',
          utaType: 'fk',
        },

        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (<UccSelect
            core="tactic"
            fieldName="tactic_id"
            form={rootStore.channel.model.crudStore.form}
            label="Tactic"
            menuItems={rootStore.uiStore.menuItems('tactics', 'makePrimeChannelSpecificOption', 'channelTactics')}
            onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
          />),
          formOrder: 50,
          label: 'Tactic',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 50,
          grid_value: (row) => property(row.tactic, 'name'),
          id: 'tactic',
          label: 'Tactic',
          name: 'tactic',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          ns: (crudStore) => abbrev(crudStore.storedData.tactic),
          ns_order: 70,
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.tactic, "name"),
          show_order: 50,
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          ns: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          ns_order: 75,
          type: 'checkbox',
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 75,
          utaType: 'boolean',
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          form: 'display_ad_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
          formOrder: 80,
          grid: true,
          grid_order: 80,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'display_ad_targetings_attributes',
          ns: (crudStore) => NameString.targetingString(crudStore),
          ns_order: 80,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "display_ad_targetings"),
          show_order: 80,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'display_ad_targetings_attributes[].id',
        },
        {
          form: 'display_ad_targetings_attributes[].display_ad_id',
        },
        {
          form: 'display_ad_targetings_attributes[].targeting_id',
        },
        {
          form: 'display_ad_targetings_attributes[].extra',
        },
        {
          form: 'display_ad_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'display_ad_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'display_ad_targetings_attributes[].on',
        },
        // {
        //   name: 'targetings',
        //   ns: () => 'targetings',
        //   utaType: 'array',
        // },
        // {
        //   name: 'display_ad_targetings',
        //   ns: () => 'targetings',
        //   utaType: 'array',
        // },
        {
          colSize: 9,
          belongsTo: ['device', 'devices'],
          form: 'device_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="device"
            fieldName="device_id"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            menuItems={rootStore.uiStore.menuItems('devices', 'makePrimeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('device_id', event)}
          />),
          formOrder: 90,
          label: 'Device',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'device',
          domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
          grid: true,
          grid_order: 90,
          grid_value: (row) => property(row.device, 'name'),
          id: 'device',
          label: 'Device',
          name: 'device',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.devices,
          ns: (crudStore) => abbrev(crudStore.storedData.device),
          ns_order: 90,
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.device, 'name'),
          show_order: 90,
        },
        {
          colSize: 9,
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="geo"
            fieldName="geo_id"
            form={rootStore.channel.model.crudStore.form}
            label="Geo"
            menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('geo_id', event)}
          />),
          formOrder: 100,
          label: 'Geo',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          grid_order: 100,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          label: 'Geo',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          name: 'geo',
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          ns_order: 100,
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 100,
        },
        {
          colSize: 15,
          domo_field: 'region',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.region),
          form: 'region',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="region"
            fieldName="region"
            form={rootStore.channel.model.crudStore.form}
            label="Region"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
          />),
          formOrder: 105,
          grid: true,
          grid_order: 105,
          grid_value: (row) => row.region,
          id: 'region',
          name: 'region',
          ns_order: 102,
          ns: (crudStore) => crudStore.storedData.region,
          label: 'Region',
          utaType: 'string',
          rules: ['string', abbrev_regex],
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.region,
          show_order: 105,
        },
        {
          colSize: 10,
          belongsTo: ['language', 'languages'],
          form: 'language_id',
          copyEditable: true,
          formField: (rootStore) => (
            <UccSelect
              core="language"
              fieldName="language_id"
              form={rootStore.channel.model.crudStore.form}
              label="Language"
              menuItems={rootStore.uiStore.menuItems('languages', 'makePrimeRegularOption')}
              onChange={(event) => rootStore.channel.model.crudStore.storeData('language_id', event)}
            />),
          formOrder: 110,
          label: 'Language',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 110,
          grid_value: (row) => property(row.language, 'name'),
          id: 'language',
          label: 'Language',
          name: 'language',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          ns: (crudStore) => abbrev(crudStore.storedData.language),
          ns_order: 110,
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 110,
        },
        {
          colSize: 10,
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={rootStore.uiStore.menuItems('genders', 'makePrimeChannelSpecificOption', 'channelGenders')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 120,
          label: 'Gender',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          grid_order: 120,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 120,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 120,
        },
        {
          colSize: 9,
          belongsTo: ['size', 'sizes'],
          form: 'size_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="size"
            fieldName="size_id"
            form={rootStore.channel.model.crudStore.form}
            label="Size"
            menuItems={rootStore.uiStore.menuItems('sizes', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('size_id', event)}
          />),
          //form still in jsx
          hideForm: true,
          label: 'Size',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'size',
          domo_value: (crudStore) => property(crudStore.storedData.size, 'name'),
          grid: true,
          grid_order: 150,
          grid_value: (row) => property(row.size, 'name'),
          id: 'size',
          label: 'Size',
          name: 'size',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.sizes,
          ns: (crudStore) => abbrev(crudStore.storedData.size),
          ns_order: 150,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.size, 'name'),
          show_order: 110,
        },
        {
          label: 'Created At',
          show_value: (row) => crudStore.storedData.created_at,
          show_order: 160,
          rules: 'required|integer',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 130, 130, 'S.'),
        dateField('end_date', 'End Date', 'blank', false, 140, 140, 'E.'),
      ),
    }
  ]
}


export const displayCreativeModelMetadata = (channel, tabIndex) => {
  //    _    _    _    _    _    _    _    _
  //   / \  / \  / \  / \  / \  / \  / \  / \
  //  ( C )( r )( e )( a )( t )( i )( v )( e )
  //   \_/  \_/  \_/  \_/  \_/  \_/  \_/  \_/
  return [
    {
      allFilterData: (apiStore) => apiStore[apiStore.rootStore.channel.codename + 'CreativesFilterOptions'],

      codename: 'DispCreat',
      controller: 'display_creative',
      crud: (rootStore) => rootStore.displayCreativesCrud,
      domoNsType: (channel) => { return ((channel == 'display' || channel == 2) ? 'DisplayCreative' : 'ProgrammaticCreative') },
      endpoint: 'display-creatives',
      filterOptionsCall: (apiStore) => (val) => apiStore[apiStore.rootStore.channel.codename + 'CreativesFilterOptions'] = val,
      form: (rootStore) => <GenericForm type="Creative" datePicker={2} numButtons={3} extraFields={['size_id']} />,
      genericModelPromiseEndpoints: [
        'audiences',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-genders',
        'channel-objectives',
        'channel-tactics',
        'channel-targetings',
        'creative-types',
        'devices',
        'genders',
        'geos',
        'languages',
        'marketing-campaigns',
        'message-types',
        'objectives',
        'publishers',
        'sizes',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore[apiStore.channel.codename + 'CreativesCrud'].getFilterOptions({ brand_id: brand_id, channel_id: channel }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType(channel), brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, channel_id: channel }; },
      name: 'Creatives',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'creatives',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'creative',
      tabDisabled: () => false,
      tabIndex: tabIndex,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,

      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          rules: 'integer|required',
          permListAndView: 80,
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 8,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 5,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: (crudStore) => { return crudStore.channel.name == 'Display' ? 'DisplayCreative' : 'ProgrammaticCreative' },
        },
        {
          default: channel,
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          form: 'channel_id',
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: () => 'hmm',
          rules: 'required',
          type: 'hidden',
          utaType: 'channel_id',
        },
        {
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'created_by',
          label: 'Created By',
          id: 'created_by',
          grid: true,
          options: (apiStore) => apiStore.Users,
          grid_order: 190,
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 9,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          ns_order: 10,
          utaType: 'group',
          rules: 'required',
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },

        {
          belongsTo: ['creative_type', 'creativeTypes'],
          form: 'creative_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="creative-type"
            fieldName="creative_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Creative Type"
            menuItems={rootStore.uiStore.menuItems('creativeTypes', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('creative_type_id', event)}
          />),
          formOrder: 10,
          label: 'Creative Type',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10,
          domo_field: 'creative_type',
          domo_value: (crudStore) => property(crudStore.storedData.creative_type, 'name'),
          grid: true,
          grid_order: 10,
          grid_value: (row) => property(row.creative_type, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.creativeTypes,
          id: 'creative_type',
          label: 'Creative Type',
          name: 'creative_type',
          ns: (crudStore) => abbrev(crudStore.storedData.creative_type),
          ns_order: 30,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.creative_type, 'name'),
          show_order: 10,
        },
        {
          colSize: 9,
          // domo_field: 'ad_name',
          // domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          // form: 'name',
          // copyEditable: true,
          // formField: (rootStore) => (<UccInputText
          //   core="name"
          //   fieldName="name"
          //   form={rootStore.channel.model.crudStore.form}
          //   label="Creative Description"
          //   onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          // />
          // ),
          // formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          // name: 'name',
          // ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          // ns_order: 35,
          // rules: 'required|string',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
          utaType: 'name',
        },
        {
          colSize: 13,
          domo_field: (crudStore) => crudStore.channel.codename == 'programmatic' ? 'campaign_name' : 'ad_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 60,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Marketing Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 35,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.marketing_campaign, 'name'),
          show_order: 60,
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Marketing Campaign"
            menuItems={rootStore.uiStore.menuItems('marketingCampaigns', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 2,
          label: 'Marketing Campaign',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'publisher',
          domo_value: (crudStore) => property(crudStore.storedData.publisher, 'name'),
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.publisher, 'name'),
          id: 'publisher',
          label: 'Publisher',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.publishers,
          name: 'publisher',
          ns: (crudStore) => abbrev(crudStore.storedData.publisher), //if blank 'Multiple Publishers'
          ns_order: 60,
          show: true,
          show_value: (row) => property(row.publisher, 'name'),
          show_order: 40,
        },
        {
          belongsTo: ['publisher', 'publishers'],
          form: 'publisher_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="publisher"
            fieldName="publisher_id"

            form={rootStore.channel.model.crudStore.form}
            label="Publisher"
            menuItems={rootStore.uiStore.menuItems('publishers', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('publisher_id', event)}
          />),
          formOrder: 40,
          label: 'Publisher',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (
            <UccSelect
              core="tactic"
              fieldName="tactic_id"
              form={rootStore.channel.model.crudStore.form}
              label="Tactic"
              menuItems={rootStore.uiStore.menuItems('tactics', 'makePrimeChannelSpecificOption', 'channelTactics')}
              onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
            />),
          formOrder: 50,
          label: 'Tactic',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 50,
          grid_value: (row) => property(row.tactic, 'name'),
          id: 'tactic',
          label: 'Tactic',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          name: 'tactic',
          ns: (crudStore) => abbrev(crudStore.storedData.tactic),
          ns_order: 70,
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.tactic, 'name'),
          show_order: 50,
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          ns: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          ns_order: 75,
          type: 'checkbox',
          utaType: 'boolean',
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 55,
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.pivotNamestring(crudStore, 'tactic', 'targeting'),
          form: 'display_creative_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
          formOrder: 55,
          grid: true,
          grid_order: 80,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'display_creative_targetings_attributes',
          ns: (crudStore) => NameString.pivotNamestring(crudStore, 'tactic', 'targeting'),
          ns_order: 80,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "display_creative_targetings"),
          show_order: 80,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'display_creative_targetings_attributes[].id',
        },
        {
          form: 'display_creative_targetings_attributes[].display_creative_id',
        },
        {
          form: 'display_creative_targetings_attributes[].targeting_id',
        },
        {
          form: 'display_creative_targetings_attributes[].extra',
        },
        {
          form: 'display_creative_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'display_creative_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'display_creative_targetings_attributes[].on',
        },
        {
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          formField: (rootStore) => (<UccSelect
            core="geo"
            fieldName="geo_id"
            form={rootStore.channel.model.crudStore.form}
            label="Geo"
            menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
          />),
          formOrder: 100,
          label: 'Geo',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          grid_order: 100,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          label: 'Geo',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          name: 'geo',
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          ns_order: 100,
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 100,
          rules: 'integer',
        },
        {
          colSize: 15,
          domo_field: 'region',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.region),
          form: 'region',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="region"
            fieldName="region"
            form={rootStore.channel.model.crudStore.form}
            label="Region"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
          />),
          formOrder: 105,
          grid: true,
          grid_order: 105,
          grid_value: (row) => row.region,
          id: 'region',
          name: 'region',
          ns: (crudStore) => crudStore.storedData.region,
          ns_order: 102,
          label: 'Region',
          utaType: 'string',
          rules: ['string', abbrev_regex],
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.region,
          show_order: 105,
        },
        {
          belongsTo: ['language', 'languages'],
          form: 'language_id',
          copyEditable: true,
          formField: (rootStore) => (
            <UccSelect
              core="language"
              fieldName="language_id"
              form={rootStore.channel.model.crudStore.form}
              label="Language"
              menuItems={rootStore.uiStore.menuItems('languages', 'makePrimeRegularOption')}
              onChange={event => rootStore.channel.model.crudStore.storeData('language_id', event)}
            />),
          formOrder: 110,
          label: 'Language',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 110,
          grid_value: (row) => property(row.language, 'name'),
          id: 'language',
          label: 'Language',
          name: 'language',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          ns: (crudStore) => abbrev(crudStore.storedData.language),
          ns_order: 110,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 110
        },
        {
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={rootStore.uiStore.menuItems('genders', 'makePrimeChannelSpecificOption', 'channelGenders')}
            onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 120,
          label: 'Gender',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          grid_order: 120,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 120,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 120
        },
        {
          belongsTo: ['size', 'sizes'],
          form: 'size_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="size"
            fieldName="size_id"
            form={rootStore.channel.model.crudStore.form}
            label="Size"
            menuItems={rootStore.uiStore.menuItems('sizes', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('size_id', event)}
          />),
          //form still in jsx
          hideForm: true,
          label: 'Size',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'size',
          domo_value: (crudStore) => property(crudStore.storedData.size, 'name'),
          grid: true,
          grid_order: 150,
          grid_value: (row) => property(row.size, 'name'),
          id: 'size',
          label: 'Size',
          name: 'size',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.sizes,
          ns: (crudStore) => abbrev(crudStore.storedData.size),
          ns_order: 150,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.size, 'name'),
          show_order: 150
        },
        {
          label: 'Created At',
          show_value: (row) => crudStore.storedData.created_at,
          show_order: 160,
          rules: 'required|integer',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 130, 130, 'S.'),
        dateField('end_date', 'End Date', 'blank', false, 140, 140, 'E.'),
      )
    }
  ]
}


export const displayPackageModelMetadata = (channel, tabIndex) => {
  // ,---.          |
  // |---',---.,---.|__/ ,---.,---.,---.,---.
  // |    ,---||    |  \ ,---||   ||---'`---.
  // `    `---^`---'`   ``---^`---|`---'`---'
  //                          `---'
  return [
    {
      allFilterData: (apiStore) => apiStore['displayPackagesFilterOptions'],
      codename: 'DispPack',
      controller: 'display_package',
      crud: (rootStore) => rootStore.displayPackagesCrud,
      domoNsType: 'DisplayPackage',
      endpoint: 'display-packages',
      filterOptionsCall: (apiStore) => (val) => apiStore['displayPackagesFilterOptions'] = val,
      form: () => <GenericForm type="Package" datePicker={2} numButtons={3} />,
      genericModelPromiseEndpoints: [
        'audiences',
        'campaign-types',
        'campaign-type-channels',
        'creative-types',
        'publishers',
        'statuses',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.displayPackagesCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, channel_id: channel }; },
      name: 'Packages',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'packages',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'package',
      tabDisabled: () => false,
      tabIndex: tabIndex,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,

      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 11,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 5,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: (crudStore) => crudStore.channel.name + 'Package',
          rules: 'required',
        },
        {
          default: channel,
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          form: 'channel_id',
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: () => 'hmm',
          rules: 'required',
          type: 'hidden',
          utaType: 'channel_id',
        },
        {
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'created_by',
          options: (apiStore) => apiStore.Users,
          label: 'Created By',
          id: 'created_by',
          grid: true,
          grid_order: 190,
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          rules: 'required',
          utaType: 'user',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 12,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          rules: 'required',
          utaType: 'group',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          ns_order: 10,
          rules: 'required',
          utaType: 'group',
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },
        {
          colSize: 9,
          domo_field: 'ad_name',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          form: 'name',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Package Description"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 10,
          grid: true,
          grid_order: 20,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          name: 'name',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          ns_order: 30,
          rules: 'required|string',
          show: true,
          show_value: (row) => row.name,
          show_order: 10,
          utaType: 'name',
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 20,
          label: 'Campaign Type',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 12,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
          grid: true,
          grid_order: 25,
          grid_value: (row) => property(row.campaign_type, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          ns_order: 40,
          show: true,
          show_value: (row) => property(row.campaign_type, 'name'),
          show_order: 25,
        },
        {
          domo_field: 'publisher',
          domo_value: (crudStore) => property(crudStore.storedData.publisher, 'name'),
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.publisher, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.publishers,
          id: 'publisher',
          label: 'Publisher',
          name: 'publisher',
          ns: (crudStore) => abbrev(crudStore.storedData.publisher),
          ns_order: 50,
          show: true,
          show_value: (row) => property(row.publisher, 'name'),
          show_order: 30,
        },
        {
          belongsTo: ['publisher', 'publishers'],
          form: 'publisher_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="publisher"
            fieldName="publisher_id"
            form={rootStore.channel.model.crudStore.form}
            label="Publisher"
            menuItems={rootStore.uiStore.menuItems('publishers', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('publisher_id', event)}
          />),
          formOrder: 30,
          label: 'Publisher',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 100, 130, 'S.'),
        dateField('end_date', 'End Date', 'blank', false, 110, 140, 'E.'),
      ),
      // concat(() => )
    }

  ]

}

