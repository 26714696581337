
import React from 'react';
import UccButton from './UccButton';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';
import UccDoubleButton from './UccDoubleButton';
@inject('rootStore')
@observer
class UccThreeButtons extends React.Component {
  constructor(props) {
    super(props);
    this.crudStore = this.props.rootStore.channel.model.crudStore;
  }

  two_buttons(form, onCancel) {
    return (<UccDoubleButton
      form={form}
      onSubmit={(e) => {
        this.crudStore.storeData('status_id', 4);
        form.$('namestring_string').set('value', this.crudStore.namestring);
        (this.props.rootStore.channel.model.endpoint.toLowerCase() == 'ads') ? form.$('utmstring_string').set('value', this.crudStore.utmstring_string) : '';
        (this.props.rootStore.channel.model.endpoint.toLowerCase() == 'ads') ? form.$('short_utm_string').set('value', this.crudStore.short_utm_string) : '';
        form.onSubmit(e);
      }}
      onCancel={() => {
        if (!!this.crudStore.storedData?.['unique_attribute']) {
          this.crudStore.prefetchId = +form.$('id').value[form.$('id').value.length - 1] + 1;
        }
        onCancel();
      }}
    />);
  }
  three_buttons(form, onCancel) {
    return (<div className="root_3">
      <div className="cancel_3">
        <UccButton
          buttonType=''
          label='cancel'
          form={form}
          onClick={onCancel}
        />
      </div>
      <div className="predraft_3">
        <UccButton
          buttonType='draft'
          label="Save Draft"
          form={form}
          onClick={(e) => {
            e.preventDefault();
            const attr = this.crudStore.storedData?.['unique_attribute'];
            this.crudStore.storeData('status_id', '2');
            form.$('namestring_string').set('value', this.crudStore.namestring);
            if (this.props.rootStore.channel.model.endpoint.toLowerCase() == 'ads') form.$('utmstring_string').set('value', this.crudStore.utmstring_string);
            if (this.props.rootStore.channel.model.endpoint.toLowerCase() == 'ads') form.$('short_utm_string').set('value', this.crudStore.short_utm_string);
            // In BulkCreate situations, we need to modify the form to pass validation - borrow an id from one of the bulkCrudStores, and take the first unique_attribute value from the array (since validation requires an integer for these fields)
            if (!!attr) {
              form.$('id').set(this.crudStore.bulkCrudStores?.[0]?.prefetchId);
              if (typeof form.$(attr).value == 'array') {
                form.$(attr).set(Number(form.$(attr).value[0]) !== NaN ? Number(form.$(attr).value[0]) : form.$(attr).value[0]);
              } else {
                form.$(attr).set(Number(form.$(attr).value));
              }
              this.crudStore.bulkCrudStores.forEach((cs, index) => {
                cs.form.$('namestring_string').set('value', cs.namestring)
              }, this);

              // console.log('form attr on submit click', attr, form.$(attr).value);
              // console.log('form marketing id on submit click', form.$('marketing_campaign_id').value, this.crudStore.bulkCrudStores?.[0]?.storedData.marketing_campaign_id);
              if (form.fields.has('marketing_campaign_id')) form.$('marketing_campaign_id').set(Number(this.crudStore.bulkCrudStores?.[0]?.storedData.marketing_campaign_id));
              if (!this.crudStore.bulkCrudStores[0].validateForDomo()) {
                console.log('validateForDomo failed');
                return;
              }
            }
            form.onSubmit(e);
          }}
          disable={form.$('status_id').value == "Inactive" ? true : false}
        />
      </div>
      <div className="submit_3">
        <UccButton
          buttonType='submit'
          disable={this.crudStore.storedData['model_status'] == "Inactive" ? true : false}
          label="Submit"
          form={form}
          onClick={(e) => {
            e.preventDefault();
            const attr = this.crudStore.storedData?.['unique_attribute'];
            this.crudStore.storeData('status_id', 4);
            form.$('namestring_string').set('value', this.crudStore.namestring);
            if (this.props.rootStore.channel.model.endpoint.toLowerCase() == 'ads') form.$('utmstring_string').set('value', this.crudStore.utmstring_string);
            if (this.props.rootStore.channel.model.endpoint.toLowerCase() == 'ads') form.$('short_utm_string').set('value', this.crudStore.short_utm_string);
            // In BulkCreate situations, we need to modify the form to pass validation - borrow an id from one of the bulkCrudStores, and take the first unique_attribute value from the array (since validation requires an integer for these fields)
            if (!!attr) {
              form.$('id').set(this.crudStore.bulkCrudStores?.[0]?.prefetchId);
              if (typeof form.$(attr).value == 'array') {
                form.$(attr).set(Number(form.$(attr).value[0]) !== NaN ? Number(form.$(attr).value[0]) : form.$(attr).value[0]);
              } else {
                form.$(attr).set(Number(form.$(attr).value));
              }
              // console.log('form attr on submit click', attr, form.$(attr).value);
              // console.log('form marketing id on submit click', form.$('marketing_campaign_id').value, this.crudStore.bulkCrudStores?.[0]?.storedData.marketing_campaign_id);
              this.crudStore.bulkCrudStores.forEach((cs, index) => {
                cs.form.$('namestring_string').set('value', cs.namestring)
              }, this);
              if (form.fields.has('marketing_campaign_id')) form.$('marketing_campaign_id').set(Number(this.crudStore.bulkCrudStores?.[0]?.storedData.marketing_campaign_id));
              // if (form.fields.has('objective_id')) form.$('objective_id').set(Number(this.crudStore.bulkCrudStores?.[0]?.storedData.objective_id));
              // if (form.fields.has('platform_id')) form.$('platform_id').set(Number(this.crudStore.bulkCrudStores?.[0]?.storedData.platform_id));
              if (!this.crudStore.bulkCrudStores[0].validateForDomo()) {
                console.log('validateForDomo failed');
                // return;
              }
            }
            form.onSubmit(e);
          }}
        />
      </div>
    </div>
    );
  }
  render() {
    const {
      rootStore, form, onCancel
    } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const initialAction = crudStore.initialAction;
    if (this.crudStore.storedData[model.parentModel] != "" && this.crudStore.storedData[model.parentModel] != undefined) {
      if (this.crudStore.initialAction == 'bulk_create' && typeof (this.crudStore.storedData[model.parentModel]) == 'object' && typeof (this.crudStore.storedData[model.parentModel].filter) === 'function' && this.crudStore.storedData[model.parentModel].map((x) => ['Copy', 'Draft'].includes(x.status.name)).includes(true)) {
        this.crudStore.storeData('model_status', 'Inactive')
      } else if (['Draft', 'Copy'].includes(this.crudStore.storedData[model.parentModel].status.name)) {
        this.crudStore.storeData('model_status', 'Inactive')
      }
      else {
        this.crudStore.storeData('model_status', 'Active')
      }
    }
    switch (initialAction) {
      case 'new':
        return this.three_buttons(form, onCancel);
        break;
      case 'edit':
        if (this.crudStore.storedData['status'].name == 'Draft' || this.crudStore.storedData['status'].name == 'Copy' || form.$('status_id').value == 2) {
          return this.three_buttons(form, onCancel);
        } else if (this.crudStore.storedData['status'].name == 'Inactive' || form.$('status_id').value == 4) {
          return this.two_buttons(form, onCancel);
        }
        break;
      default:
        return this.three_buttons(form, onCancel);
    }
  }
}

export default UccThreeButtons;
