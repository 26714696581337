import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'mobx-router';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import UccAccountCircle from '../../../../public/images/account_circle.png';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ApiStore from '../../store/ApiStore';
import UiStore from '../../store/UiStore';
import routes from './Routes';

const styles = theme => ({
  parent: {
    color: '#495057',
    '&:hover ': {
      color: '#00000',
      backgroundColor: '#f4f5fd',
      opacity: 1,
    },
    '&:focus': {
      color: '#000000',
    },
  },
  linkDecor: {
    textDecoration: 'none',
  },
});

@inject('apiStore', 'uiStore', 'rootStore')
@observer
class SessionMenu extends React.Component {
  handleSelectUser = event => {
    const { uiStore } = this.props;
    uiStore.setProfileAnchorEl(event.currentTarget);
  };

  handleSelectProfile = () => {
    const { uiStore } = this.props;
    uiStore.setProfileAnchorEl(null);
    uiStore.router.goTo(routes.userProfile, '', this.props.uiStore);
  };
  handleSwitchToAdmin = () => {
    const { uiStore } = this.props;
    uiStore.router.goTo(routes.admin, '', this.props.uiStore);
  };
  handleSelectSignout = () => {
    const { apiStore, uiStore } = this.props;
    uiStore.setProfileAnchorEl(null);
    apiStore.signout();
    uiStore.router.goTo(routes.home, '', this.props.uiStore);
  };

  closeMenu = event => {
    this.props.uiStore.setProfileAnchorEl(null);
  };

  render() {
    const { uiStore, apiStore, rootStore, classes } = this.props;
    const adminItem = apiStore.currentUser.pll > 10 ? (
      <Link href="./app/admin" view={routes.admin} store={uiStore} key="admin" className={classes.linkDecor + ' profileAdminLink'}>
        <ListItem button onClick={this.handleSwitchToAdmin} className={classes.parent}>Admin</ListItem>
      </Link>
    ) : (
      ''
    );
    return (
      apiStore.authenticated && (
        <div>

          {/* uncomment for permissions indicator: <span style={{ color: 'black' }}>{apiStore.currentUser.pl_name} ({apiStore.currentUser.pll})</span> */}

          <IconButton
            id="user-icon"
            aria-haspopup="true"
            onClick={this.handleSelectUser}
            color="inherit"
            fontSize="large"
            style={{ marginRight: '20px' }}
          >
            <AccountCircle style={{ color: '#6c7cdc' }} fontSize="large" />
          </IconButton>
          <div className={classes.parent}>
            <Menu
              id="menu-profile"
              anchorEl={uiStore.profileAnchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={uiStore.profileMenuOpen}
              onClick={this.closeMenu}
              color="blue"
            >
              <Link
                href="./app/profile"
                view={routes.userProfile}
                store={uiStore}
                className={classes.linkDecor}
              >
                <ListItem button id="profile-link" onClick={this.handleSelectProfile} className={classes.parent}>Profile</ListItem>
              </Link>
              {adminItem}
              <ListItem button id="signout" onClick={this.handleSelectSignout} className={classes.parent}>
                Sign Out
              </ListItem>
            </Menu>
          </div>
        </div>
      )
    );
  }
}

SessionMenu.wrappedComponent.propTypes = {
  apiStore: PropTypes.instanceOf(ApiStore).isRequired,
  uiStore: PropTypes.instanceOf(UiStore).isRequired,
};

export default withStyles(styles)(SessionMenu);