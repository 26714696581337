import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'blue',
    },
    '& .MuiInputLabel-root': {
    },
    '& .MuiInputBase-inputMarginDense': {
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
        borderColor: '#495057',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'blue',
      },
    },
  },
})(TextField);

const styles = (theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  input: {
    borderRadius: 4,
    width: 435,

  },
  error: {
    color: 'red',
    margin: 0,
  },
  formControl: {
    width: '100%',
  },
  fieldsetPadding: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
});

@inject('rootStore')
@observer
class UtaTextbox extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => { };

  render() {
    const {
      classes,
      core,
      fieldName,
      form,
      label,
      rootStore,
      onInput,
    } = this.props;
    return (
      <fieldset id={`fieldset-${core}`} className={classes.fieldsetPadding}>
        {/* { CREATIVE NAME / AD DESCRIPTION } */}
        <FormControl className={classes.formControl}>
          <CssTextField
            className={classes.input}
            id={`textbox-${core}`}
            onInput={onInput}
            variant="outlined"
            {...form.$(fieldName).bind()}
            size="small"
          />
          <div className={classes.error}>{form.$(fieldName).error}</div>
        </FormControl>
      </fieldset>
    );
  }
}

UtaTextbox.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UtaTextbox);
