import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'primereact/button';
import { observer, inject } from 'mobx-react';
import routes from '../Layout/Routes';

const styles = (theme) => ({
  errorPanel: {
    backgroundColor: 'white',
    borderRadius: '40px',
    boxShadow: '0 2px 4px 3px rgba(0, 0, 0, 0.5)',
    fontSize: '32px',
    width: '737px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '100px',
    paddingTop: '50px',
    paddingBottom: '50px',
    '& h3': {
      textAlign: 'center',
      fontSize: 'inherit',
      fontWeight: '500'
    },
    '& p': {
      textAlign: 'left',
      marginLeft: '60px',
      marginRight: '60px'
    }
  }
});

@inject('uiStore', 'apiStore')
@observer
class AcknowledgeError extends React.Component {

  home() {
    this.props.apiStore.serverRequestError = null;
    this.props.uiStore.router.goTo(routes.home, '', this.props.uiStore);
  }

  render() {
    const { classes, uiStore, apiStore } = this.props;
    const router = uiStore.router;
    return (
      <div className={classes.errorPanel}>
        {this.props.children}
        <Button label="RETURN HOME" style={{width: '250px', height: '52px', marginTop: '30px', fontSize: '20px' }} className={classes.errorPanelButton} onClick={() => this.home()} />
      </div>
    )
  }
}

export default withStyles(styles)(AcknowledgeError);
