import _ from 'lodash';
import ApiStore from '../../store/ApiStore';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import dvr from 'mobx-react-form/lib/validators/DVR';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MobxReactForm from 'mobx-react-form';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import validatorjs from 'validatorjs';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import withStyles from '@material-ui/core/styles/withStyles';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';


const BootstrapButton = withStyles({
  root: {
    fontStretch: 'Bold',
    fontWeight: 900,
  },
})(Button);

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    background: theme.background,
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  button: {
    textAlign: 'right',
    marginLeft: '9px',
    width: '95%',
    backgroundColor: '#6C7CDC'
  },
  error: {
    color: 'red',
    margin: 0
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  inputLabel: {
    padding: '0px 8px',
    background: '#fff'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '95%',
    margin: theme.spacing(1),
  },
  forgotPassword: {
    paddingTop: theme.spacing(1),
  },
});


const plugins = {
  dvr: dvr(validatorjs),
};

@inject('apiStore', 'rootStore')
@observer
class SignIn extends React.Component {

  @observable showPassword = false;
  constructor(props) {
    super(props);
    const { rootStore } = this.props

    const hooks = {
      onSuccess(form) {
        const info = rootStore.apiStore.signin(form);
      },
      onError(form) { },
    };
    rootStore.apiStore.alreadyChangedPassword = false;
    rootStore.apiStore.alreadyCreatedAccount = false;
    const fields = this.makeFields();
    this.form = new MobxReactForm({ fields }, { plugins, hooks })
  }

  makeFields = () => {
    return [
      {
        name: 'email',
        label: 'Email',
        rules: 'required|string',
        type: 'email'

      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Password',
        rules: 'required|string|between:5,25',
        type: 'password'
      },
    ]
  }

  handleClickShowPassword = () => {
    this.showPassword = !this.showPassword;
    const type = this.showPassword ? 'text' : 'password';
    this.form.$('password').set('type', type);
  };


  render() {
    const { apiStore, rootStore, classes } = this.props;
    const { form } = this;

    return (

      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <div className={classes.error}>{apiStore.signinError && apiStore.signinError.response && apiStore.signinError.response.data && apiStore.signinError.response.data.error} </div>
          <form className={classes.form} onSubmit={form.onSubmit}>
            <FormControl variant="outlined" className={classes.margin, classes.textField}>
              <InputLabel className={classes.inputLabel} htmlFor="email">Email</InputLabel>
              <OutlinedInput
                id="email"
                {...form.$('email').bind()}
              />
              <div className={classes.error}>{form.$('email').error}</div>
            </FormControl>

            <FormControl variant="outlined" className={classes.margin, classes.textField}>
              <InputLabel className={classes.inputLabel} htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={event => this.handleClickShowPassword(event)}
                    >
                      {this.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                {...form.$('password').bind()}

              />
              <div className={classes.error}>{form.$('password').error}</div>
            </FormControl>

            <BootstrapButton
              type="submit"
              variant="contained"
              className={classes.button}
              color="primary"
            >
              Sign in
            </BootstrapButton>

          </form>
          <Typography className={classes.forgotPassword} ><a href="/app/forgot-password" style={{ textDecoration: 'none', color: '#0086f8' }}>Forgot Password?</a></Typography>

        </Paper>
      </main>
    );
  }
}

SignIn.wrappedComponent.propTypes = {
  apiStore: PropTypes.instanceOf(ApiStore).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
