import { Button } from 'primereact/button';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { kebabCaseToCamel } from 'store/Api.js';
import UccToolbarButton from './UccToolbarButton';

@inject('rootStore')
@observer
class UccToolbarArchive extends React.Component {
    constructor(props) {
        super(props);
        this.channel = this.props.rootStore.channel;
        this.model = this.channel.model;
    }

    @observable archiveList = '';
    @action.bound

    getArchiveCount = (e) => {
        const model = this.props.model
        model.crudStore.setToolbarAction('Archive')
        const ac = model.crudStore.selectedRows;
        this.archiveList = ReactDOMServer.renderToStaticMarkup(<ul data-qa="data_archive_ul" style={{ textAlign: 'left' }}>{(_.uniq(ac).map((c) => (<li key={c.id}>{c.name}</li>)))}</ul>);
    }

    disableArchive = () => {
        const selectedRows = _.compact(this.props.model.crudStore.selectedRows);
        if (selectedRows.length < 1) return true;
        const hasStatus = this.props.rootStore.channel.hasStatus == undefined ? (this.props.rootStore.channel.model.hasStatus == undefined ? true : this.props.rootStore.channel.model.hasStatus) : this.props.rootStore.channel.hasStatus;
        if (!hasStatus) return true;
        return (selectedRows.filter(m => m.status.name != 'Archived').length > 0) ? true : false;
        // return (this.props.model.crudStore.selectedRowIds.length < 1);
    }

    render() {
        const { rootStore, model, classes } = this.props;
        const crudStore = model.crudStore;
        const currentUser = rootStore.apiStore.currentUser;
        if (!_.isNumber(model.permArchive) || currentUser.pll < model.permArchive) return (<></>);
        if (!crudStore.storedData.filtered_status_id?.includes(rootStore.apiStore.statuses.find(s => s.name == 'Archived').id)) return (<></>);

        const numSelected = model.crudStore.selectedRows.length;
        const channel = rootStore.channel;
        const id = 'toolbar_archive';
        const disabled = (numSelected == 0) ? true : false;
        const archiveList = this.archiveList;
        return (
            <div>
                <UccToolbarButton disable={this.disableArchive()} selectedRowIds={model.crudStore.selectedRowIds} model={model} label="Unarchive" icon="pi pi-folder-open" id="toolbar_unarchive" onClick={(e) => {
                    this.getArchiveCount(e);
                    return crudStore.confirmAction(
                        crudStore.onUnarchive,
                        `<span data-qa = "data_delete" style="color:gray">Are You Sure?</span><div style="color:black;margin: 10px;"><nobr>You are about to unarchive the following</nobr> ${this.archiveList}</div>`,
                        'Confirm',
                    )
                }} />
            </div>
        );
    }
}


export default UccToolbarArchive;
