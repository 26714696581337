import React from 'react';
import ReactDOMServer from 'react-dom/server';
import RootStore from '../../store/RootStore';
import UccButton from '../PrimeReactComponent/UccButton';
import { Button } from 'primereact/button';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles, lighten } from '@material-ui/core/styles';


@observer
class UccToolbarProvision extends React.Component {
  constructor(props) {
    super(props);
  }

  dsProvision = async (rowId) => {
    await this.props.rootStore.apiStore.provisionDatasets(rowId)
  }

  render() {
    if (!this.props.model.provisionButton) return (<></>);

    return (
      <>
        <div style={{ paddingLeft: '30px' }} >
          <UccButton
            icon="pi pi-cloud"
            label={'Provision Datasets'}
            onClick={() => (this.dsProvision(this.props.model.crudStore.selectedRowId))}
            buttonType="icon"
            className="add-hover-effect"
            id="toolbar-provision"
            disable={this.props.model.crudStore.selectedRowIds.length !== 1 ? true : false}
          />
        </div>
      </>
    )
  }
}


export default UccToolbarProvision;

