import React, { Component } from 'react';
import { Checkbox } from 'primereact/checkbox';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';
import UccErrorHandling from './UccErrorHandling';

@inject('rootStore')
@observer
class UccCheckBox extends Component {
  render() {
    const {
      rootStore,
      form,
      fieldName,
      classes,
      label,
      menuItems,
      core,
      onClose,
      firstOptionLabel
    } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const finalFirstOptionLabel = _.isEmpty(this.props.firstOptionLabel) ? `Select ${label}` : this.props.firstOptionLabel
    const finalLabel = !form.$(fieldName).rules.includes('required') ? `(Optional)  ${label}` : label;
    return (
      <fieldset className="fieldset" id={`fieldset-${fieldName}`}>
        <FormControl id={`formControl-${this.props.core}`}>
          <div className="container" >
            <div style={{ display: 'inline-flex', width: '525px', }}>
              <Checkbox
                inputId={`formControl-${this.props.core}_id`}
                onChange={this.props.onChange}
                checked={crudStore.storedData[fieldName]}
                label={finalLabel}
                filter={this.props.filter}>
              </Checkbox>
              <label htmlFor={`formControl-${this.props.core}_id`} className="p-checkbox-label">{finalLabel} </label>
              <UccErrorHandling form={this.props.form} fieldName={fieldName} style={{ paddingTop: '0px' }} />
            </div>

          </div>
        </FormControl>
      </fieldset >
    );
  }
}

export default UccCheckBox;
