import CrudAdminStore from './CrudAdminStore';
import Channel from './Channel';
import { action, computed, observable } from 'mobx';


class Model {
  constructor(rootStore, channel, model, name) {
    const finalName = name ? name : model.name
    model.crudStore = new CrudAdminStore(rootStore, channel, model, finalName);
    model.blah = this.blah;
    model.addCrudStore = this.addCrudStore;
    model.setChildModel = this.setChildModel;
    if (model.sortField) {
      model.crudStore.sortField = model.sortField;
      if (model.sortOrder) {
        model.crudStore.sortOrder = model.sortOrder;
      } else {
        model.crudStore.sortOrder = 'asc';
      }
    }
    return model;
  }

  blah(name) {
    // there's a lot of places we call for 'fields' from metadata. 
    // that could be done here in the model file and then they'd be a part of the object.
    // like: nsFields = namestringFields(model.columns);
  }
  @action.bound
  addCrudStore(rootStore, channel, model, name, fake = false) {
    const cs = new CrudAdminStore(rootStore, channel, model, name, fake);
    return cs;
  }
  setChildModel(childModel) {
    this.childModel = childModel;
  }
}

export default Model;
