import React from "react";

import IconButton from "@material-ui/core/IconButton";
import { observer, inject } from 'mobx-react';
import { withStyles } from "@material-ui/core/styles";
import ContentCopyIcon from '../../icons/ContentCopy';
import Tooltip from '@material-ui/core/Tooltip';


const CopyIconButton = withStyles({
  root: {
    paddingBottom: '16px',
    display: 'inline'
  },
})(IconButton);

const styles = (theme) => ({
  disabled: {
    opacity: '30%',
  },
  enabled: {
    opacity: '55%',
  }
});

@inject("rootStore")
@observer
class CopyButton extends React.Component {
  constructor(props) {
    super(props);
  }

  handleOnClick = () => {
    this.props.rootStore.addFavorite(this.props.brandId);
  };

  render() {
    const { rootStore, classes, brandId } = this.props;
    let className = [this.props.classes.copyIcon]
    if (this.props.disabled) {
      className.push(this.props.classes.disabled)
    } else {
      className.push(this.props.classes.enabled)
    }
    return (
      <Tooltip title="Copy" placement="top-start">
        <div>
          <CopyIconButton
            className={className.join(' ')}
            aria-label="Copy"
            disabled={this.props.disabled}
            onClick={this.props.onClick}
            id="toolbar-copy"
          >
            <ContentCopyIcon />
          </CopyIconButton>
        </div>
      </Tooltip>
    );
  }
}
export default withStyles(styles)(CopyButton);
