import AdminAttrValues from '../../../../../public/images/np_list_875440_000000.svg';
import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, statusField, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');

const abbrev_regex = 'regex:/^\\w+$/';
const extra_regex = 'regex:/^[a-zA-Z0-9_]+$/';


export const attr_values = {
  name: 'Attributes & Values',
  abbrev: 'AttrValues',
  codename: 'attrValues',
  endpoint: 'attributes-values',
  icon: AdminAttrValues,
  adminChannel: true,
  showViewIcon: false,
  showFilter: true,
  subtitle: 'Add, delete, or edit Attributes & Values',
  models: [
    {
      allFilterData: (apiStore) => apiStore['audiencesFilterOptions'],
      codename: 'AdminAudiences',
      controller: 'audience',
      disableBulkEdit: true,
      endpoint: 'audiences',
      genericModelPromiseEndpoints: ['audiences', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.audiencesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Audiences',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      route: 'audiences',
      permRequest: 20,
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Audience',
      sortField: 'name',
      tabIndex: 4,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.audiencesCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Audience"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Audience',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },

    {
      allFilterData: (apiStore) => apiStore['adLocationsFilterOptions'],
      codename: 'AdminAdLocations',
      controller: 'ad_location',
      disableBulkEdit: true,
      endpoint: 'ad-locations',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['ad-locations', 'statuses'],
      modelFilter: (root) => { return {}; },
      // modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
      //   apiStore.adLocationsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      // ]),
      name: 'Ad Locations',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'ad-locations',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Ad Location',
      sortField: 'name',
      tabIndex: 1,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.adLocationsCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Creative Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Creative Type',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },


    {
      allFilterData: (apiStore) => apiStore['adTypesFilterOptions'],
      codename: 'AdminAdTypes',
      controller: 'ad_type',
      endpoint: 'ad-types',
      genericModelPromiseEndpoints: ['ad-types', 'channels', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.adTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Ad Types',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'ad-types',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Ad Type',
      sortField: 'name',
      tabIndex: 2,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.adTypesCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Ad Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Ad Type',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: false,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          belongsTo: ['channels', 'channels'],
          form: 'channel_ids',
          copyEditable: true,
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'ad_type')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 40,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',

        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['marketingCampaignsFilterOptions'],
      codename: 'AdminMarketingCampaigns',
      controller: 'marketing_campaign',
      endpoint: 'marketing-campaigns',
      genericModelPromiseEndpoints: ['brands', 'marketing-campaigns', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.marketingCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      modelFilter: (root) => { return {}; },
      name: 'Campaign Names',
      permArchive: 20,
      permCreateAndEdit: 20,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'marketing-campaigns',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Campaign Name',
      sortField: 'name',
      tabIndex: 3,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.marketingCampaignsCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Name"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Campaign Name',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },

        {
          copyEditable: true,
          belongsTo: ['brands', 'brands'],
          form: 'brand_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="brands"
            fieldName="brand_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Brands"
            menuItems={rootStore.apiStore.brands.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('brand_ids', event)}
          />),
          formOrder: 40,
          label: 'brand',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.brands.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.brands,
          id: 'brands',
          label: 'Brands',
          multiple: [],
          name: 'brands',
          rules: 'array',
          show: true,
          show_value: (row) => row.brands.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 200,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 200,
          utaType: 'status',
        },
        // {
        //   belongsTo: ['channels', 'channels'],
        //   form: 'channel_ids',
        //   formField: (rootStore) => (<UccMultiSelect
        //     core="channels"
        //     fieldName="channel_ids"
        //     form={rootStore.channel.model.crudStore.form}
        //     label="Channels"
        //     menuItems={rootStore.apiStore.channels.map((p) => ({
        //       label: p.name,
        //       value: p.id,
        //     }))}
        //     onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
        //   />),
        //   formOrder: 40,
        //   label: 'Channel',
        //   multiple: [],
        //   rules: 'required|array',
        //   utaType: 'array',
        // },
        // {
        //   grid: true,
        //   grid_order: 40,
        //   grid_value: (row) => row.channels.map(c => c.name).join(', '),
        //   filter: (dt, col) => multiSelectFilter(dt, col),
        //   options: (apiStore) => apiStore.channels,
        //   id: 'channels',
        //   label: 'Channels',
        //   name: 'channels',
        //   rules: 'array',
        //   show: true,
        //   show_value: (row) => row.channels.map(c => c.name).join(', '),
        //   show_order: 50,
        //   utaType: 'array',
        //   sortable: false
        // },
      ].concat(
        dateField(
          'start_date',
          'Start Date',
          'blank',
          false,
          100,
          130,
          'S.',
        ),
        dateField('end_date', 'End Date', 'blank', false, 110, 140, 'E.'))
    },
    {
      allFilterData: (apiStore) => apiStore['campaignTypesFilterOptions'],
      codename: 'AdminCampaignTypes',
      controller: 'campaign_type',
      endpoint: 'campaign-types',
      genericModelPromiseEndpoints: ['campaign-types', 'channels', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.campaignTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Campaign Types',
      permArchive: 60,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'campaign-types',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Campaign Type',
      sortField: 'name',
      tabIndex: 6,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.campaignTypesCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Campaign Type',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          copyEditable: true,
          belongsTo: ['channels', 'channels'],
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'campaign_type')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 40,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 60,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 60,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['creativeTypesFilterOptions'],
      codename: 'AdminCreativeTypes',
      controller: 'creative_type',
      disableBulkEdit: true,
      endpoint: 'creative-types',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['creative-types', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.creativeTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Creative Types',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'creative-types',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Creative Type',
      sortField: 'name',
      tabIndex: 7,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.creativeTypesCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Creative Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Creative Type',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['devicesFilterOptions'],
      codename: 'AdminDevices',
      controller: 'device',
      disableBulkEdit: true,
      endpoint: 'devices',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['devices', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.devicesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Devices',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'devices',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Device',
      sortField: 'name',
      tabIndex: 8,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.devicesCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Device',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['emailServicePlatformsFilterOptions'],
      codename: 'AdminEmailServicePlatforms',
      controller: 'email_service_platform',
      endpoint: 'email-service-platforms',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['email-service-platforms', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.emailServicePlatformsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Sources',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'email-service-platforms',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Source',
      sortField: 'name',
      tabIndex: 24,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.emailServicePlatformsCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Source"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Source',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['emailTypesFilterOptions'],
      codename: 'AdminEmailTypes',
      controller: 'email_type',
      disableBulkEdit: true,
      endpoint: 'email-types',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['email-types', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.emailTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Email Types',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'email-types',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Email Type',
      sortField: 'name',
      tabIndex: 9,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.emailTypesCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Email Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Email Type',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['gendersFilterOptions'],
      codename: 'AdminGenders',
      controller: 'gender',
      endpoint: 'genders',
      genericModelPromiseEndpoints: ['genders', 'channels', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.gendersCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Genders',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'genders',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Gender',
      sortField: 'name',
      tabIndex: 10,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.gendersCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Gender',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          copyEditable: true,
          belongsTo: ['channels', 'channels'],
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'gender')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 40,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 60,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 60,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['geosFilterOptions'],
      codename: 'AdminGeos',
      controller: 'geo',
      endpoint: 'geos',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['geos', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.geosCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Geos',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'geos',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Geo',
      sortField: 'position',
      tabIndex: 11,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.geosCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Geo"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Geo',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 15,
          form: 'position',
          formField: (rootStore) => (<UccInputText
            core="position"
            fieldName="position"
            form={rootStore.channel.model.crudStore.form}
            label="Position"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
          />),
          formOrder: 40,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.position,
          id: 'position',
          label: 'Position',
          name: 'position',
          rules: 'required|integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.position,
          show_order: 40,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 60,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 60,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['goalsFilterOptions'],
      codename: 'AdminGoals',
      controller: 'goal',
      disableBulkEdit: true,
      endpoint: 'goals',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['goals', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.goalsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Goals',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'goals',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Goal',
      sortField: 'name',
      tabIndex: 12,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.goalsCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Goal"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Goal',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['languagesFilterOptions'],
      codename: 'AdminLanguages',
      controller: 'language',
      disableBulkEdit: true,
      endpoint: 'languages',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['languages', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.languagesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Languages',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'languages',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Language',
      sortField: 'position',
      tabIndex: 14,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.languagesCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Language"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Language',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 15,
          form: 'position',
          formField: (rootStore) => (<UccInputText
            core="position"
            fieldName="position"
            form={rootStore.channel.model.crudStore.form}
            label="Position"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
          />),
          formOrder: 40,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.position,
          id: 'position',
          label: 'Position',
          name: 'position',
          rules: 'required|integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.position,
          show_order: 40,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 60,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 60,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['messageTypesFilterOptions'],
      codename: 'AdminMessageTypes',
      controller: 'message_type',
      endpoint: 'message-types',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['message-types', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.messageTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Primary Topics',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'message-types',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Primary Topic',
      sortField: 'name',
      tabIndex: 18,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.messageTypesCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Primary Topic"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Primary Topic',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['objectivesFilterOptions'],
      codename: 'AdminObjectives',
      controller: 'objective',
      endpoint: 'objectives',
      genericModelPromiseEndpoints: ['campaign-types', 'channels', 'objectives', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.objectivesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Objectives',
      permArchive: 60,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'objectives',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Objective',
      sortField: 'name',
      tabIndex: 16,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.objectivesCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Objective"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Objective',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          copyEditable: true,
          belongsTo: ['campaign-types', 'campaignTypes'],
          form: 'campaign_type_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="campaign_types"
            fieldName="campaign_type_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Types"
            menuItems={rootStore.apiStore.campaignTypes.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('campaign_type_ids', event)}
          />),
          formOrder: 40,
          label: 'Campaign Type',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.campaign_types.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          id: 'campaign_types',
          label: 'Campaign Types',
          multiple: [],
          name: 'campaign_types',
          rules: 'array',
          show: true,
          show_value: (row) => row.campaign_types.map(c => c.name).join(', '),
          show_order: 40,
          utaType: 'array',
          sortable: false
        },
        {
          copyEditable: true,
          belongsTo: ['channels', 'channels'],
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'objective')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 50,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 50,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 60,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 60,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['objectiveModifiersFilterOptions'],
      codename: 'AdminObjectiveModifiers',
      controller: 'objective_modifier',
      endpoint: 'objective-modifiers',
      genericModelPromiseEndpoints: ['objectives', 'objective-modifiers', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.objectiveModifiersCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Keyword Category Group',
      permArchive: 40,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      route: 'objective-modifiers',
      permRequest: 20,
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Category',
      sortField: 'name',
      tabIndex: 13,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.objectiveModifiersCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Category"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Category',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}

          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 15,
          copyEditable: true,
          belongsTo: ['objectives', 'objectives'],
          form: 'objective_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="objectives"
            fieldName="objective_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Tactics"
            menuItems={rootStore.apiStore.objectives.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_ids', event)}
          />),
          formOrder: 80,
          label: 'Tactic',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 80,
          grid_value: (row) => row.objectives.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectives,
          id: 'objectives',
          label: 'Tactics',
          multiple: [],
          name: 'objectives',
          rules: 'array',
          show: true,
          show_value: (row) => row.objectives.map(c => c.name).join(', '),
          show_order: 80,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 90,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 90,
          utaType: 'status',
        },

      ],
    },
    {
      allFilterData: (apiStore) => apiStore['adModifiersFilterOptions'],
      codename: 'AdminAdModifiers',
      controller: 'ad_modifier',
      endpoint: 'ad-modifiers',
      genericModelPromiseEndpoints: ['ad-modifiers', 'objective-modifiers', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.adModifiersCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Matchtype',
      permArchive: 40,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'ad-modifiers',
      permRequest: false,
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Category 2',
      sortField: 'name',
      tabIndex: 15,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.objectiveModifiersCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Category"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Category',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 15,
          copyEditable: true,
          belongsTo: ['objective_modifier', 'objectiveModifiers'],
          form: 'objective_modifier_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="objective_modifiers"
            fieldName="objective_modifier_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Categories"
            menuItems={rootStore.apiStore.objectiveModifiers.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_modifier_ids', event)}
          />),
          formOrder: 80,
          label: 'Objective Modifier',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 80,
          grid_value: (row) => row.objective_modifiers.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectiveModifiers,
          id: 'objective_modifiers',
          label: "Categories",
          multiple: [],
          name: 'objective_modifiers',
          rules: 'array',
          show: true,
          show_value: (row) => row.objective_modifiers.map(c => c.name).join(', '),
          show_order: 80,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 90,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 90,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['platformsFilterOptions'],
      codename: 'AdminPlatforms',
      controller: 'platform',
      endpoint: 'platforms',
      genericModelPromiseEndpoints: ['channels', 'platforms', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.platformsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Platforms',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'platforms',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Platform',
      sortField: 'name',
      tabIndex: 17,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.platformsCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Platform"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 20,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Platform',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          copyEditable: true,
          belongsTo: ['channels', 'channels'],
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'platform')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 40,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 60,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 60,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['publishersFilterOptions'],
      codename: 'AdminPublishers',
      controller: 'publisher',
      disableBulkEdit: true,
      endpoint: 'publishers',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['statuses', 'publishers'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.publishersCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Publishers',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'publishers',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Publisher',
      sortField: 'name',
      tabIndex: 19,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.publishersCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Publisher"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Publisher',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['secondaryTactics'],
      codename: 'AdminSecondaryTactics',
      controller: 'secondary_tactic',
      endpoint: 'secondary-tactics',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['secondary-tactics', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.secondaryTacticsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Secondary Tactics',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      route: 'secondary-tactics',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Secondary Tactic',
      sortField: 'name',
      tabIndex: 20,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.secondaryTacticsCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Secondary Tactic"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 20,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Secondary Tactic',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['segmentsFilterOptions'],
      codename: 'AdminSegments',
      controller: 'segment',
      endpoint: 'segments',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['segments', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.segmentsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Segments',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'segments',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Segment',
      sortField: 'name',
      tabIndex: 21,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.segmentsCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Segment"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Segment',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['sendTypesFilterOptions'],
      codename: 'AdminSendTypes',
      controller: 'send_type',
      endpoint: 'send-types',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['send-types', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.sendTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Send Types',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'send-types',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Send Type',
      sortField: 'name',
      tabIndex: 22,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.sendTypesCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Send Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Send Type',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['sizesFilterOptions'],
      codename: 'AdminSizes',
      controller: 'size',
      disableBulkEdit: true,
      endpoint: 'sizes',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['sizes', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.sizesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Sizes',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'sizes',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Size',
      sortField: 'name',
      tabIndex: 23,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.sizesCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Size"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Size',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['tacticsFilterOptions'],
      codename: 'AdminTactics',
      controller: 'tactic',
      endpoint: 'tactics',
      genericModelPromiseEndpoints: ['channels', 'tactics', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.tacticsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Tactics',
      permArchive: 60,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'tactics',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Tactic',
      sortField: 'name',
      tabIndex: 25,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.tacticsCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Tactic"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Tactic',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          copyEditable: true,
          belongsTo: ['channels', 'channels'],
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'tactic')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 40,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 60,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 60,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['targetingsFilterOptions'],
      codename: 'AdminTargetings',
      controller: 'targeting',
      endpoint: 'targetings',
      genericModelPromiseEndpoints: ['channels', 'tactics', 'targetings', 'statuses'],
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.targetingsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Targetings',
      permArchive: 60,
      permCreateAndEdit: 60,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'targetings',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Targeting',
      sortField: 'position',
      tabIndex: 26,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.targetingsCrud,
      sortField: 'position',
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Targeting"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Targeting Name',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 15,
          form: 'extra_field_type',
          formField: (rootStore) => (<UccSelect
            core="extra_field_type"
            fieldName="extra_field_type"

            form={rootStore.channel.model.crudStore.form}
            label="Extra Field Type"
            menuItems={[{ label: 'Number', value: 'number' }, { label: 'Text', value: 'text' }]}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('extra_field_type', event)}
          />),
          formOrder: 40,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.extra_field_type,
          id: 'extra_field_type',
          label: 'Extra Field Type',
          name: 'Extra Field Type',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'string',
          utaType: 'string',
          show: true,
          show_value: (row) => row.extra_field_type,
          show_order: 40,
        },
        {
          colSize: 15,
          form: 'extra_required',
          formField: (rootStore) => (<UccCheckBox
            core="extra_required"
            fieldName="extra_required"
            form={rootStore.channel.model.crudStore.form}
            label="Extra Required"
            onChange={(event) => rootStore.channel.model.crudStore.storeData('extra_required', event)}
          />),
          formOrder: 50,
          grid: true,
          grid_order: 50,
          grid_value: (row) => (row.extra_required ? 'Yes' : 'No'),
          id: 'extra_required',
          label: 'Extra Required',
          name: 'extra_required',
          type: 'checkbox',
          utaType: 'boolean',
          rules: 'boolean',
          show: true,
          show_value: (row) => (row.extra_required ? 'Yes' : 'No'),
          show_order: 50
        },
        {
          colSize: 15,
          form: 'position',
          formField: (rootStore) => (<UccInputText
            core="position"
            fieldName="position"
            form={rootStore.channel.model.crudStore.form}
            label="Position"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('position', event)}
          />),
          formOrder: 60,
          grid: true,
          grid_order: 60,
          grid_value: (row) => row.position,
          id: 'position',
          label: 'Position',
          name: 'position',
          rules: 'required|integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.position,
          show_order: 60,
        },
        {
          copyEditable: true,
          belongsTo: ['channels', 'channels'],
          form: 'channel_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="channels"
            fieldName="channel_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Channels"
            menuItems={channelSpecificOptions(rootStore, 'targeting_ids')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('channel_ids', event)}
          />),
          formOrder: 70,
          label: 'Channel',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 70,
          grid_value: (row) => row.channels.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          id: 'channels',
          label: 'Channels',
          multiple: [],
          name: 'channels',
          rules: 'array',
          show: true,
          show_value: (row) => row.channels.map(c => c.name).join(', '),
          show_order: 70,
          utaType: 'array',
          sortable: false
        },
        {
          copyEditable: true,
          belongsTo: ['tactics', 'tactics'],
          form: 'tactic_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="tactics"
            fieldName="tactic_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Tactics"
            menuItems={rootStore.apiStore.tactics.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('tactic_ids', event)}
          />),
          formOrder: 80,
          label: 'Tactic',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 80,
          grid_value: (row) => row.tactics.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          id: 'tactics',
          label: 'Tactics',
          multiple: [],
          name: 'tactics',
          rules: 'array',
          show: true,
          show_value: (row) => row.tactics.map(c => c.name).join(', '),
          show_order: 80,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 90,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 90,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['testGroupsFilterOptions'],
      codename: 'AdminTestGroups',
      controller: 'test_group',
      endpoint: 'test-groups',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['test-types', 'test-groups', 'statuses'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.testGroupsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Test Groups',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: false,
      route: 'test-groups',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Test Group',
      sortField: 'name',
      tabIndex: 27,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.testGroupsCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Test Group"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 20,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Test Group',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_link: 'draft_edit',
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          copyEditable: true,
          belongsTo: ['test_types', 'testTypes'],
          form: 'test_type_ids',
          formField: (rootStore) => (<UccMultiSelect
            core="test_types"
            fieldName="test_type_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Test Types"
            menuItems={rootStore.apiStore.testTypes.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('test_type_ids', event)}
          />),
          formOrder: 40,
          label: 'Test Type',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          colSize: 15,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.test_types.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.testTypes,
          id: 'test_types',
          label: 'Test Types',
          multiple: [],
          name: 'test_types',
          rules: 'array',
          show: true,
          show_value: (row) => row.test_types.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 60,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 60,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['testTypesFilterOptions'],
      codename: 'AdminTestTypes',
      controller: 'test_type',
      endpoint: 'test-types',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['statuses', 'test-types'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.testTypesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Test Types',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'test-types',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Test Type',
      sortField: 'name',
      tabIndex: 28,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.testTypesCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Test Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Test Type',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['wavesFilterOptions'],
      codename: 'AdminWaves',
      controller: 'wave',
      endpoint: 'waves',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['statuses', 'waves'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.wavesCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Waves',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'waves',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Wave',
      sortField: 'name',
      tabIndex: 30,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.wavesCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Wave"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Wave',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['businessUnitsFilterOptions'],
      codename: 'AdminBusinessUnits',
      controller: 'business_unit',
      endpoint: 'business-units',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['statuses', 'business-units'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.businessUnitsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Business Units',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'business-units',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'Business',
      sortField: 'name',
      tabIndex: 5,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.businessUnitsCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Business Unit"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Business Unit',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['areasFilterOptions'],
      codename: 'AdminAreas',
      controller: 'area',
      endpoint: 'areas',
      form: () => <GenericForm datePicker={2} numButtons="approve" />,
      genericModelPromiseEndpoints: ['statuses', 'areas'],
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id, area_param) => ([
        apiStore.areasCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Therapeutic Area',
      permArchive: 40,
      permCreateAndEdit: 40,
      permDelete: 90,
      permListAndView: 20,
      permRequest: 20,
      route: 'areas',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'area',
      sortField: 'name',
      tabIndex: 29,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.areasCrud,
      columns: [
        {
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          label: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 15,
          form: 'name',
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Therapeutic Area"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Therapeutic Area',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|string',
          utaType: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
        },
        {
          colSize: 15,
          form: 'abbrev',
          formField: (rootStore) => (<UccInputText
            core="abbrev"
            fieldName="abbrev"
            form={rootStore.channel.model.crudStore.form}
            label="Abbreviation"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('abbrev', event)}
            disabled={rootStore.channel.model.crudStore.initialAction != 'new'}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.abbrev,
          id: 'abbrev',
          label: 'Abbreviation',
          name: 'abbrev',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: ['required', abbrev_regex],
          utaType: 'string',
          show: true,
          show_value: (row) => row.abbrev,
          show_order: 30,
        },
        {
          colSize: 8,
          name: 'status_id',
          type: 'hidden',
          filter: (dt, col) => multiSelectFilter(dt, col),
          form: 'status_id',
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 40,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show_value: (row) => property(row.status, 'name'),
          show_order: 40,
          utaType: 'status',
        },
      ],
    },
  ]
}