import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import './PrimeStyle.css';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';



const styles = (theme) => ({
    h3utm: {
        margin: '12px 0 0 0',
        fontSize: 14,
    },
    ns: {
        margin: 0
    },
    tooltipstyle: {
        background: '#6c7cdc',
        borderRadius: '5px',
        width: '170px',
        paddingLeft: '10px',
        paddingRight: '10px',
        float: 'left',
        fontFamily: 'Avenir LT W01_35 Light1475496',
        fontSize: '16px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#ffffff',
        paddingBottom: 3,
        paddingTop: 3,
        marginTop: 11
    },
    headerContainer: {
        width: '100%',
        height: '50px',
        wordBreak: 'break-word',
    },
    divh3: {
        float: 'left',
        paddingTop: 18
    },
    h3: {
        margin: "0 10px 5px 0",
        paddingRight: '10',
        color: '#495057',
        fontSize: 16,
        fontWeight: 900,
    },
});

@inject('rootStore')
@observer
class UccDisplayNamestring extends Component {
    constructor(props) {
        super(props);
        this.onClearFilterEvent = this.onClearFilterEvent.bind(this);
    }

    onClearFilterEvent(e) {
        this.props.rootStore.channel.model.crudStore.onClearFilters();
    }

    render() {
        const {
            classes,
            rootStore,
            model,
            childIndex,
            isAds,
            id,
            string,
            titlePrefix,
            displayCopyIcon = true
        } = this.props;

        const crudStore = rootStore.channel.model.crudStore;

        return (
            <div>
                <div className={classes.headerContainer} style={{ display: 'flex' }}>
                    <div className={classes.divh3}>
                        <h3 className={classes.h3} >{_.startCase(titlePrefix)} {this.props.title}</h3>
                    </div>
                    {displayCopyIcon && <div className={classes.icon}>{crudStore.getCopytoClipboard({ id })}</div>}
                    {displayCopyIcon && (id == 'namestring' ? crudStore.copyState.setOpen : id == 'utmstring' ? crudStore.copyState.setOpen2 : crudStore.copyState.setOpen3) && <div id="copied_to_clipboard_id_utm" className={classes.tooltipstyle} style={{ marginTop: 12, marginBottom: 12 }}>{crudStore.copiedToClipboardText}</div>}
                </div>
                <span className={classes.nnss} id={id} key='yadds'>{this.props.string}</span>
            </div>
        );
    }
}
export default withStyles(styles)(UccDisplayNamestring);