import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  root: {},
  error: {
    color: 'red',
    margin: 0
  },
  formControl: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
});

@inject('rootStore')
@observer
class UtaGeneric extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => { }

  render() {
    const {
      classes,
      core,
      fieldName,
      form,
      label,
      rootStore,
      onInput
    } = this.props;
    return (
      <fieldset id={`fieldset-${core}`}>
        {/* { CREATIVE NAME / AD DESCRIPTION } */}
        <FormControl className={classes.formControl}>
          <TextField
            id={`textbox-${core}`}
            onInput={onInput}
            variant="outlined"
            {...form.$(fieldName).bind()}
          />
          <div className={classes.error}>{form.$(fieldName).error}</div>
        </FormControl>

      </fieldset >
    )
  }
}


UtaGeneric.wrappedComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UtaGeneric); {/* { CREATIVE NAME / AD DESCRIPTION } */ }
