import Affiliate from '../../icons/Affiliate';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Display from '../../icons/Display';
import Divider from '@material-ui/core/Divider';
import Email from '../../icons/Email';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PaidSearch from '../../icons/PaidSearch';
import PaidSocial from '../../icons/PaidSocial';
import Programmatic from '../../icons/Programmatic';
import React from 'react';
import routes from './Routes';
import Typography from '@material-ui/core/Typography';
import { Link } from 'mobx-router';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    width: '100%',
    margin: `0px 0 0 0px`,
    color: '#495057',
  },
  chevronicon: {
    textAlign: 'right',
    color: '#495057',
    position: 'absolute',
    right: '15px',
  },
  disabledLink: {
    pointerEvents: 'none',
    cursor: 'default',
    textDecoration: 'none',
    color: 'gray',
  },
  parent: {
    color: '#495057',
    '&:hover ': {
      backgroundColor: '#f4f5fd',
      opacity: 1,
    },
  },
});
@inject('uiStore', 'apiStore')
@observer
class ChannelMenuItem extends React.Component {
  renderDisableIcon(disabled) {
    return disabled ? 'gray' : '#495057';
  }
  renderIcon(param, disabled = false) {
    switch (param) {
      case 'Display':
        return <Display fill={this.renderDisableIcon(disabled)} />;
      case 'Email':
        return <Email fill={this.renderDisableIcon(disabled)} />;
      case 'PaidSocial':
        return <PaidSocial fill={this.renderDisableIcon(disabled)} />;
      case 'PaidSearch':
        return <PaidSearch fill={this.renderDisableIcon(disabled)} />;
      case 'Programmatic':
        return <Programmatic fill={this.renderDisableIcon(disabled)} />;
      default:
        return <Affiliate fill={this.renderDisableIcon(disabled)} />;
    }
  }

  render() {
    const { uiStore, apiStore, classes, channel } = this.props;

    const {
      router: { params },
    } = uiStore;

    const disabled = this.props.disabled; //allows storemetadata to override enabled channel
    return (
      <div id={apiStore.currentBrandId + "-" + channel.name}>
        <Link
          view={routes.channelTabs}
          params={{
            brand_path: apiStore.currentBrand.path,
            channel: channel.endpoint,
            model: channel.models[0].route,
          }}
          store={uiStore}
          className={disabled ? classes.disabledLink : classes.parent}
          style={{ textDecoration: 'none' }}
          key={apiStore.currentBrand.path}
        >
          <ListItem button className={classes.parent} key={channel.name}>
            <ListItemIcon

              className={disabled ? classes.disabledLink : ''}
              key={channel.name + "_" + apiStore.currentBrand.path}
            >
              {this.renderIcon(channel.icon, disabled)}
            </ListItemIcon>
            <ListItemText
              primary={channel.name}
              className={
                !disabled ? classes.linkColor : classes.disabledLink
              }
              disableTypography={true}
              key={channel.name}
            />
            <ChevronRightIcon
              className={(classes.chevronicon, disabled ? classes.disabledLink : '')}
              key={channel.name + "__" + apiStore.currentBrand.abbrev}
            />
          </ListItem>
          <Divider variant="inset" component="li" className={classes.root} />
        </Link>
      </div>
    );
  }
}
export default withStyles(styles)(ChannelMenuItem);
