import React from 'react';
import { observer, inject } from 'mobx-react';
import AcknowledgeError from '../lib/AcknowledgeError';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';


const styles = theme => ({
  errorPaper: {
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: 102,
    marginTop: '120px',
    paddingBottom: '120px',
    height: 'auto',
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '6px',
    textAlign: 'center'
  },


});

@inject('apiStore', 'uiStore')
@observer
class ParamNotFoundError extends React.Component {

  render() {
    const { title, message, solution, classes } = this.props;

    return (
      <AcknowledgeError>
        <h3> {title} </h3>
        <p>
          {message}
        </p>
        <p>
          {solution}
        </p>
      </AcknowledgeError>
    )
  }
}

export default withStyles(styles)(ParamNotFoundError);
