import React from 'react';
import ReactDOMServer from 'react-dom/server';
import RootStore from '../../store/RootStore';
import UccButton from '../PrimeReactComponent/UccButton';
import { Button } from 'primereact/button';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles, lighten } from '@material-ui/core/styles';


@observer
class UccToolbarTestFacebook extends React.Component {
  constructor(props) {
    super(props);
    this.testFacebook = this.testFacebook.bind(this);

  }

  testFacebook = async () => {
    // todo: get circular spinning icon.. 
    const brand_id = this.props.rootStore.channel.adminChannel ?
      this.props.model.crudStore.selectedRow?.brand?.id :
      this.props.rootStore.apiStore.currentBrand.id;

    await this.props.rootStore.channel.model.crudStore.testFacebook(brand_id)
  }

  disable = () => {
    switch (this.props.rootStore.channel.adminChannel) {
      case true:
        return this.props.model.crudStore.selectedRowIds.length !== 1 ? true : false;
        break;
      default:
        return false;
    }
  }

  render() {

    // so we wont even show the button unless on the facebook connections page in admin
    // or in the paid social channel for an enabled brand. 
    // in admin only enable if one connection is checked. 
    switch (this.props.rootStore.channel.adminChannel) {
      case true:
        if (this.props.rootStore.channel.model.codename != 'FacebookConnections') return (<></>);
        break;
      default:
        if (!this.props.rootStore.apiStore.facebookConnections.find(fc => fc.brand_id == this.props.rootStore.apiStore.currentBrandId)?.enable_fb == true || this.props.rootStore.channel.codename != 'paidSocial') return (<></>);
    }

    return (
      <>
        <div style={{ paddingLeft: '30px', paddingRight: '30px' }} >
          <UccButton
            icon="pi pi-cloud"
            label={'Test Connect'}
            onClick={() => this.testFacebook()}
            buttonType="icon"
            // className="add-hover-effect"
            id="toolbar-provision"
            disable={this.disable()}
          />
        </div>
      </>
    )
  }
}


export default UccToolbarTestFacebook;

