import React from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from "@material-ui/core/Paper";
import PropTypes, { array } from "prop-types";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import routes from "./Routes";
import _ from "lodash";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ErrorWrapper from "./ErrorWrapper";
import UiStore from "../../store/UiStore";
import apiStore from "../../store/ApiStore";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "mobx-router";
import { observer, inject } from "mobx-react";
import BrandMenuItem from "./BrandMenuItem";

const styles = theme => ({
  paper: {
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: 2,
    marginTop: '10px',
    height: 'auto',
    width: '40%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '10px'
  },
  listPadding: {
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  brand: {
    paddingLeft: "0px",
    paddingRight: theme.spacing(2),
    paddingTop: 2,
    marginTop: "60px",
    height: "auto",
    width: "40%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  makeVisiblecss: {
    visibility: "hidden"
  }
});

@inject("uiStore", "apiStore", "rootStore")
@observer
class HomePanel extends React.Component {

  componentDidMount() {
    const { rootStore, apiStore } = this.props;
    apiStore.refreshCurrentUser();
    rootStore.fetchFavoriteBrands();
  };

  render() {
    const { uiStore, apiStore, rootStore, classes } = this.props;
    const { router } = uiStore;
    const nextRoute =
      router.params && router.params["brand_id"] != null
        ? router.currentView
        : routes.brandHome;

    return (
      <ErrorWrapper>
        <Typography component='h1' variant='h1' className={classes.brand}>Brands</Typography>
        <div style={{ marginBottom: '50px' }}>
          <Paper className={classes.paper} elevation={6}>
            {apiStore.fetchingBrands && <div style={{ textAlign: 'center' }}><CircularProgress /></div>}
            <List component="nav" className={classes.listPadding} key={this.props.brand}>
              {
                apiStore.brands && apiStore.sortedBrands.map(brand => (
                  <BrandMenuItem brand={brand} key={brand.id} />
                ))
              }
            </List>
          </Paper>
        </div>

      </ErrorWrapper>
    );
  }
}

// export default HomePanel;
export default withStyles(styles)(HomePanel);
