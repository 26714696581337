import DomoLogo from '../../../../../public/images/domo_logo.png';
import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');
const abbrev_regex = 'regex:/^\\w+$/';
const extra_regex = 'regex:/^[a-zA-Z0-9_]+$/';


export const domo_integration = {
  name: 'Domo Integrations',
  abbrev: 'DomoInt',
  codename: 'domoIntegrations',
  endpoint: 'domo-integrations',
  icon: DomoLogo,
  adminChannel: true,
  showViewIcon: false,
  subtitle: 'Create or manage Domo Integrations',
  hasStatus: false,
  models: [
    {
      allFilterData: (apiStore) => apiStore['domoConnectionsFilterOptions'],
      codename: 'DomoConnections',
      controller: 'domo_connection',
      disableBulkEdit: false,
      childModelFilter: (root) => { return {}; },
      endpoint: 'domo-connections',
      form: () => <GenericForm datePicker={2} numButtons={2} />,
      genericModelPromiseEndpoints: ['brands'],
      hasStatus: false,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.domoConnectionsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      name: 'Connections',
      permCreateAndEdit: 60,
      permDelete: 60,
      permListAndView: 20,
      provisionButton: true,
      permRequest: false,
      route: 'connections',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'connection',
      sortField: 'brand',
      tabIndex: 0,
      tabLabel: (m) => m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.domoConnectionsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'id',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 8,
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.channel.model.crudStore.action == 'edit' ? rootStore.apiStore.brands.map((p) => rootStore.uiStore.makePrimeRegularOption(p)) : rootStore.apiStore.brands.filter(b => !b.domo_configured).map((p) => rootStore.uiStore.makePrimeRegularOption(p))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('brand_id', event)}
            disabled={(rootStore.channel.model.crudStore.action == 'edit')}
            value={rootStore.channel.model.crudStore.storedData['brand_id']}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => property(row.brand, 'name'),
          id: 'brand',
          label: 'Brand',
          name: 'brand',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.brands,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.brand, 'name'),
          show_order: 20,
          utaType: 'fk',
        },
        {
          colSize: 8,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.pending_namestrings_count,
          id: 'pending_namestrings_count',
          label: 'Pending',
          name: 'pending_namestrings_count',
          rules: 'integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.pending_namestrings_count,
          show_order: 30,
          sortable: false,
        },
        {
          colSize: 8,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.pushed_namestrings_count,
          id: 'pushed_namestrings_count',
          label: 'Published',
          name: 'pushed_namestrings_count',
          rules: 'integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.pushed_namestrings_count,
          show_order: 40,
          sortable: false,
        },
        {
          copyEditable: true,
          colSize: 12,
          form: 'domo_instance_url',
          formField: (rootStore) => (<UccInputText
            label="Domo Instance URL"
            core="domo_instance_url"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('domo_instance_url', event)}
            fieldName="domo_instance_url"
          />),
          formOrder: 30,
          grid: true,
          grid_order: 50,
          grid_value: (row) => <a href={row.domo_instance_url} target="_blank">{row.domo_instance_url}</a>,
          id: 'domo_instance_url',
          label: 'Instance URL',
          name: 'domo_instance_url',
          rules: 'required|string',
          show: true,
          show_value: (row) => <a href={row.domo_instance_url} target="_blank">{row.domo_instance_url}</a>,
          show_order: 50,
          sortable: false,
          utaType: 'string',
        },
        {
          form: 'domo_client_id',
          formField: (rootStore) => (<UccInputText
            label="Domo Client"
            core="domo_client_id"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('domo_client_id', event)}
            fieldName="domo_client_id"
          />),
          formOrder: 40,
          label: 'Domo Client',
          rules: 'required|string',
          type: 'hidden',
        },
        {
          form: 'domo_client_secret',
          formField: (rootStore) => (<UccInputText
            label="Domo Client Secret"
            core="domo_client_secret"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('domo_client_secret', event)}
            fieldName="domo_client_secret"
          />),
          formOrder: 50,
          label: 'Domo Client Secret',
          rules: 'required|string',
          type: 'hidden',
        },
      ],
    },
    {
      allFilterData: (apiStore) => apiStore['channelDomoDatasetsFilterOptions'],
      codename: 'DomoDatasets',
      controller: 'channel_domo_dataset',
      disableBulkEdit: true,
      endpoint: 'channel-domo-datasets',
      form: () => <GenericForm datePicker={2} numButtons={2} />,
      genericModelPromiseEndpoints: ['channels', 'channel-domo-datasets'],
      hasStatus: false,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.channelDomoDatasetsCrud.getFilterOptions({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      name: 'Datasets',
      noAddButton: true,
      noToolbar: true,
      permCreateAndEdit: 60,
      permDelete: 60,
      permListAndView: 20,
      route: 'datasets',
      permRequest: false,
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'dataset',
      sortField: 'brand',
      tabIndex: 1,
      tabLabel: (m) => m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.channelDomoDatasetsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'id',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 8,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => property(row.brand, 'name'),
          id: 'brand',
          label: 'Brand',
          name: 'brand',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.brands,
          rules: 'string',
          show: true,
          show_value: (row) => property(row.brand, 'name'),
          show_order: 20,
          utaType: 'parent',
        },
        {
          colSize: 8,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.status,
          id: 'status',
          label: 'Status',
          name: 'status',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => _.uniq(apiStore.channelDomoDatasets.map(cdd => cdd.status)),
          rules: 'string',
          show: true,
          show_value: (row) => row.status,
          show_order: 30,
          utaType: 'string',
        },
        {
          colSize: 8,
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.channel, 'name'),
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.channels,
          rules: 'string',
          show: true,
          show_value: (row) => property(row.channel, 'name'),
          show_order: 40,
          utaType: 'string',
        },
        {
          colSize: 8,
          grid: true,
          grid_order: 50,
          grid_value: (row) => row.pending_namestrings_count,
          id: 'pending_namestrings_count',
          label: 'Pending',
          name: 'pending_namestrings_count',
          rules: 'integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.pending_namestrings_count,
          show_order: 50,
          sortable: false,
        },
        {
          colSize: 8,
          grid: true,
          grid_order: 60,
          grid_value: (row) => row.pushed_namestrings_count,
          id: 'pushed_namestrings_count',
          label: 'Published',
          name: 'pushed_namestrings_count',
          rules: 'integer',
          utaType: 'integer',
          show: true,
          show_value: (row) => row.pushed_namestrings_count,
          show_order: 60,
          sortable: false,
        },
        {
          colSize: 12,
          grid: true,
          grid_order: 70,
          grid_value: (row) => <a href={`${row.brand.domo_instance_url}/datasources/${row.dataset_id}/details/overview`} target="_blank">{`${row.brand.domo_instance_url}/datasources/${row.dataset_id}/details/overview`}</a>,
          id: 'dataset_url',
          label: 'Dataset URL',
          name: 'dataset url',
          rules: 'string',
          show: true,
          show_value: (row) => <a href={`${row.brand.domo_instance_url}/datasources/${row.dataset_id}/details/overview`} target="_blank">{`${row.brand.domo_instance_url}/datasources/${row.dataset_id}/details/overview`}</a>,
          show_order: 70,
          sortable: false,
          utaType: 'string',
        },
      ].concat(dateField('updated_at', 'Last Updated', 'blank', false, 80, 80, 'U.', 80)),
    },
    {
      allFilterData: (apiStore) => apiStore['namestringsFilterOptions'],
      codename: 'DomoNamestrings',
      controller: 'namestring',
      disableBulkEdit: false,
      endpoint: 'namestrings',
      form: () => <GenericForm datePicker={2} numButtons={2} />,
      genericModelPromiseEndpoints: ['brands', 'channels', 'namestrings'],
      hasStatus: false,
      modelFilter: (root) => { return {}; },
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.namestringsCrud.getFilterOptions({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      name: 'Details',
      noAddButton: true,
      noToolbar: false,
      permCreateAndEdit: 80,
      permDelete: 60,
      permListAndView: 20,
      route: 'details',
      permRequest: false,
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'detail',
      tabIndex: 2,
      tabLabel: (m) => m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.namestringsCrud,
      sortField: 'created_at',
      sortOrder: 'desc',
      columns: [
        {
          colSize: 8,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 20,
          grid_value: (row) => row.id,
          id: 'id',
          label: 'NSID',
          name: 'id',
          show: true,
          show_order: 2,
          show_value: (row) => row.id,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 6,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 1,
          grid_value: (row) => row.nsid,
          grid_link: 'biz_resource_show',
          id: 'nsid',
          label: 'Item Id',
          name: 'nsid',
          rules: 'integer',
          show: true,
          show_value: (row) => row.nsid,
          show_order: 5,
          utaType: 'integer',
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: false,
          grid_order: 7,
          grid_value: (row) => row.namestring,
          id: 'namestring',
          label: 'Namestring String',
          name: 'namestring',
          rules: 'string',
          show: true,
          show_value: (row) => row.namestring,
          show_order: 7,
          utaType: 'string',
        },
        {
          colSize: 10,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: false,
          grid_order: 7.5,
          grid_value: (row) => row.utm_string,
          id: 'utm_string',
          label: 'UTM String',
          name: 'utm_string',
          rules: 'string',
          show: true,
          show_value: (row) => row.utm_string,
          show_order: 7.5,
          utaType: 'string',
        },
        {
          colSize: 13,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: false,
          grid_order: 7.6,
          grid_value: (row) => row.utm_string,
          id: 'short_utm_string',
          label: 'Short UTM String',
          name: 'short_utm_string',
          rules: 'string',
          show: true,
          show_value: (row) => row.utm_string,
          show_order: 7.6,
          utaType: 'string',
        },
        {
          colSize: 8,
          copyEditable: true,
          form: 'status',
          formField: (rootStore) => (<UccSelect
            core="status"
            fieldName="status"
            form={rootStore.channel.model.crudStore.form}
            label="Status"
            menuItems={[
              { label: 'domoPushed', value: 'domoPushed' },
              { label: 'Draft', value: 'Draft' },
              { label: 'Inactive', value: 'Inactive' },
              { label: 'new', value: 'new' },
              { label: 'static', value: 'static' }
            ]}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('status', event)}
            value={rootStore.channel.model.crudStore.storedData['status']}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.status,
          id: 'status',
          label: 'Status',
          name: 'status',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.status)),
          rules: 'string',
          show: true,
          show_value: (row) => row.status,
          show_order: 30,
          utaType: 'string',
        },
        {
          colSize: 8,
          copyEditable: true,
          form: 'brand',
          formField: (rootStore) => (<UccInputText
            core="brand"
            fieldName="brand"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('brand', event)}
          />),
          formOrder: 10,
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.brand,
          id: 'brand',
          label: 'Brand',
          name: 'brand',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.brand)),
          rules: 'string',
          show: true,
          show_value: (row) => row.brand,
          show_order: 40,
          utaType: 'string',
        },
        {
          colSize: 8,
          grid: true,
          grid_order: 50,
          grid_value: (row) => row.channel,
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.channel)),
          rules: 'string',
          utaType: 'string',
          show: true,
          show_value: (row) => row.channel,
          show_order: 50,
        },
        {
          colSize: 8,
          grid: true,
          grid_order: 60,
          grid_value: (row) => row.ns_type,
          id: 'ns_type',
          label: 'Type',
          name: 'ns_type',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.ns_type)),
          rules: 'string',
          utaType: 'string',
          show: true,
          show_value: (row) => row.ns_type,
          show_order: 60,
        },
        {
          colSize: 12,
          copyEditable: true,
          form: 'campaign_type',
          formField: (rootStore) => (<UccInputText
            core="campaign_type"
            fieldName="campaign_type"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('campaign_type', event)}
          />),
          formOrder: 20,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 70,
          grid_value: (row) => row.campaign_type,
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          rules: 'string',
          show: true,
          show_value: (row) => row.campaign_type,
          show_order: 70,
          utaType: 'string',
        },
        {
          colSize: 8,
          copyEditable: true,
          form: 'objective',
          formField: (rootStore) => (<UccInputText
            core="objective"
            fieldName="objective"
            form={rootStore.channel.model.crudStore.form}
            label="objective"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('objective', event)}
          />),
          formOrder: 30,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 90,
          grid_value: (row) => row.objective,
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          rules: 'string',
          show: true,
          show_value: (row) => row.objective,
          show_order: 90,
          utaType: 'string',
        },
        {
          colSize: 8,
          copyEditable: true,
          form: 'tactic',
          formField: (rootStore) => (<UccInputText
            core="tactic"
            fieldName="tactic"
            form={rootStore.channel.model.crudStore.form}
            label="tactic"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('tactic', event)}
          />),
          formOrder: 40,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 100,
          grid_value: (row) => row.tactic,
          id: 'tactic',
          label: 'Tactic',
          name: 'tactic',
          rules: 'string',
          show: true,
          show_value: (row) => row.tactic,
          show_order: 100,
          utaType: 'string',
        },
        {
          colSize: 9,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 110,
          grid_value: (row) => row.suppression,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          rules: 'string',
          show: true,
          show_value: (row) => row.suppression,
          show_order: 110,
          utaType: 'string',
        },
        {
          colSize: 8,
          copyEditable: true,
          form: 'targeting',
          formField: (rootStore) => (<UccInputText
            core="targeting"
            fieldName="targeting"
            form={rootStore.channel.model.crudStore.form}
            label="targeting"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('targeting', event)}
          />),
          formOrder: 50,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 120,
          grid_value: (row) => row.targeting,
          id: 'targeting',
          label: 'Targeting',
          name: 'targeting',
          rules: 'string',
          show: true,
          show_value: (row) => row.targeting,
          show_order: 120,
          utaType: 'string',
        },
        {
          colSize: 8,
          copyEditable: true,
          form: 'device',
          formField: (rootStore) => (<UccInputText
            core="device"
            fieldName="device"
            form={rootStore.channel.model.crudStore.form}
            label="device"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('device', event)}
          />),
          formOrder: 60,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 130,
          grid_value: (row) => row.device,
          id: 'device',
          label: 'Device',
          name: 'device',
          rules: 'string',
          show: true,
          show_value: (row) => row.device,
          show_order: 130,
          utaType: 'string',
        },
        {
          colSize: 11,
          copyEditable: true,
          form: 'ad_name',
          formField: (rootStore) => (<UccInputText
            core="ad_name"
            fieldName="ad_name"
            form={rootStore.channel.model.crudStore.form}
            label="ad_name"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('ad_name', event)}
          />),
          formOrder: 70,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 130,
          grid_value: (row) => row.ad_name,
          id: 'ad_name',
          label: 'Ad Name',
          name: 'ad_name',
          rules: 'string',
          show: true,
          show_value: (row) => row.ad_name,
          show_order: 130,
          utaType: 'string',
        },
        {
          colSize: 11,
          copyEditable: true,
          form: 'ad_type',
          formField: (rootStore) => (<UccInputText
            core="ad_type"
            fieldName="ad_type"
            form={rootStore.channel.model.crudStore.form}
            label="ad_type"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('ad_type', event)}
          />),
          formOrder: 80,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 132,
          grid_value: (row) => row.ad_type,
          id: 'ad_type',
          label: 'Ad Type',
          name: 'ad_type',
          rules: 'string',
          show: true,
          show_value: (row) => row.ad_type,
          show_order: 132,
          utaType: 'string',
        },
        {
          colSize: 8,
          copyEditable: true,
          form: 'gender',
          formField: (rootStore) => (<UccInputText
            core="gender"
            fieldName="gender"
            form={rootStore.channel.model.crudStore.form}
            label="gender"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('gender', event)}
          />),
          formOrder: 90,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 140,
          grid_value: (row) => row.gender,
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          rules: 'string',
          show: true,
          show_value: (row) => row.gender,
          show_order: 140,
          utaType: 'string',
        },
        {
          colSize: 8,
          copyEditable: true,
          form: 'geo',
          formField: (rootStore) => (<UccInputText
            core="geo"
            fieldName="geo"
            form={rootStore.channel.model.crudStore.form}
            label="geo"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('geo', event)}
          />),
          formOrder: 100,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 150,
          grid_value: (row) => row.geo,
          id: 'geo',
          label: 'Geo',
          name: 'geo',
          rules: 'string',
          show: true,
          show_value: (row) => row.geo,
          show_order: 150,
          utaType: 'string',
        },
        {
          colSize: 8,
          copyEditable: true,
          form: 'language',
          formField: (rootStore) => (<UccInputText
            core="language"
            fieldName="language"
            form={rootStore.channel.model.crudStore.form}
            label="language"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('language', event)}
          />),
          formOrder: 110,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 150,
          grid_value: (row) => row.language,
          id: 'language',
          label: 'Language',
          name: 'language',
          rules: 'string',
          show: true,
          show_value: (row) => row.language,
          show_order: 150,
          utaType: 'string',
        },
        {
          colSize: 8,
          copyEditable: true,
          form: 'platform',
          formField: (rootStore) => (<UccInputText
            core="platform"
            fieldName="platform"
            form={rootStore.channel.model.crudStore.form}
            label="platform"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('platform', event)}
          />),
          formOrder: 120,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 160,
          grid_value: (row) => row.platform,
          id: 'platform',
          label: 'Plaform',
          name: 'platform',
          rules: 'string',
          show: true,
          show_value: (row) => row.platform,
          show_order: 160,
          utaType: 'string',
        },
        {
          colSize: 8,
          copyEditable: true,
          form: 'display_types',
          formField: (rootStore) => (<UccInputText
            core="display_types"
            fieldName="display_types"
            form={rootStore.channel.model.crudStore.form}
            label="display_types"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('display_types', event)}
          />),
          formOrder: 130,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 170,
          grid_value: (row) => row.display_types,
          id: 'display_types',
          label: 'Display Types',
          name: 'display_types',
          rules: 'string',
          show: true,
          show_value: (row) => row.display_types,
          show_order: 170,
          utaType: 'string',
        },
        {
          colSize: 8,
          copyEditable: true,
          form: 'region',
          formField: (rootStore) => (<UccInputText
            core="region"
            fieldName="region"
            form={rootStore.channel.model.crudStore.form}
            label="region"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
          />),
          formOrder: 140,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 180,
          grid_value: (row) => row.region,
          id: 'region',
          label: 'Region',
          name: 'region',
          rules: 'string',
          show: true,
          show_value: (row) => row.region,
          show_order: 180,
          utaType: 'string',
        },
        {
          colSize: 8,
          grid: true,
          grid_order: 225,
          grid_value: (row) => row.created_by,
          id: 'created_by',
          label: 'Created By',
          name: 'created_by',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => _.uniq(apiStore.namestrings.map(ns => ns.created_by)),
          rules: 'string',
          utaType: 'string',
          show: true,
          show_value: (row) => row.created_by,
          show_order: 225,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 210, 210, 'S.', 210),
        dateField('end_date', 'End Date', 'blank', false, 220, 220, 'E.', 220),
        dateField('created_at', 'Date Created', 'blank', false, 230, 230, 'C.', 230)
      ),
    },
  ],
};