import React, { Component } from 'react';
import { InputNumber } from 'primereact/inputnumber';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';
import UccErrorHandling from './UccErrorHandling';

@inject('rootStore')
@observer
class UccInputNumber extends Component {
  render() {
    const {
      rootStore,
      fieldName,
      label,
      core,
      style1
    } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const finalFirstOptionLabel = _.isEmpty(this.props.firstOptionLabel) ? `Select ${label}` : this.props.firstOptionLabel

    return (
      <fieldset className="fieldset" id={`fieldset-${fieldName}`}>
        <FormControl id={`formControl-${this.props.core}`}>
          <div className="container">
            <span className="p-float-label uccItems">
              <InputNumber
                value={crudStore.storedData[fieldName]}
                onChange={this.props.onInput}
                mode="decimal"
                min={0}
                showButtons
                className={this.props.className}
                id={`textbox_id-${core}`}
                suffix=" Days"
                disabled={!this.props.disabled ? false : true}
              />
              {/* <label htmlFor={`textbox_id-${core}`}>{this.props.label}</label> */}
            </span>
            <UccErrorHandling form={this.props.form} fieldName={fieldName} />
          </div>
        </FormControl>
      </fieldset>
    );
  }
}

export default UccInputNumber;
