import React from 'react';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import routes from './Routes';

const styles = theme => ({
  parent: {
    '&:hover': {
      backgroundColor: '#F4F5FD',
    },
    '&:focus': {
      backgroundColor: '#F4F5FD',
    },
    textTransform: 'none',
    fontWeight: 'bold'
  },
  linkDecor: {
    textDecoration: 'none',
  },
  nestedItem: {
    paddingLeft: 35
  }
});

const AdminAccordionNav = inject('apiStore', 'uiStore', 'rootStore')(observer((props) => {
  const [selectedIndex, setSelectedIndex] = React.useState("");

  const handlePopoverClick = event => {
    uiStore.setAccordionAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = event => {
    uiStore.setAccordionAnchorEl(null);
  };

  const handleCollapseClick = index => {
    if (selectedIndex === index) {
      setSelectedIndex("");
    } else {
      setSelectedIndex(index);
    }
  }

  const currentPrettyPath = () => {
    const { storeMetadata: { channels } } = rootStore;
    const { router } = uiStore;
    let prettyPath = "";

    const channel = router.params['channel'] ? channels.find(c => c.endpoint == router.params['channel']) : "";
    const model = router.params['model'] ? channel.models.find(m => m.route == router.params['model']) : "";

    if (router.currentPath == '/app/admin') {
      prettyPath = 'Admin Home'
    } else if (model && channel) {
      prettyPath = channel.name + " - " + model.name;
    } else if (channel) {
      prettyPath = channel.name;
    }

    return prettyPath;
  }

  const legacyAdmin = () => {
    if (rootStore.features.legacy_admin) {
      return <ListItem key="legacy-admin" button onClick={() => uiStore.router.goTo(
        routes.legacyAdmin,
        {},
        uiStore
      ) && uiStore.setAccordionAnchorEl(null)}
        data-qa="nav-channel-legacy-admin"
        classes={{ root: classes.parent }}
      >
        <ListItemText primary='Legacy Admin' />
      </ListItem>
    }
  }

  const { uiStore, apiStore, rootStore, classes } = props;
  const id = uiStore.accordionMenuOpen ? 'simple-popover' : undefined;
  const channels = rootStore.storeMetadata.channels.filter(c => c.adminChannel);
  return (
    apiStore.authenticated && (
      <div >
        <Button aria-describedby={id} onClick={handlePopoverClick} className={classes.parent} data-qa='accordion-nav' >
          {currentPrettyPath()}
          {uiStore.accordionAnchorEl ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Popover
          id={id}
          open={uiStore.accordionMenuOpen}
          anchorEl={uiStore.accordionAnchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          fontWeight='bold'
        >
          <List component="nav">
            {channels.map((channel, index) => {
              const hasNoModels = _.isEmpty(_.compact(channel.models.map(model => model.permListAndView <= model.crudStore.rootStore.apiStore.currentUser.pll)));
              if (hasNoModels) return (<></>)
              return (
                <List key={`${channel.codename}-list`} >
                  <ListItem key={channel.codename} button onClick={() => { handleCollapseClick(index) }} data-qa={`nav-channel-${channel.endpoint}`} classes={{ root: classes.parent }}>
                    <ListItemText primary={channel.name} />
                    {index === selectedIndex ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={index === selectedIndex} timeout="auto" unmountOnExit data-qa={`navCollapse`}>
                    <List component="div" disablePadding data-qa={`navtest`} >

                      {_.sortBy(channel.models, 'name').filter(model => model.permListAndView <= model.crudStore.rootStore.apiStore.currentUser.pll).map(model => {

                        return (
                          <ListItem key={model.codename} button onClick={() => uiStore.router.goTo(
                            routes.adminChannelTabs,
                            { channel: channel.endpoint, model: model.route },
                            channel[model.codename].crudStore,
                          ) && uiStore.setAccordionAnchorEl(null)}
                            data-qa={`nav-model-${model.route}`}
                            classes={{ root: classes.parent }}
                            style={{ paddingLeft: 35 }}
                          >
                            <ListItemText primary={model.name} />
                          </ListItem>
                        )
                      })}
                    </List>
                  </Collapse>
                </List>
              )
            })}
          </List>
          {legacyAdmin()}
        </Popover>
      </div>
    )
  );
}))

export default withStyles(styles)(AdminAccordionNav);
