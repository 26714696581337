import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
// import ChannelHierarchy from '../Layout/ChannelHierarchy';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';
import React from 'react';
import routes from '../Layout/Routes';
import ShowDefinitionList from './ShowDefinitionList';
import ShowHorizontalTable from './ShowHorizontalTable';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import Paper from '@material-ui/core/Paper';
import { MenuList } from '@material-ui/core';

const styles = theme => ({
  paper: {
    marginBottom: 48,
    padding: '10px 10px 10px 30px',
    // marginLeft: '44px',
    // marginRight: '100px'
  },
  showTable: {
    tableLayout: 'fixed',
    width: '100%'
  },
  showTd: {
    textAlign: 'center'
  },
  toolbar: {
    width: '100%', textAlign: 'right', height: '30px'
  },
  root: {
    display: 'inline-block'
  },
  icons: {
    height: '30px',
    width: '30px',
    position: 'relative',
    // top: '-4px',
  },
  button: {
    textAlign: 'center',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    whiteSpace: 'nowrap',
    lineHeight: '30px',
    boxSizing: 'border-box',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontStretch: 'Bold',
    fontWeight: 900,
    width: '30px',
    height: '30px'
  },

});

@inject('rootStore')
@observer
class CrudShow extends React.Component {
  @observable closed = false;

  onClose = (event) => {
    const { rootStore, model } = this.props;
    model.crudStore.action = 'index';
    model.crudStore.setShowId();
    model.crudStore.refetchDisplayedRows = true;
    this.closed = true;
  }

  editIcon() {
    const { rootStore, classes, model } = this.props;
    return rootStore.apiStore.currentUser.pll >= model.permCreateAndEdit ?
      (<Tooltip data-qa={`${model.name}-edit`} title="Edit" placement="top-start" className={classes.toolbar} key={`${model.name}-edit`} >
        <EditIcon className={classes.icons} onClick={model.crudStore.onEdit} />
      </Tooltip>) : (<></>);
  }
  render() {
    const { rootStore, classes, model } = this.props;
    const channel = rootStore.channel;
    const columns = model.columns;
    const crudStore = model.crudStore
    const selectedRow = crudStore.selectedRow;

    if (_.isEmpty(selectedRow)) return null;
    const showFields = _.orderBy(_.filter(columns, c => (c.show)), ['show_order'], ['asc'])
    if (this.closed) {
      return (<ChannelHierarchy />);
    }
    return (
      <div style={(channel.adminChannel && model.showFormat == 'definitionList') ? { display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center' } : {}} >
        {(channel.adminChannel && model.showFormat == 'definitionList') && <Typography component='h2' variant='h2' className={classes.h2} style={{ marginBottom: 20 }}>View {Pluralize.singular(model.name)} Details</Typography>}
        <Paper data-qa={`${model.name}-details`} className={classes.paper} elevation={4} style={(channel.adminChannel && model.showFormat == 'definitionList') ? { width: 550 } : {}}>
          {(() => {
            switch (model.showFormat) {
              case 'definitionList':
                return (<ShowDefinitionList model={model} onClose={this.onClose} onEdit={crudStore.onEdit} />);
              case 'horizontalTable':
              default:
                return (<>
                  <div className={classes.toolbar}>
                    {this.editIcon()}
                    <Tooltip data-qa={`${model.name}-close`} title="close" placement="top-start" className={classes.toolbar} key={`${model.name}-close`} >
                      <CloseIcon className={classes.icons} onClick={this.onClose} />
                    </Tooltip>
                  </div >
                  <ShowHorizontalTable model={model} /></>
                );
            }
          })()}
        </Paper >
      </ div>
    );
  }
}

export default withStyles(styles)(CrudShow);



