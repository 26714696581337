const axios = require('axios')

export function kebabCaseToCamel(str) {
  return str.replace(/(\-\w|\/\w|\_\w)/g, (matches) => matches[1].toUpperCase())
}


class API {
  constructor({ url }) {
    this.url = url
    this.endpoints = {}
    this.createEntity({ name: 'ab-types' });
    this.createEntity({ name: 'ad-modifiers' });
    this.createEntity({ name: 'ad-modifier-objective-modifiers' });
    this.createEntity({ name: 'ad-sets' });
    this.createEntity({ name: 'ad-type-channels' });
    this.createEntity({ name: 'ad-types' });
    this.createEntity({ name: 'ads' });
    this.createEntity({ name: 'areas' });
    this.createEntity({ name: 'audiences' });
    this.createEntity({ name: 'business-units' });
    this.createEntity({ name: 'campaign-types' });
    this.createEntity({ name: 'creative-types' });
    this.createEntity({ name: 'brand-users' });
    this.createEntity({ name: 'brands' });
    this.createEntity({ name: 'campaign-type-channels' });
    this.createEntity({ name: 'campaign-type-objectives' });
    this.createEntity({ name: 'channels' });
    this.createEntity({ name: 'channel-domo-datasets' });
    this.createEntity({ name: 'channel-genders' });
    this.createEntity({ name: 'channel-objectives' });
    this.createEntity({ name: 'channel-platforms' });
    this.createEntity({ name: 'channel-tactics' });
    this.createEntity({ name: 'channel-targetings' });
    this.createEntity({ name: 'brand-channels' });
    this.createEntity({ name: 'brand-channel-groups' });
    this.createEntity({ name: 'devices' });
    this.createEntity({ name: 'display-ads' });
    this.createEntity({ name: 'display-creatives' });
    this.createEntity({ name: 'display-packages' });
    this.createEntity({ name: 'display-placements' });
    this.createEntity({ name: 'display-types' });
    this.createEntity({ name: 'display-type-channels' });
    this.createEntity({ name: 'domo-connections' });
    this.createEntity({ name: 'email-campaigns' });
    this.createEntity({ name: 'email-service-platforms' });
    this.createEntity({ name: 'email-types' });
    this.createEntity({ name: 'extra-field-objective-modifiers' });
    this.createEntity({ name: 'extra-fields' });
    this.createEntity({ name: 'extra-field-models' });
    this.createEntity({ name: 'facebook-connections' });
    this.createEntity({ name: 'facebook-objects' });
    this.createEntity({ name: 'platforms' });
    this.createEntity({ name: 'permission-levels' });
    this.createEntity({ name: 'optimization-goals' });
    this.createEntity({ name: 'genders' });
    this.createEntity({ name: 'geos' });
    this.createEntity({ name: 'goals' });
    this.createEntity({ name: 'groups' });
    this.createEntity({ name: 'languages' });
    this.createEntity({ name: 'looks' });
    this.createEntity({ name: 'marketing-campaigns' });
    this.createEntity({ name: 'models' });
    this.createEntity({ name: 'namestrings' });
    this.createEntity({ name: 'objective-modifier-objectives' });
    this.createEntity({ name: 'objective-modifiers' });
    this.createEntity({ name: 'objectives' });
    this.createEntity({ name: 'paid-search-ad-groups' });
    this.createEntity({ name: 'paid-search-ad-names' });
    this.createEntity({ name: 'paid-search-campaigns' });
    this.createEntity({ name: 'platforms' });
    this.createEntity({ name: 'programmatic-campaigns' });
    this.createEntity({ name: 'programmatic-insertion-orders' });
    this.createEntity({ name: 'programmatic-line-items' });
    this.createEntity({ name: 'programmatic-placements' });
    this.createEntity({ name: 'programmatic-ads' });
    this.createEntity({ name: 'programmatic-creatives' });
    this.createEntity({ name: 'publishers' });
    this.createEntity({ name: 'secondary-tactics' });
    this.createEntity({ name: 'segments' });
    this.createEntity({ name: 'sizes' });
    this.createEntity({ name: 'social-campaigns' });
    this.createEntity({ name: 'tactics' });
    this.createEntity({ name: 'tactic-targetings' });
    this.createEntity({ name: 'targetings' });
    this.createEntity({ name: 'users' });
    this.createEntity({ name: 'users/current' });
    this.createEntity({ name: 'send-types' });
    this.createEntity({ name: 'statuses' });
    this.createEntity({ name: 'test-types' });
    this.createEntity({ name: 'test-groups' });
    this.createEntity({ name: 'test-group-test-types' })
    this.createEntity({ name: 'message-types' });
    this.createEntity({ name: 'waves' });
  }
  /**
   * Create and store a single entity's endpoints
   * @param {A entity Object} entity
   */
  createEntity(entity) {
    /**
     * If there is a - in the entity.name, then change it 
     * to camelCase. E.g 
     * ```
     * myApi.createEntity({ name : 'foo-bar'})
     * myApi.endpoints.fooBar.getAll(...)
     */

    const name = kebabCaseToCamel(entity.name)
    this.endpoints[name] = this.createBasicCRUDEndpoints(entity)
  }

  createEntities(arrayOfEntity) {
    arrayOfEntity.forEach(this.createEntity.bind(this))
  }
  /**
   * Create the basic endpoints handlers for CRUD operations
   * @param {A entity Object} entity
   */
  createBasicCRUDEndpoints({ name }) {
    var endpoints = {}
    const resourceURL = `${this.url}/${name}`
    endpoints.create = (toCreate, config = {}) => axios.post(resourceURL, toCreate, config)
    endpoints.delete = ({ id }, config = {}) => axios.delete(`${resourceURL}/${id}`, config).catch((error) => serverValidationError(error))
    endpoints.getAll = (params = {}, config = {}) => axios.get(resourceURL + ".json", { params }, config)
    endpoints.getDeleteCount = (params = {}, config = {}) => axios.get(resourceURL + "/delete-count.json", { params }, config)
    endpoints.getExportCount = (params = {}, config = {}) => axios.get(resourceURL + "/count.json", { params }, config)
    endpoints.getFilterOptions = (params = {}, config = {}) => axios.get(resourceURL + "/filter-options.json", { params }, config)
    endpoints.getOne = (id, config = {}) => axios.get(`${resourceURL}/${id}.json`, config)
    endpoints.getTotal = (params = {}, config = {}) => axios.get(resourceURL + "/total.json", { params }, config)
    endpoints.patch = ({ id }, toPatch, config = {}) => axios.patch(`${resourceURL}/${id}`, toPatch, config)
    endpoints.update = (toUpdate, config = {}) => axios.put(`${resourceURL}/${toUpdate.id}`, toUpdate, config)
    return endpoints
  }

}

export default API