import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getModelUrl, getBrandedProperty, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');



export const email = {
  name: 'Email',
  endpoint: 'email',
  codename: 'email',
  abbrev: 'Email',
  icon: 'Email',
  disabled: false,
  showViewIcon: true,
  models: [
    {
      allFilterData: (apiStore) => apiStore['emailCampaignsFilterOptions'],
      codename: 'EmailCamp',
      controller: 'email_campaign',
      crud: (rootStore) => rootStore.emailCampaignsCrud,
      domoNsType: 'EmailCampaign',
      endpoint: 'email-campaigns',
      filterOptionsCall: (apiStore) => (val) => apiStore['emailCampaignsFilterOptions'] = val,
      form: () => <GenericForm type="Email" datePicker={1} numButtons={3} />,
      genericModelPromiseEndpoints: [
        'ab-types',
        'campaign-types',
        'ad-types',
        'email-service-platforms',
        'geos',
        'campaign-type-objectives',
        'channel-objectives',
        'channel-platforms',
        'channel-tactics',
        'channel-targetings',
        'tactic-targetings',
        'objectives',
        'tactics',
        'targetings',
        'campaign-type-channels',
        'channel-genders',
        'genders',
        'ad-type-channels',
        'users',
        'audiences',
        'statuses',
        'test-types',
        'test-groups',
        'segments',
        'message-types',
        'marketing-campaigns',
        'waves',
        'send-types',
        'test-group-test-types',
        'email-types',
      ],
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
        apiStore.emailCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Email',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'campaigns',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'Email',
      tabDisabled: () => false,
      tabIndex: 0,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 11,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'OLD NSID/ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 4,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 2,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'EmailCampaign',
          rules: 'required',
        },

        {
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.storedData.channel),
          ns: () => 'hmm',
          name: 'channel',
          id: 'channel',
          utaType: 'channel',
          rules: 'required',
        },
        {
          colSize: 10.5,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'created_by',
          label: 'Created By',
          id: 'created_by',
          options: (apiStore) => apiStore.Users,
          grid: true,
          grid_order: 190,
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          colSize: 9,
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 7,
          label: 'Status',
          name: 'status_id',
          options: (apiStore) => apiStore.statuses,
          id: 'status_id',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },

        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          utaType: 'group',
          rules: 'required',
        },
        {
          name: 'company',
          ns: () => 'Astellas',
          //ns_order: 10,
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          // formOrder: 25,
          hideForm: true,
          type: 'hidden',
          utaType: 'brand',
          rules: 'required',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 10,
          rules: 'required',
          utaType: 'brand',
        },
        {
          domo_field: 'branded',
          domo_value: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded')),
          name: 'branded',
          ns: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded'), 'ns'),
          ns_order: 10.2,
          rules: 'required'
        },
        {
          domo_field: 'business_unit',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
          name: 'business_unit',
          rules: 'required'
        },
        {
          domo_field: 'area',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
          name: 'area',
          rules: 'required'
        },
        {
          colSize: 10.5,
          domo_field: 'additional_field',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          form: 'name',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Description"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_value: (row) => row.name,
          id: 'name',
          name: 'name',
          label: 'Description',
          filter_placeholder: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'string',
          show: true,
          show_value: (row) => row.name,
          show_order: 20,
          utaType: 'name',
        },
        {
          domo_field: 'campaign_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 15,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Campaign Name',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 60,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.marketing_campaign, 'name'),
          show_order: 15,
          utaType: 'name',
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Name"
            menuItems={rootStore.uiStore.menuItems('marketingCampaigns', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 2,
          label: 'Campaign Name',
          rules: 'required|integer',
          utaType: 'fk',
        },

        {
          colSize: 10.5,
          domo_field: 'audience',
          domo_value: (crudStore) => property(crudStore.storedData.audience, 'name'),
          grid: true,
          grid_order: 25,
          grid_value: (row) => property(row.audience, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.audiences,
          id: 'audience',
          label: 'Audience',
          name: 'audience',
          ns: (crudStore) => abbrev(crudStore.storedData.audience),
        },
        {
          belongsTo: ['audience', 'audiences'],
          form: 'audience_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="audience"
            fieldName="audience_id"
            form={rootStore.channel.model.crudStore.form}
            label="Audience"
            menuItems={rootStore.uiStore.menuItems('audiences', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('audience_id', event)}
          />),
          formOrder: 25,
          label: 'Audience',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.audience, 'name'),
          show_order: 25,
          utaType: 'fk',
        },

        {
          belongsTo: ['segment', 'segments'],
          form: 'segment_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="segment"
            fieldName="segment_id"
            form={rootStore.channel.model.crudStore.form}
            label="Segment"
            menuItems={_.sortBy(rootStore.uiStore.menuItems('segments', 'makePrimeRegularOption'), ['label'])}
            onChange={event => rootStore.channel.model.crudStore.storeData('segment_id', event)}
          />),
          formOrder: 27.5,
          label: 'Segment',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          domo_field: 'segment',
          domo_value: (crudStore) => property(crudStore.storedData.segment, 'name'),
          grid: true,
          grid_order: 27.5,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.segments,
          grid_value: (row) => property(row.segment, 'name'),
          id: 'segment',
          label: 'Segment',
          name: 'segment',
          ns: (crudStore) => abbrev(crudStore.storedData.segment),
          ns_order: 27.5,
          show: true,
          show_value: (row) => property(row.segment, 'name'),
          show_order: 27.5,
        },
        {
          belongsTo: ['email_service_platform', 'emailServicePlatforms'],
          form: 'email_service_platform_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="email-service-platform"
            fieldName="email_service_platform_id"
            form={rootStore.channel.model.crudStore.form}
            label="Source"
            menuItems={rootStore.uiStore.menuItems('emailServicePlatforms', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('email_service_platform_id', event)}
          />),
          formOrder: 30,
          label: 'Source',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10,
          domo_field: 'platform',
          domo_value: (crudStore) => property(crudStore.storedData.email_service_platform, 'name'),
          grid: true,
          grid_order: 30,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.emailServicePlatforms,
          grid_value: (row) => property(row.email_service_platform, 'name'),
          id: 'email_service_platform',
          label: 'Source',
          name: 'email_service_platform',
          ns: (crudStore) => abbrev(crudStore.storedData.email_service_platform),
          show: true,
          show_value: (row) => property(row.email_service_platform, 'name'),
          show_order: 30,
        },
        {
          belongsTo: ['send_type', 'sendTypes'],
          form: 'send_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="send-type"
            fieldName="send_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Send Type"
            menuItems={rootStore.uiStore.menuItems('sendTypes', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('send_type_id', event)}
          />),
          formOrder: 35,
          label: 'Send Type',
          rules: 'integer|required',
          utaType: 'fk',
        },
        {
          colSize: 10,
          domo_field: 'send_type',
          domo_value: (crudStore) => property(crudStore.storedData.send_type, 'name'),
          grid: true,
          grid_order: 35,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.sendTypes,
          grid_value: (row) => property(row.send_type, 'name'),
          id: 'send_type',
          label: 'Send Type',
          name: 'send_type',
          ns: (crudStore) =>
            abbrev(crudStore.storedData.send_type),
          ns_order: 70
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)} />),
          formOrder: 40,
          label: 'Campaign Type',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 13,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
          grid: true,
          grid_order: 40,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          grid_value: (row) => property(row.campaign_type, 'name'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          show: true,
          show_value: (row) => property(row.campaign_type, 'name'),
          show_order: 40,
          // ns_order: 30,
        },
        {
          colSize: 10.5,
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 50,
          grid_value: (row) => property(row.objective, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectives,
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          ns: (crudStore) => abbrev(crudStore.storedData.objective),
        },
        {
          belongsTo: ['objective', 'objectives'],
          form: 'objective_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            form={rootStore.channel.model.crudStore.form}
            label="Objective"
            makePrimeObjectiveOption
            menuItems={rootStore.uiStore.menuItems('objectives', 'makePrimeObjectiveOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 50,
          label: 'Objective',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.campaign_type, 'name'),
          show_order: 50,
          utaType: 'fk',
        },
        {
          belongsTo: ['message_type', 'messageTypes'],
          form: 'message_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="message-type"
            fieldName="message_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Primary Topic"
            menuItems={rootStore.uiStore.menuItems('messageTypes', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('message_type_id', event)}
          />),
          formOrder: 55,
          label: 'Primary Topic',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 11,
          domo_field: 'message_type',
          domo_value: (crudStore) => property(crudStore.storedData.message_type, 'name'),
          grid: true,
          grid_order: 55,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.messageTypes,
          grid_value: (row) => property(row.message_type, 'name'),
          id: 'message_type',
          label: 'Primary Topic',
          name: 'message_type',
          ns: (crudStore) => abbrev(crudStore.storedData.message_type),
          show: true,
          show_value: (row) => property(row.message_type, 'name'),
          show_order: 55,
          ns_order: 55,
        },
        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (
            <UccSelect
              core="tactic"
              fieldName="tactic_id"
              form={rootStore.channel.model.crudStore.form}
              label="Tactic"
              menuItems={rootStore.uiStore.menuItems('tactics', 'makePrimeChannelSpecificOption', 'channelTactics')}
              onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
            />),
          formOrder: 60,
          label: 'Tactic',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 9.5,
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 60,
          grid_value: (row) => property(row.tactic, 'name'),
          id: 'tactic',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          label: 'Tactic',
          name: 'tactic',
          ns: (crudStore) => abbrev(crudStore.storedData.tactic),
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.tactic, 'name'),
          show_order: 60,
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.suppressionLabel(),
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          ns: (crudStore) => crudStore.suppressionLabel(),
          type: 'checkbox',
          utaType: 'boolean',
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 65,
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          form: 'email_campaign_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} />),
          formOrder: 80,
          grid: true,
          grid_order: 80,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'email_campaign_targetings_attributes',
          // ns: (crudStore) => NameString.targetingString(crudStore),
          // ns_order: 80,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "email_campaign_targetings"),
          show_order: 80,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'email_campaign_targetings_attributes[].id',
        },
        {
          form: 'email_campaign_targetings_attributes[].display_ad_id',
        },
        {
          form: 'email_campaign_targetings_attributes[].targeting_id',
        },
        {
          form: 'email_campaign_targetings_attributes[].extra',
        },
        {
          form: 'email_campaign_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'email_campaign_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'email_campaign_targetings_attributes[].on',
        },

        {
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="geo"
            fieldName="geo_id"

            form={rootStore.channel.model.crudStore.form}
            label="Geo"
            menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
          />),
          formOrder: 80,
          label: 'Geo',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 9.5,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          grid_order: 80,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          label: 'Geo',
          name: 'geo',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 80,
        },
        {
          belongsTo: ['email_type', 'emailTypes'],
          form: 'email_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="email-type"
            fieldName="email_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Email Type"
            menuItems={rootStore.uiStore.menuItems('emailTypes', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('email_type_id', event)}
          />),
          formOrder: 120,
          label: 'Email Type',
          rules: 'integer|required',
          utaType: 'fk',
        },
        {
          colSize: 10,
          domo_field: 'email_type',
          domo_value: (crudStore) => property(crudStore.storedData.email_type, 'name'),
          grid: true,
          grid_order: 120,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.emailTypes,
          grid_value: (row) => property(row.email_type, 'name'),
          id: 'email_type',
          label: 'Email Type',
          name: 'email_type',
          ns: (crudStore) => abbrev(crudStore.storedData.email_type),
        },
        {
          belongsTo: ['test_group', 'testGroups'],
          form: 'test_group_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="test-group"
            fieldName="test_group_id"
            form={rootStore.channel.model.crudStore.form}
            label="Test Group"
            menuItems={rootStore.uiStore.menuItems('testGroups', 'makePrimeTestGroupOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('test_group_id', event)}
          />),
          formOrder: 140,
          label: 'Test Group',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 9.5,
          domo_field: 'test_group',
          domo_value: (crudStore) => { return property(crudStore.storedData.test_group, 'name'); },
          grid: true,
          grid_order: 140,
          grid_value: (row) => property(row.test_group, 'name'),
          id: 'test_group',
          label: 'Test Group',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.testGroups,
          name: 'test_group',
          ns: (crudStore) => abbrev(crudStore.storedData.test_group),
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.test_group, 'name'),
          show_order: 140,
        },
        {
          belongsTo: ['test_type', 'testTypes'],
          callback: (crudStore, thing, selected) => crudStore.clearTestGroup(crudStore, thing, selected),
          edit_callback: (crudStore, thing, selected) => crudStore.clearTestGroup(crudStore, thing, selected),
          form: 'test_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="test-type"
            fieldName="test_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Test Type"
            menuItems={rootStore.uiStore.menuItems('testTypes', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('test_type_id', event)}
          />),
          formOrder: 130,
          label: 'Test Type',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 9.5,
          domo_field: 'test_type',
          domo_value: (crudStore) => { return property(crudStore.storedData.test_type, 'name'); },
          grid: true,
          grid_order: 130,
          grid_value: (row) => property(row.test_type, 'name'),
          id: 'test_type',
          label: 'Test Type',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.testTypes,
          name: 'test_type',
          ns: (crudStore) => abbrev(crudStore.storedData.test_type),
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.test_type, 'name'),
          show_order: 140,
        },
        {
          belongsTo: ['wave', 'waves'],
          form: 'wave_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="wave"
            fieldName="wave_id"
            form={rootStore.channel.model.crudStore.form}
            label="Wave"
            menuItems={rootStore.uiStore.menuItems('waves', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('wave_id', event)}
          />),
          formOrder: 140,
          label: 'Wave',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 6,
          domo_field: 'wave',
          domo_value: (crudStore) => { return property(crudStore.storedData.wave, 'name'); },
          grid: true,
          grid_order: 140,
          grid_value: (row) => property(row.wave, 'name'),
          id: 'wave',
          label: 'Wave',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.waves,
          name: 'wave',
          ns: (crudStore) => abbrev(crudStore.storedData.wave),
          ns_order: 80,
          rules: 'integer',
        },
        {
          colSize: 15,
          domo_field: 'veeva_document_number',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.veeva_document_number),
          form: 'veeva_document_number',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="veeva_document_number"
            fieldName="veeva_document_number"
            form={rootStore.channel.model.crudStore.form}
            label="Veeva Document Number"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('veeva_document_number', event)}
          />),
          formOrder: 150,
          grid: true,
          grid_order: 150,
          grid_value: (row) => row.veeva_document_number,
          id: 'veeva_document_number',
          name: 'veeva_document_number',
          ns: (crudStore) => crudStore.storedData.veeva_document_number,
          label: 'Veeva Document Number',
          utaType: 'string',
          rules: 'required|string',
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.veeva_document_number,
          show_order: 140,
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('send_date', 'Scheduled Date', 'blank', true, 10, 20, '', 10, 12, 'YYYYMMDD'),
      ),
    },
  ],
};