import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
// import ChannelHierarchy from '../Layout/ChannelHierarchy';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import routes from '../Layout/Routes';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import Paper from '@material-ui/core/Paper';
import { MenuList } from '@material-ui/core';

const styles = theme => ({
  paper: {
    marginBottom: 48,
    padding: '10px 10px 10px 10px',
    // marginLeft: '44px',
    // marginRight: '100px'
  },
  showTable: {
    tableLayout: 'fixed',
    width: '100%'
  },
  showTd: {
    textAlign: 'center'
  },
  toolbar: {
    width: '100%', textAlign: 'right', height: '30px'
  },
  root: {
    display: 'inline-block'
  },
  icons: {
    height: '30px',
    width: '30px',
    position: 'relative',
    // top: '-4px',
  },
  button: {
    textAlign: 'center',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    whiteSpace: 'nowrap',
    lineHeight: '30px',
    boxSizing: 'border-box',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontStretch: 'Bold',
    fontWeight: 900,
    width: '30px',
    height: '30px'
  },

});

@inject('rootStore')
@observer
class ShowHorizontalTable extends React.Component {

  render() {
    const { rootStore, classes, model } = this.props;
    const channel = rootStore.channel;
    const columns = model.columns;
    const crudStore = model.crudStore
    const selectedRow = crudStore.selectedRow;

    if (_.isEmpty(selectedRow)) return null;
    const showFields = _.orderBy(_.filter(columns, c => (c.show)), ['show_order'], ['asc'])

    return (
      <table className={classes.showTable}>
        <thead data-qa={`${model.name}-header`}>
          <tr>
            {showFields.map(m => {
              return (<th>{m.label}</th>);
            })}
          </tr>
        </thead>
        <tbody data-qa={`${model.name}-rows`}>
          <tr>
            {showFields.map(m => {
              return (<td className={classes.showTd}>{m.show_value(selectedRow)}</td>);
            })}
          </tr>
        </tbody>
      </table>
    );
  }
}

export default withStyles(styles)(ShowHorizontalTable);



