import React, { Component } from 'react';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';
import { RadioButton } from 'primereact/radiobutton';
import FormControl from '@material-ui/core/FormControl';

@inject('rootStore')
@observer
class UccRadio extends Component {
  render() {
    const { crudStore, buttons, onChange, name, disabled } = this.props;
    const finalDisabled = crudStore.radioDisabled && crudStore.radioDisabled[name] ? true : disabled;
    return (
      <fieldset className="fieldset" id={`fieldset-addition`} style={{ padding: 0 }}>
        <FormControl
          id={`formControl-addition`}
          className="p-radiobutton-box"
          style={{ margin: '30px 0', display: 'inline-flex', flexDirection: 'row', gap: '30px', }}
        >
          {buttons.map(({ label, value, checked }) => (
            <>
              <div key={value} className="field-radiobutton">
                <RadioButton
                  value={value}
                  inputId={`radio_${value}`}
                  name={name}
                  onChange={onChange}
                  checked={checked}
                  disabled={finalDisabled}
                />
                <label htmlFor={`radio_${value}`} style={{ marginLeft: '10px' }}>
                  {label}
                </label>
              </div>
            </>
          ))}
        </FormControl>
      </fieldset>
    );
  }
}

export default UccRadio;