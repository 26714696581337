
import './PrimeStyle.css';
import CrudToolbar from './../CrudAdmin/CrudToolbar';
import LinkIcon from '../../../../public/images/link-icon.png';
import IconButton from '@material-ui/core/IconButton';
import NameString from './../NameString';
import React, { Component } from 'react';
import routes from '../Layout/Routes';
import storeMetadata, { getInstanceUrl, getModelUrl, formFields, namestringFields } from '../../store/StoreMetadata';
import UccDisplayNamestring from './UccDisplayNamestring';
import UccToolTip from './UccToolTip';
import { Button } from 'primereact/button';
import { chop } from 'underscore.string';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Dialog } from 'primereact/dialog';
import { formatDate } from 'moment';
import { InputText } from 'primereact/inputtext';
import { Link } from 'mobx-router';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';

var moment = require('moment');
const styles = (theme) => ({
  h3utm: {
    margin: '12px 0 0 0',
    fontSize: 14,
  },
  ns: {
    margin: 0
  },
  tooltipstyle: {
    background: '#6c7cdc',
    borderRadius: '5px',
    width: '170px',
    paddingLeft: '10px',
    paddingRight: '10px',
    float: 'left',
    fontFamily: 'Avenir LT W01_35 Light1475496',
    fontSize: '16px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff',
    paddingBottom: 3,
    paddingTop: 3,
    marginTop: 11
  },
  headerContainer: {
    width: '100%',
    height: '50px',
    wordBreak: 'break-word',
  },
  divh3: {
    float: 'left',
    paddingTop: 18
  },
  h3: {
    margin: "0 10px 5px 0",
    paddingRight: '10',
    color: '#495057',
    fontSize: 16,
    fontWeight: 900,
  },
});

@inject('rootStore')
@observer
class UccDataTable extends Component {
  constructor(props) {
    super(props);
    const { channel, apiStore, uiStore, classes } = this.props.rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    this.rowsFromColumns = this.rowsFromColumns.bind(this);

  }
  @observable selectedCampaignTypes = [];
  @observable dialogs = [];
  @observable nameString = '';
  @observable utmString = '';
  @observable newUtmString = '';
  @observable status = '';
  @observable push_date = '';
  @observable facebook_id = '';
  @observable facebook_created_at = '';
  @observable facebook_status = '';
  @observable facebook_details = '';

  @observable created_at = '';
  renderHeader() {
    return (<CrudToolbar model={this.props.model} />);
  }
  componentDidMount = async () => {
    const { apiStore, channel } = this.props.rootStore;
    this.prevModelName = apiStore.rootStore.channel.model.name;

    this.forceUpdate();
  }

  placeholder_text() {
    return '';
  }

  @action.bound
  onFilterChange(event, filterField, clearFlag = false) {
    const crudStore = this.props.model.crudStore;
    crudStore.selectedRowIds = [];
    crudStore.selectedRows = [];
    let valu = _.isEmpty(event.value) ? event.target.value : event.value;
    if (clearFlag) valu = '';
    let column = this.props.columns.find(c => c.id == filterField)
    if (typeof column != undefined && column.nestedFilterField) filterField = column.nestedFilterField;
    crudStore.storeData('filtered_' + filterField, valu);
    crudStore.setFirst(1);
    (clearFlag == true || valu == '') ? crudStore.clearFilter(filterField) : crudStore.setFilter(filterField);
    let additionalFilter = crudStore.storedData.isChild ? this.props.rootStore.channel.model.childModelFilter(this.props.rootStore) : {};
    const is_grand_parent_field = this.props.rootStore.channel.model.columns.filter(a => a.id == filterField).map(b => b.grand_parent_field);
    if (is_grand_parent_field) {
      additionalFilter.grand_parent = typeof this.props.rootStore.channel.model.grandParentModel != "undefined" ? this.props.rootStore.channel.model.grandParentModel : this.props.rootStore.channel.model.parentModel;
      // additionalFilter.parent = typeof this.props.rootStore.channel.model.parentModel != "undefined" ? this.props.rootStore.channel.model.parentModel : '';
      additionalFilter.gp_field = is_grand_parent_field;
    }
    crudStore.setDisplayClearFilter(filterField);
    crudStore.fetchStats(additionalFilter, this.props.columns);
    this.forceUpdate();
  }

  @action.bound
  filterElement(col) {
    if (typeof col.filter == 'function') {
      return col.filter(this, col);
    }
  }


  columnStyle = (col) => {
    const len = col.header.length;
    let colsize;
    colsize = len + 1;
    colsize = colsize + 'em';
    return {
      width: colsize,
      textAlign: 'left',
      textWrap: 'none',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: '#495057',
      height: '65px'
    };
  }

  hashToDL(hash) {
    return (<dl>
      {Object.entries(hash).map(dd => {
        const dt = _.isEmpty(dd[0]) ? 'empty' : dd[0];
        return (<><dt>{dt}</dt><dd>{dd[1]}</dd></>)
      })}
    </dl>);
  }

  rowsFromColumns(columns) {
    const rows = columns.map(c => {
      const stats = this.props.model.crudStore.stats;
      // console.log(c);
      // console.log(jsp(stats));
      if (_.isEmpty(this.props.model.crudStore.stats)) return;
      const stat = stats.find(s => s.field == c.name || s.field == c.id)
      const dist = _.isEmpty(stat?.dist) ? '' : this.hashToDL(stat.dist);

      const stat_hash = _.isEmpty(stat) ?
        {
          "name": '',
          "filter": c.filter(this, c),
          "count": '',
          'dist': '',
          'avg': '',
          'max': '',
          'min': '',
        } :
        {
          "name": c.name,
          "filter": (<span style={{ height: 10 }}>{c.filter(this, c)}</span>),
          "count": stat.count,
          'dist': dist,
          'avg': stat.avg,
          'max': stat.max,
          'min': stat.min,
        }



      return { "key": c.grid_order, "data": stat_hash };
    }, this);
    return rows;
  }

  @action.bound
  columnTemplate(rowData, column) {
    const dash = <span style={{ width: '100%', display: 'inline-block' }}>&#8211;</span>;
    const brand = this.props.rootStore.apiStore.currentBrand;
    switch (column.header) {
      case 'Filter':
        return 'okok ok'
      default:

        return 'this' + column.header;
    }
  }

  render() {
    const {
      classes,
      columns,
      order,
      orderBy,
      onRequestSort,
      rootStore,
      model,
      childIndex
    } = this.props;
    const { channel, apiStore, uiStore } = rootStore;
    const crudStore = model.crudStore;
    const header = this.renderHeader();
    let rows = this.rowsFromColumns(columns);

    let cols = [
      { field: 'name', header: 'Name' },
      { field: 'filter', header: 'Filter', class: 'p-filter-column' },
      { field: 'count', header: 'Count' },
      { field: 'dist', header: 'Distribution' },
      { field: 'avg', header: 'Average' },
      { field: 'max', header: 'Max' },
      { field: 'min', header: 'Min' },
    ];

    if (_.isEmpty(crudStore.stats)) return (<></>);
    let dynamicColumns = cols.map((col, i) => {
      const colClasses = col.class ? "p-c " + col.class : "p-c"
      return <Column
        key={col.field}
        style={this.columnStyle(col)}
        field={col.field}
        header={col.header}
        className={colClasses}
      />;
    });

    return (
      <TreeTable
        value={rows}
        columnResizeMode="expand"
        resizableColumns={true}

      >
        {dynamicColumns}
      </TreeTable >
    );
  }
}

export default withStyles(styles)(UccDataTable);