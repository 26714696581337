import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';
import Moment from 'react-moment';
import UccErrorHandling from './UccErrorHandling';


@inject('rootStore')
@observer
class UccDate extends Component {
  render() {
    var moment = require('moment');
    const { rootStore, form, fieldName, label, core, newFinalLabel } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const finalFirstOptionLabel = _.isEmpty(this.props.firstOptionLabel) ? `Select ${label}` : this.props.firstOptionLabel;
    const finalLabel = newFinalLabel ? newFinalLabel : !form.$(fieldName).rules.includes('required') ? `(Opt.) ` + label : label;
    const date = (crudStore.storedData[fieldName] == null || crudStore.storedData[fieldName] == 0) ? '' : crudStore.storedData[fieldName];
    const isError = (!!(form.$(fieldName).error) && (date.toString() === '' || date.toString() === '0') ? false : true)
    const isStartGreater = _.isEmpty(crudStore.storedData['end_date']) ? false : crudStore.storedData['end_date'].toString() != '0' && (crudStore.storedData['start_date'] > crudStore.storedData['end_date']) ? true : false;
    const value = date == 0 ? null : moment(date)._d
    return (
      <fieldset className="fieldset-datefield" id={`fieldset-${fieldName}`}>
        <FormControl id={`formControl-${this.props.core}`}>
          <div className="container">
            <span className="p-float-label one-calendar">
              <Calendar
                type="text"
                id={`textbox_id-${core}`}
                value={value}
                onChange={this.props.onInput}
                inputClassName={isError && !isStartGreater ? '' : 'error-btn'}
                inputStyle={(isError && !isStartGreater) ? { width: '172px' } : { width: '172px', borderTop: '2px solid #FF5656', borderLeft: '2px solid #FF5656', borderBottom: '2px solid #FF5656' }}
                showIcon={true}
                showButtonBar={true}
                todayButtonClassName='today'
                className='calendar-btn'
                onClearButtonClick={this.props.onClear}
              />
              <label htmlFor={`textbox_id-${core}`}>{finalLabel}</label>
              <UccErrorHandling form={this.props.form} fieldName={fieldName} />
            </span>
          </div>
        </FormControl>
      </fieldset >
    );
  }
}

export default UccDate;
