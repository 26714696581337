import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  tableSortLabel: {
    fontWeight: 'bold',
    color: '#495057',
  },
});

const CrudTableHead = (props => {
  const {
    classes, columns, order, orderBy, numSelected, rowCount, onRequestSort, onSelectAll,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow key={'table_header_key'} >
        <TableCell padding="checkbox">
          <Checkbox
            checked={numSelected === rowCount}
            onChange={onSelectAll}
            inputProps={{ 'aria-label': 'Select all campaigns' }}
          />
        </TableCell>
        {columns.map(col => (
          <TableCell
            key={`${col.id}_header_key`}
            align='left'
            padding='default'
            sortDirection={orderBy === col.id ? order : false}
          >
            <TableSortLabel
              className={classes.tableSortLabel}
              active={orderBy === col.id}
              direction={order}
              onClick={createSortHandler(col.id)}
            >
              {!!col.grid_label ? col.grid_label : col.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

CrudTableHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CrudTableHead);
