import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './PrimeStyle.css';
import { InputNumber } from 'primereact/inputnumber';
import { withStyles } from '@material-ui/core/styles';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import { Dialog } from 'primereact/dialog';
import BackButton from '../../../../public/images/back_button.svg';

const styles = theme => ({
  bold: {
    fontSize: '20px',
    fontWeight: '800',
    fontFamily: 'Avenir LT W01_95 Black1475556',
  },
  italic: {
    fontWeight: '300',
    fontSize: '20px',
    fontStyle: 'italic',
    fontFamily: 'Avenir LT W01_65 Medium1475532'
  }
})

class UccDialogBox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, rootStore } = this.props;
    const crudStore = rootStore.channel.model.crudStore;
    const title = this.props.title
    const subtitle = this.props.subtitle
    const numberofCopies = crudStore.numberofCopies * (title == 'Preview' ? crudStore.copiedPredraftRecords.length : crudStore.selectedRowIds.length);
    return (
      <div data-qa={this.props.data_qa} style={{ textAlign: 'center' }}>
        <div className={classes.bold} style={{ color: '#4C5059' }}>
          {title}
        </div>
        <span className={classes.italic} style={{ fontWeight: 300, color: '#6c7cdc' }}>{numberofCopies}</span>
        <span className={classes.italic} style={{ fontWeight: 300, color: '#4C5059' }}> {subtitle}</span>
      </div>
    );
  }
}

export default withStyles(styles)(UccDialogBox);