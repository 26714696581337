import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getBrandedProperty, getInstanceUrl, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');



export const paid_search = {
  name: 'Paid Search',
  endpoint: 'paid-search',
  codename: 'paidSearch',
  abbrev: 'PaidSearch',
  icon: 'PaidSearch',
  disabled: false,
  invisible: false,
  showViewIcon: true,
  models: [
    {
      allFilterData: (apiStore) => apiStore['paidSearchCampaignsFilterOptions'],
      codename: 'PaidSearchCamp',
      controller: 'paid_search_campaign',
      crud: (rootStore) => rootStore.paidSearchCampaignsCrud,
      domoNsType: 'PaidSearchCampaign',
      dontDisplayDate: true,
      endpoint: 'paid-search-campaigns',
      filterOptionsCall: (apiStore) => (val) => apiStore['paidSearchCampaignsFilterOptions'] = val,
      form: () => <GenericForm type="Campaign" numButtons={3} />,
      genericModelPromiseEndpoints: [
        'areas',
        'audiences',
        'business-units',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-objectives',
        'channel-platforms',
        'devices',
        'extra-field-objective-modifiers',
        'geos',
        'goals',
        'languages',
        'objectives',
        'objective-modifiers',
        'objective-modifier-objectives',
        'platforms',
        'secondary-tactics',
        'segments',
        'statuses',
        'users',
      ],
      hasChildren: true,
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.paidSearchCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.extraFieldsCrud.getAll({ model_id: '9' }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Paid Search Campaigns',
      name: 'Campaigns',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'campaigns',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'campaign',
      tabData: (apiStore) => apiStore.genericTabPromiseResults(),
      tabPromiseEndpoints: (rootStore, brand_param) => {
        const allFilter = _.merge(
          brand_param,
          rootStore.paidSearch.PaidSearchAdGroup.modelFilter(rootStore),
        );
        return [
          rootStore.paidSearchCampaignsCrud.getTotal(brand_param),
          rootStore.paidSearchAdGroupsCrud.getTotal(allFilter, ''),
        ];
      },
      tabDisabled: () => false,
      tabIndex: 0,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 8,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'OLD NSID/ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 4,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          colSize: 9,
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 7,
          label: 'Status',
          name: 'status_id',
          options: (apiStore) => apiStore.statuses,
          id: 'status_id',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 7,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 2,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'PaidSearchCampaign',
          rules: 'required',
        },

        {
          domo_field: 'channel',
          domo_value: () => 'PaidSearch',
          ns: () => 'PaidSearch',
          name: 'channel',
          id: 'channel',
          utaType: 'channel',
          rules: 'required',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          utaType: 'group',
          rules: 'required',
        },
        {
          name: 'company',
          ns: () => 'Astellas',
          ns_order: 5,
        },
        {
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          name: 'brand_id',
          ns: () => 'brand_id',
          type: 'hidden',
          utaType: 'brand',
          rules: 'required',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 10,
          rules: 'required',
          utaType: 'brand',
        },
        {
          domo_field: 'branded',
          domo_value: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded')),
          name: 'branded',
          ns: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded'), 'ns'),
          ns_order: 10.2,
          rules: 'required'
        },
        {
          domo_field: 'business_unit',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
          name: 'business_unit',
          rules: 'required'
        },
        {
          domo_field: 'area',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
          name: 'area',
          rules: 'required'
        },
        {
          belongsTo: ['audience', 'audiences'],
          form: 'audience_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="audience"
            fieldName="audience_id"
            form={rootStore.channel.model.crudStore.form}
            label="Audience"
            menuItems={rootStore.uiStore.menuItems('audiences', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('audience_id', event)}
          />),
          formOrder: 15,
          label: 'Audience',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          ns: (crudStore) => 'Search',
          ns_order: 43,
        },
        {
          colSize: 10.5,
          domo_field: 'audience',
          domo_value: (crudStore) => property(crudStore.storedData.audience, 'name'),
          grid: true,
          grid_order: 15,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.audiences,
          grid_value: (row) => property(row.audience, 'name'),
          id: 'audience',
          label: 'Audience',
          name: 'audience',
          ns: (crudStore) => plusSigns([abbrev(crudStore.storedData.audience), abbrev(crudStore.storedData.segment)]),
          ns_order: 47,
          show: true,
          show_value: (row) => property(row.audience, 'name'),
          show_order: 15,
        },
        {
          belongsTo: ['segment', 'segments'],
          form: 'segment_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="segment"
            fieldName="segment_id"
            form={rootStore.channel.model.crudStore.form}
            label="Segment"
            menuItems={rootStore.uiStore.menuItems('segments', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('segment_id', event)}
          />),
          formOrder: 17,
          label: 'Segment',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          domo_field: 'segment',
          domo_value: (crudStore) => property(crudStore.storedData.segment, 'name'),
          grid: true,
          grid_order: 15,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.segments,
          grid_value: (row) => property(row.segment, 'name'),
          id: 'segment',
          label: 'Segment',
          name: 'segment',
          ns: (crudStore) => abbrev(crudStore.storedData.segment),
          show: true,
          show_value: (row) => property(row.segment, 'name'),
          show_order: 17,
          // ns_order: 15,
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('campaign_type_id', event)}
          />),
          formOrder: 20,
          name: 'campaign_type_id',
          label: 'Campaign Type',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 11,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          filter: (dt, col) => multiSelectFilter(dt, col),
          filter_placeholder: 'Type',
          grid: true,
          grid_order: 20,
          grid_value: (row) => property(row.campaign_type, 'name'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          ns_order: 60,
          options: (apiStore) => apiStore.campaignTypes,
          show: true,
          show_value: (row) => property(row.campaign_type, 'name'),
          show_order: 20,
        },
        {
          belongsTo: ['platform', 'platforms'],
          form: 'platform_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="platform"
            fieldName="platform_id"
            label="Platform"
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.menuItems('platforms', 'makePrimeChannelSpecificOption', 'channelPlatforms')}
            filter={false}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
          />),
          formOrder: 40,
          label: 'Platform',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 10,
          domo_field: 'platform',
          domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.platform, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.platforms,
          id: 'platform',
          label: 'Platform',
          name: 'platform',
          ns: (crudStore) => abbrev(crudStore.storedData.platform),
          show: true,
          show_value: (row) => property(row.platform, 'name'),
          show_order: 40,
          ns_order: 40,
        },
        {
          belongsTo: ['objective', 'objectives'],
          callback: (crudStore, thing, selected) => crudStore.clearObjectiveModifier(crudStore, thing, selected),
          form: 'objective_id',
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            form={rootStore.channel.model.crudStore.form}
            label="Tactic"
            menuItems={rootStore.uiStore.menuItems('objectives', 'makePrimeObjectiveOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 50,
          label: 'Tactic',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 9,
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 50,
          grid_value: (row) => property(row.objective, 'name'),
          id: 'objective',
          label: 'Tactic',
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'objective',
          ns: (crudStore) => plusSigns([abbrev(crudStore.storedData.objective), abbrev(crudStore.storedData.secondary_tactic)]),
          ns_order: 50,
          rules: 'required',
          options: (apiStore) => apiStore.objectives,
          show: true,
          show_value: (row) => property(row.objective, 'name'),
          show_order: 20,
        },
        {
          belongsTo: ['secondary_tactic', 'secondaryTactics'],
          callback: (crudStore, thing, selected) => crudStore.clearObjectiveModifier(crudStore, thing, selected),
          form: 'secondary_tactic_id',
          formField: (rootStore) => (<UccSelect
            core="secondary-tactic"
            fieldName="secondary_tactic_id"
            form={rootStore.channel.model.crudStore.form}
            label="Secondary Tactic"
            menuItems={rootStore.uiStore.menuItems('secondaryTactics', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('secondary_tactic_id', event)}
          />),
          formOrder: 50,
          label: 'Secondary Tactic',
          rules: 'integer',
          utaType: 'fk',
        },

        {
          colSize: 13.5,
          domo_field: 'secondary_tactic',
          domo_value: (crudStore) => property(crudStore.storedData.secondary_tactic, 'name'),
          grid: true,
          grid_order: 50,
          grid_value: (row) => property(row.secondary_tactic, 'name'),
          id: 'secondary_tactic',
          label: 'Secondary Tactic',
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'secondary_tactic',
          ns: (crudStore) => abbrev(crudStore.storedData.secondary_tactic),
          // ns_order: 50,
          options: (apiStore) => apiStore.secondaryTactics,
          show: true,
          show_value: (row) => property(row.secondary_tactic, 'name'),
          show_order: 50,
        },
        {
          colSize: 9.5,
          contrivance: (crudStore) => objectiveModifierNs('campaign', crudStore.storedData),
          domo_field: 'objective_modifier',
          domo_value: (crudStore) => objectiveModifierNs('campaign', crudStore.storedData),
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'obj_mod_string',
          grid: true,
          grid_order: 60,
          grid_value: (row) => row.obj_mod_string,
          id: 'obj_mod_string',
          label: 'Category',
          name: 'objective_modifier',
          ns: (crudStore) => objectiveModifierNs('campaign', crudStore.storedData),
          // ns_order: 60,
          options: (apiStore) => apiStore.objectiveModifiers,
          placeholder: 'Objective Modifier',
          show: true,
          show_value: (row) => row.obj_mod_string,
          show_order: 60,
          type: 'hidden',
        },
        // {
        //   form: 'category',
        //   formField: (rootStore) => (<UccInputText
        //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'category')}
        //     core="category"
        //     fieldName="category"
        //     form={rootStore.channel.model.crudStore.form}
        //     label="Category"
        //     onInput={(event) => rootStore.channel.model.crudStore.storeData('category', event)}
        //   // className={classes.modifierField}
        //   />),
        //   formOrder: 64,
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   grid_value: (row) => row.category,
        //   id: 'category',
        //   label: 'Category',
        //   name: 'category',
        //   ns: (crudStore) => crudStore.storedData.category,
        //   rules: 'string',
        //   show: true,
        //   show_value: (row) => row.category,
        //   show_order: 64,
        //   utaType: 'text',
        // },

        {
          colSize: 10,
          copyEditable: true,
          domo_field: 'funnel_segment',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.funnel_segment),
          form: 'funnel_segment',
          formField: (rootStore) => (<UccInputText
            label="Funnel Segment"
            core="funnel_segment"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('funnel_segment', event)}
            fieldName="funnel_segment"
          />),
          formOrder: 80,
          grid: true,
          grid_order: 80,
          grid_value: (row) => row.funnel_segment,
          grid_label: 'Funnel Segment',
          filter_placeholder: 'Funnel Segment',
          id: 'funnel_segment',
          name: 'funnel_segment',
          filter: (dt, col) => inputTextFilter(dt, col),
          label: 'Funnel Segment',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.funnel_segment),
          ns_order: 55,
          utaType: 'string',
          rules: 'string',
          show: true,
          show_value: (row) => row.funnel_segment,
          show_order: 80,
        },

        {
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="geo"
            fieldName="geo_id"
            form={rootStore.channel.model.crudStore.form}
            label="Geo"
            // menuItems={apiStore.geos.map((p) => ({ label: p.name, value: p.id }))}
            menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('geo_id', event)}
          />),
          formOrder: 90,
          label: 'Geos',
          rules: 'integer',

          utaType: 'fk',
        },
        {
          colSize: 8.5,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          grid_order: 90,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          label: 'Geo',
          name: 'geo',
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          rules: 'integer',
          sortable: true,
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 90,
          utaType: 'geos'
        },
        {
          belongsTo: ['language', 'languages'],
          form: 'language_id',
          copyEditable: true,
          formField: (rootStore) => (
            <UccSelect
              core="language"
              fieldName="language_id"
              form={rootStore.channel.model.crudStore.form}
              label="Language"
              menuItems={rootStore.uiStore.menuItems('languages', 'makePrimeRegularOption')}
              onChange={(event) => rootStore.channel.model.crudStore.storeData('language_id', event)}
            />),
          formOrder: 100,
          label: 'Language',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 100,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          grid_value: (row) => property(row.language, 'name'),
          id: 'language',
          label: 'Language',
          name: 'language',
          ns: (crudStore) => abbrev(crudStore.storedData.language),
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 100,
          utaType: 'languages'
        },
        {
          colSize: 11,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 150,
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          label: 'Created By',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.Users,
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ],
    },

    //   _____       _      _____                                 
    //  (_____)     (_)    (_____)  _                        ____ 
    // (_)___(_)  __(_)   (_)  ___ (_)__  ___   _   _  ____ (____)
    // (_______) (____)   (_) (___)(____)(___) (_) (_)(____)(_)__ 
    // (_)   (_)(_)_(_)   (_)___(_)(_)  (_)_(_)(_)_(_)(_)_(_)_(__)
    // (_)   (_) (____)    (_____) (_)   (___)  (___) (____)(____)
    //                                                (_)         
    //                                                (_)         

    {
      allFilterData: (apiStore) => apiStore['paidSearchAdGroupsFilterOptions'],
      codename: 'PaidSearchAdGroup',
      controller: 'paid_search_ad_group',
      crud: (rootStore) => rootStore.paidSearchAdGroupsCrud,
      dontDisplayDate: true,
      disableBulkCreate: true,
      disableBulkEdit: true,
      disableCopyEdit: true,
      domoNsType: 'PaidSearchAdGroup',
      endpoint: 'paid-search-ad-groups',
      filterOptionsCall: (apiStore) => (val) => apiStore['paidSearchAdGroupsFilterOptions'] = val,
      form: () => <GenericForm type="Ad Group" numButtons={3} />,
      genericModelPromiseEndpoints: [
        'ad-modifiers',
        'ad-modifier-objective-modifiers',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-objectives',
        'channel-platforms',
        'devices',
        'extra-field-objective-modifiers',
        'geos',
        'goals',
        'languages',
        'objectives',
        'objective-modifiers',
        'objective-modifier-objectives',
        'paid-search-ad-groups',
        'paid-search-campaigns',
        'platforms',
        'statuses',
        'users',
      ],
      hasChildren: true,
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.paidSearchCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.paidSearchAdGroupsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.extraFieldsCrud.getAll({ model_id: '10' }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, paid_search_campaign_id: root.paidSearch.PaidSearchCamp.crudStore.selectedRowIds, }; },
      name: 'Ad Groups',
      nameField: 'name',
      noDataText: (root) => '',
      parentModel: 'paid_search_campaign',
      parentModelCode: 'PaidSearchCamp',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'ad-groups',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'ad group',
      tabData: (apiStore) => apiStore.genericTabPromiseResults(),
      tabPromiseEndpoints: (rootStore, brand_param) => {
        const allFilter = _.merge(
          brand_param,
          rootStore.paidSearch.PaidSearchAdGroup.modelFilter(rootStore),
        );
        return [
          rootStore.paidSearchCampaignsCrud.getTotal(brand_param),
          rootStore.paidSearchAdGroupsCrud.getTotal(allFilter, ''),
        ];
      },
      tabDisabled: (r) => r.apiStore.adGroupTabDisabled ? true : false,
      tabIndex: 1,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 8,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'OLD NSID/ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 4,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          name: 'company',
          ns: () => 'Astellas',
          ns_order: 10,
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          colSize: 9,
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 3,
          label: 'Status',
          name: 'status_id',
          options: (apiStore) => apiStore.statuses,
          id: 'status_id',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 1,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'PaidSearchAdGroup',
          rules: 'required',
        },

        {
          domo_field: 'channel',
          domo_value: () => 'PaidSearch',
          // ns: () => 'PaidSearch',
          name: 'channel',
          id: 'channel',
          utaType: 'channel',
          rules: 'required',
        },
        {
          colSize: 11,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 150,
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          label: 'Created By',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.Users,
        },

        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.rootStore.apiStore.currentGroup),
          name: 'group',
          utaType: 'group',
          rules: 'required',
        },
        {
          name: 'company',
          ns: () => 'Astellas',
          // ns_order: 5,
        },

        {
          domo_field: 'brand',
          domo_value: (crudStore) => abbrev(crudStore.rootStore.apiStore.currentBrand),
          name: 'brand',
          rules: 'required',
          utaType: 'brand',
        },
        {
          domo_field: 'branded',
          domo_value: (crudStore) => getBrandedProperty(property(crudStore.rootStore.apiStore.currentBrand, 'branded')),
          name: 'branded',
          ns: (crudStore) => getBrandedProperty(property(crudStore.rootStore.apiStore.currentBrand, 'branded'), 'ns'),
          ns_order: 10.2,
          rules: 'required'
        },
        {
          domo_field: 'business_unit',
          domo_value: (crudStore) => property(property(crudStore.rootStore.apiStore.currentBrand, 'business_unit'), 'name'),
          name: 'business_unit',
          rules: ''
        },
        {
          domo_field: 'area',
          domo_value: (crudStore) => property(property(crudStore.rootStore.apiStore.currentBrand, 'area'), 'name'),
          name: 'area',
          rules: ''
        },

        {
          belongsTo: ['paid_search_campaign', 'paidSearchCampaigns'],
          form: 'paid_search_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="paid_search_campaign"
            fieldName="paid_search_campaign_id"
            label="Paid Search Campaign"
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.channel.model.crudStore.makePrimePaidSearchCampaignOptions(rootStore.apiStore.paidSearchCampaigns)}
            filter={false}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('paid_search_campaign_id', event)}
          />),
          formOrder: 20,
          label: 'Campaign',
          rules: 'required|integer',
          utaType: 'fk',
          subType: 'inherit',

        },
        {
          name: 'paid_search_campaign',
          ns: (crudStore) => 'PARENT'
        },
        {
          colSize: 12,
          grid: true,
          grid_order: 4,
          grid_value: (row) => property(row.paid_search_campaign, 'namestring_string'),
          filter: (dt, col) => inputTextFilter(dt, col),
          id: 'paid_search_campaigns.namestring_string',
          label: 'Campaign Namestring',
          name: 'campaign_name',
          ns: () => 'PARENT',
          utaType: 'integer',
          show: true,
          show_value: (row) => property(row.paid_search_campaign, 'namestring_string'),
          show_order: 20,
        },
        {
          domo_field: 'audience',
          domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'audience'),
          id: 'audience',
          name: 'audience',
          utaType: 'parent',
        },
        {
          domo_field: 'campaign_type',
          domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'campaign_type'),
          id: 'campaign_type',
          name: 'campaign_type',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'segment',
          domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'segment'),
          id: 'segment',
          name: 'segment',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'secondary_tactic',
          domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'secondary_tactic'),
          id: 'secondary_tactic',
          name: 'secondary_tactic',
          utaType: 'parent',
          rules: ''
        },
        {
          // this is tactic?
          domo_field: 'objective',
          domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'objective'),
          id: 'objective',
          name: 'objective',
          utaType: 'parent',
          rules: 'required'
        },
        {
          domo_field: 'geo',
          domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'geo'),
          id: 'geo',
          name: 'geo',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'language'),
          id: 'language',
          name: 'language',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'platform',
          domo_value: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'platform'),
          id: 'platform',
          name: 'platform',
          utaType: 'parent',
          rules: ''
        },
        {
          belongsTo: ['objective_modifier', 'objectiveModifiers'],
          copyEditable: true,
          form: 'objective_modifier_id',
          formField: (rootStore) => (<UccSelect
            core="objective-modifier"
            fieldName="objective_modifier_id"
            label="Keyword Category Group"
            form={rootStore.channel.model.crudStore.form}
            menuItems={_.sortBy(rootStore.uiStore.menuItems('objectiveModifiers', 'makePrimeObjectiveModifierOption', rootStore.instance.name), ['label'])}
            filter={false}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_modifier_id', event)}
          />),
          formOrder: 50,
          label: 'Keyword Category Group',
          rules: 'required|integer',
          utaType: 'fk',
        },

        {
          colSize: 12,
          // contrivance: (crudStore) => abbrev(crudStore.storedData.objective_modifier),
          domo_field: 'objective_modifier',
          domo_value: (crudStore) => abbrev(crudStore.storedData.objective_modifier),
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'obj_mod_string',
          grid: true,
          grid_order: 60,
          grid_value: (row) => property(row.objective_modifier, 'name'),
          id: 'objective_modifier_id',
          label: 'Keyword Category Group',
          name: 'objective_modifier',
          ns: (crudStore) => abbrev(crudStore.storedData.objective_modifier),
          ns_order: 60,
          options: (apiStore) => apiStore.objectiveModifiers,
          placeholder: 'Keyword Category Group',
          show: true,
          show_value: (row) => property(row.objective_modifier, 'name'),
          show_order: 60,
          type: 'hidden',
        },
        // {
        //   domo_field: 'objective_modifier',
        //   domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'objective_modifier'), 'name'),
        //   filter: (dt, col) => multiSelectFilter(dt, col),
        //   grid: true,
        //   grid_order: 20,
        //   grid_value: (row) => property(row.paid_search_campaign.objective_modifier, 'name'),
        //   id: 'objective_modifier',
        //   label: 'Category',
        //   name: 'objective_modifier',
        //   ns: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'objective_modifier'),
        //   ns_order: 20,
        //   options: (apiStore) => apiStore.objectiveModifiers,
        //   // sortField: 'objective_modifiers.name',
        //   utaType: 'parent'
        // },

        {
          belongsTo: ['ad_modifier', 'adModifiers'],
          form: 'ad_modifier_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            // note this code can be used to not display this field in certain cases. better test it if u use it.
            // display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'ad_modifier_id')}
            core="ad-modifier"
            // display={rootStore.apiStore.displayChildExtraField(rootStore.channel.model.crudStore, 'paid_search_campaign_id', 'ad_modifier_id')}
            fieldName="ad_modifier_id"

            label="Matchtype"
            form={rootStore.channel.model.crudStore.form}
            // note: the client may decide to only show options based on campaign objective_modifiers (looks). this line enables that.
            menuItems={_.sortBy(rootStore.uiStore.menuItems('adModifiers', 'makePrimeAdModifierOption', rootStore.instance.name), ['label'])}
            filter={false}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('ad_modifier_id', event)}
          />),
          formOrder: 60,
          label: 'Matchtype',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 11,
          domo_field: 'ad_modifier',
          domo_value: (crudStore) => property(crudStore.storedData.ad_modifier, 'name'),
          grid: true,
          grid_order: 140,
          grid_value: (row) => property(row.ad_modifier, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.adModifiers,
          id: 'ad_modifier',
          label: 'Matchtype',
          name: 'ad_modifier',
          ns: (crudStore) => abbrev(crudStore.storedData.ad_modifier),
          ns_order: 140,
          show: true,
          show_value: (row) => property(row.ad_modifier, 'name'),
          show_order: 140,
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ],
    },

    //    ('-.     _ .-') _             .-') _    ('-.     _   .-')       ('-.    .-')    
    //    ( OO ).-.( (  OO) )           ( OO ) )  ( OO ).-.( '.( OO )_   _(  OO)  ( OO ).  
    //   / . --. / \     .'_       ,--./ ,--,'   / . --. / ,--.   ,--.)(,------.(_)---\_) 
    //   | \-.  \  ,`'--..._)      |   \ |  |\   | \-.  \  |   `.'   |  |  .---'/    _ |  
    // .-'-'  |  | |  |  \  '      |    \|  | ).-'-'  |  | |         |  |  |    \  :` `.  
    //  \| |_.'  | |  |   ' |      |  .     |/  \| |_.'  | |  |'.'|  | (|  '--.  '..`''.) 
    //   |  .-.  | |  |   / :      |  |\    |    |  .-.  | |  |   |  |  |  .--' .-._)   \ 
    //   |  | |  | |  '--'  /      |  | \   |    |  | |  | |  |   |  |  |  `---.\       / 
    //   `--' `--' `-------'       `--'  `--'    `--' `--' `--'   `--'  `------' `-----'  
    {
      allFilterData: (apiStore) => apiStore['paidSearchAdNamesFilterOptions'],
      codename: 'PaidSearchAdName',
      controller: 'paid_search_ad_name',
      crud: (rootStore) => rootStore.paidSearchAdNamesCrud,
      domoNsType: 'PaidSearchAdName',
      endpoint: 'paid-search-ad-names',
      filterOptionsCall: (apiStore) => (val) => apiStore['paidSearchAdNamesFilterOptions'] = val,
      form: (rootStore) => <GenericForm type="Ad Name" datePicker={2} numButtons={3} extraFields={['version']} />,
      genericModelPromiseEndpoints: [
        'marketing-campaigns',
        'paid-search-ad-groups',
        'paid-search-ad-names',
        'paid-search-campaigns',
        'statuses',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.paidSearchAdNamesCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      grandParentModel: 'paid_search_campaign',
      modelFilter: (root) => _.merge({ paid_search_ad_group_id: root.paidSearch.PaidSearchAdGroup.crudStore.selectedRowIds, }, { paid_search_campaign_id: root.paidSearch.PaidSearchCamp.crudStore.selectedRowIds, }, { brand_id: root.apiStore.currentBrandId }),
      name: 'Ad Names',
      nameField: 'name',
      noDataText: (root) => '',
      parentModel: 'paid_search_ad_group',
      parentModelCode: 'PaidSearchAdGroup',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'ad-names',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'ad name',

      tabData: (apiStore) => apiStore.genericTabPromiseResults(),
      tabPromiseEndpoints: (rootStore, brand_param) => {
        const allFilter = _.merge(
          brand_param,
          rootStore.paidSearch.PaidSearchAdGroup.modelFilter(rootStore),
        );
        return [
          rootStore.paidSearchCampaignsCrud.getTotal(brand_param),
          rootStore.paidSearchAdGroupsCrud.getTotal(allFilter, ''),
        ];
      },
      tabDisabled: (r) => (r.apiStore.adNameTabDisabled) ? true : false,
      tabIndex: 2,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 8,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'OLD NSID/ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 4,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          colSize: 9,
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 5,
          label: 'Status',
          name: 'status_id',
          options: (apiStore) => apiStore.statuses,
          id: 'status_id',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 2,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'PaidSearchAdName',
          rules: 'required',
        },

        {
          domo_field: 'channel',
          domo_value: () => 'PaidSearch',
          // ns: () => 'PaidSearch',
          name: 'channel',
          id: 'channel',
          utaType: 'channel',
          rules: 'required',
        },
        {
          colSize: 9,
          domo_field: 'campaign_name',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group, 'paid_search_campaign'), 'name'),
          form: 'campaign_name',
          filter_placeholder: 'Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStoresocialCampaigns,
          id: 'paid_search_campaign_id',
          name: 'name',
          label: 'Description',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          utaType: 'name',
          rules: 'string',
        },

        {
          colSize: 11,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 150,
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          label: 'Created By',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.Users,
        },
        {
          name: 'company',
          ns: () => 'Astellas',
          ns_order: 10,
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          name: 'brand_id',
          ns: () => 'brand_id',
          type: 'hidden',
          utaType: 'brand',
          rules: 'required',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },
        {
          domo_field: 'branded',
          domo_value: (crudStore) => getBrandedProperty(property(crudStore.rootStore.apiStore.currentBrand, 'branded')),
          name: 'branded',
          ns: (crudStore) => getBrandedProperty(property(crudStore.rootStore.apiStore.currentBrand, 'branded'), 'ns'),
          ns_order: 10.2,
          rules: 'required'
        },
        {
          domo_field: 'business_unit',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
          name: 'business_unit',
          rules: 'required'
        },
        {
          domo_field: 'area',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
          name: 'area',
          rules: 'required'
        },
        {
          colSize: 14,
          domo_field: 'grand_parent_namestring',
          domo_value: (crudStore) => property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'namestring_string'),
          form: 'campaign_name',
          grid: true,
          grid_order: 5,
          grid_value: (row) => `${property(row.paid_search_ad_group.paid_search_campaign, 'namestring_string')} `,
          grid_label: 'Campaign Namestring',
          filter_placeholder: 'Campaign',
          id: 'a.b.namestring_string',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          label: 'Description',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          utaType: 'name',
          rules: 'string',
        },
        {
          belongsTo: ['paid_search_ad_group', 'paidSearchAdGroups'],
          copyEditable: false,
          form: 'paid_search_ad_group_id',
          formField: (rootStore) => (<UccSelect
            core="paid_search_ad_group"
            fieldName="paid_search_ad_group_id"

            form={rootStore.channel.model.crudStore.form}
            label="Ad Group Namestring"
            menuItems={rootStore.channel.model.crudStore.makePrimeAdGroupOptions(rootStore.apiStore.paidSearchAdGroups)}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('paid_search_ad_group_id', event)}
          />),
          formOrder: 1,
          label: "Ad Group Namestring",
          rules: 'required|integer',
          show: true,
          show_value: (row) => `${property(row.paid_search_ad_group.paid_search_campaign, 'namestring_string')} `,
          show_order: 10,
          utaType: 'fk',
          subType: 'inherit',
        },
        {
          domo_field: 'audience',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'audience'), 'name'),
          id: 'audience',
          name: 'audience',
          utaType: 'parent',
        },
        {
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'campaign_type'), 'name'),
          id: 'campaign_type',
          name: 'campaign_type',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'segment',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'segment'), 'name'),
          id: 'segment',
          name: 'segment',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'ad_modifier',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group, 'ad_modifier'), 'name'),
          id: 'ad_modifier',
          name: 'ad_modifier',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'objective_modifier',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'objective_modifier'), 'name'),
          id: 'objective_modifier',
          name: 'objective_modifier',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'secondary_tactic',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'secondary_tactic'), 'name'),
          id: 'secondary_tactic',
          name: 'secondary_tactic',
          utaType: 'parent',
          rules: ''
        },
        {
          // this is tactic?
          domo_field: 'objective',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'objective'), 'name'),
          id: 'objective',
          name: 'objective',
          utaType: 'parent',
          rules: 'required'
        },
        {
          domo_field: 'geo',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'geo'), 'name'),
          id: 'geo',
          name: 'geo',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'platform',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'platform'), 'name'),
          id: 'platform',
          name: 'platform',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'language'), 'name'),
          id: 'language',
          name: 'language',
          utaType: 'parent',
          rules: ''
        },
        {
          colSize: 14,
          domo_field: 'parent_namestring',
          domo_value: (crudStore) => property(crudStore.storedData.paid_search_ad_group, 'namestring_string'),
          grid: true,
          grid_order: 6,
          grid_value: (row) => `${property(row.paid_search_ad_group, 'namestring_string')} `,
          grid_type: 'namestring',
          grid_label: 'Ad Group Namestring',
          id: 'namestring_string',
          label: 'Ad Group Namestring',
          name: 'paid_search_ad_group',
          filter: (dt, col) => inputTextFilter(dt, col),
          ns: (crudStore) => HeadedCamelCase(property(crudStore.storedData.paid_search_ad_group, 'name')),

          rules: 'required|integer',
          utaType: 'integer',
        },
        {
          colSize: 14,
          domo_field: 'additional_field',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.purpose),
          form: 'purpose',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="purpose"
            fieldName="purpose"
            form={rootStore.channel.model.crudStore.form}
            label="Purpose / Differentiator"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('purpose', event)}
          />),
          formOrder: 120,
          grid: true,
          grid_order: 120,
          grid_value: (row) => row.purpose,
          id: 'purpose',
          name: 'purpose',
          label: 'Purpose / Differentiator',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.purpose),
          ns_order: 120,
          utaType: 'string',
          rules: 'string',
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.purpose,
          show_order: 120,
        },
        {
          domo_field: 'ab_version',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.version),
          form: 'version',
          formOrder: 160,
          grid: true,
          grid_order: 145,
          grid_value: (row) => row.version,
          id: 'version',
          label: 'Version',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="version"
            fieldName="version"
            form={rootStore.channel.model.crudStore.form}
            label="Version"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('version', event)}
          />),
          filter: (dt, col) => inputTextFilter(dt, col),
          hideForm: true,
          name: 'version',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.version),
          ns_order: 160,
          rules: 'string',
          show: true,
          show_value: (row) => row.version,
          show_order: 220,
          utaType: 'text',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 130, 130, 'S.', 200),
        dateField('end_date', 'End Date', 'blank', false, 140, 140, 'E.', 210),
      ),
    },
  ],
}; 