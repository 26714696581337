import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import UccObjectiveModifierField from './UccObjectiveModifierField';
import { InputText } from 'primereact/inputtext';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import UccSwitch from './UccSwitch';
// import jsp from '../lib/jsp';

const styles = (theme) => ({
  root: {},
  targeting: {
    paddingTop: "3 !important",
  },
  targetingH3: {
    marginBottom: 18,
    marginLeft: 32,
    fontFamily: 'Avenir LT W01_65 Medium1475532',
    fontSize: 18,
    fontWeight: 300,
    fontStretch: 'normal',
    letterSpacing: 'normal',
    // paddingTop: 12,
    paddingLeft: 16,
  },
  contrainerWidth: {
    borderRadius: 4,
    width: 487,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  fieldsetPadding: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
});

@inject('rootStore')
@observer
class UccObjectiveModifier extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => { };

  isChecked = (val = false) => {
    return this.props.form.$('suppression').value == false ? false : true;
  };

  objectiveModifierFields = () => {
    const finalObjectiveModifierFields = [];
    this.props.rootStore.apiStore.objectiveModifierFields.map((apiStoreObjectiveModifier, index) => {
      finalObjectiveModifierFields.push(<UccObjectiveModifierField
        row_id={this.props.rootStore.channel.model.crudStore.selectedRowId}
        form={this.props.form}
        index={apiStoreObjectiveModifier.id}
        that={parent}
        objectiveModifier={apiStoreObjectiveModifier}
        setNameString={parent.setNameString}
        callFrom={`days`}
      />
      )
    })
    return finalObjectiveModifierFields;
  }

  render() {
    const {
      form,
      rootStore,
      classes,
    } = this.props;
    const { crudStore } = rootStore.channel.model;
    // if (!parseInt(form.$('tactic_id').value)) return '';
    return (
      <fieldset id="fieldset-targeting" className={classes.fieldsetPadding}>
        <Box border={1} className={classes.contrainerWidth}>
          <Grid container spacing={6} alignItems="center">
            <Grid xs={4} className={classes.targeting}>
              <Typography
                component="h3"
                variant="h3"
                className={classes.targetingH3}
              >
                Objective Modifier Fields
              </Typography>
            </Grid>
          </Grid>

          <div className="targeting-container">{this.objectiveModifierFields()}</div>

        </Box>
      </fieldset>
    );
  }
}

UccObjectiveModifier.wrappedComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UccObjectiveModifier);
{
  /* { CREATIVE NAME / AD DESCRIPTION } */
}
