import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './PrimeStyle.css';
import { InputNumber } from 'primereact/inputnumber';
import { withStyles } from '@material-ui/core/styles';
import { InputText } from 'primereact/inputtext';
import IconButton from '@material-ui/core/IconButton';
import Pluralize from 'pluralize';
import { Dialog } from 'primereact/dialog';
import BackButton from '../../../../public/images/back_button.svg';
class UccPreviewTitle extends Component {
  constructor(props) {
    super(props);
    this.onBackEvent = this.onBackEvent.bind(this);
  }

  onBackEvent(e) {
    // this.props.rootStore.channel.model.crudStore.bulkCrudStores = [];
    const cs = this.props.rootStore.channel.model.crudStore;
    cs.bulkCrudStores = [];
    cs.storedData['attribute_to_be_edited'] = '';
    cs.setFirstPage(true);
    cs.bulkAttributeSelected = false;
  }

  render() {
    const rootStore = this.props.rootStore;
    const crudStore = rootStore.channel.model.crudStore;
    const title = this.props.title
    const subtitle = this.props.subtitle
    // const numberofCopies = crudStore.numberofCopies * (title == 'Preview' ? crudStore.copiedPredraftRecords.length : crudStore.selectedRowIds.length);
    const numberofCopies = crudStore.selectedRowIds.length;
    return (
      <div style={{ verticalAlign: 'middle' }} >
        <div className="back-button" style={{ float: 'left', color: '#6c7cdc', marginTop: '-10px' }}>
          <IconButton id="back_id_button_123123" aria-label="Back" onClick={this.onBackEvent} style={{ color: "#6c7cdc", paddingLeft: '5px', fontSize: '18px', fontWeight: 300 }}> <img src={BackButton} />  Back </IconButton>
        </div>
        <div data-qa={this.props.data_qa} style={{ textAlign: 'center' }}>
          <div style={{ fontWeight: 800, color: '#4C5059', fontFamily: 'Avenir LT W01_95 Black1475556' }}>
            {title}:
            <span style={{ fontWeight: 300, color: '#6c7cdc' }}> {numberofCopies}</span>
            <span style={{ fontWeight: 300, color: '#4C5059' }}> {numberofCopies > 1 ? Pluralize.plural(subtitle) : Pluralize.singular(subtitle)}</span>
          </div>

        </div>
      </div>
    );
  }
}

export default UccPreviewTitle;