import React from "react";

const PaidSearchIcon = (props) => (
<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 100 100"
>
  <g fill={props.fill}>
  <path d="m16.668 25c-0.42969 0-0.85547-0.16406-1.1797-0.48828-0.65234-0.65234-0.65234-1.7031 0-2.3555l6.668-6.668c0.65234-0.65234 1.7031-0.65234 2.3555 0 0.65234 0.65234 0.65234 1.7031 0 2.3555l-6.668 6.668c-0.32422 0.32422-0.75 0.48828-1.1758 0.48828"/>
  <path d="m16.668 33.332c-0.42969 0-0.85547-0.16016-1.1797-0.48828-0.65234-0.65234-0.65234-1.7031 0-2.3555l3.332-3.332c0.65234-0.65234 1.707-0.65234 2.3594 0 0.64844 0.65234 0.64844 1.7031 0 2.3555l-3.3359 3.332c-0.32422 0.32812-0.75 0.48828-1.1758 0.48828"/>
  <path d="m23.332 26.668c-0.11719 0-0.21484-0.019531-0.33203-0.035157-0.10156-0.015624-0.19922-0.050781-0.30078-0.097656-0.097657-0.035156-0.19922-0.085937-0.30078-0.15234-0.082032-0.050781-0.16406-0.13281-0.25-0.19922-0.30078-0.31641-0.48047-0.73438-0.48047-1.1836s0.17969-0.86719 0.48047-1.1836c0.085937-0.066406 0.16797-0.14844 0.25-0.19922 0.10156-0.066407 0.20312-0.11719 0.30078-0.15234 0.10156-0.046875 0.19922-0.082032 0.30078-0.097656 0.55078-0.11719 1.1328 0.066406 1.5156 0.44922 0.30078 0.31641 0.48438 0.75 0.48438 1.1836s-0.18359 0.86719-0.48438 1.1836c-0.082031 0.066406-0.16797 0.14844-0.25 0.19922-0.097656 0.066407-0.19922 0.11719-0.30078 0.15234-0.10156 0.046875-0.19922 0.082032-0.31641 0.097656-0.097657 0.015626-0.21484 0.035157-0.31641 0.035157"/>
  <path d="m26.668 23.332c-0.42969 0-0.85547-0.16016-1.1797-0.48828-0.65234-0.65234-0.65234-1.7031 0-2.3555l5-5c0.65234-0.65234 1.7031-0.65234 2.3555 0 0.65234 0.65234 0.65234 1.7031 0 2.3555l-5 5c-0.32422 0.32812-0.75 0.48828-1.1758 0.48828"/>
  <path d="m16.668 41.668c-0.42969 0-0.85547-0.16406-1.1797-0.48828-0.65234-0.65234-0.65234-1.707 0-2.3594l15-15c0.65234-0.64844 1.7031-0.64844 2.3555 0 0.65234 0.65234 0.65234 1.707 0 2.3594l-15 15c-0.32422 0.32422-0.75 0.48828-1.1758 0.48828"/>
  <path d="m35 23.332c-0.43359 0-0.86719-0.18359-1.1836-0.48047-0.30078-0.31641-0.48438-0.75-0.48438-1.1836 0-0.43359 0.18359-0.86719 0.48438-1.1836 0.61719-0.61719 1.7344-0.61719 2.3672 0 0.30078 0.31641 0.48438 0.75 0.48438 1.1836 0 0.43359-0.18359 0.86719-0.48438 1.1836-0.31641 0.29688-0.75 0.48047-1.1836 0.48047"/>
  <path d="m38.332 20c-0.42578 0-0.85156-0.16406-1.1758-0.48828-0.65234-0.65234-0.65234-1.7031 0-2.3555l1.6641-1.668c0.65234-0.65234 1.707-0.65234 2.3594 0 0.64844 0.65234 0.64844 1.7031 0 2.3555l-1.668 1.668c-0.32422 0.32422-0.75 0.48828-1.1797 0.48828"/>
  <path d="m8.332 3.332c-2.7539 0-5 2.2461-5 5v60c0 2.7578 2.2461 5 5 5h83.336c2.7539 0 5-2.2422 5-5v-60c0-2.7539-2.2461-5-5-5zm83.336 73.336h-83.336c-4.5938 0-8.332-3.7383-8.332-8.3359v-60c0-4.5938 3.7383-8.332 8.332-8.332h83.336c4.5938 0 8.332 3.7383 8.332 8.332v60c0 4.5977-3.7383 8.3359-8.332 8.3359z"/>
  <path d="m91.668 63.332c-0.92188 0-1.668-0.74609-1.668-1.6641v-50c0-0.91797-0.74609-1.668-1.668-1.668h-76.664c-0.92188 0-1.668 0.75-1.668 1.668v50c0 0.91797-0.74609 1.6641-1.668 1.6641s-1.6641-0.74609-1.6641-1.6641v-50c0-2.7578 2.2422-5 5-5h76.664c2.7578 0 5 2.2422 5 5v50c0 0.91797-0.74219 1.6641-1.6641 1.6641"/>
  <path d="m3.332 63.332v5c0 2.7578 2.2461 5 5 5h83.336c2.7539 0 5-2.2422 5-5v-5zm88.336 13.336h-83.336c-4.5938 0-8.332-3.7383-8.332-8.3359v-6.6641c0-0.92188 0.74609-1.668 1.668-1.668h96.664c0.92188 0 1.668 0.74609 1.668 1.668v6.6641c0 4.5977-3.7383 8.3359-8.332 8.3359z"/>
  <path d="m55 70h-10c-0.92188 0-1.668-0.74609-1.668-1.668 0-0.91797 0.74609-1.6641 1.668-1.6641h10c0.92188 0 1.668 0.74609 1.668 1.6641 0 0.92188-0.74609 1.668-1.668 1.668"/>
  <path d="m83.332 68.332c0-0.91797-0.74219-1.6641-1.6641-1.6641s-1.668 0.74609-1.668 1.6641c0 0.92188 0.74609 1.668 1.668 1.668s1.6641-0.74609 1.6641-1.668"/>
  <path d="m91.668 68.332c0-0.91797-0.74609-1.6641-1.668-1.6641s-1.668 0.74609-1.668 1.6641c0 0.92188 0.74609 1.668 1.668 1.668s1.668-0.74609 1.668-1.668"/>
  <path d="m33.332 93.332v3.3359h33.336v-3.3359zm33.336 6.668h-33.336c-1.8359 0-3.332-1.4961-3.332-3.332v-3.3359c0-1.8359 1.4961-3.332 3.332-3.332h33.336c1.8359 0 3.332 1.4961 3.332 3.332v3.3359c0 1.8359-1.4961 3.332-3.332 3.332z"/>
  <path d="m58.332 93.332c-0.77734 0-1.4766-0.54688-1.6328-1.3398l-3.332-16.664c-0.18359-0.90234 0.40625-1.7812 1.3047-1.9609 0.91406-0.18359 1.7812 0.40625 1.9609 1.3047l3.332 16.668c0.18359 0.90234-0.40234 1.7812-1.3047 1.9609-0.10938 0.023438-0.22266 0.03125-0.32812 0.03125"/>
  <path d="m41.668 93.332c-0.10938 0-0.21875-0.007812-0.32812-0.03125-0.90234-0.17969-1.4883-1.0586-1.3047-1.9609l3.332-16.668c0.17969-0.90234 1.0586-1.4883 1.9609-1.3047 0.89844 0.17969 1.4883 1.0586 1.3047 1.9609l-3.332 16.664c-0.15625 0.79297-0.85547 1.3398-1.6328 1.3398"/>
 </g>
</svg>
);

export default PaidSearchIcon;
