import 'typeface-roboto';
import lolex from 'lolex';
import AuthenticatedApp from './Auth/AuthenticatedApp';
import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from '@date-io/moment';
import React from 'react';
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/browser';
import RootStore from '../store/RootStore';
import routes from './Layout/Routes';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { observer, Provider } from 'mobx-react';
import { startRouter } from 'mobx-router';
import { theme } from './theme';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

window.jsp = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

@observer
class App extends React.Component {
  constructor(props) {
    super(props);

    this.rootStore = new RootStore(routes, this.props);
    if (this.rootStore.features.sentry_dsn) {
      Sentry.init({
        dsn: this.rootStore.features.sentry_dsn,
        environment: this.props.app_environment,
        release: this.props.app_release,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        integrations: [
          new Sentry.Replay({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
          }),
        ],
      });
    } else {
      console.log("Sentry alerts disabled");
    }
    this.setupMockTime(this.rootStore.features.mock_browser_time, this.rootStore.features.unix_millis);

    startRouter(routes, this.rootStore.uiStore);

    if (this.rootStore.features.gtm_enabled === "true") {
      const tagManagerArgs = {
        gtmId: this.rootStore.instance.name == 'astellas' ? 'GTM-TR3JQ3D' : 'GTM-5J6NDGN'
      }
      TagManager.initialize(tagManagerArgs)
    }

  }

  setupMockTime(mock_browser_time, unix_millis) {
    if (mock_browser_time === true) {
      lolex.install({ now: Number(unix_millis), shouldAdvanceTime: true });
      const date = new Date();
      console.log(`Test in progress: Setting javascript time back to ${date}`);
    }
  }

  render() {
    return (
      <Provider rootStore={this.rootStore} apiStore={this.rootStore.apiStore} store={this.rootStore.uiStore} uiStore={this.rootStore.uiStore}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AuthenticatedApp />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
