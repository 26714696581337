import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './PrimeStyle.css';
import { InputNumber } from 'primereact/inputnumber';
import { withStyles } from '@material-ui/core/styles';
import { InputText } from 'primereact/inputtext';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';


@inject('rootStore')
@observer
class UccClearFilter extends Component {
  constructor(props) {
    super(props);
    this.onClearFilterEvent = this.onClearFilterEvent.bind(this);
  }

  onClearFilterEvent(e) {
    this.props.rootStore.channel.model.crudStore.onClearFilters();
  }

  render() {
    const rootStore = this.props.rootStore;
    const crudStore = rootStore.channel.model.crudStore;
    return (
      <div >
        {crudStore.displayClearFilter &&
          <Button icon="pi pi-times" onClick={this.onClearFilterEvent} className="p-button-link" style={{ backgroundColor: '#f1f1f1', border: '0px', color: '#6c7cdc', paddingLeft: '5px', marginTop: '10px', fontSize: '16px', width: '200px', marginRight: '50px' }} label="Clear All Filters" />
        }
      </div>
    );
  }
}

export default UccClearFilter;