import AdminUserForm from './../components/CrudAdmin/forms/AdminUserForm';
import AdminGroupForm from './../components/CrudAdmin/forms/AdminGroupForm';
import AdminBrandForm from './../components/CrudAdmin/forms/AdminBrandForm';
import { Dropdown } from 'primereact/dropdown';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { formatDate } from 'moment';
import { blueMetadata } from './BlueMetadata.js';
import { astellasMetadata } from './AstellasMetadata.js';
import jsp from '../lib/jsp';
import { InputText } from 'primereact/inputtext';
import UccSelect from './../components/PrimeReactComponent/UccSelect';

import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from '../lib/Calendar';

var moment = require('moment');

// this file holds metadata for channels, models and fields for the UTA app.
// its really important.
// two non-obvious things:
// the id: of each field is actually just controlling whether it appears on the 'grids'.
// the form: of each field actually turns into the html input's name="" attribute.
// name is also used as a standalone key for namestrings. slightly confusing there, I know.
// this first 'targetingFields' array is concat'd onto the real fields.. so skip down to see the main data.


export const storeMetadata = (instance) => {
  switch (instance) {
    case 'astellas':
      return astellasMetadata;
    case 'blue':
      return blueMetadata;
    default:
      return blueMetadata;
  }
}

export const channelSpecificOptions = (rootStore, model) => {
  return rootStore.channels.filter(C => {
    return !C.disabled;
  }).filter(C => {
    return C.models.some(m => { return m.columns.some(c => c.id == model) })
  }).map((p) => {
    let chan = rootStore.apiStore.channels.find(c => c.codename === p.codename);
    return { label: chan.name, value: chan.id };
  })
}

export const modelSpecificOptions = (rootStore, model) => {
  return rootStore.channels.filter(C => {
    return C.models.some(m => { return m.columns.some(c => c.id == model) })
  }).map((p) => {
    let chan = rootStore.apiStore.channels.find(c => c.codename === p.codename);
    return { label: chan.name, value: chan.id };
  })
}

export function statusField() {
  //for now only shows two options: Active and Archived
  // debugger;
  return;
  return [
    {
      belongsTo: ['status', 'statuses'],
      form: 'status_id',
      copyEditable: false,
      formField: (rootStore) => (<UccSelect
        core="status"
        fieldName="status_id"
        form={rootStore.channel.model.crudStore.form}
        label="Status"
        menuItems={rootStore.uiStore.menuItems('statuses', 'makePrimeRegularOption').filter(mi => mi.label == 'Active' || mi.label == 'Archived')}
        onChange={event => rootStore.channel.model.crudStore.storeData('status_id', event)}
      />),
      formOrder: 500,
      label: 'Status',
      permCreateAndEdit: 80,
      rules: 'integer',
      utaType: 'fk',
    },
  ]
}

export function dateField(field, label, def, required, gridpos, nspos, nsprefix, showPos, colSize = 8, dateFormat = 'MM.DD.YYYY') {
  const nLabel = required ? label : `(Opt.) ${label}`;
  return [
    {
      domo_field: field,
      domo_value: (crudStore) => validDate(crudStore.storedData[field]) ? postgresDate(crudStore.storedData[field]) : '',
      form: field,
      format: 'formatDate',
      filter: (dt, col) => dateFilter(dt, col),
      grid: !!gridpos,
      grid_value: (row) => !!row[field] ? moment(row[field]).format('MM/DD/YYYY') : '',
      grid_order: gridpos,
      grid_label: label,
      id: field,
      label: nLabel,
      name: field,
      ns: (crudStore) => validDate(crudStore.storedData[field]) ? `${nsprefix}${moment(crudStore.storedData[field]).format(dateFormat)}` : '',
      ns_order: nsprefix == 'dns' ? null : nspos,
      utaType: 'date',
      rules: required ? 'required' : 'date',
      subType: def,
      value: def,
      show: true,
      show_value: (row) => !!row[field] ? moment(row[field]).format('MM/DD/YYYY') : '',
      show_order: showPos,
      colSize: colSize
    },
  ];
};

export function childModelFilter(rootStore, id_field) {
  const obj = {}
  obj[id_field] = rootStore.channel.model.crudStore.showId
  return obj;
}

export const dropDownFilter = (dt, col) => {
  const model = dt.props.model;
  const crudStore = model.crudStore;
  const apiStore = dt.props.rootStore.apiStore;
  if (_.isEmpty(model.allFilterData(apiStore))) return;
  const opts = crudStore.setFilterOptions(
    col.id,
    col.options(apiStore),
    model.allFilterData(apiStore),
    col.filterOptionType,
  );
  return (
    <div data-qa={'filtered_' + col.id}>
      <Dropdown
        className="p-column-filter"
        data-qa={'filtered_' + col.id}
        value={crudStore.storedData['filtered_' + col.id]}
        options={opts}
        onChange={(event) => dt.onFilterChange(event, col.id)}
        filter={false}
        filterBy="label"
        filterMatchMode="contains"
        placeholder={"Select " + dt.placeholder_text(col)}
      />
    </div>
  );
};

export const multiSelectFilter = (dt, col) => {
  const model = dt.props.model;
  const crudStore = model.crudStore;
  const apiStore = dt.props.rootStore.apiStore;
  if (_.isEmpty(model.allFilterData(apiStore))) return;
  const opts = crudStore.setFilterOptions(
    col.id,
    col.options(apiStore),
    model.allFilterData(apiStore),
    col.filterOptionType,
    col.filter_label
  );
  // console.log(JSON.stringify(opts));
  let value = crudStore.storedData['filtered_' + col.id] ? crudStore.storedData['filtered_' + col.id] : (crudStore.filterData[col.id] ? crudStore.filterData[col.id] : '')
  // if (value != '') value = opts.filter(o => value.includes(o.value)).length > 0 ? value : '';
  return (
    <div data-qa={'filtered_' + col.id}>
      <MultiSelect
        className="p-column-filter"
        data-qa={'filtered_' + col.id}
        // value={crudStore.storedData['filtered_' + col.id]}
        value={value}
        options={opts}
        onChange={(event) => dt.onFilterChange(event, col.id)}
        filterBy="label"
        filterMatchMode="contains"
        placeholder={"Select " + dt.placeholder_text(col)}
        style={{
          minWidth: '40px',
          maxWidth: '300px',
          height: '37px',
          marginTop: '10px',
          marginBottom: '10px',
          contentAlign: 'left',
        }}
      />
    </div>
  );
};
export const generateFilterLabel = (id, name) => {
  return name + (name != '' ? ' - ' : '') + id;
};

export const inputTextFilter = (dt, col) => {
  const model = dt.props.model;
  const crudStore = model.crudStore;
  const apiStore = dt.props.rootStore.apiStore;
  if (_.isEmpty(model.allFilterData(apiStore))) return;
  return (
    <div data-qa={'filtered_' + col.id}>
      <InputText
        className="p-column-filter"
        value={crudStore.storedData['filtered_' + col.id] ? crudStore.storedData['filtered_' + col.id] : (crudStore.filterData[col.id] ? crudStore.filterData[col.id] : '')}
        onChange={(event) => dt.onFilterChange(event, col.id)}
        placeholder={"Search " + dt.placeholder_text(col)}
        keyfilter={col.label.toLowerCase() == 'id' ? 'pint' : ''}
        style={{
          minWidth: '45px',
          maxWidth: '300px',
          height: '37px',
          marginTop: '10px',
          marginBottom: '10px',
          contentAlign: 'left',
        }}
      />
    </div>
  );
};

export const dateFilter = (dt, col) => {
  const model = dt.props.model;
  const crudStore = model.crudStore;
  const apiStore = dt.props.rootStore.apiStore;
  if (_.isEmpty(model.allFilterData(apiStore))) return;
  return (
    <div data-qa={'filtered_' + col.id}>
      <Calendar
        className="p-column-filter"
        data-qa={'filtered_' + col.id}
        // value={crudStore.storedData['filtered_' + col.id]}
        value={crudStore.storedData['filtered_' + col.id] ? crudStore.storedData['filtered_' + col.id] : (crudStore.filterData[col.id] ? crudStore.filterData[col.id] : '')}
        onChange={(event) => dt.onFilterChange(event, col.id)}
        onClearButtonClick={(event) => dt.onFilterChange(event, col.id, true)}
        selectionMode="range"
        style={{ maxWidth: '75%', fontSize: 'inherit' }}
        placeholder={"Select Range"}
        panelStyle={{ width: '330px', height: 'initial', left: '-359px', paddingBottom: '0px' }}
        appendTo={document.body}
        showButtonBar
        showIcon
        todayButtonClassName='today'
        clearButtonClassName='clearButton'
      />
    </div>
  );
};

export const HeadedCamelCase = (str) => {
  return _.startCase(_.toLower(str)).replace(/[^A-Z0-9]/gi, '');
};

// const copyButton = () () {}
export const abbrev = (data) => {
  return _.isEmpty(data) ? '' : data.abbrev;
};

export const getRelationId = (data, type) => {
  if (data.storedData[`#{type}_id`]) return data.storedData[`#{type}_id`];
  return _.isEmpty(data.storedData[type]) ? '' : data.storedData[type].id;
};

export const property = (data, prop) => {
  return _.isEmpty(data) ? '' : data[prop];
};

export const getBrandedProperty = (data, prop = 'domo_value') => {
  switch (prop) {
    case 'ns':
      return data ? 'B' : 'UB';
    default:
      return data ? 'Branded' : 'Unbranded';
  }

};

export const multiAbbrev = (data) => {
  return _.isEmpty(data) ? '' : data.map((d) => d.abbrev).join('+');
};

export const parentAbbrev = (parent, property) => {
  if (_.isEmpty(parent)) return '';
  return abbrev(parent[property]);
};

export const objectiveModifierNs = (model, data) => {
  if (!data.objective_modifier) return '';
  const ns = []
  ns.push(abbrev(data.objective_modifier));
  if (model == 'campaign') {
    if ([5].includes(data.objective_modifier.id)) ns.push(HeadedCamelCase(data.three_p_brand));
    if ([5, 4].includes(data.objective_modifier.id)) ns.push(HeadedCamelCase(data.category));
    if ([3, 5].includes(data.objective_modifier.id)) ns.push(HeadedCamelCase(data.product_details));
    if ([3, 4, 5, 6].includes(data.objective_modifier.id)) ns.push(HeadedCamelCase(data.custom_modifier));
  } else {
    if ([1, 2].includes(data.objective_modifier.id)) ns.push(abbrev(data.ad_modifier));
    if ([5].includes(data.objective_modifier.id)) ns.push(abbrev(data.three_p_brand));
    if ([4, 5].includes(data.objective_modifier.id)) ns.push(HeadedCamelCase(data.category));
    if ([3, 5].includes(data.objective_modifier.id)) ns.push(HeadedCamelCase(data.product_details));
    if ([3, 4, 5, 6].includes(data.objective_modifier.id)) ns.push(HeadedCamelCase(data.custom_modifier));
  }
  return _.compact(ns).join("+");
};

export const adGroupModifierNs = (data) => {
  if (!data) return '';
  if (data.paid_search_campaign == '' || data.paid_search_campaign == undefined) return '';
  const ns = []
  if ([1, 2].includes(data.paid_search_campaign.objective_modifier.id)) ns.push(abbrev(data.ad_modifier));
  if ([4, 5].includes(data.paid_search_campaign.objective_modifier.id)) ns.push(HeadedCamelCase(data.category));
  if ([3, 5].includes(data.paid_search_campaign.objective_modifier.id)) ns.push(HeadedCamelCase(data.product_details));
  if ([3, 4, 5, 6].includes(data.paid_search_campaign.objective_modifier.id)) ns.push(HeadedCamelCase(data.custom_modifier));
  return _.compact(ns).join("+");
};

const ad_id_value = (crudStore) => {
  if (crudStore.storedData.id) return crudStore.storedData.id.toString();
  return _.isEmpty(crudStore.storedData.ad_id.toString())
    ? root.form.$('id').value
    : root.channel.model.crudStore.storedData.ad_id.toString();
};

export const postgresDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

export const plusSigns = (segments) => {
  return segments.filter(s => !_.isEmpty(s)).join('+')
};

export const validDate = (date) => {
  return (
    moment(date, 'MM/DD/YYYY', true).isValid() ||
    moment(date, 'YYYY-MM-DD', true).isValid()
  );
};

export const formFields = (rootStore, type) => {
  const fields = [];
  const otherFields = {};
  rootStore.channel.model.columns.forEach(f => {
    // console.log(jsp(f));
    // console.log(f.form);
    const key = f.form ? f.form : f.name;
    if (f["form"]) {
      otherFields[f["form"]] = f[type];
      fields.push(f.form);
    }
  });
  return type == 'form' ? fields : otherFields;
  // return otherFields;
}

export const formColumns = (columns) => {
  const formColumns = _.filter(columns, (f) => !_.isEmpty(f.form)).map((f) => ({
    callback: f.callback,
    contrivance: f.contrivance,
    default: f.default,
    // fields: typeof f.fields == 'function' ? f.fields(rootStore) : f.fields,
    form: f.form,
    label: f.label,
    formField: f.formField,
    name: f.form, // note: now form becomes name for mobx purposes.
    placeholder: f.placeholder,
    rules: f.rules,
    subType: f.subType,
    type: f.type,
    utaType: f.utaType,
    value: f.value,
  }));
  return formColumns;
}

export const appendFields = (dataList) => {
  return dataList.filter(a => a).join('+');
}

export const showFields = (columns) => {
  const showPieces = _.orderBy(
    _.filter(columns, (c) => !_.isEmpty(c.show) || _.isFunction(c.show)),
    ['show_order'],
    ['asc'],
  );
  return showPieces;
};

export const namestringFields = (columns) => {
  const nsPieces = _.orderBy(
    _.filter(columns, (c) => !_.isEmpty(c.ns) || _.isFunction(c.ns)),
    ['ns_order'],
    ['asc'],
  );
  return nsPieces;
};

export const formElements = (rootStore, hideForm = true) => {
  let elements = _.orderBy(
    _.filter(rootStore.channel.model.columns, (c) => (!_.isEmpty(c.formField) || _.isFunction(c.formField)) && ((!_.isEmpty(c.hideForm) || (c.hideForm != hideForm)))),
    // _.filter(rootStore.channel.model.columns, (c) => (!_.isEmpty(c.hideForm) || !c.hideForm)),
    ['formOrder'],
    ['asc'],
  );
  elements = elements.filter(elem => {
    return (_.isUndefined(elem.permCreateAndEdit) || elem.permCreateAndEdit < rootStore.apiStore.currentUser.pll)
  }, rootStore)
  return elements;
};

export const copyEditDropDownList = (rootStore) => {
  const elements =
    _.filter(rootStore.channel.model.columns, (c) => (!_.isEmpty(c.formField) || (_.isFunction(c.formField) && (_.isFunction(c.copyEditable) ? c.copyEditable(rootStore) : c.copyEditable))))
  return elements;
};

export const getFormElement = (rootStore, fieldName) => {
  const elements =
    _.filter(_.filter(rootStore.channel.model.columns, (c) => (!_.isEmpty(c.formField) || (_.isFunction(c.formField) && c.copyEditable))), (a) => a.fieldName = fieldName)
  return elements;
}

export const disableSubmitButton = (rootStore, event) => {

  if (!_.isEmpty(event.originalEvent._targetInst.key) && (event.originalEvent._targetInst.key.indexOf('Draft') > 0 || event.originalEvent._targetInst.key.indexOf('Copy') > 0)) {
    rootStore.channel.model.crudStore.storeData('model_status', 'Inactive')
  } else {
    rootStore.channel.model.crudStore.storeData('model_status', 'Active')
  }
}

export const domoFields = (crudStore) => {
  const domoFieldArr = _.filter(
    crudStore.model.columns,
    (c) => !_.isEmpty(typeof c.domo_field == 'function' ? c.domo_field(crudStore) : c.domo_field),
  );

  const domoObj = Object.assign(
    {},
    ...domoFieldArr.map((item) => {
      var value = item.domo_value(crudStore);
      // console.log('domo list', item, value)
      if (item.rules && item.rules.includes('required') && _.isEmpty(value.toString())) {
        console.log('========= domo error ========', jsp(item), value)
        throw 'domo error';
      }

      return { [typeof item.domo_field == 'function' ? item.domo_field(crudStore) : item.domo_field]: value };
    }),
  );
  return domoObj;
};

export const removeTrailingValue = (field, separator) => {
  if (field.indexOf(separator) === -1) return;
  const arr = field.split(separator);
  const newArr = arr.pop();
  return newArr == 'ids' ? arr.join(separator) + 's' : newArr == 'id' ? arr.join(separator) : field;
}

export const isDisabled = (data) => {
  // return data != null ? data != "" ? !(data["name"].toLowerCase().includes("facebook & instagram")) : false : false;
  return _.isEmpty(data) ? false : !(data["name"].toLowerCase().includes("facebook & instagram"))
};

export const getInstanceUrl = (channel, model, objectId) =>
  `${model.endpoint}/${objectId}.json`;

export const getModelUrl = (model) => `${model.endpoint}.json`;

export const between = (num, a, b, inclusiveA = true, inclusiveB = true) => {
  if (a > b) [a, b, inclusiveA, inclusiveB] = [b, a, inclusiveB, inclusiveA];
  if (a == b && (inclusiveA || inclusiveB)) [inclusiveA, inclusiveB] = [true, true];
  return (inclusiveA ? num >= a : num > a) && (inclusiveB ? num <= b : num < b);
};

export const permRequest = (rootStore) => {
  const pll = rootStore.apiStore.currentUser.pll;
  const start_range = rootStore.channel.model.permRequest;
  const end_range = rootStore.channel.model.permCreateAndEdit;
  if (typeof start_range == 'number') {
    return between(pll, start_range, end_range, true, false);
  } else return false;
}

export const getPlatformList = (data) => {
  const platform_list = data.filter((a) => !a.social_campaign?.platform?.name.includes(' & '))
  return (platform_list.length == 0) ? data.filter((a) => a.social_campaign).map((b) => b.platform)[0] : []
}

export const getButtonLabel = (rootStore) => {
  if (permRequest(rootStore)) {
    if (rootStore.channel.model.crudStore.initialAction == 'new') {
      return 'Request'
    }
    return false
  }
}

export default storeMetadata;