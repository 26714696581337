import CircularProgress from '@material-ui/core/CircularProgress';
import CrudTableBody from './CrudTableBody';
import CrudTableHead from './CrudTableHead';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import { observer, inject } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import UccDataTable from '../PrimeReactComponent/UccDataTable';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

@inject('rootStore')
@observer
class CrudTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'startDate',
      page: 0,
      rowsPerPage: 100,
    };

  }

  @observable prevModelName = '';
  @observable loading = false;

  componentDidMount = async () => {
    const { rootStore } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    this.loading = true;
    await this.props.model.requiredData(apiStore);
    this.prevModelName = this.props.model.name;
    this.loading = false;
  }


  componentDidUpdate = async (prevProps) => {
    const { apiStore, channel } = this.props.rootStore;
    if (!this.loading && this.props.model.name != this.prevModelName) {
      this.prevModelName = this.props.model.name;
      this.loading = true;
      await this.props.model.requiredData(apiStore);
      this.loading = false;
    }
  }


  handleRequestSort = () => { };

  render() {
    const {
      classes,
      rootStore,
      model,
      childIndex
    } = this.props;
    const channel = rootStore.channel;
    const crudStore = model.crudStore;
    const { order, orderBy, page, rowsPerPage } = this.state;

    // step 1: filter by custom rules defined in metadata:
    const gridColumnsTmp = model.columns.filter(function (element) {
      if (element.grid == undefined) return false;
      return typeof element.grid === "boolean" ? element.grid : element.grid(this);
    }, this);
    // step 2: filter by permissions defined in metadata
    const gridColumnsTmp2 = _.filter(gridColumnsTmp, ({ permListAndView }) => (permListAndView == undefined || parseInt(permListAndView) <= rootStore.apiStore.currentUser.pll))
    // step 3: sort em (feel free to try to get all 3 steps in one line of code!)
    const gridColumns = _.orderBy(gridColumnsTmp2, ['grid_order'], ['asc']);

    if (this.loading) return (<div style={{ textAlign: 'center' }}><CircularProgress /></div>);
    return (
      <UccDataTable
        columns={gridColumns}
        rowsPerPage={rowsPerPage}
        page={page}
        model={model}
        childIndex={childIndex ? true : false}
      />
    );
  }
}

CrudTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CrudTable);
