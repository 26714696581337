import React from 'react';
import { Route } from 'mobx-router';
import TagManager from 'react-gtm-module'
import HomePanel from './HomePanel';
import LegacyAdminPanel from './LegacyAdminPanel';
import SidekiqDashboardPanel from './SidekiqDashboardPanel';
import AdminPanel from './AdminPanel';
import BrandHome from './BrandHome';
import ChannelHierarchy from './ChannelHierarchy';
import UserProfilePanel from '../Auth/UserProfilePanel';

const analytics = (route, params, store) => {
  const cu = store.rootStore.apiStore.currentUser
  const tagMgrArgs = {
    dataLayer: {
      event: 'PageView',
      route: route,
      brandId: params.brand_id,
      model: params.model,
      channel: params.channel,
      userId: cu ? cu.id : ''
    }
  }
  TagManager.dataLayer(tagMgrArgs);
}
import PasswordEditForm from '../Auth/PasswordEditForm';
import PasswordForgotForm from '../Auth/PasswordEditForm';
import NewAccountForm from '../Auth/NewAccountForm';

const homeCrumb = { label: 'Home', viewName: 'home' };

const areaCrumb = (params, store) => {
  const apiStore = store.rootStore.apiStore;
  const brand = apiStore.getBrandFromPath(params.brand_path);
  if (apiStore.brandChanging(brand)) apiStore.changeBrands(brand);

  const label = store.rootStore.channel.adminChannel ?
    'Admin Home' :
    apiStore.currentBrand.name;
  const viewName = store.rootStore.channel.adminChannel ? 'admin' : 'brandHome';
  return { label: label, viewName: viewName, routeParams: params }
};

const channelCrumb = (params, store) => {
  const apiStore = store.rootStore.apiStore;
  const label = store.rootStore.channel.name;
  return { label: label, viewName: '', routeParams: params };
}

const channelBreadCrumb = (params, store) => {
  const { apiStore, uiStore } = store.rootStore;
  uiStore.setBreadcrumbs([
    homeCrumb,
    areaCrumb(params, store),
    channelCrumb(params, store)
  ]);
}

const channelTabsChanged = async (route, params, store) => {
  let { rootStore } = store;
  let { apiStore } = rootStore;
  const brand = apiStore.getBrandFromPath(params.brand_path);
  if (apiStore.brandChanging(brand)) apiStore.changeBrands(brand);
  rootStore.setCurrentChannelAndModel(params.channel, params.model);
  const crudStore = rootStore.channel.model.crudStore;
  if (params.id) {
    crudStore.setShowId(params.id);
    crudStore.action = 'show';
    if (!_.isEmpty(rootStore.channel.model.children)) {
      rootStore.channel.model.children.forEach(sister => sister.crudStore.clearFilters());
      if (!params.childModel) {
        params.childModel = rootStore.channel.model.children[0];
      } else {
        params.childModel = rootStore.channel.model.children.find(m => m.endpoint == params.childModel)
      }
      rootStore.channel.model.setChildModel(params.childModel);
      await params.childModel.crudStore.fetchDisplayedRows(rootStore.channel.model.childModelFilter(rootStore));
      params.childModel.crudStore.fetchTotalRecords(rootStore.channel.model.childModelFilter(rootStore));
    }
    await crudStore.fetchOne();
  } else {
    crudStore.action = 'index';
    const filter = rootStore.channel.adminChannel ? {} : { brand_id: apiStore.currentBrandId };
    await crudStore.fetchDisplayedRows(filter);
    crudStore.fetchTotalRecords({ filter });
  }
  channelBreadCrumb(params, store)
  analytics(route, params, store);
};


const crudChanged = (route, params, store) => {
  // might be used with ROUTING 2.0
  let { rootStore } = store;
  let { apiStore } = rootStore;
  rootStore.setCurrentChannelAndModel(params.channel, params.model);
  rootStore.channel.model.crudStore.setShowId(params.id);
  rootStore.channel.model.crudStore.selectedRowIds = [params.id]
  rootStore.channel.model.crudStore.onEdit()
  // console.log('cc')
}

const brandName = (brand) => {
  return brand ? brand.name : null;
}

const channelName = (channel) => {
  return channel ? channel.name : null;
}


const basicCrumb = (store, crumb) => {
  store.rootStore.uiStore.setBreadcrumbs([
    homeCrumb,
    {
      label: crumb,
    },
  ]);
}


const routes = {
  home: new Route({
    path: '/app',
    component: <HomePanel />,
    onEnter: (route, params, store) => {
      store.rootStore.apiStore.serverRequestError = null;
      store.rootStore.apiStore.clearBrands();
      store.setBreadcrumbs(null);
      analytics(route, params, store)
    },
  }),
  legacyAdmin: new Route({
    path: '/app/legacy-admin',
    component: <LegacyAdminPanel />,
    onEnter: (route, params, store) => {
      basicCrumb(store, 'Legacy Admin');
      analytics(route, params, store)
    }
  }),
  sidekiqDashboard: new Route({
    path: '/app/monitoring/sidekiq',
    component: <SidekiqDashboardPanel />,
    onEnter: (route, params, store) => {
      basicCrumb(store, 'Sidekiq Dashboard')
      analytics(route, params, store)
    }
  }),

  // adminChannelModelEdit: new Route({
  //   // might be used with ROUTING 2.0
  //   path: '/app/admin/:channel/:model/:path/:id/edit',
  //   component: <ChannelHierarchy />,
  //   onEnter: (route, params, store) => crudChanged(route, params, store),
  //   onParamsChange: (route, params, store) => crudChanged(route, params, store),
  // }),


  adminChannelModelChild: new Route({
    path: '/app/admin/:channel/:model/:id/:childModel',
    component: <ChannelHierarchy />,
    onEnter: (route, params, store) => channelTabsChanged(route, params, store),
    onParamsChange: (route, params, store) => channelTabsChanged(route, params, store),
  }),

  adminChannelModelShowTabs: new Route({
    path: '/app/admin/:channel/:model/:id',
    component: <ChannelHierarchy />,
    onEnter: (route, params, store) => channelTabsChanged(route, params, store),
    onParamsChange: (route, params, store) => channelTabsChanged(route, params, store),
  }),
  adminChannelTabs: new Route({
    path: '/app/admin/:channel/:model',
    component: <ChannelHierarchy />,
    onEnter: (route, params, store) => channelTabsChanged(route, params, store),
    onParamsChange: (route, params, store) => channelTabsChanged(route, params, store),
  }),
  adminModelTabs: new Route({
    path: '/app/admin/:channel',
    component: <ChannelHierarchy />,
    onEnter: (route, params, store) => channelTabsChanged(route, params, store),
    onParamsChange: (route, params, store) => channelTabsChanged(route, params, store),
  }),


  admin: new Route({
    path: '/app/admin',
    component: <AdminPanel />,
    onEnter: (route, params, store) => {
      basicCrumb(store, 'Admin');
      analytics(route, params, store)
    }
  }),
  userPasswordEdit: new Route({
    path: '/app/profile/edit-password',
    component: (<PasswordEditForm />),
  }),

  newAccount: new Route({
    path: '/users/new-account',
    component: (<NewAccountForm />),
  }),

  userProfile: new Route({
    path: '/app/profile',
    component: <UserProfilePanel />,
    onEnter: (route, params, store) => {
      basicCrumb(store, 'Profile');
      analytics(route, params, store)
    },
    onParamsChange: (route, params, store) => {
      basicCrumb(store, 'Profile');
      analytics(route, params, store)
    }
  }),

  // channelModelEdit: new Route({
  // might be used with ROUTING 2.0
  //   path: '/app/brands/:channel/:model/:path/:id/edit',
  //   component: <ChannelHierarchy />,
  //   onEnter: (route, params, store) => crudChanged(route, params, store),
  //   onParamsChange: (route, params, store) => crudChanged(route, params, store),
  // }),

  channelTabs: new Route({
    path: '/app/brands/:brand_path/:channel/:model',
    component: <ChannelHierarchy />,
    onEnter: (route, params, store) => channelTabsChanged(route, params, store),
    onParamsChange: (route, params, store) => channelTabsChanged(route, params, store),
  }),
  modelTabs: new Route({
    path: '/app/brands/:brand_path/:channel',
    component: <ChannelHierarchy />,
    onEnter: (route, params, store) => channelTabsChanged(route, params, store),
    onParamsChange: (route, params, store) => channelTabsChanged(route, params, store),
  }),
  brandHome: new Route({
    path: '/app/brands/:brand_path',
    home: 'brandHome',
    component: <BrandHome />,
    onEnter: (route, params, store) => {
      const { apiStore, uiStore, crudStore } = store.rootStore;
      if (crudStore) crudStore.setStoredData('all');
      const brand = apiStore.getBrandFromPath(params.brand_path);
      if (apiStore.brandChanging(brand)) apiStore.changeBrands(brand);
      basicCrumb(store, brandName(apiStore.currentBrand));
      analytics(route, params, store);
    },
    onParamsChange: (route, params, store) => {
      const { apiStore, uiStore } = store.rootStore;
      const brand = apiStore.getBrandFromPath(params.brand_path);
      if (apiStore.brandChanging(brand)) apiStore.changeBrands(brand);
      basicCrumb(store, brandName(apiStore.currentBrand));
      analytics(route, params, store);
    }
  })
};

export default routes;
