import React from 'react';
import * as Sentry from '@sentry/browser';
import { captureException } from '../../lib/sentry';
import { withStyles } from '@material-ui/core/styles';
import UccButton from '../PrimeReactComponent/UccButton';


const styles = (theme) => ({
  errorPanel: {
    borderRadius: '40px',
    boxShadow: '0 2px 4px 3px rgba(0, 0, 0, 0.5)',
    fontSize: '32px',
    width: '737px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '100px',
    paddingTop: '50px',
    paddingBottom: '50px'
  },
  errorPanelParagraph: {
    textAlign: 'left',
    marginLeft: '60px',
    marginRight: '60px'
  },
  errorPanelButton: {
    fontSize: '30px'
  }
});

class AppErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = captureException(error);
      this.setState({ eventId, errorInfo });
    })
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      return (
        <div className={classes.errorPanel}>
          <h3> Application Error </h3>
          <p className={classes.errorPanelParagraph}>
            An unexpected error has occurred and we have been notified of the problem.
          </p>
          <p className={classes.errorPanelParagraph}>
            Please reload the browser and try again.
          </p>
          <p className={classes.errorPanelParagraph}>
            If the problem persists please notify the UCC administrator.
          </p>
          {/* this is where an a link to the user feedback form might go. */}
        </div>
      )
    }

    return this.props.children; 
  }
}

export default withStyles(styles)(AppErrorBoundary);
