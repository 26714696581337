import _ from 'lodash';
import dvr from 'mobx-react-form/lib/validators/DVR';
import FormButtons from '../CrudAdmin/forms/FormButtons';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MobxReactForm from 'mobx-react-form';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import routes from '../Layout/Routes';
import validatorjs from 'validatorjs';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link } from 'mobx-router';
import { observable } from 'mobx';
import { observer, inject, Provider } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const plugins = {
  dvr: dvr(validatorjs),
};

const styles = (theme) => ({
  root: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    marginTop: theme.spacing(8),
  },
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3,
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  error: {
    color: 'red',
    margin: 0,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  textField: {
    width: '95%',
    margin: theme.spacing(2),
  },
  inputLabel: {
    padding: '0px 8px 8px',
    background: '#fff',
    marginBottom: '10px',
  },
  buttonMargin: {
    paddingRight: '10px',
  },
});

@inject('rootStore')
@observer
class PasswordEditForm extends React.Component {
  @observable showPassword = false;
  constructor(props) {
    super(props);
    const { rootStore } = this.props;

    const hooks = {
      onSuccess(form) {
        const info = rootStore.apiStore.savePassword(form);
      },
      onError(form) {
        // crudAdminStore.setValidationError(Object.values(form.errors()).filter(Boolean));
      },
    };
    rootStore.apiStore.alreadySavedPassword = false;
    const fields = this.makeFields();
    this.form = new MobxReactForm({ fields }, { plugins, hooks });
  }

  makeFields = () => {
    return [
      {
        name: 'oldPass',
        label: 'Old Password',
        rules: 'required|string',
        type: 'password',
      },
      {
        name: 'newPass',
        label: 'New Password',
        placeholder: 'Insert Password',
        rules: 'required|string|between:5,25',
        type: 'password',
      },
      {
        name: 'confirmPass',
        label: 'Confirm Password',
        rules: 'required|string|same:newPass',
        type: 'password',
      },
    ];
  };

  savedAlready = () => {
    const apiStore = this.props.rootStore.apiStore;
    const uiStore = this.props.rootStore.uiStore;
    if (_.isEmpty(uiStore) || _.isEmpty(apiStore)) return false;
    if (apiStore.alreadySavedPassword) {
      return true;
    } else {
      return false;
    }
  };

  onCancel(f) {
    f.props.rootStore.uiStore.router.goTo(
      routes.userProfile,
      {},
      f.props.rootStore.uiStore,
    );
  }

  handleClickShowPassword = () => {
    this.showPassword = !this.showPassword;
    const type = this.showPassword ? 'text' : 'password';
    this.form.$('oldPass').set('type', type);
    this.form.$('newPass').set('type', type);
    this.form.$('confirmPass').set('type', type);
  };

  render() {
    const { classes, rootStore } = this.props;
    const { apiStore } = rootStore;
    const { form } = this;
    const savedAlready = this.savedAlready();

    if (savedAlready) {
      return (
        <React.Fragment>
          <Link view={routes.userProfile} store={rootStore.uiStore}>
            Password Saved
          </Link>
          {/* <UserProfilePanel /> */}
        </React.Fragment>
      );
    } else {
      return (
        <div>
          <div className={classes.root}>
            <div className={classes.root}>
              <Typography component="h2" variant="h2" className={classes.h2}>
                Edit Password
              </Typography>
            </div>
            <Paper className={classes.paper} elevation={6}>
              <form className={classes.form} onSubmit={form.onSubmit}>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={(classes.margin, classes.textField)}
                    >
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor="oldPass"
                      >
                        Old Password
                      </InputLabel>
                      <OutlinedInput
                        id="oldPass"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(event) =>
                                this.handleClickShowPassword(event)
                              }
                            >
                              {this.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        {...form.$('oldPass').bind()}
                      />
                      <div className={classes.error}>
                        {form.$('oldPass').error}
                      </div>
                    </FormControl>

                    <FormControl
                      variant="outlined"
                      className={(classes.margin, classes.textField)}
                    >
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor="newPass"
                      >
                        New Password
                      </InputLabel>
                      <OutlinedInput
                        id="newPass"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(event) =>
                                this.handleClickShowPassword(event)
                              }
                            >
                              {this.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        {...form.$('newPass').bind()}
                      />
                      <div className={classes.error}>
                        {form.$('newPass').error}
                      </div>
                    </FormControl>

                    <FormControl
                      variant="outlined"
                      className={(classes.margin, classes.textField)}
                    >
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor="confirmPass"
                      >
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        id="confirmPass"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(event) =>
                                this.handleClickShowPassword(event)
                              }
                            >
                              {this.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        {...form.$('confirmPass').bind()}
                      />
                      <div className={classes.error}>
                        {form.$('confirmPass').error}
                      </div>
                    </FormControl>

                    {!!apiStore.serverValidationErrors ? (
                      <div className={classes.error} id="validation-errors">
                        <List>
                          {apiStore.serverValidationErrors.map((er) => (
                            <ListItem button key={er}>
                              <ListItemText primary={er} />
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    ) : (
                      ''
                    )}
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      spacing={2}
                      className={classes.buttonMargin}
                    >
                      <FormButtons
                        form={form}
                        onSubmit={form.onSubmit}
                        onCancel={() => this.onCancel(this)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </div>
        </div>
      );
    }
  }
}

PasswordEditForm.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PasswordEditForm);
