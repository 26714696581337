import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import Typography from '@material-ui/core/Typography';

import RootStore from '../../store/RootStore';

const AppTitle = inject('rootStore')(observer(({ rootStore }) => (
  <Typography variant="h6" color="inherit" style={{ flex: 1 }}>
    {rootStore.features.instance_title ?
      (<p style={{ color: 'white', marginLeft: '20px', fontSize: '20px' }}>{_.startCase(_.toLower(rootStore.instance.name))}</p>) :
      ('')
    }
  </Typography>
)));

AppTitle.wrappedComponent.propTypes = {
  rootStore: PropTypes.instanceOf(RootStore).isRequired,
};

export default AppTitle;
