import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import CrudAdminRoot from '../CrudAdmin/CrudAdminRoot';
import Breadcrumbs from '../Layout/Breadcrumbs';
import AdminLeftNav from '../Layout/AdminLeftNav';
import ErrorWrapper from '../Layout/ErrorWrapper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

@observer
@inject('rootStore')
class ChannelHierarchy extends React.Component {

  render() {
    const classes = this.props.classes;
    let leftClassings = ['uccLeftColumn'];
    return (
      <ErrorWrapper>
        <div className="uccMainWrapper">
          {/* <div className={leftClassings.join(' ')}></div> */}
          <div className="uccCenterColumn"><CrudAdminRoot /></div>
          {/* <div className="uccRightColumn">&nbsp;</div> */}
        </div>
      </ErrorWrapper>
    );
  }
}


export default withStyles(styles)(ChannelHierarchy);
