import React from 'react';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FavoriteIcon from './FavoriteIcon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import routes from './Routes';
import { right } from 'inquirer/lib/utils/readline';

const styles = theme => ({
  parent: {
    '&:hover': {
      backgroundColor: '#F4F5FD',
    },
    '&:focus': {
      backgroundColor: '#F4F5FD',
    },
    textTransform: 'none',
    fontWeight: 'bold',
    margin: '0px 0 0 0px',
    position: 'relative',

  },
  linkDecor: {
    textDecoration: 'none',
  },
  nestedItem: {
    paddingLeft: 35
  },
  expandicon: {
    position: 'absolute',
    right: '15px',
  },
});

const BrandAccordionNav = inject('apiStore', 'uiStore', 'rootStore')(observer((props) => {
  const [selectedIndex, setSelectedIndex] = React.useState("");
  const [isHovered, setIsHovered] = React.useState("");




  const handlePopoverClick = event => {
    uiStore.setAccordionAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = event => {
    uiStore.setAccordionAnchorEl(null);
  };

  const handleCollapseClick = index => {
    if (selectedIndex === index) {
      setSelectedIndex("");
    } else {
      setSelectedIndex(index);
    }
  }
  const handleMouseEnter = (index) => {
    setIsHovered(index);
  };

  const currentPrettyPath = () => {
    const metaChannels = rootStore.storeMetadata.channels;
    const { router } = uiStore;
    let prettyPath = "";

    const channel = router.params['channel'] ? metaChannels.find(c => c.endpoint == router.params['channel']) : "";
    const brand = router.params['brand_path'] ? brands.find(b => b.path == router.params['brand_path']) : "";

    if (router.currentPath == '/app') {
      prettyPath = 'Home'
    } else if (brand && channel) {
      prettyPath = brand.name + " - " + channel.name;
    } else if (brand) {
      prettyPath = brand.name;
    }

    return prettyPath;
  }

  const { uiStore, apiStore, rootStore, classes } = props;
  const id = uiStore.accordionMenuOpen ? 'simple-popover' : undefined;
  const { brands, channels } = apiStore;
  if (_.isEmpty(apiStore.brandsChannelsMap)) return;

  return (
    apiStore.authenticated && (
      <div >
        <Button aria-describedby={id} onClick={handlePopoverClick} className={classes.parent} data-qa='accordion-nav' >
          {currentPrettyPath()}
          {uiStore.accordionAnchorEl ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Popover
          id={id}
          open={uiStore.accordionMenuOpen}
          anchorEl={uiStore.accordionAnchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          fontWeight='bold'
        >
          <List component="nav">
            {apiStore.brandsChannelsMap.map((bcm, index) => {
              return (
                <List key={`${bcm.brand.abbrev}-list`} disablePadding >
                  <ListItem onMouseEnter={() => { handleMouseEnter(bcm.brand.id) }} onMouseLeave={() => { handleMouseEnter("") }} key={bcm.brand.abbrev} button data-qa={`nav-channel-${bcm.brand.path}`} classes={{ root: classes.parent }}>
                    <ListItemIcon>
                      <div key={bcm.brand.id}>
                        <FavoriteIcon
                          hoverState={bcm.brand.id === isHovered ? true : false}
                          brandId={bcm.brand.id}
                          key={bcm.brand.id}
                        />
                      </div>
                    </ListItemIcon>
                    <ListItemText onClick={() => { handleCollapseClick(index) }} style={{ width: 250 }} >
                      {bcm.brand.name}
                      {index === selectedIndex ? <ExpandLess className={(classes.expandicon)} /> : <ExpandMore className={(classes.expandicon)} />}
                    </ListItemText>

                  </ListItem>
                  <Collapse in={index === selectedIndex} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {bcm.channels.map(channel => {
                        let metaChannel = rootStore.storeMetadata.channels.find(c => c.codename == channel.codename)
                        if (!metaChannel || metaChannel.disabled) return (<></>)
                        // console.log(metaChannel.name)
                        let model = metaChannel.models[0];
                        return (
                          <ListItem key={`${metaChannel.endpoint}-${model.route}`} button onClick={() => uiStore.router.goTo(
                            routes.channelTabs,
                            { brand_path: bcm.brand.path, channel: metaChannel.endpoint, model: model.route },
                            metaChannel[model.codename].crudStore,
                          ) && uiStore.setAccordionAnchorEl(null)}
                            data-qa={`nav-model-${bcm.brand.path}-${metaChannel.endpoint}`}
                            classes={{ root: classes.parent }}
                          >
                            <ListItemText primary={channel.name} style={{ marginLeft: 90 }} />
                          </ListItem>
                        )
                      })}
                    </List>
                  </Collapse>
                </List>
              )
            })}
          </List>
        </Popover>
      </div>
    )
  );
}))

export default withStyles(styles)(BrandAccordionNav);
