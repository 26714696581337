import React from 'react';
import { observer, inject } from 'mobx-react';
import routes from '../Layout/Routes';
import { Dropdown } from 'primereact/dropdown';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  dropdown: {
    display: 'inline',
    textAlign: 'left',
    width: '200px',
    height: '40px',
    marginTop: '12px',
    marginLeft: '10px',
  }
});

const UCCDropDownNav = inject('uiStore', 'rootStore')(observer((props) => {
  const { classes, uiStore, rootStore: { storeMetadata: { channels } } } = props;
  const { router } = uiStore;
  const channel = router.params['channel'] ? channels.find(c => c.endpoint == router.params['channel']) : "";
  const model = router.params['model'] ? channel.models.find(m => m.route == router.params['model']) : "";
  const filter = channel.showFilter ? true : false;
  const opts = _.sortBy(channel.models, 'name').filter(model => model.permListAndView <= model.crudStore.rootStore.apiStore.currentUser.pll).map((m) => { return { label: m.name, value: m } });
  return <Dropdown
    value={model}
    options={opts}
    onChange={(e) => uiStore.router.goTo(routes.adminChannelTabs, { channel: channel.endpoint, model: e.value.route }, e.value.crudStore)}
    className={classes.dropdown}
    filter={filter}
  />;
}))

export default withStyles(styles)(UCCDropDownNav);
