import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import routes from './Routes';
import { Link } from 'mobx-router';
import { observer, inject } from 'mobx-react';
import FavoriteIcon from './FavoriteIcon';

const styles = (theme) => ({
  chevronicon: {
    textAlign: 'right',
    position: 'absolute',
    right: '15px',
  },
  parent: {
    width: '100%',
    margin: '0px 0 0 0px',
    position: 'relative',
    display: 'inline-block',
  },
  dividerInset: {
    marginLeft: '0px',
  },
  parent: {
    height: 60,
    paddingTop: 10,
    '&:hover ': {
      color: '#495057',
      backgroundColor: '#f4f5fd',
      opacity: 1,
    },
  },
  linkHover: {
    color: '#495057',
    height: 60,
    paddingTop: 15,
    '&:hover ': {
      color: '#495057',
      opacity: 1,
    },
  },
});
@inject('uiStore', 'apiStore')
@observer
class BrandMenuItem extends React.Component {
  constructor() {
    super();
    this.state = {
      isHovered: {},
    };
  }

  handleMouseEnter = (index) => {
    this.setState((prevState) => {
      return { isHovered: { ...prevState.isHovered, [index]: true } };
    });
  };
  handleMouseLeave = (index) => {
    this.setState((prevState) => {
      return { isHovered: { ...prevState.isHovered, [index]: false } };
    });
  };
  render() {
    const { isHovered } = this.state;
    const { uiStore, apiStore, classes } = this.props;
    const { router } = uiStore;
    const nextRoute =
      router.params && router.params['brand_id'] != null
        ? router.currentView
        : routes.brandHome;
    return (
      <div id={this.props.brand.abbrev}>
        <ListItem
          onMouseEnter={() => this.handleMouseEnter(this.props.brand.id)}
          onMouseLeave={() => this.handleMouseLeave(this.props.brand.id)}
          button
          className={classes.parent}
        >
          <ListItemIcon>
            <div key={this.props.brand.id}>
              <FavoriteIcon
                hoverState={isHovered[this.props.brand.id]}
                brandId={this.props.brand.id}
                key={this.props.brand.id}
              />
            </div>
          </ListItemIcon>
          <Link
            underline="none"
            view={nextRoute}
            params={_.merge(
              {},
              router.params,
              { brand_path: this.props.brand.path }, //uta-107 note: this may need to change to send to brand home later.
            )}
            store={uiStore}
            key={this.props.brand.id}
            component="button"
            className={classes.linkHover}
            style={{ textDecoration: 'none', width: '100%' }}
          >
            <ListItemText
              disableTypography={true}
            >
              {this.props.brand.name}{' '}
              <ChevronRightIcon
                className={(classes.chevronicon)}
              />
            </ListItemText>
          </Link>
        </ListItem>
        <Divider
          variant="inset"
          component="li"
          className={classes.dividerInset}
        />
      </div>
    );
  }
}

export default withStyles(styles)(BrandMenuItem);
