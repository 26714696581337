
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import NameString from './../../NameString';
import UccApproveDenyButton from '../../PrimeReactComponent/UccApproveDenyButtons';
import UccDoubleButton from '../../PrimeReactComponent/UccDoubleButton';
import UccThreeButtons from '../../PrimeReactComponent/UccThreeButtons';
import UccDate from '../../PrimeReactComponent/UccDate';
import UccDoubleDatePicker from '../../PrimeReactComponent/UccDoubleDatePicker';
import { formElements } from '../../../store/StoreMetadata';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { getButtonLabel } from '../../../store/StoreMetadata.js'



const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: '7px',
    paddingRight: '6px',
    flexGrow: 1,
  },
  tall: {
    height: 30,
  },
  modifierWrapper: {
    // border: '1px solid black',
    width: 'min-content',
    marginLeft: 20,
    borderRadius: 4,
    width: 487,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  modifierField: {
    width: '468px'
  }
});

@inject('rootStore')
@observer
class GenericForm extends React.Component {
  @observable suggestedEndDate = '';

  constructor(props) {
    super(props);
    const crudStore = this.props.rootStore.channel.model.crudStore;
    const hooks = crudStore.hooks(crudStore);
    this.form = crudStore.form;
    this.props.rootStore.react = this;
  }

  componentDidMount = async () => {
    const { apiStore, uiStore, channel } = this.props.rootStore;
    const crudStore = channel.model.crudStore;
    crudStore.formComponentDidMount();
    /* Display Forms */
    if (['copy', 'edit'].includes(crudStore.action)) {
      crudStore.pivotControlChanged('tactic', 'targeting', false, true);
    }
    /* Ad Form */
    if (crudStore.form && crudStore.form.fields.has('social_ad_set_id')) {
      channel.model.crudStore.storeData('social_ad_set_id', channel.model.crudStore.form.$('social_ad_set_id').value);
    }
    /* Paid Search Form */
    if (['copy', 'edit'].includes(channel.model.crudStore.action)) {
      // channel.model.crudStore.primeTacticChanged(this.props.rootStore.channel.model.crudStore.storedData.tactic_id, true);
      channel.model.crudStore.pivotControlChanged('objective_modifier', 'extra_field', false, true);
    }
  };

  componentWillUnmount = () => {
    const crudStore = this.props.rootStore.channel.model.crudStore;
    const apiStore = this.props.rootStore.apiStore;
    if (apiStore.adNameTabDisabled) crudStore.selectedRowIds = crudStore.selectedRowIds.slice();
    this.props.rootStore.uiStore.unmountComponent();
  }

  render() {
    const { classes, rootStore, type, datePicker, numButtons, extraFields } = this.props;
    const { channel, apiStore, uiStore } = rootStore;
    const { form } = this;
    const model = channel.model;
    const crudStore = model.crudStore;
    const selectedAttribute = crudStore.storedData?.['unique_attribute'];

    const crudParam = selectedAttribute?.replace('_id', '');

    const getLabel = getButtonLabel(rootStore);
    const action = crudStore.action;
    if (_.isEmpty(crudStore.form)) return null;
    if (crudStore.loading) return (<div style={{ textAlign: 'center' }}><CircularProgress /></div>);
    if (crudStore.loading) return (<div>loading</div>);

    const fullForm = formElements(rootStore).map(fe => fe.formField(rootStore));
    const extraFormFields = !!extraFields && extraFields.map(ef => {
      const column = model.columns?.find(f => f.form && f.form == ef);
      return column?.formField(rootStore);
    });

    return (
      <div className={classes.root}>
        <Grid xs={12} container direction="row" justify="flex-start" alignItems="stretch" wrap="nowrap">
          <form className={classes.form}>
            <Grid container alignItems="flex-start">
              <Grid item xs={12} >

                {datePicker === 1 && (
                  <div className={classes.dates} style={{ paddingLeft: '8px' }}>
                    <UccDate
                      label={this.props.rootStore.instance.name == 'astellas' ? "Scheduled Date" : "Send Date"}
                      core="send_date"
                      form={crudStore.form}
                      onInput={(event) => crudStore.storeData('send_date', event)}
                      fieldName="send_date"
                    />
                  </div>
                )}

                {fullForm.map((component, index) => (<span key={index}>{component}</span>))}
                {!!extraFields && extraFormFields}

                {datePicker === 2 && (
                  <UccDoubleDatePicker form={crudStore.form} />
                )}
                {crudStore.validationErrorsPrint()}
                {numButtons === 'approve' ? (
                  <UccApproveDenyButton
                    form={crudStore.form}
                    onSubmit={crudStore.form.onSubmit}
                    onCancel={crudStore.onCancel}
                    label={getLabel}
                  />
                ) : numButtons === 2 ? (
                  <UccDoubleButton
                    form={crudStore.form}
                    onSubmit={crudStore.form.onSubmit}
                    onCancel={crudStore.onCancel}
                  />
                ) : numButtons === 3 && (
                  <UccThreeButtons
                    form={crudStore.form}
                    onSubmit={crudStore.form.onSubmit}
                    onCancel={crudStore.onCancel}
                  />
                )}
              </Grid>
            </Grid>
          </form>
          {!!type && (
            <Grid item xs >
              <NameString type={type} store={crudStore} bulkCreateAttribute={crudParam} />
            </Grid>
          )}
        </Grid>
        <div className={classes.tall}>&nbsp;</div>
      </div>
    );
  }
}

GenericForm.wrappedComponent.propTypes = {
  // apiStore: PropTypes.instanceOf(ApiStore).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GenericForm);
