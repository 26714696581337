import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import UccBulkNamestringModal from './UccBulkNamestringModal';
import UccDoubleDatePicker from './UccDoubleDatePicker';
import UccDoubleButton from './UccDoubleButton';
import UccErrorHandling from './UccErrorHandling';
import UccInputText from './UccInputText';
import UccSelect from './UccSelect';
import { copyEditDropDownList } from '../../store/StoreMetadata';
import { getFormElement } from '../../store/StoreMetadata';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { InputNumber } from 'primereact/inputnumber';
import { withStyles } from '@material-ui/core/styles';
import { InputText } from 'primereact/inputtext';
import { dateField } from '../../store/StoreMetadata';
import './PrimeStyle.css';

@inject('rootStore')
@observer
class UccBulkCalendar extends Component {
  constructor(props) {
    super(props);
  }

  onChangeHandler(event, param) {
    this.props.parent.setIsError();
    this.props.rootStore.channel.model.crudStore.storeData(param, event)
  }

  render() {
    const { rootStore, form, label, classes, display, onCancel } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    crudStore.storeData('isStartDateRequired', this.props.rootStore.channel.model.columns.find(d => d.form == 'start_date') === undefined ? false : this.props.rootStore.channel.model.columns.find(d => d.form == 'start_date').rules.includes('required'));
    crudStore.storeData('isEndDateRequired', this.props.rootStore.channel.model.columns.find(d => d.form == 'end_date') === undefined ? false : this.props.rootStore.channel.model.columns.find(d => d.form == 'end_date').rules.includes('required'));
    crudStore.storeData('isSendDateRequired', this.props.rootStore.channel.model.columns.find(d => d.form == 'send_date') === undefined ? false : this.props.rootStore.channel.model.columns.find(d => d.form == 'send_date').rules.includes('required'));
    return (
      <div>
        {channel.endpoint !== 'email' && (!channel.model.dontDisplayDate) &&
          <div style={{ marginTop: '53px', marginBottom: '10px' }} className="container " >
            <div className="p-float-label one-calendar" style={{ paddingLeft: '0px' }}>
              <Calendar
                id='text_id-startDate'
                type="text"
                showButtonBar={true}
                showIcon={true}
                disabled={crudStore.storedData['keep_original_dates']}
                model={model}
                value={crudStore.storedData['start_date']}
                onChange={(event) => this.onChangeHandler(event, 'start_date')}
                className='calendar-btn'
                inputClassName={crudStore.startDateValid ? '' : 'error-btn'}
                inputStyle={(crudStore.startDateValid) ? { width: '172px' } : { width: '172px', borderTop: '2px solid #FF5656', borderLeft: '2px solid #FF5656', borderBottom: '2px solid #FF5656' }}
              />
              <label htmlFor='text_id-startDate'>{!crudStore.storedData['isStartDateRequired'] ? '(Opt.) ' : ''} Start Date  </label>
              {!crudStore.startDateValid && <UccErrorHandling fieldName='start_date' callFrom='modal' />}
            </div>
            <div className="calendarSecondHalf p-float-label" style={{ marginLeft: '54%' }}>
              <Calendar
                id='text_id-endDate'
                type="text"
                showButtonBar={true}
                showIcon={true}
                disabled={crudStore.storedData['keep_original_dates']}
                onChange={(event) => this.onChangeHandler(event, 'end_date')}
                value={crudStore.storedData['end_date']}
                className='calendar-btn'
                inputClassName={crudStore.endDateValid ? '' : 'error-btn'}
                inputStyle={(crudStore.endDateValid) ? { width: '172px' } : { width: '172px', borderTop: '2px solid #FF5656', borderLeft: '2px solid #FF5656', borderBottom: '2px solid #FF5656' }}
              />
              <label htmlFor='text_id-endDate'>{!crudStore.storedData['isEndDateRequired'] ? '(Opt.)' : ''} End Date </label>
              {!crudStore.endDateValid && <UccErrorHandling fieldName='end_date' callFrom='modal' />}
            </div>
          </div>
        }
        {channel.endpoint === 'email' &&
          <div style={{ marginTop: '45px', marginBottom: '10px' }} className="container">
            <div className="calendarFirstHalf p-float-label" style={{ paddingLeft: '0px' }}>
              <Calendar
                id='text_id-startDate'
                type="text" showButtonBar={true}
                showIcon={true} value={crudStore.storedData['send_date']}
                disabled={crudStore.storedData['keep_original_dates']}
                onChange={(event) => this.onChangeHandler(event, 'send_date')}
                className='calendar-btn'
                inputClassName={crudStore.sendDateValid ? '' : 'error-btn'}
                inputStyle={(crudStore.sendDateValid) ? { width: '172px' } : { width: '172px', borderTop: '2px solid #FF5656', borderLeft: '2px solid #FF5656', borderBottom: '2px solid #FF5656' }}
              />
              <label htmlFor='text_id-startDate'>{!crudStore.storedData['isSendDateRequired'] ? '(Opt.)' : ''} Send Date </label>
              {!crudStore.sendDateValid && <UccErrorHandling fieldName='send_date' callFrom='modal' />}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default UccBulkCalendar;