import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import TargetingField from '../TargetingField';
import PivotField from '../../../PrimeReactComponent/PivotField';
import { InputText } from 'primereact/inputtext';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import UccSwitch from '../../../PrimeReactComponent/UccSwitch';
import jsp from '../../../../lib/jsp';

const styles = (theme) => ({
  root: {},
  targeting: {
    paddingTop: "3 !important",
  },
  targetingH3: {
    marginBottom: 18,
    marginLeft: 32,
    fontFamily: 'Avenir LT W01_65 Medium1475532',
    fontSize: 18,
    fontWeight: 300,
    fontStretch: 'normal',
    letterSpacing: 'normal',
    // paddingTop: 12,
    paddingLeft: 16,
  },
  contrainerWidth: {
    borderRadius: 4,
    width: 473,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  fieldsetPadding: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
});

@inject('rootStore')
@observer
class UtaTargeting extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => { };

  isChecked = (val = false) => {
    return this.props.form.$('suppression').value == false ? false : true;
  };

  suppressionField = () => {
    if (this.props.dependent != 'targeting') return null;

    return (
      // <Grid item xs={4}>
      <div style={{ marginTop: '30px' }}>
        <UccSwitch
          checked={this.isChecked()}
          onChange={(e) => this.props.rootStore.channel.model.crudStore.storeData('suppression', e)}
          label='Suppression'
        />
      </div>

      // </Grid>
    )
  }


  pivotFields = (pivotFields) => {
    const { form, rootStore, classes, control = 'tactic', dependent = 'targeting' } = this.props;
    const finalPivotFields = [];
    const checkboxes = dependent == 'targeting' ? true : false;
    rootStore.apiStore[_.camelCase(dependent) + 'Fields'].sort(function (a, b) { return a.position - b.position }).map((currentField, index) => {

      const props = {
        row_id: rootStore.channel.model.crudStore.storedData['id'],
        form: form,
        field: currentField,
        setNameString: parent.setNameString,
        callFrom: 'days',
        control: control,
        dependent: dependent,
        checkboxes: checkboxes
      };
      // this.props.dependent == 'objective_modifier' ?
      finalPivotFields.push(<PivotField  {...props} />); //:
      // finalPivotFields.push(<TargetingField  {...props} />);

    })
    return finalPivotFields;
  }

  render() {
    const { form, rootStore, classes, control = 'tactic', dependent = 'targeting' } = this.props;
    // console.log('UtaTargeting form fields', form.$(`${rootStore.channel.model.controller}_targetings_attributes`).values().map(v => v['targeting_id']));
    const { crudStore } = rootStore.channel.model;
    const uiStore = this.props.rootStore.uiStore;
    const displayFields = (dependent == 'extra_field' && this.props.rootStore.apiStore.sortedExtraFieldFields.length > 0) || dependent == 'targeting'
    if (!form.has(control + '_id')) return '';
    if (!parseInt(form.$(control + '_id').value)) return '';

    return (

      displayFields &&
      <fieldset id="fieldset-targeting" className={classes.fieldsetPadding} >
        <Box border={1} className={classes.contrainerWidth}>
          <Grid container spacing={6} alignItems="center">
            <Grid xs={displayFields ? 6 : 4} className={classes.targeting} style={(dependent == 'extra_field' || this.props.dependent == undefined) ? { marginTop: '40px' } : { marginTop: '30px' }}>
              <Typography
                component="h3"
                variant="h3"
                className={classes.targetingH3}
              >
                {uiStore.humanize(dependent == 'extra_field' ? 'objective_modifier' : dependent)}
              </Typography>
            </Grid>

            {this.suppressionField()}
          </Grid>

          <div className="targeting-container">{this.pivotFields()}</div>

          {/* {
            this.targetingField('beep')this.props.rootStore.apiStore.targetings.map(targeting => {
              return (
                <p>
                  {this.targetingField('beep')}
                </p>);

            })
          } */}


          {/* <div className="targeting-container">{crudStore.targetingFields}</div> */}
        </Box>
      </fieldset >
    );
  }
}

UtaTargeting.wrappedComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UtaTargeting);
{
  /* { CREATIVE NAME / AD DESCRIPTION } */
}
