import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, disableSubmitButton, formFields, getInstanceUrl, getModelUrl, getPlatformList, getRelationId, generateFilterLabel, HeadedCamelCase, inputTextFilter, isDisabled, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');


export const paid_social = {
  abbrev: 'PaidSocial',
  codename: 'paidSocial',
  endpoint: 'paid-social',
  icon: 'PaidSocial',
  id: 1,
  name: 'Paid Social',
  showViewIcon: true,
  models: [
    //    ___   __   _  _  ____   __   __  ___  __ _  ____
    //   / __) / _\ ( \/ )(  _ \ / _\ (  )/ __)(  ( \/ ___)
    //  ( (__ /    \/ \/ \ ) __//    \ )(( (_ \/    /\___ \
    //   \___)\_/\_/\_)(_/(__)  \_/\_/(__)\___/\_)__)(____/

    {
      allFilterData: (apiStore) => apiStore['socialCampaignsFilterOptions'],
      codename: 'SocCamp',
      controller: 'social_campaign',
      domoNsType: 'SocialCampaign',
      endpoint: 'social-campaigns',
      filterOptionsCall: (apiStore) => (val) => apiStore['socialCampaignsFilterOptions'] = val,
      form: () => <GenericForm type="Campaign" datePicker={2} numButtons={3} />,
      genericModelPromiseEndpoints: [
        'ad-sets',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-objectives',
        'channel-platforms',
        'facebook-connections',
        'objectives',
        'platforms',
        'statuses',
        'users',
      ],
      hasChildren: true,
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.newCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
        // apiStore.displayAdsCrud.getFacebookConnections(and_id }, ''),'facebook-connections',
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Campaigns',
      permCreateAndEdit: 10,
      permDelete: 20,
      permListAndView: 20,
      permRequest: false,
      route: 'campaigns',
      send2domo: true,
      singular: 'campaign',
      showFormat: 'definitionList',
      tabData: (apiStore) => apiStore.genericTabPromiseResults(),
      tabPromiseEndpoints: (rootStore, brand_param) => {
        const allFilter = _.merge(
          brand_param,
          rootStore.paidSocial.SocAdSet.modelFilter(rootStore),
        );
        return [
          rootStore.newCampaignsCrud.getTotal(brand_param),
          rootStore.newAdsetsCrud.getTotal(allFilter, ''),
        ];
      },
      tabIndex: 0,
      tabLabel: (m) => {
        return m.crudStore.selectedRowIds.length > 0
          ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)`
          : m.name
      },
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.newCampaignsCrud,
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 6,
          // domo_field: 'nsid',
          // domo_value: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 4,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'SocialCampaign',
          rules: 'required',
        },
        {
          form: 'group_id',
          rules: 'required',
          type: 'hidden',
          utaType: 'group',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          form: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          ns_order: 1,
          utaType: 'group',
        },
        {
          domo_field: 'brand_id',
          domo_value: (crudStore) => getRelationId(crudStore, 'brand'),
          form: 'brand_id',
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          form: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 2,
          utaType: 'brand',
        },
        {
          domo_field: 'channel',
          domo_value: (crudStore) => crudStore.channel.abbrev,
          id: 'channel',
          form: 'channel',
          name: 'channel',
          ns: (crudStore) => crudStore?.channel?.abbrev,
          ns_order: 3,
          type: 'hidden',
          utaType: 'channel',
        },
        {
          colSize: 8,
          domo_field: 'status',
          domo_value: () => 'static',
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 4,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },

        {
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: (crudStore) => crudStore.props.rootStore.apiStore.currentBrand.enable_fb,
          grid_link: 'facebook-campaigns',
          grid_order: 5,
          grid_value: (row) => row.facebook_id,
          id: 'facebook_id',
          label: 'facebook_id',
          name: 'facebook_id',
          permListAndView: 20,
          rules: 'integer',
          utaType: 'string',
          ns: (crudStore) => crudStore.storedData.facebook_id,
        },
        {
          grid: false,
          id: 'facebook_detail',
          label: 'facebook_detail',
          name: 'facebook_detail',
          rules: 'integer',
          utaType: 'string',
          ns: (crudStore) => crudStore.storedData.facebook_detail,
        },
        {
          grid: false,
          id: 'facebook_created_at',
          label: 'facebook_created_at',
          name: 'facebook_created_at',
          rules: 'date',
          utaType: 'date',
          ns: (crudStore) => crudStore.storedData.facebook_created_at,
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
          copyEditable: true,
          form: 'campaign_type_id',
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            label={'Campaign Type'}
            form={rootStore.channel.model.crudStore.form}
            // menuItems={rootStore.apiStore.campaignTypes.map((ct) => rootStore.uiStore.makePrimeRegularOption(ct))}
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 2,
          name: 'campaign_type_id',
          label: 'Campaign  Type',
          rules: 'required|integer',

          utaType: 'fk',
        },
        {
          colSize: 12,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          grid: true,
          grid_order: 6,
          grid_value: (row) => property(row.campaign_type, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          // options: (apiStore) => apiStore.campaignTypes;
          filter_placeholder: 'Type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          ns_order: 4,
          options: (apiStore) => apiStore.campaignTypes,
          show: true,
          show_value: (row) => property(row.campaign_type, "name"),
          show_order: 4,
        },
        {
          colSize: 10,
          copyEditable: true,
          domo_field: 'campaign_name',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          form: 'name',
          formField: (rootStore) => (<UccInputText
            label="Description"
            core="name"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
            fieldName="name"
          />),
          formOrder: 7,
          grid: true,
          grid_order: 16,
          grid_value: (row) => row.name,
          grid_label: 'Description',
          filter_placeholder: 'Description',
          id: 'name',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          label: 'Description',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          ns_order: 7,
          utaType: 'name',
          rules: 'string',
          show: true,
          show_value: (row) => row.name,
          show_order: 7,
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 2,
          grid_type: 'namestring',
          id: 'namestring',
          filter: false,
          field: 'namestring',
          label: 'Namestring',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          colSize: 8,
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 7,
          grid_value: (row) => property(row.objective, 'name'),
          id: 'objective',
          label: 'Objective',
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'objective',
          ns: (crudStore) => abbrev(crudStore.storedData.objective),
          ns_order: 5,
          rules: 'required',
          options: (apiStore) => apiStore.objectives,
          show: true,
          show_value: (row) => property(row.objective, "name"),
          show_order: 5,
        },
        {
          belongsTo: ['objective', 'objectives'],
          copyEditable: true,
          form: 'objective_id',
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            label={'Objective'}
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.makeObjectiveOptions()}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 3,
          label: 'Objective',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          belongsTo: ['platform', 'platforms'],
          colSize: 8,
          copyEditable: true,
          form: 'platform_id',
          formField: (rootStore) => (<UccSelect
            core="platform"
            fieldName="platform_id"
            label="Platform"
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.menuItems('platforms', 'makePrimeChannelSpecificOption', 'channelPlatforms')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
          />),
          formOrder: 4,
          label: 'Platform',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.platform, "name"),
          show_order: 5,
          utaType: 'fk',
        },
        {
          domo_field: 'platform',
          domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
          grid: true,
          grid_order: 8,
          grid_value: (row) => property(row.platform, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.platforms,
          id: 'platform',
          label: 'Platform',
          name: 'platform',
          ns: (crudStore) => abbrev(crudStore.storedData.platform),
          ns_order: 6,
          rules: 'required',
        },
        {
          colSize: 10,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 150,
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          label: 'Created By',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.Users,
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
        {
          label: 'Facebook Push Date',
          show_value: (row) => crudStore.storedData.facebook_updated_at,
          show_order: 195,
          id: 'facebook_updated_at',
          name: 'facebook_updated_at',
          ns: (crudStore) => crudStore.storedData.facebook_updated_at,
        },
      ].concat(
        dateField(
          'start_date',
          'Start Date',
          'blank',
          false,
          100,
          130,
          'S.',
        ),
        dateField('end_date', 'End Date', 'blank', false, 110, 140, 'E.'),
      ),
    },

    //    ___      _ _____      _
    //   / _ \    | /  ___|    | |
    //  / /_\ \ __| \ `--.  ___| |_ ___
    //  |  _  |/ _` |`--. \/ _ \ __/ __|
    //  | | | | (_| /\__/ /  __/ |_\__ \
    //  \_| |_/\__,_\____/ \___|\__|___/

    {
      allFilterData: (apiStore) => apiStore['adSetsFilterOptions'],
      codename: 'SocAdSet',
      controller: 'social_ad_set',
      crud: (rootStore) => rootStore.newAdsetsCrud,
      domoNsType: 'SocialAdSet',
      filterOptionsCall: (apiStore) => (val) => apiStore['adSetsFilterOptions'] = val,
      endpoint: 'ad-sets',
      form: () => <GenericForm type="Adset" datePicker={2} numButtons={3} />,
      genericModelPromiseEndpoints: [
        'ad-types',
        'ad-type-channels',
        'campaign-types',
        'channel-genders',
        'channel-platforms',
        'channel-tactics',
        'channel-targetings',
        'devices',
        'genders',
        'geos',
        'languages',
        'objectives',
        'platforms',
        'social-campaigns',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      hasChildren: true,
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.newAdsetsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, social_campaign_id: root.paidSocial.SocCamp.crudStore.selectedRowIds, }; },
      name: 'AdSets',
      noDataText: (r) => r.paidSocial.SocCamp.crudStore.selectedRowIds.length > 0 ? ' for the selected Campaign(s)' : '',
      parentModel: 'social_campaign',
      parentModelCode: 'SocCamp',
      permCreateAndEdit: 10,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'ad-sets',
      send2domo: true,
      singular: 'adset',
      showFormat: 'definitionList',
      tabData: (apiStore) => apiStore.genericTabPromiseResults(),
      tabPromiseEndpoints: (rootStore, brand_param) => {
        const allFilter = _.merge(
          brand_param,
          rootStore.paidSocial.SocAdSet.modelFilter(rootStore),
        );
        return [
          rootStore.newCampaignsCrud.getTotal(brand_param),
          rootStore.newAdsetsCrud.getTotal(allFilter, ''),
        ];
      },
      tabDisabled: (r) => r.apiStore.adsetTabDisabled ? true : false,
      tabIndex: 1,
      tabLabel: (m) => {
        return m.crudStore.selectedRowIds.length > 0 ?
          `${m.name} (${m.crudStore.selectedRowIds.length} selected)` :
          m.name;
      },
      updateCallback: (root) => root.channel.SocAd.crudStore.adsetOptions = [],
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 4,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },

        {
          domo_field: 'ns_type',
          domo_value: () => 'SocialAdSet',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: () => 'group',
          utaType: 'group',
          rules: 'required',
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 2,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'channel',
          domo_value: () => 'PaidSocial',
          ns: () => 'hmm',
          name: 'channel',
          id: 'channel',
          utaType: 'channel',
          rules: 'required',
        },
        {
          colSize: 10,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          name: 'created_by',
          grid: true,
          grid_order: 180,
          id: 'created_by',
          label: 'Created By',
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.Users,
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: () => 'group',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 1,
          utaType: 'brand',
        },
        {

          domo_field: 'brand_id',
          domo_value: (crudStore) => getRelationId(crudStore, 'brand'),
          name: 'brand_id',
          ns: () => 'brand_id',
          utaType: 'brand',
          rules: 'required',
        },
        {
          domo_field: 'channel',
          domo_value: (crudStore) => crudStore.channel.abbrev,
          id: 'channel',
          name: 'channel',
          ns: () => 'store me',
          rules: 'required',
          utaType: 'channel',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: () => 'static',
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 5,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: (crudStore) => crudStore.props.rootStore.apiStore.currentBrand.enable_fb,
          grid_link: 'facebook-adsets',
          grid_order: 5,
          grid_value: (row) => row.facebook_id,
          id: 'facebook_id',
          label: 'facebook_id',
          name: 'facebook_id',
          permListAndView: 20,
          rules: 'integer',
          utaType: 'string',
          ns: (crudStore) => crudStore.storedData.facebook_id,
        },
        {
          grid: false,
          id: 'facebook_detail',
          label: 'facebook_detail',
          name: 'facebook_detail',
          rules: 'integer',
          utaType: 'string',
          ns: (crudStore) => crudStore.storedData.facebook_detail,
        },
        {
          grid: false,
          id: 'facebook_created_at',
          label: 'facebook_created_at',
          name: 'facebook_created_at',
          rules: 'date',
          utaType: 'date',
          ns: (crudStore) => crudStore.storedData.facebook_created_at,
        },
        {
          belongsTo: ['social_campaign', 'socialCampaigns'],
          form: 'social_campaign_id',
          label: 'Campaign',
          rules: 'required|integer',
          utaType: 'fk',
          copyEditable: false,
          formField: (rootStore) => (<UccSelect
            core="social_campaign"
            fieldName="social_campaign_id"
            label="Social Campaign"
            // disabled={true}
            disabled={rootStore.paidSocial?.SocCamp?.selectedRowIds?.length == 1 && rootStore.channel.model.crudStore.initialAction == 'bulk_create'}
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.channel.model.crudStore.makePrimeCampaignOptionsArray(_.sortBy(rootStore.apiStore.socialCampaigns, c => - c.namestring_id))}
            onChange={(event) => {
              disableSubmitButton(rootStore, event);
              rootStore.channel.model.crudStore.storeData('social_campaign_id', event);
              if (rootStore.channel.model.crudStore.firstPage || rootStore.channel.model.crudStore.storedData['unique_attribute'] != 'social_campaign_id') {
                rootStore.channel.model.crudStore.storeData('platform_id', property(property(rootStore.paidSocial.SocAdSet.crudStore.storedData['social_campaign'], 'platform'), 'id'));
              }
            }
            }
          />),
          formOrder: 1,
          subType: 'inherit',
        },
        {
          name: 'social_campaign',
          ns: (crudStore) => 'PARENT'
        },
        {
          copyEditable: true,
          domo_field: 'campaign_name',
          domo_value: (crudStore) => property(crudStore.storedData.social_campaign, 'name'),
          grid: true,
          grid_order: 20,
          grid_value: (row) => `${property(row.social_campaign, 'name')} #${row.social_campaign.namestring_id}`,
          filter: (dt, col) => multiSelectFilter(dt, col),
          filter_label: (id, name) => generateFilterLabel(id, name),
          options: (apiStore) => apiStore.socialCampaigns,
          id: 'social_campaign',
          label: 'Campaign',
          name: 'campaign_name',
          ns: () => 'PARENT',
          utaType: 'integer',
          show: true,
          show_value: (row) => `${property(row.social_campaign, 'name')} #${row.social_campaign.namestring_id}`,
          show_order: 1,
        },
        {
          colSize: 14,
          grid: true,
          grid_order: 8,
          grid_value: (row) => property(row.social_campaign, 'namestring_string'),
          grid_type: 'namestring',
          grid_label: 'Campaign Namestring',
          id: 'a.b.namestring_string',
          label: 'Campaign Namestring',
          name: 'social_campaign_namestring',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|integer',
          utaType: 'integer',
        },
        {
          domo_field: 'campaign_type',
          domo_value: (crudStore) => parentAbbrev(crudStore.storedData.social_campaign, 'campaign_type'),
          id: 'campaign_type',
          name: 'campaign_type',
          ns: (crudStore) => parentAbbrev(crudStore.storedData.social_campaign, 'campaign_type'),
          ns_order: 2,
          options: (apiStore) => apiStore.campaignTypes,
          utaType: 'parent',
        },
        {
          domo_field: 'objective',
          domo_value: (crudStore) => parentAbbrev(crudStore.storedData.social_campaign, 'objective'),
          id: 'objective',
          name: 'objective',
          ns: (crudStore) => parentAbbrev(crudStore.storedData.social_campaign, 'objective'),
          ns_order: 3,
          utaType: 'parent',
        },
        {
          colSize: 10,
          copyEditable: true,
          domo_field: 'adset_name',
          domo_value: (crudStore) => crudStore.storedData.name,
          form: 'name',
          formField: (rootStore) => (
            <UccInputText
              label="Description"
              core="campaign_name"
              form={rootStore.channel.model.crudStore.form}
              onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              fieldName="name"
            />),
          formOrder: 2,
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.name,
          id: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          label: 'Description',
          filter_placeholder: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          name: 'name',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          ns_order: 9,
          utaType: 'name',
          rules: 'string',
          show: true,
          show_value: (row) => row.name,
          show_order: 2,
        },
        {
          belongsTo: ['platform', 'platforms'],
          copyEditable: (rootStore) => { return !isDisabled(getPlatformList(rootStore.paidSocial.SocAdSet.crudStore.selectedRows)) },
          // copyEditable: true,
          form: 'platform_id',
          default: (rootStore) => property(property(rootStore.paidSocial.SocCamp.crudStore.selectedRow, 'platform'), 'id'),
          defaultType: 'platforms',
          formField: (rootStore) => (<UccSelect
            core="platform"
            fieldName="platform_id"
            label="Platform"
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.makePlatformOptions(rootStore, ['bulk_edit', 'copy_edit'].includes(rootStore.paidSocial.SocAdSet.crudStore.toolbarAction) ? getPlatformList(rootStore.paidSocial.SocAdSet.crudStore.selectedRows) : property(rootStore.paidSocial.SocAdSet.crudStore.storedData['social_campaign'], 'platform'))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
            disabled={isDisabled(['bulk_edit', 'copy_edit'].includes(rootStore.paidSocial.SocAdSet.crudStore.toolbarAction) ? getPlatformList(rootStore.paidSocial.SocAdSet.crudStore.selectedRows) : property(rootStore.paidSocial.SocAdSet.crudStore.storedData['social_campaign'], 'platform'))}
          />),
          formOrder: 4,
          label: 'Platform',
          rules: 'required|integer',
          utaType: 'fk',
          show: true,
          show_value: (row) => property(row.platform, 'name'),
          show_order: 3,
        },
        {
          domo_field: 'platform',
          domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
          grid: true,
          default: (rootStore) => property(rootStore.paidSocial.SocCamp.crudStore.selectedRow, 'platform'),
          grid_order: 40,
          grid_value: (row) => property(row.platform, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.platforms,
          id: 'platform',
          label: 'Platform',
          name: 'platform',
          ns: (crudStore) => abbrev(crudStore.storedData.platform),
          ns_order: 4,
          rules: 'required',
          utaType: 'platforms',
        },
        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (
            <UccSelect
              core="tactic"
              fieldName="tactic_id"
              label="Tactic"
              form={rootStore.channel.model.crudStore.form}
              menuItems={rootStore.uiStore.menuItems('tactics', 'makePrimeChannelSpecificOption', 'channelTactics')}
              onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
            />),
          formOrder: 5,
          label: 'Tactic',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 60,
          grid_value: (row) => property(row.tactic, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          id: 'tactic',
          label: 'Tactic',
          name: 'tactic',
          ns: (crudStore) => abbrev(crudStore.storedData.tactic),
          ns_order: 5,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.tactic, 'name'),
          show_order: 5,
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          form: 'social_ad_set_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
          formOrder: 6,
          grid: true,
          grid_order: 80,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'social_ad_set_targetings_attributes',
          ns: (crudStore) => NameString.targetingString(crudStore),
          ns_order: 8,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "social_ad_set_targetings"),
          show_order: 7,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'social_ad_set_targetings_attributes[].id',
        },
        {
          form: 'social_ad_set_targetings_attributes[].social_ad_set_id',
        },
        {
          form: 'social_ad_set_targetings_attributes[].targeting_id',
        },
        {
          form: 'social_ad_set_targetings_attributes[].extra',
        },
        {
          form: 'social_ad_set_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'social_ad_set_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'social_ad_set_targetings_attributes[].on',
        },
        {
          belongsTo: ['device', 'devices'],
          copyEditable: true,
          form: 'device_id',
          formField: (rootStore) => (
            <UccSelect
              core="device"
              fieldName="device_id"
              form={rootStore.channel.model.crudStore.form}
              label="Device"
              menuItems={rootStore.uiStore.menuItems('devices', 'makePrimeRegularOption')}
              onChange={(event) => rootStore.channel.model.crudStore.storeData('device_id', event)}
            />),
          formOrder: 7,
          label: 'Device',
          // multiple: [],
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'device',
          domo_value: (crudStore) => abbrev(crudStore.storedData.device),
          grid: true,
          grid_order: 80,
          grid_value: (row) => property(row.device, 'name'),
          id: 'device',
          label: 'Device',
          name: 'device',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.devices,
          ns: (crudStore) => abbrev(crudStore.storedData.device),
          ns_order: 8,
          rules: 'required|integer',
          sortable: true,
          show: true,
          show_value: (row) => property(row.device, 'name'),
          show_order: 7,
        },
        {
          belongsTo: ['geo', 'geos'],
          copyEditable: true,
          form: 'geo_id',
          formField: (rootStore) => (<UccSelect
            core="geo"
            fieldName="geo_id"
            form={rootStore.channel.model.crudStore.form}
            label="Geo"
            menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('geo_id', event)}
          />),
          formOrder: 8,
          label: 'Geos',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'geo',
          domo_value: (crudStore) => abbrev(crudStore.storedData.geo),
          grid: true,
          grid_order: 90,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          label: 'Geo',
          name: 'geo',
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          ns_order: 10,
          rules: 'required|integer',
          sortable: true,
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 8,
        },
        {
          belongsTo: ['gender', 'genders'],
          copyEditable: true,
          form: 'gender_id',
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={rootStore.uiStore.menuItems('genders', 'makePrimeChannelSpecificOption', 'channelGenders')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />
          ),
          formOrder: 9,
          label: 'Gender',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          grid_order: 100,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 12,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 9,
        },
        {
          belongsTo: ['language', 'languages'],
          copyEditable: true,
          form: 'language_id',
          formField: (rootStore) => (<UccSelect
            core="language"
            fieldName="language_id"
            form={rootStore.channel.model.crudStore.form}
            label="Language"
            menuItems={rootStore.uiStore.menuItems('languages', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('language_id', event)}
          />),
          formOrder: 10,
          label: 'Language',
          rules: 'integer|required',
          utaType: 'fk',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 110,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          grid_value: (row) => property(row.language, 'name'),
          id: 'language',
          label: 'Language',
          name: 'language',
          ns: (crudStore) => abbrev(crudStore.storedData.language),
          ns_order: 11,
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 10,
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.suppressionLabel(),
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          ns: (crudStore) => crudStore.suppressionLabel(),
          ns_order: 6,
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 6.5,
          type: 'checkbox',
          utaType: 'boolean',
        },
        //targeting fields
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'curherit', true, 110, 130, 'S.'),
        dateField('end_date', 'End Date', 'blankherit', false, 120, 140, 'E.'),
      ),
    },

    //    _____       .___
    //   /  _  \    __| _/______
    //  /  /_\  \  / __ |/  ___/
    // /    |    \/ /_/ |\___ \
    // \____|__  /\____ /____  >
    //         \/      \/    \/

    {
      allFilterData: (apiStore) => apiStore['adsFilterOptions'],
      codename: 'SocAd',
      controller: 'social_ad',
      crud: (rootStore) => rootStore.adsCrud,
      domoNsType: 'SocialAd',
      endpoint: 'ads',
      filterOptionsCall: (apiStore) => (val) => apiStore['adsFilterOptions'] = val,
      form: () => <GenericForm type="Ad" datePicker={2} numButtons={3} />,
      genericModelPromiseEndpoints: [
        'ad-sets',
        'ad-types',
        'ad-type-channels',
        'campaign-types',
        'channel-targetings',
        'devices',
        'genders',
        'geos',
        'languages',
        'marketing-campaigns',
        'objectives',
        'platforms',
        'social-campaigns',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.newAdsCrud.getFilterOptions({ brand_id: brand_id, ...adset_param }, ''),
        apiStore.newAdsetsCrud.getAll({ brand_id: brand_id, ...adset_param }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      grandParentModel: 'social_campaign',
      modelFilter: (root) => _.merge({ social_adset_id: root.paidSocial.SocAdSet.crudStore.selectedRowIds, }, { social_campaign_id: root.paidSocial.SocCamp.crudStore.selectedRowIds, }, { brand_id: root.apiStore.currentBrandId }),
      name: 'Ads',
      noDataText: (r) => r.paidSocial.SocAdSet.crudStore.selectedRowIds.length > 0 ? ' for the selected AdSet(s)' : '',
      parentModel: 'social_ad_set',
      parentModelCode: 'SocAdSet',
      permCreateAndEdit: 10,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'ads',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'ad',
      tabData: (apiStore) => apiStore.genericTabPromiseResults(),
      tabPromiseEndpoints: (rootStore, brand_param) => {
        const allFilter = _.merge(
          brand_param,
          rootStore.paidSocial.SocAdSet.modelFilter(rootStore),
        );
        return [
          rootStore.newCampaignsCrud.getTotal(brand_param),
          rootStore.newAdsetsCrud.getTotal(allFilter, ''),
        ];
      },
      tabDisabled: (r) => (r.apiStore.adTabDisabled) ? true : false,
      tabIndex: 2,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      targeting: 'parent',
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'namestring_show',
          grid_order: 1,
          grid_value: (row) => row.id,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 4,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'SocialAd',
          rules: 'required',
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 3,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'utmstring',
          domo_value: (crudStore) => NameString.getAdUtmString(crudStore),
        },
        {
          form: 'utmstring_string',
          name: 'utmstring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'short_utm_string',
          domo_value: (crudStore) => NameString.getNewUtmString(crudStore),
        },
        {
          form: 'short_utm_string',
          name: 'short_utm_string',
          formOrder: 67,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 180,
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          label: 'Created By',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.Users,
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: () => 'group',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.brand),
          ns_order: 1,
          utaType: 'brand',
        },
        {

          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.brand, 'id'),
          name: 'brand_id',
          ns: () => 'brand_id',
          utaType: 'brand',
          rules: 'required',
        },
        {
          domo_field: 'channel',
          domo_value: (crudStore) => crudStore.channel.abbrev,
          id: 'channel',
          name: 'channel',
          ns: (crudStore) => crudStore.channel.abbrev,
          rules: 'required',
          utaType: 'channel',
        },
        {
          colSize: 9,
          copyEditable: true,
          domo_field: 'campaign_name',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'name'),
          form: 'campaign_name',
          grid: true,
          grid_order: 40,
          grid_value: (row) => `${property(row.social_ad_set.social_campaign, 'name')} #${property(row.social_ad_set.social_campaign, 'namestring_id')}`,
          grid_label: 'Campaign',
          filter_placeholder: 'Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          filter_label: (id, name) => generateFilterLabel(id, name),
          options: (apiStore) => apiStore.socialCampaigns,
          id: 'social_campaign_id',
          name: 'name',
          // filter: (dt, col) => inputTextFilter(dt, col),
          label: 'Description',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          // ns_order: 6,
          utaType: 'name',
          rules: 'string',
        },
        {
          copyEditable: true,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'campaign_type'), 'name'),
          id: 'campaign_type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'campaign_type')),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          ns_order: 2,
          utaType: 'grandParent',
          rules: 'required',
        },
        {
          copyEditable: true,
          domo_field: 'objective',
          domo_value: (crudStore) => property(property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'objective'), 'name'),
          id: 'objective',
          name: 'objective',
          ns: (crudStore) => abbrev(property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'objective')),
          ns_order: 3,
          utaType: 'grandParent',
          rules: 'required',
        },
        {
          belongsTo: ['social_ad_set', 'adSets'],
          form: 'social_ad_set_id',
          copyEditable: true,
          bulkCreatable: false,
          formField: (rootStore) => (<UccSelect
            core="social_ad_set"
            fieldName="social_ad_set_id"
            form={rootStore.channel.model.crudStore.form}
            label="Ad Set"
            menuItems={rootStore.channel.model.crudStore.makePrimeAdsetOptions(_.sortBy(rootStore.apiStore.adSets, as => - as.namestring_id))}
            onChange={(event) => {
              disableSubmitButton(rootStore, event)
              isDisabled(property(rootStore.paidSocial.SocCamp.crudStore.selectedRow, 'platform'))
              rootStore.channel.model.crudStore.storeData('social_ad_set_id', event)
            }
            }
          />),
          formOrder: 1,
          label: 'AdSet',
          rules: 'required|integer',
          utaType: 'fk',
          subType: 'inherit',
        },
        {
          domo_field: 'adset_name',
          domo_value: (crudStore) => HeadedCamelCase(property(property(crudStore.storedData, 'social_ad_set'), 'name')),
        },
        {
          colSize: 14,
          grid: true,
          grid_order: 25,
          grid_value: (row) => property(row.social_ad_set.social_campaign, 'namestring_string'),
          grid_type: 'namestring',
          grid_label: 'Campaign Namestring',
          grand_parent_field: true,
          id: 'a.b.namestring_string',
          label: 'Campaign Namestring',
          name: 'social_campaign_namestring',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|integer',
          utaType: 'integer',
        },
        {
          grid: true,
          grid_order: 50,
          grid_value: (row) => `${property(row.social_ad_set, 'name')} #${property(row.social_ad_set, 'namestring_id')}`,
          id: 'social_ad_set',
          label: 'Ad Set',
          name: 'social_ad_set',
          filter: (dt, col) => multiSelectFilter(dt, col),
          filter_label: (id, name) => generateFilterLabel(id, name),
          options: (apiStore) => apiStore.adSets,
          ns: (crudStore) => HeadedCamelCase(property(crudStore.storedData.social_ad_set, 'name')),
          ns_order: 4,
          rules: 'required|integer',
          show: true,
          show_value: (row) => `${property(row.social_ad_set, 'name')} #${property(row.social_ad_set, 'id')}`,
          show_order: 50,
          utaType: 'integer',
        },
        {
          colSize: 14,
          grid: true,
          grid_order: 28,
          grid_value: (row) => property(row.social_ad_set, 'namestring_string'),
          grid_type: 'namestring',
          grid_label: 'Adset Namestring',
          id: 'namestring_string',
          label: 'Adset Namestring',
          name: 'social_adset_namestring',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|integer',
          utaType: 'integer',
        },
        {
          domo_field: 'tactic',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'tactic'), 'name'),
          id: 'tactic',
          name: 'tactic',
          ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'tactic')),
          ns_order: 5,
          utaType: 'parent',
          rules: 'required',
        },
        {
          domo_field: 'device',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'device'), 'name'),
          id: 'device',
          name: 'device',
          utaType: 'parent',
          rules: 'required',
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => property(crudStore.storedData.social_ad_set, 'suppression') ? 'SupOn' : 'SupOff',
          id: 'suppression',
          name: 'suppression',
          ns: (crudStore) => property(crudStore.storedData.social_ad_set, 'suppression') ? 'SupOn' : 'SupOff',
          ns_order: 6,
          utaType: 'parent',
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          id: 'targeting_ids',
          name: 'targeting',
          ns: (crudStore) => NameString.targetingString(crudStore),
          ns_order: 7,
          utaType: 'targeting',
          rules: 'required',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 20,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: (crudStore) => crudStore.props.rootStore.apiStore.currentBrand.enable_fb,
          grid_link: 'facebook-ads',
          grid_order: 5,
          grid_value: (row) => row.facebook_id,
          id: 'facebook_id',
          label: 'facebook_id',
          name: 'facebook_id',
          permListAndView: 20,
          rules: 'integer',
          utaType: 'string',
          ns: (crudStore) => crudStore.storedData.facebook_id,
        },
        {
          grid: false,
          id: 'facebook_detail',
          label: 'facebook_detail',
          name: 'facebook_detail',
          rules: 'integer',
          utaType: 'string',
          ns: (crudStore) => crudStore.storedData.facebook_detail,
        },
        {
          grid: false,
          id: 'facebook_created_at',
          label: 'facebook_created_at',
          name: 'facebook_created_at',
          rules: 'date',
          utaType: 'date',
          ns: (crudStore) => crudStore.storedData.facebook_created_at,
        },
        {
          domo_field: 'ad_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 60,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Marketing Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 8,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.marketing_campaign, 'name'),
          show_order: 60,
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Marketing Campaign"
            menuItems={rootStore.uiStore.menuItems('marketingCampaigns', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 2,
          label: 'Marketing Campaign',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10,
          grid: true,
          grid_order: 55,
          grid_value: (row) => row.creative_name,
          filter: (dt, col) => inputTextFilter(dt, col),
          id: 'creative_name',
          label: 'Description',
          name: 'creative_name',
          rules: 'string',
          utaType: 'name',
        },

        {
          form: 'brand_url',
          copyEditable: true,
          formField: (rootStore) => (
            <UccInputText
              core="brand-url"
              fieldName="brand_url"
              form={rootStore.channel.model.crudStore.form}
              label="Brand Url"
              onInput={(event) => rootStore.channel.model.crudStore.storeData('brand_url', event)}
            />),
          formOrder: 5,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 80,
          grid_value: (row) => row.brand_url,
          id: 'brand_url',
          label: 'Brand Url',
          name: 'brand_url',
          ns: () => 'stored',
          rules: 'string',
          show: true,
          show_value: (row) => row.brand_url,
          show_order: 80,
          utaType: 'text',
        },
        {
          domo_field: 'ad_type',
          domo_value: (crudStore) => property(crudStore.storedData.ad_type, 'name'),
          grid: true,
          grid_order: 70,
          grid_value: (row) => property(row.ad_type, 'name'),
          id: 'ad_type',
          label: 'Ad Type',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.adTypes,
          name: 'ad_type',
          ns: (crudStore) => abbrev(crudStore.storedData.ad_type),
          ns_order: 9,
          show: true,
          show_value: (row) => property(row.ad_type, 'name'),
          show_order: 70,
          rules: 'required',
        },
        {
          belongsTo: ['ad_type', 'adTypes'],
          form: 'ad_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="ad_type"
            fieldName="ad_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Ad Type"
            menuItems={rootStore.uiStore.menuItems('adTypes', 'makePrimeChannelSpecificOption', 'adTypeChannels')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('ad_type_id', event)}
          />),
          formOrder: 3,
          label: 'Ad Type',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
        {
          domo_field: 'platform',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'platform'), 'name'),
          id: 'platform',
          name: 'platform',
          ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'platform')),
          ns_order: 10,
          rules: 'required',
          utaType: 'platforms',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'gender'), 'name'),
          id: 'gender',
          name: 'gender',
          ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'gender')),
          ns_order: 11,
          rules: 'required',
          utaType: 'parent',
        },
        {
          domo_field: 'geo',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'geo'), 'name'),
          id: 'geo',
          name: 'geo',
          ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'geo')),
          ns_order: 12,
          utaType: 'parent',
          rules: 'required',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'language'), 'name'),
          id: 'language',
          name: 'language',
          ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'language')),
          ns_order: 13,
          utaType: 'parent',
        },
      ].concat(
        dateField('start_date', 'Start Date', 'curherit', true, 140, 14, 'S.'),
        dateField('end_date', 'End Date', 'blankherit', false, 150, 15, 'E.'),
      ),
    },
  ],
};