import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';
import UccErrorHandling from './UccErrorHandling';

class UccAttrLink extends Component {


  render() {
    const { rootStore, fieldName } = this.props;

    const attrEndpoint = rootStore.attrValues.models.find(m => m.controller + "_id" == fieldName)?.endpoint ?
      rootStore.attrValues.models.find(m => m.controller + "_id" == fieldName)?.endpoint : "";

    if (_.isEmpty(attrEndpoint)) return (<></>);

    const attrHref = "/app/admin/attributes-values/" + attrEndpoint;

    const attrName = fieldName.substring(0, fieldName.length - 3);

    return (
      <span>&nbsp;
        <a className="pi pi-plus-circle plusLink" title={`Links to create a new ${attrName} (changes to form will be lost)`} href={attrHref}></a>
      </span>
    );
  }
}
export default UccAttrLink;
