import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import MobxReactForm from 'mobx-react-form';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const BootstrapButton = withStyles({
  root: {
    fontStretch: 'Bold',
    fontWeight: 900,
  },
})(Button);
const styles = theme => ({
  root: {
    paddingLeft: 128,
    paddingTop: theme.spacing(2),
    display: 'inline-block',
    width: '100vh',
    maxWidth: 468,
  },
  button: {
    width: 135,
    fontColor: '#828282',
  },
  cancel: {
    maxWidth: 150,
    width: '50vh',
    float: 'left',
    padding: '20',
  },
  submit: {
    maxWidth: 150,
    width: '50vh',
    float: 'right',
    paddingLeft: '20',
    paddingRight: '0',
  },
});

@inject('uiStore')
@observer
class FormButtons extends React.Component {

  render () {
    const { classes, form, onCancel } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.cancel}>
            <BootstrapButton
              className={classes.button}
              onClick={onCancel}
              variant="outlined"
              color="common"
            >
              Cancel
            </BootstrapButton>
          </div>
          <div className={classes.submit}>
            <BootstrapButton
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={form.onSubmit}
            >
              Submit
            </BootstrapButton>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FormButtons.wrappedComponent.propTypes = {
  form: PropTypes.instanceOf(MobxReactForm).isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FormButtons);
