import FacebookLogo from '../../../../../public/images/facebook_logo.png';
import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');
const abbrev_regex = 'regex:/^\\w+$/';
const extra_regex = 'regex:/^[a-zA-Z0-9_]+$/';


export const facebook_integration = {
  abbrev: 'FaceInt',
  adminChannel: true,
  codename: 'facebookIntegrations',
  endpoint: 'facebook-integrations',
  icon: FacebookLogo,
  name: 'Facebook Integrations',
  subtitle: 'Create or manage Facebook Integrations',
  hasStatus: false,
  models: [
    {
      allFilterData: (apiStore) => apiStore['facebookConnectionsFilterOptions'],
      codename: 'FacebookConnections',
      controller: 'facebook_connection',
      disableBulkEdit: true,
      endpoint: 'facebook-connections',
      filterOptionsCall: (apiStore) => (val) => apiStore['facebookConnectionsFilterOptions'] = val,
      form: (rootStore) => <GenericForm numButtons={2} />,
      genericModelPromiseEndpoints: ['brands'],
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.facebookConnectionsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      // modelFilter: (root) => _.merge({ social_adset_id: root.paidSocial.SocAdSet.crudStore.selectedRowIds, }, { social_campaign_id: root.paidSocial.SocCamp.crudStore.selectedRowIds, }, { brand_id: root.apiStore.currentBrandId }),
      modelFilter: (root) => { return {}; },
      name: 'Connections',
      noExport: true,
      permCreateAndEdit: 60,
      permDelete: 60,
      permListAndView: 60,
      route: 'connections',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'connection',
      sortField: 'enable_fb',
      sortOrder: 'desc',
      tabIndex: 0,
      tabLabel: (m) => m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.facebookConnectionsCrud,
      columns: [
        {
          colSize: 6,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          permListAndView: 80,
          rules: 'integer',
          type: 'hidden',
        },
        {
          colSize: 14,
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.channel.model.crudStore.action == 'edit' ? rootStore.apiStore.brands.filter(b => b.facebook_configured).map((p) => rootStore.uiStore.makePrimeRegularOption(p)) : rootStore.apiStore.brands.filter(b => !b.facebook_configured).map((p) => rootStore.uiStore.makePrimeRegularOption(p))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('brand_id', event)}
            disabled={(rootStore.channel.model.crudStore.action == 'edit')}
            value={rootStore.channel.model.crudStore.storedData['brand_id']}
          />),
          formOrder: 20,
          grid: true,
          grid_order: 20,
          grid_link: 'draft_edit',
          grid_value: (row) => property(row.brand, 'name'),
          id: 'brand',
          label: 'Brand',
          name: 'brand',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.brands,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.brand, 'name'),
          show_order: 20,
          utaType: 'fk',
        },

        {
          colSize: 8,
          form: 'enable_fb',
          formField: (rootStore) => (<UccCheckBox
            core="enable_fb"
            fieldName="enable_fb"
            form={rootStore.channel.model.crudStore.form}
            label="API Enabled"
            onChange={(event) => rootStore.channel.model.crudStore.storeData('enable_fb', event)}
          />),
          formOrder: 30,
          grid: true,
          grid_order: 30,
          grid_value: (row) => (row.enable_fb ? 'Yes' : 'No'),
          id: 'enable_fb',
          label: 'Enabled',
          name: 'enable_fb',
          type: 'checkbox',
          utaType: 'boolean',
          rules: 'boolean',
          show: true,
          show_value: (row) => (row.enable_fb ? 'Yes' : 'No'),
          show_order: 30
        },
        {
          form: 'facebook_access_token',
          formField: (rootStore) => (<UccInputText
            label="FB Access Token"
            core="facebook_access_token"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_access_token', event)}
            fieldName="facebook_access_token"
          />),
          formOrder: 40,
          label: 'FB Access Token',
          rules: 'required|string',
          type: 'hidden',
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'facebook_ad_account_id',
          formField: (rootStore) => (<UccInputText
            label="FB Ad Account"
            core="facebook_ad_account_id"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_ad_account_id', event)}
            fieldName="facebook_ad_account_id"
          />),
          formOrder: 50,
          grid: true,
          grid_order: 50,
          grid_value: (row) => row.facebook_ad_account_id,
          id: 'facebook_ad_account_id',
          label: 'FB Ad Account',
          name: 'facebook_ad_account_id',
          rules: 'required|string',
          show: true,
          show_value: (row) => row.facebook_ad_account_id,
          show_order: 50,
          sortable: true,
          utaType: 'string',
        },

        {
          form: 'facebook_app_secret',
          formField: (rootStore) => (<UccInputText
            label="FB App Secret"
            core="facebook_app_secret"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_app_secret', event)}
            fieldName="facebook_app_secret"
          />),
          formOrder: 60,
          label: 'Facebook App Secret',
          rules: 'required|string',
          type: 'hidden',
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'facebook_application_id',
          formField: (rootStore) => (<UccInputText
            label="FB Application Id"
            core="facebook_application_id"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_application_id', event)}
            fieldName="facebook_application_id"
          />),
          formOrder: 70,
          grid: true,
          grid_order: 70,
          grid_value: (row) => row.facebook_application_id,
          id: 'facebook_application_id',
          label: 'FB Application Id',
          name: 'facebook_application_id',
          rules: 'required|string',
          show: true,
          show_value: (row) => row.facebook_application_id,
          show_order: 70,
          sortable: true,
          utaType: 'string',
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'facebook_business_id',
          formField: (rootStore) => (<UccInputText
            label="FB Business Id"
            core="facebook_business_id"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_business_id', event)}
            fieldName="facebook_business_id"
          />),
          formOrder: 80,
          grid: true,
          grid_order: 80,
          grid_value: (row) => row.facebook_business_id,
          id: 'facebook_business_id',
          label: 'FB Business Id',
          name: 'facebook_business_id',
          rules: 'required|string',
          show: true,
          show_value: (row) => row.facebook_business_id,
          show_order: 80,
          sortable: true,
          utaType: 'string',
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'facebook_creative_default_id',
          formField: (rootStore) => (<UccInputText
            label="Default Creative Id"
            core="facebook_creative_default_id"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_creative_default_id', event)}
            fieldName="facebook_creative_default_id"
          />),
          formOrder: 90,
          grid: true,
          grid_order: 90,
          grid_value: (row) => row.facebook_creative_default_id,
          id: 'facebook_creative_default_id',
          label: 'Default Creative Id',
          name: 'facebook_creative_default_id',
          rules: 'required|string',
          show: true,
          show_value: (row) => row.facebook_creative_default_id,
          show_order: 90,
          sortable: true,
          utaType: 'string',
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'facebook_creative_default_message',
          formField: (rootStore) => (<UccInputText
            label="Default Creative Message"
            core="facebook_creative_default_message"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_creative_default_message', event)}
            fieldName="facebook_creative_default_message"
          />),
          formOrder: 100,
          grid: true,
          grid_order: 100,
          grid_value: (row) => row.facebook_creative_default_message,
          id: 'facebook_creative_default_message',
          label: 'Default Creative Message',
          name: 'facebook_creative_default_message',
          rules: 'required|string',
          show: true,
          show_value: (row) => row.facebook_creative_default_message,
          show_order: 100,
          sortable: true,
          utaType: 'string',
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'facebook_creative_link',
          formField: (rootStore) => (<UccInputText
            label="Default Creative Link"
            core="facebook_creative_link"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_creative_link', event)}
            fieldName="facebook_creative_link"
          />),
          formOrder: 110,
          grid: true,
          grid_order: 110,
          grid_value: (row) => row.facebook_creative_link,
          id: 'facebook_creative_link',
          label: 'Default Creative Link',
          name: 'facebook_creative_link',
          rules: 'required|string',
          show: true,
          show_value: (row) => row.facebook_creative_link,
          show_order: 110,
          sortable: true,
          utaType: 'string',
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'facebook_image_hash',
          formField: (rootStore) => (<UccInputText
            label="Creative Image Hash"
            core="facebook_image_hash"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_image_hash', event)}
            fieldName="facebook_image_hash"
          />),
          formOrder: 120,
          grid: true,
          grid_order: 120,
          grid_value: (row) => row.facebook_image_hash,
          id: 'facebook_image_hash',
          label: 'Creative Image Hash',
          name: 'facebook_image_hash',
          rules: 'required|string',
          show: true,
          show_value: (row) => row.facebook_image_hash,
          show_order: 120,
          sortable: true,
          utaType: 'string',
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'facebook_page_id',
          formField: (rootStore) => (<UccInputText
            label="FB Page Id"
            core="facebook_page_id"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('facebook_page_id', event)}
            fieldName="facebook_page_id"
          />),
          formOrder: 85,
          grid: true,
          grid_order: 85,
          grid_value: (row) => row.facebook_page_id,
          id: 'facebook_page_id',
          label: 'FB Page Id',
          name: 'facebook_page_id',
          rules: 'required|string',
          show: true,
          show_value: (row) => row.facebook_page_id,
          show_order: 85,
          sortable: true,
          utaType: 'string',
        },
      ]
    },

    {
      allFilterData: (apiStore) => apiStore['facebookObjectsFilterOptions'],
      codename: 'FacebookObjects',
      controller: 'facebook-object',
      disableBulkEdit: true,
      endpoint: 'facebook-objects',
      filterOptionsCall: (apiStore) => (val) => apiStore['facebookObjectsFilterOptions'] = val,
      form: () => <AttrValuesForm />,
      genericModelPromiseEndpoints: ['facebook-objects'],
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.facebookObjectsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      // modelFilter: (root) => _.merge({ social_adset_id: root.paidSocial.SocAdSet.crudStore.selectedRowIds, }, { social_campaign_id: root.paidSocial.SocCamp.crudStore.selectedRowIds, }, { brand_id: root.apiStore.currentBrandId }),
      modelFilter: (root) => { return {}; },
      noAddButton: true,
      noToolbar: true,
      noEdit: true,
      name: 'Response Detail',
      permCreateAndEdit: 80,
      permDelete: 80,
      permListAndView: 60,
      route: 'objects',
      send2domo: false,
      showFormat: 'definitionList',
      singular: 'object',
      sortField: 'id',
      sortOrder: 'desc',
      tabIndex: 0,
      tabLabel: (m) => m.name,
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.facebookObjectsCrud,
      columns: [
        {
          colSize: 6,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 20,
          grid_value: (row) => row.id,
          id: 'id',
          label: 'id',
          name: 'id',
          permListAndView: 80,
          show: true,
          show_value: (row) => row.id,
          show_order: 20,
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 22,
          grid_value: (row) => row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          show: true,
          show_value: (row) => row.namestring_id,
          show_order: 22,
        },
        // {
        //   colSize: 8,
        //   filter: (dt, col) => multiSelectFilter(dt, col),
        //   grid: true,
        //   grid_order: 22,
        //   grid_value: row) => property(row.brand, 'name'),
        //   grid_link: 'draft_edit',
        //   id: 'brand',
        //   label: 'Brand',
        //   name: 'brand',
        //   show: true,
        //   show_value: row) => property(row.brand, 'name'),
        //   show_order: 22,
        //   utaType: 'fk',
        // },
        {
          colSize: 14,
          grid: true,
          grid_order: 22,
          grid_link: 'draft_edit',
          grid_value: (row) => property(row.brand, 'name'),
          id: 'brand',
          label: 'Brand',
          name: 'brand_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.brands,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.brand, 'name'),
          show_order: 22,
          utaType: 'fk',
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'business_model_type',
          grid: true,
          grid_order: 30,
          grid_value: (row) => row.business_model_type.replace('Social', ''),
          id: 'business_model_type',
          label: 'Namestring Type',
          name: 'business_model_type',
          show: true,
          show_value: (row) => row.business_model_type,
          show_order: 30,
          sortable: true,
        },
        {
          colSize: 13,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 40,
          grid_value: (row) => row.business_model_id,
          id: 'business_model_id',
          label: 'Business Resource Id',
          name: 'business_model_id',
          permListAndView: 80,
          show: true,
          show_value: (row) => row.business_model_id,
          show_order: 40,
          sortable: true,
          utaType: 'id',
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: false,
          grid_order: 45,
          grid_value: (row) => (<a href={`/app/admin/user-management/brands/${row.brand_id}`} target="_blank">{row.brand_id}</a>),
          id: 'brand_id',
          label: 'Brand Id',
          name: 'brand_id',
          show: true,
          show_value: (row) => row.brand_id,
          show_order: 45,
          sortable: true,
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 50,
          grid_value: (row) => row.status,
          id: 'status',
          label: 'Status',
          name: 'status',
          show: true,
          show_value: (row) => row.status,
          show_order: 50,
          sortable: true,
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 60,
          grid_value: (row) => row.facebook_id,
          id: 'facebook_id',
          label: 'facebook_id',
          name: 'facebook_id',
          show: true,
          show_value: (row) => row.facebook_id,
          show_order: 60,
          sortable: true,
        },
        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 70,
          grid_value: (row) => row.pushed_namestring,
          id: 'pushed_namestring',
          label: 'Pushed Namestring',
          name: 'pushed_namestring',
          show: true,
          show_value: (row) => row.pushed_namestring,
          show_order: 70,
          sortable: true,
        },

        {
          colSize: 12,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 80,
          grid_value: (row) => row.details,
          id: 'details',
          label: 'Details',
          name: 'details',
          show: true,
          show_value: (row) => row.details,
          show_order: 80,
          sortable: true,
        },
        {
          colSize: 12,
          filter: (dt, col) => dateFilter(dt, col),
          grid: true,
          grid_order: 90,
          grid_value: (row) => row.created_at,
          id: 'created_at',
          label: 'Push Date',
          name: 'created_at',
          show: true,
          show_value: (row) => row.created_at,
          show_order: 90,
          sortable: true,
        },
        {
          colSize: 12,
          filter: (dt, col) => dateFilter(dt, col),
          grid: true,
          grid_order: 100,
          grid_value: (row) => row.updated_at,
          id: 'updated_at',
          label: 'Updated Date',
          name: 'updated_at',
          show: true,
          show_value: (row) => row.updated_at,
          show_order: 100,
          sortable: true,
        },
      ],
    },
  ],
};