import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/Inbox';
import MailIcon from '@material-ui/icons/Mail';


import { Link } from 'mobx-router';

import ApiStore from '../../store/ApiStore';
import UiStore from '../../store/UiStore';
import routes from './Routes';

@inject('apiStore', 'uiStore')
@observer
class ChannelMenu extends React.Component {
  onClickChannel = () => {
    this.props.uiStore.closeAppDrawer();
  };

  render() {
    const { uiStore, apiStore } = this.props;
    const channels = uiStore.rootStore.storeMetadata.channels;
    return (
      <List>
        {
          (apiStore.currentBrandId != null) && channels.map((ch) => (
            <Link view={routes.channelTabs} params={{ brand_path: apiStore.currentBrand.path, channel: ch.endpoint, model: ch.models[0].endpoint }} store={uiStore} key={ch.name} >
              <ListItem button key={ch}>
                <ListItemIcon><InboxIcon /></ListItemIcon>
                <ListItemText primary={ch.name} />
              </ListItem>
            </Link>
          ))
        }
      </List>
    );
  }
}

ChannelMenu.wrappedComponent.propTypes = {
  uiStore: PropTypes.instanceOf(UiStore).isRequired,
  apiStore: PropTypes.instanceOf(ApiStore).isRequired,
};

export default ChannelMenu;
