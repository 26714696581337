import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';
import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');

export const paid_search = {
  name: 'Paid Search',
  endpoint: 'paid-search',
  codename: 'paidSearch',
  abbrev: 'PaidSearch',
  icon: 'PaidSearch',
  id: 4,
  disabled: false,
  showViewIcon: true,
  models: [
    {
      allFilterData: (apiStore) => apiStore['paidSearchCampaignsFilterOptions'],
      codename: 'PaidSearchCamp',
      controller: 'paid_search_campaign',
      crud: (rootStore) => rootStore.paidSearchCampaignsCrud,
      domoNsType: 'PaidSearchCampaign',
      dontDisplayDate: true,
      endpoint: 'paid-search-campaigns',
      filterOptionsCall: (apiStore) => (val) => apiStore['paidSearchCampaignsFilterOptions'] = val,
      form: () => <GenericForm type="Campaign" numButtons={3} />,
      genericModelPromiseEndpoints: [
        'areas',
        'audiences',
        'business-units',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-objectives',
        'channel-platforms',
        'devices',
        'extra-field-objective-modifiers',
        'geos',
        'goals',
        'languages',
        'objectives',
        'objective-modifiers',
        'objective-modifier-objectives',
        'platforms',
        'secondary-tactics',
        'segments',
        'statuses',
        'users',
      ],
      hasChildren: true,
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.paidSearchCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.extraFieldsCrud.getAll({ model_id: '9' }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Paid Search Campaigns',
      name: 'Campaigns',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'campaigns',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'campaign',
      tabData: (apiStore) => apiStore.genericTabPromiseResults(),
      tabPromiseEndpoints: (rootStore, brand_param) => {
        const allFilter = _.merge(
          brand_param,
          rootStore.paidSearch.PaidSearchAdGroup.modelFilter(rootStore),
        );
        return [
          rootStore.paidSearchCampaignsCrud.getTotal(brand_param),
          rootStore.paidSearchAdGroupsCrud.getTotal(allFilter, ''),
        ];
      },
      tabDisabled: () => false,
      tabIndex: 0,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 6,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {

          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          colSize: 9,
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 10,
          label: 'Status',
          name: 'status_id',
          options: (apiStore) => apiStore.statuses,
          id: 'status_id',
          rules: 'required|integer',
          utaType: 'fk',
        },

        {
          colSize: 7,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 5,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'PaidSearchCampaign',
          rules: 'required',
        },

        {
          domo_field: 'channel',
          domo_value: () => 'PaidSearch',
          ns: () => 'PaidSearch',
          name: 'channel',
          id: 'channel',
          utaType: 'channel',
          rules: 'required',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          ns_order: 10,
          utaType: 'group',
          rules: 'required',
        },
        {
          copyEditable: false,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('campaign_type_id', event)}
          />),
          formOrder: 20,
          name: 'campaign_type_id',
          label: 'Campaign Type',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 11,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          filter: (dt, col) => multiSelectFilter(dt, col),
          filter_placeholder: 'Type',
          grid: true,
          grid_order: 20,
          grid_value: (row) => property(row.campaign_type, 'name'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          options: (apiStore) => apiStore.campaignTypes,
          show: true,
          show_value: (row) => property(row.campaign_type, 'name'),
          show_order: 20,
        },
        {
          belongsTo: ['platform', 'platforms'],
          form: 'platform_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="platform"
            fieldName="platform_id"
            label="Platform"
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.menuItems('platforms', 'makePrimeChannelSpecificOption', 'channelPlatforms')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
          />),
          formOrder: 40,
          label: 'Platform',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10,
          domo_field: 'platform',
          domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.platform, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.platforms,
          id: 'platform',
          label: 'Platform',
          name: 'platform',
          ns: (crudStore) => abbrev(crudStore.storedData.platform),
          ns_order: 40,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.platform, 'name'),
          show_order: 40,

        },
        {
          belongsTo: ['objective', 'objectives'],
          callback: (crudStore, thing, selected) => crudStore.clearObjectiveModifier(crudStore, thing, selected),
          form: 'objective_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            form={rootStore.channel.model.crudStore.form}
            label="Objective"
            menuItems={rootStore.uiStore.menuItems('objectives', 'makePrimeObjectiveOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 50,
          label: 'Objective',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 11,
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 50,
          grid_value: (row) => property(row.objective, 'name'),
          id: 'objective',
          label: 'Objective',
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'objective',
          ns: (crudStore) => abbrev(crudStore.storedData.objective),
          ns_order: 50,
          rules: 'required',
          options: (apiStore) => apiStore.objectives,
          show: true,
          show_value: (row) => property(row.objective, 'name'),
          show_order: 50,
        },
        {
          belongsTo: ['objective_modifier', 'objectiveModifiers'],
          form: 'objective_modifier_id',
          formField: (rootStore) => (<UccSelect
            core="objective-modifier"
            fieldName="objective_modifier_id"
            label="Objective Modifier"
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.menuItems('objectiveModifiers', 'makePrimeObjectiveModifierOption')}
            onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('objective_modifier', 'extra_field', event)}
          />),
          formOrder: 60,
          label: 'Objective Mod.',
          rules: 'required|integer',
          utaType: 'fk',
        },

        {
          colSize: 13,
          contrivance: (crudStore) => [abbrev(crudStore.storedData.objective_modifier), NameString.pivotNamestring(crudStore, 'objective_modifier', 'extra_field')].filter(e => e.length > 0).join('+'),
          domo_field: 'objective_modifier',
          domo_value: (crudStore) => [abbrev(crudStore.storedData.objective_modifier), NameString.pivotNamestring(crudStore, 'objective_modifier', 'extra_field')].filter(e => e.length > 0).join('+'),
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'obj_mod_string',
          grid: true,
          grid_order: 60,
          grid_value: (row) => row.obj_mod_string,
          id: 'obj_mod_string',
          label: 'Objective Mod.',
          name: 'objective_modifier',
          ns: (crudStore) => {
            return [abbrev(crudStore.storedData.objective_modifier), NameString.pivotNamestring(crudStore, 'objective_modifier', 'extra_field')].filter(e => e != undefined && e.length > 0).join('+')
          },
          ns_order: 60,
          options: (apiStore) => apiStore.objectiveModifiers,
          placeholder: 'Objective Modifier',
          type: 'hidden',
          show: true,
          show_value: (row) => row.obj_mod_string,
          show_order: 60,
        },

        {
          // domo_field: 'extra_field',
          // domo_value: (crudStore) => NameString.extraFieldString(crudStore),
          colSize: 14,
          form: 'paid_search_campaign_extra_fields_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} control="objective_modifier" dependent="extra_field" />),
          formOrder: 80,
          // grid: true,
          // grid_order: 80,
          // grid_value: (row) => NameString.pivotShow(row, "paid_search_campaign_extra_fields", "extra_field"),
          id: 'extra_field_ids',
          label: 'Extra Fields',
          multiple: [],
          name: 'paid_search_campaign_extra_fields_attributes',
          ns: (crudStore) => NameString.pivotNamestring(crudStore, 'objective_modifier', 'extra_field'),
          // ns_order: 80,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "paid_search_campaign_extra_fields", "extra_field"),
          show_order: 80,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'paid_search_campaign_extra_fields_attributes[].id',
        },
        {
          form: 'paid_search_campaign_extra_fields_attributes[].paid_search_campaign_id',
        },
        {
          form: 'paid_search_campaign_extra_fields_attributes[].extra_field_id',
        },
        {
          form: 'paid_search_campaign_extra_fields_attributes[].extra',
        },
        {
          form: 'paid_search_campaign_extra_fields_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'paid_search_campaign_extra_fields_attributes[].changed',
          default: 'false'
        },
        {
          form: 'paid_search_campaign_extra_fields_attributes[].on',
        },  //no geo or language for astellas



        // {
        //   // domo_field: 'objective_modifier',
        //   // domo_value: (crudStore) => NameString.targetingString(crudStore),
        //   form: 'paid_search_campaign_objective_modifiers_attributes',
        //   formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} control="objective" dependent="objective_modifier" />),
        //   formOrder: 60,
        //   grid: false,
        //   grid_order: 60,
        //   id: 'objective_modifier_ids',
        //   label: 'Objective Modifier',
        //   multiple: [],
        //   name: 'paid_search_campaign_objective_modifiers_attributes',
        //   // ns: (crudStore) => NameString.pivotNamestring(crudStore).name
        //   ns: (crudStore) => { NameString.pivotNamestring(crudStore, 'objective', 'objetcive_modifier') },
        //   ns_order: 60,
        //   show: true,
        //   //   show_value: (row) => row.obj_mod_string,
        //   show_value: (row) => NameString.pivotShow(row, "paid_search_campaign_objective_modifiers", "objective_modifier"),
        //   show_order: 60,
        //   utaType: 'targeting_array',
        // },
        // // display_placement_targetings_attributes
        // {
        //   form: 'paid_search_campaign_objective_modifiers_attributes[].id',
        // },
        // {
        //   form: 'paid_search_campaign_objective_modifiers_attributes[].paid_search_campaign_id',
        // },
        // {
        //   form: 'paid_search_campaign_objective_modifiers_attributes[].objective_modifier_id',
        // },
        // {
        //   form: 'paid_search_campaign_objective_modifiers_attributes[].extra',
        // },
        // {
        //   form: 'paid_search_campaign_objective_modifiers_attributes[]._destroy',
        //   default: 'false'
        // },
        // {
        //   form: 'paid_search_campaign_objective_modifiers_attributes[].changed',
        //   default: 'false'
        // },
        // {
        //   form: 'paid_search_campaign_objective_modifiers_attributes[].on',
        // },

        // {
        //   // are term and product needed in meta data?
        //   form: 'term',
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   grid_value: (row) => row.term,
        //   id: 'term',
        //   label: 'Term',
        //   name: 'term',
        //   ns: (crudStore) => crudStore.storedData.term,
        //   rules: 'string',
        //   utaType: 'text',
        // },
        // {
        //   // is product needed in meta data?
        //   form: 'product',
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   grid_value: (row) => row.product,
        //   id: 'product',
        //   label: 'Product',
        //   name: 'product',
        //   ns: (crudStore) => crudStore.storedData.product,
        //   rules: 'string',
        //   utaType: 'text',
        // },
        // {
        //   form: 'product_details',

        //   formField: (rootStore) => (<UccInputText
        //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'product_details')}
        //     core="product-details"
        //     fieldName="product_details"
        //     form={rootStore.channel.model.crudStore.form}
        //     label="Product Details"
        //     onInput={(event) => rootStore.channel.model.crudStore.storeData('product_details', event)}
        //   // className={classes.modifierField}
        //   />),
        //   formOrder: 66,
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   grid_value: (row) => row.product_details,
        //   id: 'product_details',
        //   label: 'Product Details',
        //   name: 'product_details',
        //   ns: (crudStore) => crudStore.storedData.product_details,
        //   rules: 'string',
        //   utaType: 'text',
        //   show: true,
        //   show_value: (row) => row.product_details,
        //   show_order: 60,
        // },
        // {
        //   // is general needed in meta data?
        //   form: 'general',
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   grid_value: (row) => row.general,
        //   id: 'general',
        //   label: 'General',
        //   name: 'general',
        //   ns: (crudStore) => crudStore.storedData.general,
        //   rules: 'string',
        //   utaType: 'text',
        // },
        // {
        //   form: 'category',

        //   formField: (rootStore) => (<UccInputText
        //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'category')}
        //     core="category"
        //     fieldName="category"
        //     form={rootStore.channel.model.crudStore.form}
        //     label="Category"
        //     onInput={(event) => rootStore.channel.model.crudStore.storeData('category', event)}
        //   // className={classes.modifierField}
        //   />),
        //   formOrder: 64,
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   grid_value: (row) => row.category,
        //   id: 'category',
        //   label: 'Category',
        //   name: 'category',
        //   ns: (crudStore) => crudStore.storedData.category,
        //   rules: 'string',
        //   show: true,
        //   show_value: (row) => row.category,
        //   show_order: 64,
        //   utaType: 'text',
        // },
        // {
        //   form: 'custom_modifier',

        //   formField: (rootStore) => (<UccInputText
        //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'custom_modifier')}
        //     core="custom_modifier"
        //     fieldName="custom_modifier"
        //     form={rootStore.channel.model.crudStore.form}
        //     label="Custom Modifier"
        //     onInput={(event) => rootStore.channel.model.crudStore.storeData('custom_modifier', event)}
        //   // className={classes.modifierField}
        //   />),
        //   formOrder: 68,
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   grid_value: (row) => row.custom_modifier,
        //   id: 'custom_modifier',
        //   label: 'Custom Modifier',
        //   name: 'custom_modifier',
        //   ns: (crudStore) => crudStore.storedData.custom_modifier,
        //   rules: 'string',
        //   show: true,
        //   show_value: (row) => row.custom_modifier,
        //   show_order: 68,
        //   utaType: 'text',
        // },
        // {
        //   form: 'three_p_brand',

        //   formField: (rootStore) => (<UccInputText
        //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'three_p_brand')}
        //     core="three_p_brand"
        //     fieldName="three_p_brand"
        //     form={rootStore.channel.model.crudStore.form}
        //     label="3p Brand"
        //     onInput={(event) => rootStore.channel.model.crudStore.storeData('three_p_brand', event)}
        //   // className={classes.modifierField}
        //   />),
        //   formOrder: 62,
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   grid_value: (row) => row.three_p_brand,
        //   id: 'three_p_brand',
        //   label: '3p Brand',
        //   name: 'three_p_brand',
        //   ns: (crudStore) => crudStore.storedData.three_p_brand,
        //   rules: 'string',
        //   utaType: 'text',
        // },
        {
          belongsTo: ['goal', 'goals'],
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="goal"
            fieldName="goal_id"
            form={rootStore.channel.model.crudStore.form}
            label="Goal"
            menuItems={rootStore.uiStore.menuItems('goals', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('goal_id', event)}
          />),
          formOrder: 80,
          default: 1,
          form: 'goal_id',
          label: 'Goal',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.goal, 'name'),
          show_order: 80,
          utaType: 'fk',
        },
        {
          colSize: 8.5,
          domo_field: 'goal',
          domo_value: (crudStore) => property(crudStore.storedData.goal, 'name'),
          default: 1,
          defaultType: 'goals',
          grid: true,
          grid_order: 80,
          grid_value: (row) => property(row.goal, 'name'),
          id: 'goal',
          label: 'Goal',
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'goal',
          ns: (crudStore) => abbrev(crudStore.storedData.goal),
          ns_order: 80,
          rules: 'required',
          options: (apiStore) => apiStore.goals,

          utaType: 'goals',
        },

        {
          belongsTo: ['device', 'devices'],
          default: 1,
          form: 'device_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="device"
            fieldName="device_id"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            menuItems={rootStore.uiStore.menuItems('devices', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('device_id', event)}
          />),
          formOrder: 90,
          label: 'Device',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.device, 'name'),
          show_order: 90,
          utaType: 'fk',
        },
        {
          colSize: 9,
          domo_field: 'device',
          domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
          default: 1,
          grid: true,
          grid_order: 90,
          grid_value: (row) => property(row.device, 'name'),
          id: 'device',
          label: 'Device',
          name: 'device',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.devices,
          rules: 'integer',
          sortable: true,
          utaType: 'devices',
        },
        {
          belongsTo: ['geo', 'geos'],
          default: 13,
          form: 'geo_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="geo"
            fieldName="geo_id"
            form={rootStore.channel.model.crudStore.form}
            label="Geo"
            // menuItems={rootStore.apiStore.geos.map((p) => ({ label: p.name, value: p.id }))}
            menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('geo_id', event)}
          />),
          formOrder: 95,
          label: 'Geos',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 95,
          utaType: 'fk',
        },
        {
          colSize: 8.5,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          default: 13,
          grid: true,
          grid_order: 95,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          label: 'Geo',
          name: 'geo',
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          ns_order: 95,
          rules: 'integer',
          sortable: true,
          utaType: 'geos'
        },
        {
          belongsTo: ['language', 'languages'],
          default: 1,
          form: 'language_id',
          copyEditable: true,
          formField: (rootStore) => (
            <UccSelect
              core="language"
              fieldName="language_id"
              form={rootStore.channel.model.crudStore.form}
              label="Language"
              menuItems={rootStore.uiStore.menuItems('languages', 'makePrimeRegularOption')}
              onChange={(event) => rootStore.channel.model.crudStore.storeData('language_id', event)}
            />),
          formOrder: 100,
          label: 'Language',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 100,
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          default: 1,
          grid: true,
          grid_order: 100,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          grid_value: (row) => property(row.language, 'name'),
          id: 'language',
          label: 'Language',
          name: 'language',
          ns: (crudStore) => abbrev(crudStore.storedData.language),
          ns_order: 100,
          utaType: 'languages'

        },
        {
          colSize: 11,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 150,
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          label: 'Created By',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.Users,
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ],
    },

    //   _____       _      _____                                 
    //  (_____)     (_)    (_____)  _                        ____ 
    // (_)___(_)  __(_)   (_)  ___ (_)__  ___   _   _  ____ (____)
    // (_______) (____)   (_) (___)(____)(___) (_) (_)(____)(_)__ 
    // (_)   (_)(_)_(_)   (_)___(_)(_)  (_)_(_)(_)_(_)(_)_(_)_(__)
    // (_)   (_) (____)    (_____) (_)   (___)  (___) (____)(____)
    //                                                (_)         
    //                                                (_)         

    {
      allFilterData: (apiStore) => apiStore['paidSearchAdGroupsFilterOptions'],
      disableBulkCreate: true,
      disableCopyEdit: true,
      disableBulkEdit: true,
      codename: 'PaidSearchAdGroup',
      controller: 'paid_search_ad_group',
      crud: (rootStore) => rootStore.paidSearchAdGroupsCrud,
      domoNsType: 'PaidSearchAdGroup',
      endpoint: 'paid-search-ad-groups',
      dontDisplayDate: true,
      filterOptionsCall: (apiStore) => (val) => apiStore['paidSearchAdGroupsFilterOptions'] = val,
      form: () => <GenericForm type="Ad Group" numButtons={3} />,
      genericModelPromiseEndpoints: [
        'ad-modifiers',
        'ad-modifier-objective-modifiers',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-objectives',
        'channel-platforms',
        'devices',
        'extra-field-objective-modifiers',
        'geos',
        'goals',
        'languages',
        'objectives',
        'objective-modifiers',
        'objective-modifier-objectives',
        'paid-search-ad-groups',
        'paid-search-campaigns',
        'platforms',
        'statuses',
        'users',
      ],
      hasChildren: true,
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.paidSearchCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.paidSearchAdGroupsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.extraFieldsCrud.getAll({ model_id: '10' }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, paid_search_campaign_id: root.paidSearch.PaidSearchCamp.crudStore.selectedRowIds, }; },
      name: 'Ad Groups',
      nameField: 'name',
      noDataText: (root) => '',
      parentModel: 'paid_search_campaign',
      parentModelCode: 'PaidSearchCamp',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'ad-groups',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'ad group',
      tabData: (apiStore) => apiStore.genericTabPromiseResults(),
      tabPromiseEndpoints: (rootStore, brand_param) => {
        const allFilter = _.merge(
          brand_param,
          rootStore.paidSearch.PaidSearchAdGroup.modelFilter(rootStore),
        );
        return [
          rootStore.paidSearchCampaignsCrud.getTotal(brand_param),
          rootStore.paidSearchAdGroupsCrud.getTotal(allFilter, ''),
        ];
      },
      tabDisabled: (r) => r.apiStore.adGroupTabDisabled ? true : false,
      tabIndex: 1,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      extra_field: 'parent',
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 6,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          colSize: 9,
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 7,
          label: 'Status',
          name: 'status_id',
          options: (apiStore) => apiStore.statuses,
          id: 'status_id',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 2,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'PaidSearchAdGroup',
          rules: 'required',
        },

        {
          domo_field: 'channel',
          domo_value: () => 'PaidSearch',
          // ns: () => 'PaidSearch',
          name: 'channel',
          id: 'channel',
          utaType: 'channel',
          rules: 'required',
        },
        {
          colSize: 11,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 150,
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          label: 'Created By',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.Users,
        },

        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.rootStore.apiStore.currentGroup),
          name: 'group',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => abbrev(crudStore.rootStore.apiStore.currentBrand),
          name: 'brand',
          rules: 'required',
          utaType: 'brand',
        },
        {
          copyEditable: false,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.rootStore.apiStore.currentBrand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          belongsTo: ['paid_search_campaign', 'paidSearchCampaigns'],
          form: 'paid_search_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="paid_search_campaign"
            fieldName="paid_search_campaign_id"
            label="Paid Search Campaign"
            form={rootStore.channel.model.crudStore.form}
            // menuItems={rootStore.uiStore.menuItems('paidSearchCampaigns', 'makePrimePaidSearchCampaignOptions')}
            menuItems={rootStore.channel.model.crudStore.makePrimePaidSearchCampaignOptions(rootStore.apiStore.paidSearchCampaigns)}
            onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('paid_search_campaign', 'extra_field', event)}
          />),
          formOrder: 20,
          label: 'Campaign',
          rules: 'required|integer',
          utaType: 'fk',
          subType: 'inherit',
        },
        {
          name: 'paid_search_campaign',
          ns: (crudStore) => 'PARENT'
        },
        {
          colSize: 14,
          grid: true,
          grid_order: 9,
          grid_value: (row) => property(row.paid_search_campaign, 'namestring_string'),
          filter: (dt, col) => inputTextFilter(dt, col),
          id: 'paid_search_campaigns.namestring_string',
          label: 'Campaign Namestring',
          name: 'campaign_name',
          ns: () => 'PARENT',
          show: true,
          show_value: (row) => property(row.paid_search_campaign, 'namestring_string'),
          show_order: 20,
          utaType: 'integer',
        },
        // {
        //   belongsTo: ['ad_modifier', 'adModifiers'],
        //   form: 'ad_modifier_id',
        //   formField: (rootStore) => (<UccSelect
        //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'ad_modifier_id')}
        //     core="ad_modifier"
        //     fieldName="ad_modifier_id"
        //     label="Ad Group Modifier"
        //     form={rootStore.channel.model.crudStore.form}
        //     menuItems={_.compact(rootStore.apiStore.adModifiers.map(ad_modifier => rootStore.uiStore.makePrimeAdModifierOption(ad_modifier)))}
        //     filter={false}
        //     onChange={(event) => rootStore.channel.model.crudStore.storeData('ad_modifier_id', event)}
        //   />),
        //   formOrder: 30,
        //   label: 'Ad Group Mod.',
        //   rules: 'integer',
        //   show: true,
        //   show_value: (row) => row.ad_mod_string,
        //   show_order: 30,
        //   utaType: 'fk',
        // },
        // {
        //   colSize: 11,
        //   contrivance: (crudStore) => adGroupModifierNs(crudStore.storedData),
        //   domo_field: 'ad_modifier',
        //   domo_value: (crudStore) => adGroupModifierNs(crudStore.storedData),
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   form: 'ad_mod_string',
        //   grid: true,
        //   grid_order: 60,
        //   grid_value: (row) => row.ad_mod_string,
        //   id: 'ad_mod_string',
        //   name: 'ad_modifier',
        //   label: 'Ad Group Mod.',
        //   ns: (crudStore) => adGroupModifierNs(crudStore.storedData),
        //   ns_order: 50,
        //   options: (apiStore) => apiStore.adModifiers,
        //   type: 'hidden',
        // },
        {
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'campaign_type'), 'name'),
          id: 'campaign_type',
          name: 'campaign_type',
          options: (apiStore) => apiStore.campaignTypes,
          utaType: 'parent',
        },

        {
          colSize: 10,
          domo_field: 'platform',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'platform'), 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid: true,
          grid_order: 10,
          grid_value: (row) => property(row.paid_search_campaign.platform, 'name'),
          id: 'platform',
          label: 'Platform',
          name: 'platform',
          ns: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'platform'),
          ns_order: 10,
          options: (apiStore) => apiStore.platforms,
          sortField: 'platforms.name',
          utaType: 'parent',

        },
        {
          domo_field: 'objective',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'objective'), 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid: true,
          grid_order: 20,
          grid_value: (row) => property(row.paid_search_campaign.objective, 'name'),
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          ns: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'objective'),
          ns_order: 20,
          options: (apiStore) => apiStore.objectives,
          sortField: 'objectives.name',
          utaType: 'parent'
        },
        {
          domo_field: 'objective_modifier',
          domo_value: (crudStore) => objectiveModifierNs('campaign', crudStore.storedData.row ? crudStore.storedData.row.paid_search_campaign : crudStore.storedData.paid_search_campaign ? crudStore.storedData.paid_search_campaign : crudStore.storedData),
          id: 'objective_modifier',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 30,
          grid_value: (row) => property(property(row, 'paid_search_campaign'), 'obj_mod_string'),
          label: 'Objective Mod.',
          name: 'objective_modifier',
          nestedFilterField: 'paid_search_campaigns.obj_mod_string',
          ns: (crudStore) => crudStore.storedData.paid_search_campaign == undefined ? '' : [crudStore.storedData.paid_search_campaign.obj_mod_string].join('_'),
          ns_order: 30,
          sortField: 'paid_search_campaigns.obj_mod_string',
          options: (apiStore) => apiStore.objectiveModifiers,
          utaType: 'parent',
        },
        {
          domo_field: 'extra_field',
          domo_value: (crudStore) => { NameString.pivotNamestring(crudStore, 'paid_search_campaign', 'extra_field') },
          contrivance: (crudStore) => NameString.pivotNamestring(crudStore, 'paid_search_campaign', 'extra_field'),
          form: 'paid_search_ad_group_extra_fields_attributes',
          formField: (rootStore) => <UtaTargeting form={rootStore.channel.model.crudStore.form} control="paid_search_campaign" dependent="extra_field" />,
          formOrder: 80,
          grid: true,
          grid_order: 80,
          grid_value: (row) => row["paid_search_ad_group_extra_fields"].map(a => a.extra).join('+'),
          id: 'extra_field_ids',
          label: 'Ad Group Mod.',
          multiple: [],
          name: 'paid_search_ad_group_extra_fields_attributes',
          ns: (crudStore) => NameString.pivotNamestring(crudStore, 'objective_modifier', 'extra_field'),
          ns_order: 40,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "paid_search_ad_group_extra_fields", "extra_field"),
          show_order: 80,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'paid_search_ad_group_extra_fields_attributes[].id',
        },
        {
          form: 'paid_search_ad_group_extra_fields_attributes[].paid_search_ad_group_id',
        },
        {
          form: 'paid_search_ad_group_extra_fields_attributes[].extra_field_id',
        },
        {
          form: 'paid_search_ad_group_extra_fields_attributes[].extra',
        },
        {
          form: 'paid_search_ad_group_extra_fields_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'paid_search_ad_group_extra_fields_attributes[].changed',
          default: 'false'
        },
        {
          form: 'paid_search_ad_group_extra_fields_attributes[].on',
        },  //no geo or language for astellas




        // {
        //   form: 'product_details',
        //   formField: (rootStore) => (
        //     <UccInputText
        //       display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'product_details')}
        //       core="product_details"
        //       fieldName="product_details"
        //       form={rootStore.channel.model.crudStore.form}
        //       label="Product Details"
        //       onInput={(event) => rootStore.channel.model.crudStore.storeData('product_details', event)}
        //     />),
        //   formOrder: 50,
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   grid_value: (row) => row.product_details,
        //   id: 'product_details',
        //   label: 'Product Details',
        //   name: 'product_details',
        //   ns: (crudStore) => crudStore.storedData.product_details,
        //   rules: 'string',
        //   utaType: 'text',
        // },

        // {
        //   form: 'category',
        //   formField: (rootStore) => (<UccInputText
        //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'category')}
        //     core="category"
        //     fieldName="category"
        //     form={rootStore.channel.model.crudStore.form}
        //     label="Category"
        //     onInput={(event) => rootStore.channel.model.crudStore.storeData('category', event)}
        //   />),
        //   formOrder: 40,
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   grid_value: (row) => row.category,
        //   id: 'category',
        //   label: 'Category',
        //   name: 'category',
        //   ns: (crudStore) => crudStore.storedData.category,
        //   rules: 'string',
        //   utaType: 'text',
        // },

        // {
        //   form: 'custom_modifier',

        //   formField: (rootStore) => (<UccInputText
        //     display={rootStore.apiStore.paidSearchExtraField(rootStore.channel.model.crudStore, 'custom_modifier')}
        //     core="custom_modifier"
        //     fieldName="custom_modifier"
        //     form={rootStore.channel.model.crudStore.form}
        //     label="Custom Modifier"
        //     onInput={(event) => rootStore.channel.model.crudStore.storeData('custom_modifier', event)}
        //   />),
        //   formOrder: 60,
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   grid_value: (row) => row.custom_modifier,
        //   id: 'custom_modifier',
        //   label: 'Custom Modifier',
        //   name: 'custom_modifier',
        //   ns: (crudStore) => crudStore.storedData.custom_modifier,
        //   rules: 'string',
        //   utaType: 'text',
        // },

        // {
        //   label: '3p Brand',
        //   name: 'three_p_brand',
        //   ns: (crudStore) => crudStore.storedData.three_p_brand,
        //   rules: 'string',
        //   utaType: 'text',
        // },

        {
          colSize: 8.5,
          domo_field: 'goal',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'goal'), 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.paid_search_campaign.goal, 'name'),
          id: 'goal',
          label: 'Goal',
          name: 'goal',
          ns: (crudStore) => parentAbbrev(crudStore.storedData.paid_search_campaign, 'goal'),
          options: (apiStore) => apiStore.goals,
          sortField: 'goals.name',
          utaType: 'parent',
        },
        {
          domo_field: 'device',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'device'), 'name'),
          id: 'device',
          name: 'device',
          options: (apiStore) => apiStore.devices,
          utaType: 'parent',
        },
        {
          domo_field: 'geo',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'geo'), 'name'),
          id: 'geo',
          name: 'geo',
          options: (apiStore) => apiStore.geos,
          utaType: 'parent',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_campaign, 'language'), 'name'),
          id: 'language',
          name: 'language',
          options: (apiStore) => apiStore.languages,
          utaType: 'parent',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ],
    },






    //    ('-.     _ .-') _             .-') _    ('-.     _   .-')       ('-.    .-')    
    //    ( OO ).-.( (  OO) )           ( OO ) )  ( OO ).-.( '.( OO )_   _(  OO)  ( OO ).  
    //   / . --. / \     .'_       ,--./ ,--,'   / . --. / ,--.   ,--.)(,------.(_)---\_) 
    //   | \-.  \  ,`'--..._)      |   \ |  |\   | \-.  \  |   `.'   |  |  .---'/    _ |  
    // .-'-'  |  | |  |  \  '      |    \|  | ).-'-'  |  | |         |  |  |    \  :` `.  
    //  \| |_.'  | |  |   ' |      |  .     |/  \| |_.'  | |  |'.'|  | (|  '--.  '..`''.) 
    //   |  .-.  | |  |   / :      |  |\    |    |  .-.  | |  |   |  |  |  .--' .-._)   \ 
    //   |  | |  | |  '--'  /      |  | \   |    |  | |  | |  |   |  |  |  `---.\       / 
    //   `--' `--' `-------'       `--'  `--'    `--' `--' `--'   `--'  `------' `-----'  
    {
      allFilterData: (apiStore) => apiStore['paidSearchAdNamesFilterOptions'],
      codename: 'PaidSearchAdName',
      controller: 'paid_search_ad_name',
      crud: (rootStore) => rootStore.paidSearchAdNamesCrud,
      domoNsType: 'PaidSearchAdName',
      endpoint: 'paid-search-ad-names',
      filterOptionsCall: (apiStore) => (val) => apiStore['paidSearchAdNamesFilterOptions'] = val,
      form: (rootStore) => <GenericForm type="Ad Name" datePicker={2} numButtons={3} extraFields={['version']} />,
      genericModelPromiseEndpoints: [
        'marketing-campaigns',
        'paid-search-ad-groups',
        'paid-search-ad-names',
        'paid-search-campaigns',
        'statuses',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.paidSearchAdNamesCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      grandParentModel: 'paid_search_campaign',
      modelFilter: (root) => _.merge({ paid_search_ad_group_id: root.paidSearch.PaidSearchAdGroup.crudStore.selectedRowIds, }, { paid_search_campaign_id: root.paidSearch.PaidSearchCamp.crudStore.selectedRowIds, }, { brand_id: root.apiStore.currentBrandId }),
      name: 'Ad Names',
      nameField: 'name',
      noDataText: (root) => '',
      parentModel: 'paid_search_ad_group',
      parentModelCode: 'PaidSearchAdGroup',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'ad-names',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'ad name',
      tabData: (apiStore) => apiStore.genericTabPromiseResults(),
      tabPromiseEndpoints: (rootStore, brand_param) => {
        const allFilter = _.merge(
          brand_param,
          rootStore.paidSearch.PaidSearchAdGroup.modelFilter(rootStore),
        );
        return [
          rootStore.paidSearchCampaignsCrud.getTotal(brand_param),
          rootStore.paidSearchAdGroupsCrud.getTotal(allFilter, ''),
        ];
      },
      tabDisabled: (r) => (r.apiStore.adNameTabDisabled) ? true : false,
      tabIndex: 2,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 3,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 250,
          utaType: 'namestring_id'
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          colSize: 9,
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 4,
          label: 'Status',
          name: 'status_id',
          options: (apiStore) => apiStore.statuses,
          id: 'status_id',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 2,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'PaidSearchAdName',
          rules: 'required',
        },

        {
          domo_field: 'channel',
          domo_value: () => 'PaidSearch',
          // ns: () => 'PaidSearch',
          name: 'channel',
          id: 'channel',
          utaType: 'channel',
          rules: 'required',
        },
        {
          colSize: 14,
          domo_field: 'grand_parent_namestring',
          domo_value: (crudStore) => property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'namestring_string'),
          form: 'campaign_name',
          grid: true,
          grid_order: 4,
          grid_value: (row) => `${property(row.paid_search_ad_group.paid_search_campaign, 'namestring_string')} `,
          grid_label: 'Campaign Namestring',
          filter_placeholder: 'Campaign',
          id: 'a.b.namestring_string',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          label: 'Description',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          utaType: 'name',
          rules: 'string',
        },
        {
          colSize: 11,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 150,
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          label: 'Created By',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.Users,
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          utaType: 'group',
          rules: 'required',
        },
        {
          copyEditable: false,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          rules: 'required',
          utaType: 'brand',
        },
        {
          domo_field: 'audience',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'audience'), 'name'),
          id: 'audience',
          name: 'audience',
          utaType: 'parent',
        },
        {
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'campaign_type'), 'name'),
          id: 'campaign_type',
          name: 'campaign_type',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'segment',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'segment'), 'name'),
          id: 'segment',
          name: 'segment',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'device',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'device'), 'name'),
          id: 'device',
          name: 'device',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'goal',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'goal'), 'name'),
          id: 'goal',
          name: 'goal',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'ad_modifier',
          domo_value: (crudStore) => adGroupModifierNs(crudStore.storedData.paid_search_ad_group),
          id: 'ad_modifier',
          name: 'ad_modifier',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'objective_modifier',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'objective_modifier'), 'name'),
          id: 'objective_modifier',
          name: 'objective_modifier',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'secondary_tactic',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'secondary_tactic'), 'name'),
          id: 'secondary_tactic',
          name: 'secondary_tactic',
          utaType: 'parent',
          rules: ''
        },
        {
          // this is tactic?
          domo_field: 'objective',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'objective'), 'name'),
          id: 'objective',
          name: 'objective',
          utaType: 'parent',
          rules: 'required'
        },
        {
          domo_field: 'geo',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'geo'), 'name'),
          id: 'geo',
          name: 'geo',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'platform',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'platform'), 'name'),
          id: 'platform',
          name: 'platform',
          utaType: 'parent',
          rules: ''
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(property(crudStore.storedData.paid_search_ad_group.paid_search_campaign, 'language'), 'name'),
          id: 'language',
          name: 'language',
          utaType: 'parent',
          rules: ''
        },
        {
          belongsTo: ['paid_search_ad_group', 'paidSearchAdGroups'],
          copyEditable: false,
          form: 'paid_search_ad_group_id',
          formField: (rootStore) => (<UccSelect
            core="paid_search_ad_group"
            fieldName="paid_search_ad_group_id"
            form={rootStore.channel.model.crudStore.form}
            label="Ad Group"
            menuItems={rootStore.channel.model.crudStore.makePrimeAdGroupOptions(rootStore.apiStore.paidSearchAdGroups)}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('paid_search_ad_group_id', event)}
          />),
          formOrder: 10,
          label: 'Ad Group',
          rules: 'required|integer',
          utaType: 'fk',
          subType: 'inherit',
        },
        {
          domo_field: 'parent_namestring',
          domo_value: (crudStore) => property(crudStore.storedData.paid_search_ad_group, 'namestring_string'),
        },
        {
          colSize: 14,
          copyEditable: true,
          grid: true,
          grid_order: 5,
          grid_value: (row) => property(row.paid_search_ad_group, 'namestring_string'),
          grid_type: 'namestring',
          grid_label: 'Ad Group Namestring',
          id: 'namestring_string',
          label: 'Ad Group Namestring',
          name: 'paid_search_ad_group',
          filter: (dt, col) => inputTextFilter(dt, col),
          options: (apiStore) => apiStore.paidSearchAdGroups,
          ns: (crudStore) => HeadedCamelCase(property(crudStore.storedData.paid_search_ad_group, 'name')),
          show: true,
          show_value: (row) => property(row.paid_search_ad_group, 'namestring_string'),
          show_order: 20,
          rules: 'required|integer',
          utaType: 'integer',
        },
        {
          colSize: 14,
          domo_field: 'ad_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 6,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Marketing Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 8,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.marketing_campaign, 'name'),
          show_order: 30,
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Marketing Campaign"
            menuItems={rootStore.uiStore.menuItems('marketingCampaigns', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 30,
          label: 'Marketing Campaign',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10,
          filter: (dt, col) => inputTextFilter(dt, col),
          filter_placeholder: 'Description',
          grid: true,
          grid_order: 25,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          name: 'name',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          utaType: 'name',
          rules: 'string',
        },
        {
          domo_field: 'ab_version',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.version),
          form: 'version',
          formOrder: 160,
          grid: true,
          grid_order: 145,
          grid_value: (row) => row.version,
          id: 'version',
          label: 'Version',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="version"
            fieldName="version"
            form={rootStore.channel.model.crudStore.form}
            label="Version"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('version', event)}
          />),
          filter: (dt, col) => inputTextFilter(dt, col),
          hideForm: true,
          name: 'version',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.version),
          ns_order: 160,
          rules: 'string',
          show: true,
          show_value: (row) => row.version,
          show_order: 250,
          utaType: 'text',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 130, 130, 'S.', 200),
        dateField('end_date', 'End Date', 'blank', false, 140, 140, 'E.', 210),
      ),
    },




  ],
};