import _ from 'lodash';
import ax from 'axios';
import { action, computed, observable } from 'mobx';
import CrudAdminStore from './CrudAdminStore';
// import storeMetadata from './StoreMetadata';
import Model from './Model';
import rootStore from './RootStore';

class Channel {
  constructor(rootStore, storeMetadata, channel) {
    const chan = storeMetadata.channels.find((ch) => ch.codename == channel);
    // console.log(chan)
    if (typeof chan == 'undefined') return;
    chan.models.forEach((mainModel) => {
      chan[mainModel.codename] = new Model(rootStore, chan, mainModel);
      chan[mainModel.codename]['children'] = [];
      if (!_.isEmpty(mainModel.childModelEndpoints)) {

        mainModel.childModelEndpoints.forEach((childModelEndpoint, index) => {
          const childModelMeta = chan.models.find(channelModels => channelModels.endpoint == childModelEndpoint);
          const childModelMetaCopy = { ...childModelMeta }
          const childModel = new Model(rootStore, chan, childModelMetaCopy, `${chan[mainModel.codename].name}${childModelMetaCopy.name}Child`);
          chan[mainModel.codename]['children'].push(childModel);
          // delete m.childModelEndpoints[index]; // otherwise its infinite!
        })
      }
    }, this);
    return chan;
  }
}

export default Channel;
