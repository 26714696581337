import React from 'react';
import { observer, inject } from 'mobx-react';
import AcknowledgeError from '../lib/AcknowledgeError';

@inject('apiStore', 'uiStore')
@observer
class ErrorWrapper extends React.Component {

  render() {
    const { apiStore, children } = this.props;

    if (apiStore.serverRequestError != null) {
      return (
        <AcknowledgeError>
          <h3> Application Network Error </h3>
          <p>
            An error has occurred and we have been notified of the problem.
          </p>
          <p>
            If the problem persists, please notify the UCC administrator.
          </p>
        </AcknowledgeError>
      )
    }

    return children
  }
}

export default ErrorWrapper;
