import AdminHomeIcon from '../../icons/AdminHomeIcon';
import AdminUserManagementIcon from '../../icons/AdminUserManagementIcon';
import AdminOldManIcon from '../../icons/AdminOldManIcon';
import AdminAttrValues from '../../icons/AdminAttrValues';
import React from 'react';
import routes from './Routes';
import { Link } from 'mobx-router';
import { observer, inject } from 'mobx-react';

import { withStyles } from '@material-ui/core/styles';
const styles = () => ({
  iconSpreader: {
    padding: '30px 0 0',
  },
  topSpread: {
    paddingTop: '60px'
  }
});


@inject('apiStore', 'uiStore', 'rootStore')
@observer
class AdminLeftNav extends React.Component {

  legacyAdmin() {
    const { rootStore, uiStore, classes } = this.props;
    const { router } = uiStore;
    if (rootStore.features.legacy_admin) {
      return (
        <div className={classes.iconSpreader} >
          <Link href="./app/legacy-admin" view={routes.legacyAdmin} store={uiStore} key="legacyAdmin1">
            <AdminOldManIcon color={router.currentPath == "/app/legacy-admin" ? '#5EABF8' : '#ffffff'} />
          </Link>
        </div>
      )
    }
  }

  render() {
    if (!this.props.admin) return (<></>);
    const { rootStore, uiStore, classes } = this.props;
    const { router } = uiStore;

    if (!rootStore.apiStore.currentUser.admin) return '';
    return (
      <>
        <div className={classes.iconSpreader, classes.topSpread}>
          <Link href="/app/admin" view={routes.admin} store={uiStore} key={'Admin'}>
            <AdminHomeIcon color={router.currentPath == "/app/admin" ? '#5EABF8' : '#ffffff'} />
          </Link>
        </div>
        <div className={classes.iconSpreader} >
          <Link href="/app/admin/user-management"
            view={routes.adminChannelTabs}
            params={{ channel: 'user-management', model: 'users' }}
            store={uiStore}
            key={'User Management'}
          >
            <AdminUserManagementIcon color={router.currentPath.match(/\/app\/admin\/user-?(M|m)anagement.*/) ? '#5EABF8' : '#ffffff'} />
          </Link>
        </div>
        <div className={classes.iconSpreader} >
          <Link href="/app/admin/attributes-values"
            view={routes.adminChannelTabs}
            params={{ channel: 'attributes-values', model: 'ab-types' }}
            store={uiStore}
            key={'Attributes & Values'}
          >
            <AdminAttrValues color={router.currentPath.match(/\/app\/admin\/attr(ibutes-)?(V|v)alues.*/) ? '#5EABF8' : '#E6E6E6'} />
          </Link>
        </div>
        {this.legacyAdmin()}
      </>
    );
  }
}

AdminLeftNav.wrappedComponent.propTypes = {

};

export default withStyles(styles)(AdminLeftNav);
