import React from 'react';
import UccButton from './UccButton';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';
import UccDoubleButton from './UccDoubleButton';
import { between, permRequest } from '../../store/StoreMetadata.js';

@inject('rootStore')
@observer
class UccApproveDenyButton extends React.Component {
  constructor(props) {
    super(props);
    const crudStore = this.props.rootStore.channel.model.crudStore;
  }

  two_buttons(form, onCancel) {
    return (<UccDoubleButton
      form={form}
      onSubmit={form.onSubmit}
      onCancel={onCancel}
      label={this.props.label}
    />);
  }
  three_buttons(form, onCancel) {
    const crudStore = this.props.rootStore.channel.model.crudStore;
    return (<div className="root_3">
      <div className="cancel_3">
        <UccButton
          buttonType=''
          label='cancel'
          form={form}
          onClick={onCancel}
        />
      </div>
      <div className="predraft_3">
        <UccButton
          buttonType='deny'
          label="Deny"
          form={form}
          onClick={(e) => {
            form.$('status_id').set('value', '7');
            crudStore.clearSelection();
            form.onSubmit(e);
          }}
        />
      </div>
      <div className="submit_3">
        <UccButton
          buttonType='submit'
          label="Approve"
          form={form}
          onClick={(e) => {
            form.$('status_id').set('value', '3');
            crudStore.clearSelection();
            form.onSubmit(e);
          }}
        />
      </div>
    </div>
    );
  }
  render() {
    const {
      rootStore, form, onCancel
    } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const initialAction = crudStore.initialAction;
    const requested = 6;
    // switch (initialAction) {
    //   case 'new' && permRequest(rootStore):
    //     return this.two_buttons(form, onCancel);
    //   default:
    //     return this.three_buttons(form, onCancel);
    // }
    if (permRequest(rootStore)) {
      return this.two_buttons(form, onCancel);
    }
    else {
      if (initialAction == 'edit' && crudStore.storedData['status_id'] == requested)
        return this.three_buttons(form, onCancel);
      else return this.two_buttons(form, onCancel);
    }
  }
}

export default UccApproveDenyButton;
