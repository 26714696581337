import jsp from '../lib/jsp';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { email } from './metadata/astellas/email.js';
import { facebook_integration } from './metadata/astellas/facebook-integration';
import { paid_social } from './metadata/astellas/paid-social.js';
import { display } from './metadata/astellas/display.js';
import { paid_search } from './metadata/astellas/paid-search';
import { user_management } from './metadata/astellas/user-management';
import { attr_values } from './metadata/astellas/attr-and-value';
import { domo_integration } from './metadata/astellas/domo-integration';
import { Calendar } from '../lib/Calendar';

var moment = require('moment');



export const astellasMetadata = {

  // don't change codenames!
  channels: [
    {
      name: 'Affiliate',
      endpoint: 'affiliate',
      codename: 'affiliate',
      abbrev: 'affiliate',
      icon: 'Affiliate',
      disabled: true,
      invisible: true,
      showViewIcon: true,
      models: [
        {
          name: 'Affiliate Campaigns',
          singular: 'campaign',
          endpoint: 'affiliate-campaigns',
        },
      ],
    },
  ].concat(email, paid_social, display, paid_search, user_management, attr_values, domo_integration, facebook_integration),
};