import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';

const CssKeyboardDatePicker = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'blue',
    },
    '& .MuiInputLabel-root': {

    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 10,
      paddingBottom: 10,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover fieldset': {
    
      },
      '& .Mui-focused fieldset': {
        borderColor: 'blue',
      },
      '& .MuiSvgIcon-root': {
        width: '0.75em',
      },
    },
  },
})(KeyboardDatePicker);

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    display: 'inline-block',
    border: '3px solid #fff',
    width: '100vh',
    maxWidth: 475,
  },
  error: {
    color: 'red',
    margin: 0
  },
  datePicker: {
    maxWidth: 180,
    maxHeight: 40,
  },
  dates: {
    marginBottom: theme.spacing(1),
    maxWidth: 200,
    width: '50vh',
    float: 'left',
    padding: '20',
  },
  enddates: {
    marginBottom: theme.spacing(1),
    maxWidth: 200,
    width: '50vh',
    float: 'right',
    paddingLeft: '20',
    paddingRight: '0',
    height: 40,
  },
});

@inject('rootStore')
@observer
class UtaDoubleDatePicker extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      classes,
      form,
      rootStore,
      startClear,
      endClear
    } = this.props;

    const crudStore = rootStore.channel.model.crudStore;
    return (
      <div className={classes.root}>
        <div className={classes.dates}>
          <CssKeyboardDatePicker
            InputAdornmentProps={{ position: "start" }}
            clearable={startClear}
            className={classes.datePicker}
            format='MM/DD/YYYY' // HH:mm
            inputVariant="outlined"
            label="Start Date Thing"
            onAccept={(event) => crudStore.storeData('start_date', event)}
            onKeyUp={(event) => crudStore.storeData('start_date', event)}
            {...form.$('start_date').bind()}
          />
          <div className={classes.error}>{form.$('start_date').error}</div>
        </div>
        <div className={classes.enddates}>
          <CssKeyboardDatePicker
            InputAdornmentProps={{ position: "start" }}
            className={classes.datePicker}
            clearable={endClear}
            format='MM/DD/YYYY' // HH:mm
            inputVariant="outlined"
            label="End Date"
            onAccept={(event) => crudStore.storeData('end_date', event)}
            onKeyUp={(event) => crudStore.storeData('end_date', event)}
            {...form.$('end_date').bind()}
          />
          <div className={classes.error}>{form.$('end_date').error}</div>
        </div>

        {this.suggestedEndDate}
      </div>
    )
  }
}


UtaDoubleDatePicker.wrappedComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UtaDoubleDatePicker); {/* { CREATIVE NAME / AD DESCRIPTION } */ }
