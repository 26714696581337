import _ from 'lodash';
import dvr from 'mobx-react-form/lib/validators/DVR';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MobxReactForm from 'mobx-react-form';
import Paper from '@material-ui/core/Paper';
import NavigationSimple from '../Layout/NavigationSimple';
import PropTypes from 'prop-types';
import React from 'react';
import routes from '../Layout/Routes';
import TextField from '@material-ui/core/TextField';
import UserProfilePanel from './UserProfilePanel';
import validatorjs from 'validatorjs';
import { Link } from 'mobx-router';
import { observer, inject, Provider } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const BootstrapButton = withStyles({
  root: {
    fontStretch: 'Bold',
    fontWeight: 900,
  },
})(Button);
const plugins = {
  dvr: dvr(validatorjs),
};

const styles = (theme) => ({
  root: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    paddingTop: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '400px',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  error: {
    color: 'red',
    margin:0
  },
  button:{
    alignItems: 'center',
    width: '350px'
  },
  formControl: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },

});

@inject('rootStore')
@observer
class PasswordForgotForm extends React.Component {
  constructor(props) {
    super(props);
    const { rootStore } = this.props
 
    const hooks = {
      onSuccess(form) {
        const info = rootStore.apiStore.sendForgotPasswordEmail(form);
      },
      onError(form) {
        // crudAdminStore.setValidationError(Object.values(form.errors()).filter(Boolean));
      },
    };
    rootStore.apiStore.alreadySentForgotPasswordEmail = false;
    const fields = this.makeFields();
    this.form = new MobxReactForm({ fields }, { plugins, hooks })
  }

  makeFields = () => {
   return [
      {
        name: 'email',
        label: 'Email',
        rules: 'required|string',
        type: 'email'
      },
    ];
  }


  sentAlready = () => {
    const apiStore = this.props.rootStore.apiStore;
    const uiStore = this.props.rootStore.uiStore;
    if (_.isEmpty(uiStore) || _.isEmpty(apiStore)) return false;
    if (apiStore.alreadySentForgotPasswordEmail) {
      return true;
    } else {
      return false;
    }
  }
  onCancel(f) { 
    window.location.href = '/app';
  }

  render() {
    const { classes, rootStore } = this.props
    const { apiStore } = rootStore;
    const { form } = this;
    const sentAlready = this.sentAlready();

    if (sentAlready) {
      return ( 
        <React.Fragment>      
          <NavigationSimple />
          <div className={classes.root}>
            <Paper className={classes.paper} elevation={6}>
              <p>Check your Email!</p>
              <p>You may close this window</p>
            </Paper>
          </div>
        </React.Fragment>
      )
    } else {
      return (
        <div>
          <NavigationSimple />
          <div className={classes.root}>
          <Typography component='h2' variant='h2'  className={classes.h2}>Reset Password</Typography>
          </div>
          <Paper className={classes.paper} elevation={6}>
            <form onSubmit={form.onSubmit}>
              <Grid container justify="center" alignItems="center">             
                <Grid item xs={12} justify="center" alignItems="center">
                  <FormControl className={classes.formControl}>
                    <TextField
                        id="email"
                        variant="outlined"
                        {...form.$('email').bind()}
                      />
                      <div className={classes.error}>{form.$('email').error}</div>
                  </FormControl>

                  { !!apiStore.serverValidationErrors ? (
                    <div className={classes.error} id="validation-errors">  
                      <List>
                        {
                          (apiStore.serverValidationErrors.map((er) => (
                            <ListItem button key={er}>
                                <ListItemText primary={er} />
                            </ListItem>
                          )))
                        }
                      </List>
                    </div>
                  ) : ('doggie') }
                 
                    {/* <FormButtons form={form} onSubmit={form.onSubmit} onCancel={() => this.onCancel(this)}/> */}
                    <BootstrapButton
                      variant="contained"
                      className={classes.button}
                      onClick={form.onSubmit} 
                      color="primary"
                    >
                      Send Reset Link
                    </BootstrapButton>

                  {/* </Grid> */}
                </Grid>
              </Grid>
            </form>
          </Paper>
        </div>
      );
    }
  }
}

PasswordForgotForm.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PasswordForgotForm);
