import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { MobxRouter } from 'mobx-router';
import MobxRouterWithErrorHandling from './MobxRouterWithErrorHandling';

import RootStore from '../../store/RootStore';

const styles = theme => ({});

@inject('rootStore')
@observer
class CurrentPanel extends React.Component {
  render() {
    const { classes, rootStore } = this.props;
    return (
      <MobxRouterWithErrorHandling />
    );
  }
}

CurrentPanel.wrappedComponent.propTypes = {
  rootStore: PropTypes.instanceOf(RootStore),
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CurrentPanel);
