import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import Breadcrumbs from './Breadcrumbs';
import FavoriteIcon from "./FavoriteIcon";
import PaidSocial from '../../icons/PaidSocial';
import PaidSearch from '../../icons/PaidSearch';
import Email from '../../icons/Email';
import Affiliate from '../../icons/Affiliate';
import ChannelMenuItem from './ChannelMenuItem';
import AcknowledgeError from '../lib/AcknowledgeError';
import Typography from '@material-ui/core/Typography';
import ParamNotFoundError from './ParamNotFoundError';

const styles = theme => ({
  root: {
    width: '100%',
    margin: `0px 0 0 0px`,
  },
  padding: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  paper: {
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: 2,
    marginTop: '10px',
    height: 'auto',
    width: '40%',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '10px'
  },
  title: {
    width: '80%',
    top: '20px',
    bottom: '0',
    position: 'absolute',
    display: 'inline-block',
  },
  icon: {
    width: '24px',
    height: '24px',
  },
  parent: {
    width: '100%',
    height: '60px',
    position: 'relative',
    overflow: 'hidden',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'inline-block',
  },
  rightchild: {
    width: 50,
    top: '10px',
    right: '10px',
    bottom: '0',
    position: 'absolute',
    display: 'inline-block',
  },
  brand: {
    paddingLeft: '0px',
    paddingRight: theme.spacing(2),
    paddingTop: 2,
    paddingBottom: 0,
    height: 'auto',
    width: '40%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

@inject('uiStore', 'apiStore')
@observer
class BrandHome extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const apiStore = this.props.apiStore;
    await apiStore.fetchBrandUserChannels(apiStore.currentBrandId, apiStore.currentUser.id);
  };

  renderIcon(param, disabled = false) {
    switch (param) {
      case 'Display':
        return <Display fill={disabled ? "gray" : "black"} />;
      case 'Email':
        return <Email fill={disabled ? "gray" : "black"} />;
      case 'PaidSocial':
        return <PaidSocial fill={disabled ? "gray" : "black"} />;
      case 'PaidSearch':
        return <PaidSearch fill={disabled ? "gray" : "black"} />;
      default:
        return <Affiliate fill={disabled ? "gray" : "black"} />;
    }
  }
  render() {
    const { uiStore, apiStore, classes } = this.props;
    const {
      router: { params },
    } = uiStore;
    if (_.isEmpty(apiStore.currentBrand)) return (<ParamNotFoundError title="Brand Not Found" solution="Either the brand is misspelled in the url or you don't have access " />);
    const brandName = apiStore.currentBrand
      ? apiStore.currentBrand.name
      : '';
    const channels = _.sortBy(uiStore.rootStore.storeMetadata.channels.filter(c => !c.adminChannel).filter(c => !c.invisible), ['name']);
    return (
      <React.Fragment>
        <div style={{ marginLeft: '30px' }}><Breadcrumbs /></div>
        <div className={classes.brand} key="title_id">
          <Typography component='h1' variant='h1'>Channels</Typography>
        </div>
        <Paper className={classes.paper} elevation={6} key="paper_id">
          {apiStore.fetchingChannels && <div style={{ textAlign: 'center' }}><CircularProgress /></div>}
          <div className={classes.parent}>
            <Typography className={classes.title} component='h2' variant='h2' key={brandName} >Brand: {brandName}</Typography>
            <FavoriteIcon hoverState="true" fontSize="Large" className={classes.rightchild} brandId={apiStore.currentBrandId} key={apiStore.currentBrandId} />
          </div>
          <Divider />
          <List className={classes.padding} key="list_id_name">
            {apiStore.currentBrandId != null && !apiStore.fetchingChannels &&
              channels.map(ch => {
                if (apiStore.brandChannels.find(bc => bc.channel.codename == ch.codename)) {
                  return <ChannelMenuItem channel={ch} key={ch.codename} />
                }
              })}
          </List>
        </Paper>
      </React.Fragment>
    );

  }
}

export default withStyles(styles)(BrandHome);
