import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import jsp from '../../lib/jsp';
import MenuItem from '@material-ui/core/MenuItem';
import MobxReactForm from 'mobx-react-form';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import UccErrorHandling from './UccErrorHandling';
import UccInputNumber from './UccInputNumber';
import UccInputText from './UccInputText';
import UccSelect from './UccSelect';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { hidden } from 'ansi-colors';
import { InputText } from 'primereact/inputtext';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  error: {
    color: 'red',
    margin: 0,
  },

  select: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  textFieldIndented: {
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(0),
    fontWeight: 300,
    fontFamily: 'Avenir LT W01_35 Light1475496',
    fontSize: 18,
    fontWeight: 300,
    fontStretch: 'normal',
    letterSpacing: 'normal',
  },
  pivotInputWidth: {
    width: '33px',
  },
  number: {
    width: theme.spacing(12),
  },
  pivotformControl: {
    display: 'flex',
    whiteSpace: 'nowrap',
    paddingLeft: theme.spacing(1),
    verticalAlign: 'middle',
    maxHeight: '80px',
    // paddingBottom: theme.spacing(0.5),
    // paddingTop:  theme.spacing(0.5),
  },
});

@inject('rootStore')
@observer
class PivotField extends React.Component {

  constructor(props) {
    super(props);
    const { rootStore, form } = this.props;
    const { apiStore } = rootStore;
    this.crudStore = rootStore.channel.model.crudStore;
    this.form = this.crudStore.form;
    this.mainField = `${props.rootStore.channel.model.controller}_${props.dependent}s_attributes`;
    this.uberIdField = `${props.rootStore.channel.model.controller}_id`;
    this.uberId = props.row_id;
    this.checkboxes = props.checkboxes;
    this.fieldIdField = `${props.dependent}_id`;
    // this.setExtraFieldRequired(this.props.field);

  }

  @observable isError = false;
  @observable changeNumber = 0;
  @observable isRequired = true;

  componentDidMount = async () => {
    const rootStore = this.props.rootStore;
    this.setInitialForm(this.props.field);
    this.setInitialRules(this.props.field);
    this.setInitialLabels(this.props.field);
    this.setIsError(this.props.field);
    // this.setExtraFieldRequired(this.props.field);
    if (['copy', 'edit'].includes(this.crudStore.action)) {
    }
  };

  componentDidUpdate = async () => {
    // this.setExtraFieldRequired(this.props.field);
    this.setIsError(this.props.field);
  };

  setInitialForm = (field) => {
    const index = this.getFormIndex(field.id);
    if (index == -1) {
      this.addField(field);

      // $(index).$('extra').set('label', `${field.name} extra`);
    }
  }
  setInitialLabels = (field) => {
    const index = this.getFormIndex(field.id);
    if (index != -1 && !_.isEmpty(field.extra_field_type)) {
      this.form.$(this.mainField).$(index).$('extra').set('label', `${field.name} extra`);
    }
  }

  setInitialRules = (field) => {
    const index = this.getFormIndex(field.id);
    const val = this.form.$(this.mainField).$(index).values();
    const unset = this.props.dependent == 'targeting' ? !val.on : false;
    if (index != -1) {
      this.setRules(field, index, val, unset)
    }
  }

  addField = (field) => {
    const value = this.newValue(field.id)
    const newIndex = this.form.$(this.mainField).size;
    this.form.$(this.mainField).add()
    this.form.$(this.mainField).$(newIndex).set(value);
    this.setRules(field, newIndex, value, false);
  }

  requireable = (field, val) => {
    if (this.props.dependent == 'targeting') return field.extra_required;
    const obj_mod_id = _.isEmpty(this.crudStore.storedData["objective_modifier_id"]) ?
      this.crudStore.storedData['paid_search_campaign']['objective_modifier_id'] :
      this.crudStore.storedData["objective_modifier_id"];
    if (_.isEmpty(obj_mod_id.toString())) return false;
    const obj_mod = field.objective_modifiers.find(om => om.id == obj_mod_id)
    if (_.isEmpty(obj_mod)) return false;
    const efom = field.extra_field_objective_modifiers.find(efom => efom.objective_modifier_id.toString() == obj_mod_id.toString())
    if (_.isEmpty(efom)) return false;
    return efom.is_required;
  }

  setRules = (field, index, value, unset = false) => {
    if (this.requireable(field, value)) {

      const rule = unset ? '' : 'required';
      this.form.$(this.mainField).$(index).$('extra').set('rules', rule);
      if (!_.isEmpty(rule)) {
        this.form.$(this.mainField).$(index).$('extra').set('label', `${this.removeUnderScore(field.name)}`)
      } else this.isRequired = false;
    } else this.isRequired = false;
  }

  getFormIndex = (field_id) => {
    return this.form.$(this.mainField).values().findIndex(i => i[this.fieldIdField] == field_id);
  }

  newValue = (fieldId) => {
    return { changed: false, extra: null, [this.uberIdField]: this.uberId, on: false, [this.fieldIdField]: fieldId, _destroy: true }
  }

  crudStoreAttribute = (fieldId) => {
    return this.crudStore.storedData[this.mainField].find(attr => attr[this.fieldIdField] == fieldId);
  }

  emptyValue = (fieldId) => {
    return _.isEmpty(this.crudStoreAttribute(fieldId))
  }

  isChecked = (fieldId) => {
    return _.isEmpty(this.crudStoreAttribute(fieldId)) ? false : this.crudStoreAttribute(fieldId).on;
  };

  isDisabled = (fieldId) => {
    if (!this.checkboxes) return false;
    return !(this.crudStoreAttribute(fieldId) && this.crudStoreAttribute(fieldId)?.on);
  };



  deleteFormAndStoreValue = (value) => {
    value._destroy = true;
    // this.crudStore.storedData[this.mainField] = this.crudStore.storedData[this.mainField].filter(a => a[this.fieldIdField] != value[this.fieldIdField])
  }

  valueChanged = (id, el) => {
    // console.log('valueChanged', this.fieldIdField, id);
    // console.log(this.form.$(this.mainField).values().map(v => v[this.fieldIdField]));
    const index = this.form.$(this.mainField).values().findIndex(v => {
      // console.log('findIndex', v[this.fieldIdField], id);
      return v[this.fieldIdField] == id;
    });
    // console.log(index);
    const value = this.emptyValue(id) ? this.newValue(id) : this.value;
    if (el.target.type != 'checkbox') value.extra = el.target.value;

    if (this.checkboxes) {
      if (el.target.type == 'checkbox') {
        value.on = el.target.checked;
        value._destroy = !el.target.checked;
        this.setRules(this.props.field, index, value, !value.on)
      }
    } else {
      // if (el.target.value == '') this.deleteFormAndStoreValue(value);
      if (value.extra != '') value._destroy = false;
    }
    this.form.$(this.mainField).$(index).set(value);
    // console.log(this.form.$(this.mainField).values().map(v => v[this.fieldIdField]));
    this.crudStore.storePivotValueData(id, value, this.props.dependent);
    this.setIsError(this.props.field);
  };

  textFieldClasses = (ft) => {
    const classes = [];
    return classes.join(' ');
  };



  fieldType = (val) => {
    if (val == '') return 'hidden';
    if (val == 'select') return 'select';
    return val == 'number' ? 'number' : 'text';
  };

  label = (val) => {
    if (val == '') return 'hidden';

    return val == 'number' ? 'Days' : '';
  };

  removeUnderScore(key) {
    return key.replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function (key) { return key.toUpperCase() });
  }
  menuItem = (item) => {
    return (
      { label: item[1], value: item[0].toString() }
    );
  };

  makeMenuItemsArray = () => {
    switch (this.props.field.id) {
      // these cases may not be unique.. ultimately would need to pass in another params.
      case 14:
        return [
          ['fb', 'Facebook'],
          ['inst', 'Instagram'],
          ['both', 'Both'],
        ];
      case 1:
        return this.props.rootStore.uiStore.makeAdModifierOptions(this.crudStore.storedData['paid_search_campaign']['objective_modifier_id'])
    }

  };

  setValue = (field) => {
    this.value = this.emptyValue(field.id) ?
      {} :
      this.crudStoreAttribute(field.id)
  }


  @action.bound
  setChangeNumber() {
    this.changeNumber = this.changeNumber + 1;
    this.setState({ changeNumber: this.changeNumber });
    // console.log('changenumber set to ' + this.changeNumber)
  }

  @action.bound
  setIsError(pivot_field) {
    if (!this.form) {
      this.isError = !this.crudStore.displayError;
    } else if (_.isEmpty(this.value.extra) && this.crudStoreAttribute(pivot_field.id)?.on && pivot_field.extra_required) {
      this.isError = true;
    } else {
      this.isError = false;
    }
  }

  checkbox = () => {
    if (!this.checkboxes) return null;
    return (<Checkbox
      id={'chk_pivot_ids_' + (this.props.field.abbrev != undefined ? this.props.field.abbrev : this.props.field.id)}
      name={'chk_pivot_name_' + (this.props.field.abbrev != undefined ? this.props.field.abbrev : this.props.field.id)}
      value={this.props.field.id}
      checked={this.isChecked(this.props.field.id)}
      onChange={(t) => this.valueChanged(this.props.field.id, t)}
      style={this.props.field.extra_field_type == 'text' ? { marginTop: '28px' } : { marginTop: '0px' }}
    />);
  }

  spacing_style = (type, position) => {
    switch (type) {
      case 'number':
        return { marginTop: '25px' }
      default:
        return {}
    }
  }

  render() {
    const { classes, rootStore, form, row_id } = this.props;
    this.setValue(this.props.field);
    const style = this.props.field.extra_field_type == 'text' ? 'width: 100' : 'width: 100';
    // console.log(`pivot_id: ${this.props.field.id}, name: ${this.props.field.name}, abbrev: ${this.props.field.abbrev}, extra_field_Type: ${this.props.field.extra_field_type}`); //, extra required: ${ pivot.extra_required } extra: ${ this.extra }, stored: ${ this.empty }, ft: ${ ft } `);
    // console.log(`tve: ${this.value.extra}`)
    return (
      <React.Fragment>
        <div className={classes.pivotformControl} style={this.spacing_style(this.props.field.extra_field_type, this.props.field.position)}>
          {this.checkbox()}
          {(this.props.field.extra_field_type == 'text' || this.props.field.extra_field_type == undefined) ? (
            ''
          ) : (
            <label htmlFor={'chk_pivot_ids_' + this.props.field.abbrev} className="pivot-label">
              {' '}
              {this.props.field.name}{' '}
            </label>
          )}

          {(() => {
            switch (this.fieldType(this.props.field.extra_field_type)) {
              case 'hidden':
              case '':
              case null:
                return '';
              case 'select':
                const menuItemArray = this.makeMenuItemsArray();
                const menuItems = menuItemArray.map((item) =>
                  this.menuItem(item),
                );
                return (
                  <>
                    <FormControl id={`formControl - ${this.props.core} `}>
                      <div className="container" style={{ width: '415px' }} >
                        <span className={"fieldset-text p-float-label uccItems"} style={this.props.callFrom == "days" ? { bottom: '8px', left: '20px' } : {}}>
                          <Dropdown
                            options={menuItems}
                            onChange={(t) => this.valueChanged(this.props.field.id, t)}
                            value={this.value.extra}
                            label="Objective Modifier whoo"
                            filter={typeof this.props.filter == "undefined" ? true : this.props.filter}
                            className={this.props.className == '' ? this.props.className : 'uccItems'}
                            panelClassName='changeLabelColor'
                            style={(this.isError) ? { border: '' } : { border: '2px solid #FF5656' }}
                            showClear={true}
                            disabled={false}
                          />
                        </span>
                      </div>
                    </FormControl>
                    <UccErrorHandling form={this.props.form} fieldName={this.mainField} isError={this.isError} justReturn={!this.value.on} />
                  </>
                );

              case 'number':
                return (
                  <>
                    <FormControl id={`formControl - ${this.props.core} `}>
                      <div>
                        <span className={"fieldset-text p-float-label uccItems"} style={this.props.callFrom == "days" ? { bottom: '8px', left: '20px' } : {}}>
                          <InputText
                            type="text"
                            id={'targ_text_' + this.props.field.abbrev}
                            value={this.value.extra ? this.value.extra : ''}
                            onChange={(t) => this.valueChanged(this.props.field.id, t)}
                            className={this.props.field.extra_field_type == 'text' ? 'pivot' : 'pivot-width'}
                            disabled={this.isDisabled(this.props.field.id)}
                            keyfilter="int"
                            width={12}
                          />
                          <label htmlFor={`targ_text_${this.props.field.abbrev} `}>{this.props.field.extra_field_type == 'text' ? this.props.field.name : this.label(this.props.field.extra_field_type)}</label>
                        </span>
                      </div>
                    </FormControl>
                    <UccErrorHandling form={this.props.form} fieldName={this.mainField} isError={this.isError} justReturn={!this.value.on} />
                  </>
                );
              default:
                return (
                  <>
                    <fieldset className={this.props.callFrom == "Days" ? "fieldset-textfield-targeting" : "fieldset-textfield"} id={`fieldset - ${(this.props.field.abbrev != undefined) ? this.props.field.abbrev : this.props.field.id} `} style={(this.props.callFrom == "targeting" || this.props.dependent == "extra_field") ? { paddingTop: '25px' } : {}} >
                      <FormControl id={`formControl - ${this.props.core} `}>
                        <div className="container" style={this.props.dependent == "extra_field" ? { width: '450px' } : { width: '415px' }} >
                          <span className={"fieldset-text p-float-label uccItems"} style={this.props.callFrom == "days" ? { bottom: '8px' } : {}}>
                            <InputText
                              type="text"
                              id={'targ_text_' + (this.props.field.abbrev != undefined ? this.props.field.abbrev : this.props.field.id)}
                              value={this.value.extra ? this.value.extra : ''}
                              onChange={(t) => this.valueChanged(this.props.field.id, t)}
                              className={this.textFieldClasses()}
                              disabled={this.isDisabled(this.props.field.id)}
                              style={this.isError ? { border: '2px solid #FF5656' } : { border: '' }}
                            />
                            <label htmlFor={`targ_text_${(this.props.field.abbrev != undefined) ? this.props.field.abbrev : this.props.field.id} `}>{(!this.isRequired) ? '(Optional) ' : ''}{(this.props.field.extra_field_type == 'text' || this.props.field.extra_field_type == undefined) ? this.removeUnderScore(this.props.field.name) : this.label(this.props.field.extra_field_type)}</label>
                          </span>
                          <UccErrorHandling form={this.props.form} fieldName={this.mainField} isError={this.isError} justReturn={!this.value.on} />
                        </div>
                      </FormControl>
                    </fieldset>
                  </>
                );
            }
          })()}
        </div>
      </React.Fragment >
    );
  }
}

PivotField.wrappedComponent.propTypes = {
  targeting: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default withStyles(styles)(PivotField);
