import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import dvr from 'mobx-react-form/lib/validators/DVR';
import Grid from '@material-ui/core/Grid';
import NameString from './../../NameString';
import Pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import UccDoubleDatePicker from '../../PrimeReactComponent/UccDoubleDatePicker';
import UccInputText from '../../PrimeReactComponent/UccInputText';
import UccSelect from '../../PrimeReactComponent/UccSelect';
import UccThreeButtons from '../../PrimeReactComponent/UccThreeButtons';
import validatorjs from 'validatorjs';
import { formElements } from './../../../store/StoreMetadata';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';

const plugins = {
  dvr: dvr(validatorjs),
};

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: '7px',
    paddingRight: '6px',

    flexGrow: 1,
  },
  tall: {
    height: 30,
  }
});

var moment = require('moment');

@inject('rootStore')
@observer
class GenericTwoDateForm extends React.Component {
  constructor(props) {
    super(props);
    const crudStore = this.props.rootStore.channel.model.crudStore;
    const hooks = crudStore.hooks(crudStore);
    this.props.rootStore.react = this;
  }

  componentDidMount = async () => {
    const { apiStore, uiStore, channel } = this.props.rootStore;
    channel.model.crudStore.formComponentDidMount();
  }

  componentWillUnmount() {
    this.props.rootStore.uiStore.unmountComponent();
  }

  render() {
    const { classes, rootStore } = this.props
    const { channel, apiStore, uiStore } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    if (crudStore.loading) return (<div style={{ textAlign: 'center' }}><CircularProgress /></div>);
    // const targetingFields = rootStore.channel.model.crudStore.setTargetingFieldsArray(crudStore);
    // crudStore.targetingFields = targetingFields;
    const fullForm = formElements(rootStore).map(fe => fe.formField(rootStore));

    return (
      <div className={classes.root}>
        <Grid xs={12} container direction="row" justify="flex-start" alignItems="stretch" wrap="noWrap">
          <form className={classes.form} onSubmit={crudStore.form.onSubmit}>
            <Grid item xs>

              {fullForm.map((component, index) => (<span key={index}>{component}</span>))}

              {/*  when refactoring other forms to use this first turn this and the size form element into metadata.. 
                like
                hasDoubleDatePicker: true
                hasSize: true
              */}

              <UccDoubleDatePicker
                form={crudStore.form}
              />

              {crudStore.validationErrorsPrint()}
              <UccThreeButtons
                form={crudStore.form}
                onSubmit={crudStore.form.onSubmit}
                onCancel={crudStore.onCancel}
              />
              {/* <PredraftButtons form={crudStore.form} onSubmit={crudStore.form.onSubmit} onCancel={crudStore.onCancel} /> */}
            </Grid>
          </form>
          <Grid item xs >
            <NameString type={_.capitalize(model.singular)} store={crudStore} />
          </Grid>
        </Grid>
        <div className={classes.tall}>&nbsp;</div>
      </div>
    );
  }
}

GenericTwoDateForm.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GenericTwoDateForm);
