import React from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CrudTabs from './CrudTabs';
import CrudAdmin from './CrudAdmin';
import Breadcrumbs from '../Layout/Breadcrumbs';

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  admin: {
    paddingTop: theme.spacing(3)
  }
});

@inject('rootStore','uiStore')
@observer
class CrudAdminRoot extends React.Component {
  render() {
    const { classes, uiStore } = this.props;
    return (
      <div className={classes.root}>
        <Breadcrumbs />
        <CrudTabs />
      </div>
    );
  }
}

CrudAdminRoot.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CrudAdminRoot);
