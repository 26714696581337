import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Pluralize from 'pluralize';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import { InputNumber } from 'primereact/inputnumber';
import UccDialogBox from '../PrimeReactComponent/UccDialogBox';
import UccPreviewTitle from '../PrimeReactComponent/UccPreviewTitle';
import UccBulkMain from '../PrimeReactComponent/UccBulkMain';
import '../PrimeReactComponent/PrimeStyle.css';

const styles = theme => ({
  bold: {
    fontWeight: '900',
    fontSize: '18px',
    fontFamily: 'Avenir LT W01_95 Black1475556'
  },
  green: {
    color: '#00bf73'
  },
  paper: {
    overflowY: 'unset',
    padding: '20px 3px 20px 20px'
  },
})

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
@inject('rootStore')
@observer
class AcknowledgeDialog extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { buttonLabel, message, onAcknowledge, onCancel, classes, } = this.props;
    const { apiStore, channel } = this.props.rootStore;
    const crudStore = channel.model.crudStore;
    const subtitle = crudStore.selectedRows.length == 1 ? Pluralize.singular(channel.model.name) : Pluralize(channel.model.name);
    const getHeader = (message) => {
      switch (message) {
        case 'copy':
          return <UccDialogBox data_qa="dialog-copy" rootStore={this.props.rootStore} title='Copy Namestring' subtitle='Total Copy' />
          break;
        case 'copy_edit':
          if (crudStore.firstPage) {
            return <UccDialogBox data_qa="dialog-copy-edit" rootStore={this.props.rootStore} title='Copy & Edit' subtitle={subtitle + ' Selected'} />
          } else if (!crudStore.firstPage) {
            return <UccPreviewTitle data_qa="dialog-preview" rootStore={this.props.rootStore} title='Preview' subtitle={subtitle} />
          }
          break;
        case 'bulk_edit':
          if (crudStore.firstPage) {
            return <UccDialogBox data_qa="dialog-copy-edit" rootStore={this.props.rootStore} title='Bulk Edit' subtitle={subtitle + ' Selected'} />
          } else if (!crudStore.firstPage) {
            return <UccPreviewTitle data_qa="dialog-preview" rootStore={this.props.rootStore} title='Preview' subtitle={subtitle} />
          }
          break;
        default:
          return <DialogContentText id="alert-dialog-slide-description" dangerouslySetInnerHTML={{ __html: message }}></DialogContentText>
          break;
      }
    }

    const getBody = (message) => {
      if (message == 'copy') {
        return (<div data-qa="onCopy-dialog" className={"p-grid p-fluid p-dialogbox"} style={{ paddingLeft: '30px', width: '372px' }} >
          <span className={"p-field p-col-12 p-md-3"}>
            <label htmlFor="copies">Copies</label>
            <br />
            <InputNumber id='copies' data-qa='number_of_copies-text' value={crudStore.numberofCopies} onChange={(event) => crudStore.setNumberOfCopies(event.target.value)} showButtons style={{ height: '33px', top: '-10px', left: '-3.51562px', width: '121px' }} min={1} max={100} />
          </span>
        </div>)
      } else if (message == 'copy_edit' || message == 'bulk_edit') {
        return (<UccBulkMain rootStore={this.props.rootStore} onChange={(event) => crudStore.setNumberOfCopies(event.target.value)} onCancel={onCancel} />)
      }
    }
    return (
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        open
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="1000px"
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogContent style={{ textAlign: 'center', minHeight: '67px' }}>
          {getHeader(message)}
        </DialogContent>
        {getBody(message)}
        {
          (crudStore.toolbarAction != 'copy_edit' && crudStore.toolbarAction != 'bulk_edit') && <DialogActions style={{ textAlign: 'left', display: 'inline', paddingLeft: '22px' }}>
            <Button onClick={onAcknowledge} id='modal-confirm' className={classNames(classes.bold, classes.green)}>
              {buttonLabel}
            </Button>
            <Button onClick={onCancel} id='modal-cancel' color="black" className={classes.bold}>
              Cancel
            </Button>
          </DialogActions>}
      </Dialog>
    );
  }
}


AcknowledgeDialog.propTypes = {
  message: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAcknowledge: PropTypes.func.isRequired,
};



export default withStyles(styles)(AcknowledgeDialog);
// export default AcknowledgeDialog;
