import React from "react";

const PaidSearchIcon = (props) => (

    <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24.000000pt"
    height="24.000000pt"
    viewBox="0 0 300.000000 300.000000"
    preserveAspectRatio="xMidYMid meet"
  >
<metadata>
Created by potrace 1.15, written by Peter Selinger 2001-2017
</metadata>
<g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
fill= {props.fill} stroke="none">
<path d="M180 2919 c-20 -11 -47 -37 -60 -56 l-25 -37 -3 -1054 c-1 -694 1
-1066 8 -1091 14 -52 46 -90 94 -112 39 -18 84 -19 862 -19 l822 0 160 -197
c88 -109 175 -212 193 -230 48 -45 98 -63 174 -63 106 0 182 43 229 130 57
104 41 223 -41 311 l-46 49 109 0 c155 1 217 33 244 129 8 24 10 376 8 1092
l-3 1055 -25 37 c-13 20 -41 45 -61 56 -37 21 -44 21 -1320 21 -1275 0 -1284
-1 -1319 -21z m2586 -113 c17 -13 19 -30 22 -210 l3 -196 -1291 0 -1291 0 3
193 c2 146 6 198 17 210 12 16 114 17 1265 17 1065 0 1255 -2 1272 -14z m24
-1306 l0 -781 -25 -24 c-24 -25 -27 -25 -199 -25 l-175 0 -115 93 -116 92 0
52 c0 70 -9 90 -75 161 l-57 62 16 47 c59 182 39 393 -57 578 -58 112 -190
244 -302 302 -134 69 -210 88 -355 88 -149 0 -215 -15 -345 -79 -192 -94 -335
-275 -390 -491 -25 -99 -24 -266 4 -368 63 -239 242 -434 474 -518 l52 -18
-431 -1 c-411 0 -433 1 -455 19 l-24 19 -3 786 -2 786 1290 0 1290 0 0 -780z
m-1271 494 c158 -53 286 -160 359 -302 54 -102 75 -186 75 -292 0 -348 -280
-629 -628 -630 -100 0 -177 19 -280 69 -206 102 -332 297 -343 531 -8 190 47
330 182 466 104 104 224 167 361 188 65 10 198 -5 274 -30z m488 -1026 c53
-51 47 -68 -70 -185 -57 -57 -111 -103 -120 -103 -9 0 -35 17 -58 37 l-41 37
23 16 c61 39 138 112 179 168 24 34 47 62 49 62 3 0 20 -14 38 -32z m279 -374
c231 -187 241 -196 254 -245 9 -33 8 -47 -9 -84 -32 -73 -115 -106 -186 -73
-19 9 -258 291 -367 434 -6 8 11 31 49 68 32 30 60 56 62 56 2 0 91 -70 197
-156z"/>
<path d="M424 2741 c-122 -55 -124 -228 -4 -288 55 -28 92 -29 146 -2 119 57
119 231 0 288 -52 26 -89 26 -142 2z"/>
<path d="M795 2746 c-57 -26 -88 -70 -93 -131 -5 -66 9 -105 54 -144 49 -43
110 -50 171 -20 68 35 88 67 88 144 0 68 -15 99 -67 138 -34 25 -113 32 -153
13z"/>
<path d="M1154 2741 c-122 -55 -124 -228 -4 -288 55 -28 92 -29 146 -2 119 57
119 231 0 288 -52 26 -89 26 -142 2z"/>
<path d="M1453 2650 c-52 -21 -38 -105 19 -115 18 -3 289 -5 601 -3 550 3 569
4 583 22 18 25 18 57 0 82 -14 18 -33 19 -598 21 -330 1 -593 -2 -605 -7z"/>
<path d="M1298 1778 c-2 -17 -12 -24 -39 -29 -62 -10 -125 -53 -149 -104 -28
-58 -25 -150 6 -193 24 -32 105 -79 152 -88 l32 -6 0 -89 c0 -55 -4 -89 -10
-89 -20 0 -84 31 -119 57 l-34 27 -39 -45 c-21 -24 -38 -46 -38 -49 0 -16 118
-80 175 -95 64 -18 65 -19 65 -51 0 -32 2 -34 35 -34 33 0 35 2 35 33 0 33 2
34 53 46 113 25 173 102 165 210 -6 91 -55 141 -173 176 l-40 12 -3 87 c-3
100 -5 99 90 51 l46 -23 31 43 c17 24 31 48 31 53 0 17 -100 61 -152 68 -42 6
-48 9 -48 30 0 21 -5 24 -34 24 -28 0 -35 -4 -38 -22z"/>
</g>
</svg>
);

export default PaidSearchIcon;