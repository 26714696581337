import './PrimeStyle.css';
import FormControl from '@material-ui/core/FormControl';
import React, { Component } from 'react';
import UccErrorHandling from './UccErrorHandling';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { observer, inject } from 'mobx-react';
import { Checkbox } from 'primereact/checkbox';
import _ from 'lodash';

@inject('rootStore')
@observer
class UccBrandChannelGroupDataTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      brand_channel_ids: []
    }
    this.onAllBrandChannelChange = this.onAllBrandChannelChange.bind(this);
    this.onBrandChannelChange = this.onBrandChannelChange.bind(this);
    this.allBrandChannelsBodyTemplate = this.allBrandChannelsBodyTemplate.bind(this);
    this.brandChannelBodyTemplate = this.brandChannelBodyTemplate.bind(this);
  }

  componentDidMount() {
    const crudStore = this.props.model.crudStore;

    let curBrandChannelIds = [...crudStore.storedData['brand_channel_ids']];
    this.setState({ brand_channel_ids: curBrandChannelIds });
  }

  onAllBrandChannelChange(e) {
    const { model } = this.props;
    const crudStore = model.crudStore;

    let selectedBrandChannelIds = this.state.brand_channel_ids;

    e.value.brand_channel_ids.map(bc => {
      if (e.checked && !selectedBrandChannelIds.includes(bc))
        selectedBrandChannelIds.push(bc);
      else if (!e.checked && selectedBrandChannelIds.includes(bc))
        selectedBrandChannelIds.splice(selectedBrandChannelIds.indexOf(bc), 1);
    })

    crudStore.storeData('brand_channel_ids', selectedBrandChannelIds);
    this.setState({ brand_channel_ids: selectedBrandChannelIds });
  }

  onBrandChannelChange(e) {
    const { model } = this.props;
    const crudStore = model.crudStore;

    let selectedBrandChannelIds = [...this.state.brand_channel_ids];

    if (e.checked)
      selectedBrandChannelIds.push(e.value.brand_channel_id);
    else
      selectedBrandChannelIds.splice(selectedBrandChannelIds.indexOf(e.value.brand_channel_id), 1);

    crudStore.storeData('brand_channel_ids', selectedBrandChannelIds);
    this.setState({ brand_channel_ids: selectedBrandChannelIds });
  }

  allBrandChannelsBodyTemplate(rowData) {
    const curBrandChannelIds = rowData.brand_channel_ids;

    return <Checkbox
      key={`Brand-${rowData.id}`}
      checked={curBrandChannelIds.every(val => this.state.brand_channel_ids.includes(val))}
      disabled={curBrandChannelIds ? false : true}
      onChange={this.onAllBrandChannelChange}
      value={{ brand_channel_ids: curBrandChannelIds, brand_id: rowData.id }}
    />;
  }

  brandChannelBodyTemplate(rowData, col) {
    const apiStore = this.props.rootStore.apiStore;
    const curBrandChannelId = apiStore.brandChannels.find(bc => bc.channel_id == col.columnKey && bc.brand_id == rowData.id)?.id;
    return <Checkbox
      key={`BrandChannel-${curBrandChannelId}`}
      checked={this.state.brand_channel_ids.includes(curBrandChannelId)}
      disabled={curBrandChannelId ? false : true}
      onChange={this.onBrandChannelChange}
      value={{ brand_channel_id: curBrandChannelId, brand_id: rowData.id }}
    />;
  }

  render() {
    const brands = this.props.rootStore.apiStore.brands.filter(b => b.channel_ids.length > 0);
    const channels = this.props.rootStore.apiStore.sortedChannels;
    return (
      <fieldset className="fieldset">
        <FormControl id={`formControl-${this.props.core}`}>
          <DataTable value={brands} autoLayout sortField="name" sortOrder={1}>
            <Column
              key='brand'
              field='name'
              header='Brand'
            />
            <Column
              key='all'
              header='All'
              body={this.allBrandChannelsBodyTemplate}
            />
            {channels.map((channel) => {
              let metaChannel = this.props.rootStore.storeMetadata.channels.find(c => c.codename == channel.codename)
              if (metaChannel.disabled) return (<></>)
              return <Column
                key={`${channel.id}`}
                columnKey={`${channel.id}`}
                header={channel.name}
                body={this.brandChannelBodyTemplate}
                style={{ textAlign: 'center' }}
              />;
            })}
          </DataTable>
        </FormControl>
      </fieldset>
    );
  }
}

export default UccBrandChannelGroupDataTable;