import React from 'react';
import { observer, inject } from 'mobx-react';
import Iframe from 'react-iframe';
import RootStore from '../../store/RootStore';
import Breadcrumbs from './Breadcrumbs';

@inject('rootStore')
@observer
class LegacyAdminPanel extends React.Component {
  render() {
    const { rootStore } = this.props;
    if (!rootStore.apiStore.currentUser.pll >= 60) return '';
    return (
      <React.Fragment>
        <Breadcrumbs />
        <Iframe
          url="/legacy-admin"
          id="myId"
          width="100%"
          height="100%"
          className="myClassname"
          display="initial"
          position="relative"
        />
      </React.Fragment>
    );
  }
}

export default LegacyAdminPanel;
