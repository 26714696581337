import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {},
  select: {
    width: '100%',
    paddingTop: theme.spacing(1),
    margin: 0,
    padding: 0,
  },
  error: {
    color: 'red',
    margin: 0
  },
  formControl: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  gray: {
    color: '#707070'
  },
  inputLabel: {
    // paddingLeft: theme.spacing(1),
    // padding: theme.spacing(1),
    marginBottom: '3px',
    color: '#7d7d7d'
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
    padding: 0,
    height: 35,
  },
  select: {
    width: '100%',
    paddingTop: theme.spacing(1),
    margin: 0,
    padding: 0,
  },
});

@inject('rootStore')
@observer
class UccPrimeSelect extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount = async () => { }

  render() {
    const {
      rootStore,
      form,
      fieldName,
      classes,
      label,
      menuItems,
      core,
      onClose,
      firstOptionLabel
    } = this.props;
    const { apiStore, uiStore, crudStore } = rootStore;
    const finalFirstOptionLabel = _.isEmpty(this.props.firstOptionLabel) ? `Select ${label}` : this.props.firstOptionLabel
    const finalLabel = !form.$(fieldName).rules.includes('required') ? `(Optional) ${label}` : label;
    return (
      <fieldset id={`fieldset-${fieldName}`}>
        <FormControl id={`formControl-${core}`} className={classes.formControl}>
          <div className={classes.inputLabel} htmlFor={form.$(fieldName).id}>{finalLabel}</div>
          <Dropdown
            options={menuItems}
            onChange={onClose}
            placeholder={finalFirstOptionLabel}
            label={finalLabel}
            value={crudStore.storedData[fieldName]}
          />
          <div className={classes.error}>{form.$(fieldName).error}</div>
        </FormControl>
      </fieldset >
    )
  }
}


UtaPrimeSelect.wrappedComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  // channelHierarchyStore: PropTypes.instanceOf(CrudAdminStore),
};
export default withStyles(styles)(UccPrimeSelect); 