
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import dvr from 'mobx-react-form/lib/validators/DVR';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import UccDoubleButton from '../../PrimeReactComponent/UccDoubleButton';
import UccApproveDenyButton from '../../PrimeReactComponent/UccApproveDenyButtons';
import UccDoubleDatePicker from '../../PrimeReactComponent/UccDoubleDatePicker';
import validatorjs from 'validatorjs';
import { formElements } from './../../../store/StoreMetadata';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { between, permRequest, getButtonLabel } from '../../../store/StoreMetadata.js'



const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: '7px',
    paddingRight: '6px',
    flexGrow: 1,
  },
  tall: {
    height: 30,
  },
});

@inject('rootStore')
@observer
class AttrValuesForm extends React.Component {
  @observable suggestedEndDate = '';

  constructor(props) {
    super(props);
    const crudStore = this.props.rootStore.channel.model.crudStore;
    this.form = crudStore.form;
    this.props.rootStore.react = this;
  }

  componentDidMount = async () => {
    const { channel } = this.props.rootStore;
    const crudStore = channel.model.crudStore;
    crudStore.formComponentDidMount();
  };

  componentWillUnmount = () => {
    this.props.rootStore.uiStore.unmountComponent();
  }
  render() {
    const { classes, rootStore } = this.props;
    const { channel } = rootStore;
    const { form } = this;
    const model = channel.model;
    const crudStore = model.crudStore;
    const getLabel = getButtonLabel(rootStore);
    // console.log(rootStore);
    // console.log(getButtonLabel);
    // console.log(getLabel);

    if (crudStore.loading) return (<div style={{ textAlign: 'center' }}><CircularProgress /></div>);
    const fullForm = formElements(rootStore).map(fe => fe.formField(rootStore));

    return (
      <div className={classes.root}>
        <form className={classes.form} onSubmit={form.onSubmit}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12} sm={6}>

              {fullForm.map((component, index) => (<span key={index}>{component}</span>))}

              <UccDoubleDatePicker form={crudStore.form} />

              {crudStore.validationErrorsPrint()}

              {/*  */}
              <UccApproveDenyButton
                form={crudStore.form}
                onSubmit={crudStore.form.onSubmit}
                onCancel={crudStore.onCancel}
                label={getLabel}
              />
            </Grid>
          </Grid>
        </form>
        <div className={classes.tall}>&nbsp;</div>
      </div>
    );
  }
}

AttrValuesForm.wrappedComponent.propTypes = {
  // apiStore: PropTypes.instanceOf(ApiStore).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AttrValuesForm);
