import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Draggable from 'react-draggable';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../NameString';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  h3: {
    margin: 0,
    fontSize: 14,
  },
  h3utm: {
    margin: '12px 0 0 0',
    fontSize: 14,
  },
  paper: {
    padding: 10,
  }
});


function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function PaperComponent(props = null) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}


@inject('rootStore')
@observer
class CrudTableBody extends React.Component {
  @observable dialogs = [];
  @observable nameString = '';
  @observable utmString = '';

  constructor(props) {
    super(props);
    const { rootStore, classes } = this.props;
    const { apiStore, channel } = rootStore;
  }



  showNamestringModal = (e) => {
    const row_id = e.target.closest('tr[id]').id.split("_")[1];
    if (!this.dialogs.includes(row_id)) this.dialogs.push(row_id);
    NameString.prepNamestring(this.props.rootStore.channel.model, row_id);
    this.nameString = (<NameString store={this.props.rootStore.channel.model.crudStore} ret='string' />)
    return this.utmString = this.makeUtmString(row_id);
  }

  closeNamestringModal = (e) => {
    this.dialogs = [];
    this.props.rootStore.channel.model.crudStore.storedData = {};
  }


  makeUtmString = (row_id, type = 'ads') => {
    const { apiStore, channel } = this.props.rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    if (model.codename != 'SocAd') return;
    const row = crudStore.displayedRows.find(r => r.id.toString() == row_id);
    if (_.isEmpty(row)) return;
    switch (type) {
      case 'ads':
        const theUtmString = NameString.getAdUtmString(crudStore);
        const platform = _.isEmpty(crudStore.storedData.social_ad_set) ?
          '' :
          ['SC', 'PIN'].includes(crudStore.storedData.social_ad_set.platform.abbrev) ?
            crudStore.storedData.social_ad_set.platform.name :
            '';
        return this.utmString = (
          <React.Fragment>
            <DialogTitle id="draggable-dialog-title">{platform} UTM Parameter</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {theUtmString}
              </DialogContentText>
            </DialogContent>
          </React.Fragment>);
      default: return '';
    }
  }


  renderCell = (col, row) => {
    switch (col.format) {
      case "action":
        return (<Tooltip title="Namestring" placement="top-start">
          <div>
            <IconButton aria-label="Namestring" onClick={(e) => this.showNamestringModal(e)}>
              <img style={{ height: 20, width: 20 }} src={LinkIcon} />
            </IconButton>
          </div>
        </Tooltip>);
      default: return col.grid_value(row);
    }
  }

  isRowSelected = (row) => this.props.selectedRowIds.indexOf(row.id) !== -1;

  render() {
    const {
      order, rootStore, classes, orderBy, page, rowsPerPage, columns, displayedRows, toggleRowSelect,
    } = this.props;
    const channel = rootStore.channel;
    const model = channel.model;
    const crudStore = model.crudStore;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, displayedRows.length - page * rowsPerPage);
    return (
      <TableBody>
        {stableSort(displayedRows, getSorting(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const isItemSelected = this.isRowSelected(row);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                hover
                onClick={() => toggleRowSelect(row)}
                role="checkbox"
                id={'row_' + row.id}
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </TableCell>
                { }
                {columns.map(col => {
                  return (<TableCell id={'row_' + row.id + '_col_' + col.id} align="left" key={col.id}>{this.renderCell(col, row)}</TableCell>)
                }, this)}
              </TableRow>
            );
          }, rootStore)
        }
        <Dialog
          open={this.dialogs.length > 0}
          style={{ overflowWrap: 'break-word' }}
          PaperComponent={PaperComponent}
          onClose={() => this.closeNamestringModal()}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {model.name} Namestring
            <IconButton style={{ float: 'right', margin: -8 }} aria-label="close" id='ns-close' className={classes.closeButton} onClick={() => this.closeNamestringModal()}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.nameString}
            </DialogContentText>
          </DialogContent>
          {this.utmString}
        </Dialog>

      </TableBody>
    );
  }
}

CrudTableBody.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRowIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  toggleRowSelect: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

// export default CrudTableBody;

export default withStyles(styles)(CrudTableBody);
