import React from "react";
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    height: '30px',
    width: '30px',
    fill: props => props.color,
    '&:hover': {
      fill: '#5EABF8'
    }
  }
}

const AdminOldManIcon = (props) => {
  const { classes } = props;
  return (
    <svg className={classes.root} version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m75 15.625c0 5.1758-4.1992 9.375-9.375 9.375s-9.375-4.1992-9.375-9.375 4.1992-9.375 9.375-9.375 9.375 4.1992 9.375 9.375"/>
        <path d="m75.578 45.137-12.727-3.582-7.2109-17.836h-0.003906c-1.7344-4.3008-6.6172-6.3945-10.926-4.6875-5.0859 2.4219-9.4883 6.0703-12.812 10.617-3.1094 3.8477-5.9102 7.9297-8.3867 12.211-2.4023 4.0742-1.6914 8.3125-1.4219 12.785l0.78125 12.969 1.2227 20.289 0.011719 0.18359v0.003906c0.17969 3.1758 2.8086 5.6602 5.9883 5.6602 3.1836 0 5.8125-2.4844 5.9922-5.6602l2.3125-38.25c3.0312-5.4375 6.9219-10.348 11.527-14.535l4.8789 12.059c0.66016 1.6211 2.1523 2.7539 3.8906 2.957l15.48 1.8555c0.14062 0.023438 0.28516 0.039063 0.42969 0.042969 1.8047 0.003906 3.3359-1.3242 3.582-3.1133 0.24609-1.7891-0.86719-3.4844-2.6094-3.9688z"/>
        <path d="m78.223 93.75c-1.2969 0-2.3438-1.0508-2.3438-2.3438v-34.309c0-2.1953-1.7812-3.9727-3.9727-3.9727s-3.9727 1.7773-3.9727 3.9727c0 1.293-1.0469 2.3438-2.3438 2.3438-1.293 0-2.3438-1.0508-2.3438-2.3438 0-4.7812 3.8789-8.6602 8.6602-8.6602s8.6602 3.8789 8.6602 8.6602v34.309c0 1.293-1.0508 2.3438-2.3438 2.3438z"/>
      </g>
    </svg>
  )};

export default withStyles(styles)(AdminOldManIcon);
