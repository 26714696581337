import ax from 'axios';
import _ from 'lodash';
import { observable, action, computed } from 'mobx';

var moment = require('moment');


class ChannelHierarchyStore {
  constructor(rootStore, storeMetadata, channel = null, model = null) {
    this.rootStore = rootStore;
    if (channel) this.setChannel(channel);
    if (model) this.setModel(model);
  }

  @observable modelTabIndex;
  @observable modelTabName;
  @observable selectedRowCount;
  @observable model;
  @observable columns;
  @observable filterer;
  @observable crudStore;
  @observable suggestedEndDate;

  @computed get selectedRowCounter() {
    return this.rootStore.campaignCrud.selectedRowIds.length;
  }

  static valueInit = (selected) => {
    if (typeof selected == 'undefined') return;

    if (selected === null) selected = '';

    if (isNaN(parseInt(selected))) {
      if (selected.target && selected.target.type == 'checkbox') {
        return selected.target.checked;
      }

      if (selected._isAMomentObject) {
        return moment(selected).format('MM/DD/YYYY');
      }
      switch (selected.constructor.name) {
        case 'Date':
          return selected;
        case 'String':
        case 'Boolean':
        case 'Array':
          return selected;
        case 'Object':
          if (selected.target) return selected.target.value == null ? "" : selected.target.value;
          return selected;
          if (
            selected.target !== undefined &&
            selected.target.value !== undefined
          ) {
            return selected.target.value;
          }
      }

      if (typeof selected.target == 'undefined') {
        return null;
      }
      if (selected.target.value == 'true') return true;
      if (selected.target.value == 'false') return false;
      if (!_.isEmpty(selected.target.value)) {
        if (moment(selected.target.value, 'MM/DD/YYYY', true).isValid()) {
          return moment(selected.target.value).format('MM/DD/YYYY');
        }
        return selected.target.value;
      }

      if (typeof selected.target.attributes == 'undefined' || typeof selected.target.attributes["data-value"] == 'undefined') {
        return null;
      }
      return typeof selected.target == 'undefined' ? null : selected.target.attributes["data-value"].value;
    } else return selected;
  }


  static changedViaPulldown = (selected) => {
    return (isNaN(parseInt(selected))) ? true : false;
  }
}

export const valueInit = ChannelHierarchyStore.valueInit;
export const changedViaPulldown = ChannelHierarchyStore.changedViaPulldown;
export default ChannelHierarchyStore;
