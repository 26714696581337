import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getBrandedProperty, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');



export const display = {
  name: 'Display',
  endpoint: 'display',
  codename: 'display',
  abbrev: 'Display',
  icon: 'Display',
  showViewIcon: true,
  models: [
    {
      // ______ _                                     _
      // | ___ \ |                                   | |
      // | |_/ / | __ _  ___ ___ _ __ ___   ___ _ __ | |_ ___
      // |  __/| |/ _` | / __/ _ \ '_ ` _ \ / _ \ '_ \| __ / __ |
      // | |   | | (_| | (_|  __/ | | | | |  __/ | | | |_\__ \
      // \_|   |_|\__,_|\___\___|_| |_| |_|\___|_| |_|\__|___/

      allFilterData: (apiStore) => apiStore['displayPlacementsFilterOptions'],
      codename: 'DispPlace',
      controller: 'display_placement',
      crud: (rootStore) => rootStore.displayPlacementsCrud,
      domoNsType: 'DisplayPlacement',
      endpoint: 'display-placements',
      filterOptionsCall: (apiStore) => (val) => apiStore['displayPlacementsFilterOptions'] = val,
      form: (rootStore) => <GenericForm type="Placement" datePicker={2} numButtons={3} extraFields={['size_id']} />,
      genericModelPromiseEndpoints: [
        'audiences',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-genders',
        'channel-objectives',
        'channel-tactics',
        'channel-targetings',
        'creative-types',
        'devices',
        'display-types',
        'display-type-channels',
        'genders',
        'geos',
        'languages',
        'marketing-campaigns',
        'objectives',
        'publishers',
        'sizes',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.displayPlacementsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.displayAdsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Placements',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'placements',
      send2domo: true,
      singular: 'placement',
      showFormat: 'definitionList',
      tabDisabled: () => false,
      tabIndex: 0,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 11,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'OLD NSID/ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 6,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 260,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 2,
          grid_type: 'namestring',
          filter: false,
          id: 'namestring',
          filter: false,
          filterField: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'DisplayPlacement',
          rules: 'required',
        },
        {
          default: 1,
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          form: 'channel_id',
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: () => 'hmm',
          rules: 'required',
          type: 'hidden',
          utaType: 'channel_id',
        },
        {
          colSize: 10.5,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 180,
          label: 'Created By',
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.Users
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          colSize: 9,
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 7,
          label: 'Status',
          name: 'status_id',
          options: (apiStore) => apiStore.statuses,
          id: 'status_id',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          utaType: 'group',
          rules: 'required',
        },
        {
          name: 'company',
          ns: () => 'Astellas',
          ns_order: 10,
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            // menuItems={_.compact(rootStore.apiStore.brands.map((brand_id) => rootStore.uiStore.makePrimeRegularOption(brand_id)))}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },
        {
          domo_field: 'business_unit',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
          name: 'business_unit',
          rules: 'required'
        },
        {
          domo_field: 'area',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
          name: 'area',
          rules: 'required'
        },
        {
          domo_field: 'branded',
          domo_value: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded')),
          name: 'branded',
          ns: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded'), 'ns'),
          ns_order: 25,
          rules: 'required'
        },
        {
          belongsTo: ['audience', 'audiences'],
          form: 'audience_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="audience"
            fieldName="audience_id"
            form={rootStore.channel.model.crudStore.form}
            label="Audience"
            menuItems={rootStore.uiStore.menuItems('audiences', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('audience_id', event)}
          />),
          formOrder: 30,
          label: 'Audience',
          rules: 'required|integer',

          utaType: 'fk',
        },
        {
          colSize: 10.5,
          domo_field: 'audience',
          domo_value: (crudStore) => property(crudStore.storedData.audience, 'name'),
          grid: true,
          grid_order: 30,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.audiences,
          grid_value: (row) => property(row.audience, 'name'),
          id: 'audience',
          label: 'Audience',
          name: 'audience',
          ns: (crudStore) => abbrev(crudStore.storedData.audience),
          ns_order: 40,
          show: true,
          show_value: (row) => property(row.audience, 'name'),
          show_order: 30,
          rules: 'required',
        },

        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
          edit_callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 40,
          label: 'Campaign Type',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 13,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
          grid: true,
          grid_order: 40,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          grid_value: (row) => property(row.campaign_type, 'name'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          ns_order: 30,
          show: true,
          show_value: (row) => property(row.campaign_type, 'name'),
          show_order: 40,
          rules: 'required',
        },
        {
          colSize: 10.5,
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 50,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectives,
          grid_value: (row) => property(row.objective, 'name'),
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          ns: (crudStore) => abbrev(crudStore.storedData.objective),
          ns_order: 50,
          rules: 'required',
        },
        {
          belongsTo: ['objective', 'objectives'],
          form: 'objective_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            form={rootStore.channel.model.crudStore.form}
            label="Objective"
            menuItems={rootStore.uiStore.menuItems('objectives', 'makePrimeObjectiveOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 50,
          label: 'Objective',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.objective, 'name'),
          show_order: 50,
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          // domo_field: 'ad_name',
          // domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          form: 'name',
          copyEditable: true,
          // formField: (rootStore) => (
          //   <UccInputText
          //     core="name"
          //     fieldName="name"
          //     form={rootStore.channel.model.crudStore.form}
          //     label="Placement Description"
          //     onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          //   />),
          // formOrder: 60,
          grid: true,
          filter: true,
          grid_order: 60,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          filter_placeholder: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          name: 'name',
          show: true,
          show_value: (row) => row.name,
          show_order: 60,
          rules: 'string',
          utaType: 'name',
        },
        {
          domo_field: 'ad_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 65,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Campaign Name',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          // ns_order: 60,
          show: true,
          show_value: (row) => property(row.marketing_campaign, 'name'),
          show_order: 60,
          utaType: 'name',
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Name"
            menuItems={rootStore.uiStore.menuItems('marketingCampaigns', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 60,
          label: "Campaign Name",
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'publisher',
          domo_value: (crudStore) => property(crudStore.storedData.publisher, 'name'),
          grid: true,
          grid_order: 70,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.publishers,
          grid_value: (row) => property(row.publisher, 'name'),
          id: 'publisher',
          label: 'Publisher',
          name: 'publisher',
          ns: (crudStore) => abbrev(crudStore.storedData.publisher),
          ns_order: 70,
          rules: '',
        },
        {
          belongsTo: ['publisher', 'publishers'],
          copyEditable: true,
          form: 'publisher_id',
          formField: (rootStore) => (<UccSelect
            core="publisher"
            fieldName="publisher_id"
            form={rootStore.channel.model.crudStore.form}
            label="Publisher"
            menuItems={rootStore.uiStore.menuItems('publishers', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('publisher_id', event)}
          />),
          formOrder: 70,
          label: 'Publisher',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.publisher, 'name'),
          show_order: 70,
          utaType: 'fk',
        },
        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (<UccSelect
            core="tactic"
            fieldName="tactic_id"
            form={rootStore.channel.model.crudStore.form}
            label="Tactic"
            menuItems={rootStore.uiStore.menuItems('tactics', 'makePrimeChannelSpecificOption', 'channelTactics')}
            onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
          />),
          formOrder: 80,
          label: 'Tactic',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 9,
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 80,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          grid_value: (row) => property(row.tactic, 'name'),
          id: 'tactic',
          label: 'Tactic',
          name: 'tactic',
          ns: (crudStore) => abbrev(crudStore.storedData.tactic),
          ns_order: 80,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.tactic, 'name'),
          show_order: 80,
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.suppressionLabel(),
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          ns_order: 90,
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 82,
          type: 'checkbox',
          utaType: 'boolean',
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          form: 'display_placement_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} />),
          formOrder: 80,
          grid: true,
          grid_order: 80,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'display_placement_targetings_attributes',
          ns: (crudStore) => NameString.targetingString(crudStore),
          ns_order: 95,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "display_placement_targetings"),
          show_order: 87,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'display_placement_targetings_attributes[].id',
        },
        {
          form: 'display_placement_targetings_attributes[].display_ad_id',
        },
        {
          form: 'display_placement_targetings_attributes[].targeting_id',
        },
        {
          form: 'display_placement_targetings_attributes[].extra',
        },
        {
          form: 'display_placement_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'display_placement_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'display_placement_targetings_attributes[].on',
        },

        {
          belongsTo: ['creative_type', 'creativeTypes'],
          form: 'creative_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="creative-type"
            fieldName="creative_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Creative Type"
            menuItems={rootStore.uiStore.menuItems('creativeTypes', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('creative_type_id', event)}
          />),
          formOrder: 105,
          label: 'Creative Type',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 11,
          domo_field: 'creative_type',
          domo_value: (crudStore) => property(crudStore.storedData.creative_type, 'name'),
          grid: true,
          grid_order: 105,
          grid_value: (row) => property(row.creative_type, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.creativeTypes,
          id: 'creative_type',
          label: 'Creative Type',
          name: 'creative_type',
          ns: (crudStore) => abbrev(crudStore.storedData.creative_type),
          ns_order: 105,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.creative_type, 'name'),
          show_order: 105,
        },
        {
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={rootStore.uiStore.menuItems('genders', 'makePrimeChannelSpecificOption', 'channelGenders')}
            onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 110,
          label: 'Gender',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 9.5,
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          grid_order: 110,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 110,
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 110,
          rules: 'required|integer',
        },
        {
          belongsTo: ['device', 'devices'],
          form: 'device_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="device"
            fieldName="device_id"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            menuItems={rootStore.uiStore.menuItems('devices', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('device_id', event)}
          />),
          formOrder: 120,
          label: 'Device',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 9.5,
          domo_field: 'device',
          domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
          grid: true,
          grid_order: 120,
          grid_value: (row) => property(row.device, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.devices,
          id: 'device',
          label: 'Device',
          name: 'device',
          show: true,
          show_value: (row) => property(row.device, 'name'),
          show_order: 120,
          rules: 'integer',
        },
        {
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          copyEditable: true,
          formField: (rootStore) => (
            <UccSelect
              core="geo"
              fieldName="geo_id"
              form={rootStore.channel.model.crudStore.form}
              label="Geo"
              menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeOption')}
              onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
            />
          ),
          formOrder: 130,
          label: 'Geo',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 8.5,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          grid_order: 130,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          label: 'Geo',
          name: 'geo',
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 130,
          rules: 'integer',
        },
        {
          belongsTo: ['language', 'languages'],
          form: 'language_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="language"
            fieldName="language_id"
            form={rootStore.channel.model.crudStore.form}
            label="Language"
            menuItems={rootStore.uiStore.menuItems('languages', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('language_id', event)}
          />),
          formOrder: 140,
          label: 'Language',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 11,
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 140,
          grid_value: (row) => property(row.language, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          id: 'language',
          label: 'Language',
          name: 'language',
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 140,
          rules: 'integer',
        },
        {
          belongsTo: ['size', 'sizes'],
          copyEditable: true,
          hideForm: true,
          formField: (rootStore) => (<UccSelect
            core="size"
            fieldName="size_id"
            form={rootStore.channel.model.crudStore.form}
            label="Size"
            menuItems={rootStore.uiStore.menuItems('sizes', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('size_id', event)}
          />),
          form: 'size_id',
          label: 'Size',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'size',
          domo_value: (crudStore) => property(crudStore.storedData.size, 'name'),
          grid: true,
          grid_order: 190,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.sizes,
          grid_value: (row) => property(row.size, 'name'),
          id: 'size',
          label: 'Size',
          name: 'size',
          ns: (crudStore) => abbrev(crudStore.storedData.size),
          ns_order: 190,
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.size, 'name'),
          show_order: 190,
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', false, 160, 160, 'dns', 200),
        dateField('end_date', 'End Date', 'blank', false, 170, 170, 'dns', 210),
      ),
    },
    // ___   _            _                 _       _
    // |   \ (_) ___ _ __ | | __ _  _  _    /_\   __| | ___
    // | |) || |(_-<| '_ \| |/ _` || || |  / _ \ / _` |(_-<
    // |___/ |_|/__/| .__/|_|\__,_| \_, | /_/ \_\\__,_|/__/
    //              |_|             |__/
    {
      allFilterData: (apiStore) => apiStore['displayAdsFilterOptions'],
      codename: 'DispAd',
      controller: 'display_ad',
      crud: (rootStore) => rootStore.displayAdsCrud,
      domoNsType: 'DisplayAd',
      endpoint: 'display-ads',
      filterOptionsCall: (apiStore) => (val) => apiStore['displayAdsFilterOptions'] = val,
      form: (rootStore) => <GenericForm type="Display Ad" datePicker={2} numButtons={3} extraFields={['size_id', 'veeva_document_number']} />,
      genericModelPromiseEndpoints: [
        'audiences',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-genders',
        'channel-objectives',
        'channel-tactics',
        'channel-targetings',
        'creative-types',
        'devices',
        'display-types',
        'display-type-channels',
        'genders',
        'geos',
        'languages',
        'marketing-campaigns',
        'objectives',
        'publishers',
        'sizes',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.displayPlacementsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.displayAdsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Ads',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'ads',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'ad',
      tabDisabled: () => false,
      tabIndex: 1,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 11,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'OLD NSID/ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 4,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },

        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 3,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'DisplayAd',
          rules: 'required',
        },
        {
          default: 1,
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          form: 'channel_id',
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: () => 'hmm',
          rules: 'required',
          type: 'hidden',
          utaType: 'channel_id',
        },
        {
          colSize: 10.5,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          name: 'created_by',
          label: 'Created By',
          id: 'created_by',
          grid: true,
          grid_order: 190,
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          colSize: 9,
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 6,
          label: 'Status',
          name: 'status_id',
          options: (apiStore) => apiStore.statuses,
          id: 'status_id',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          utaType: 'group',
          rules: 'required',
        },
        {
          name: 'company',
          ns: () => 'Astellas',
          ns_order: 5,
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          type: 'hidden',
          utaType: 'brand',
          rules: 'required',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 10,
          rules: 'required',
          utaType: 'brand',
        },
        {
          domo_field: 'business_unit',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
          name: 'business_unit',
          rules: 'required'
        },
        {
          domo_field: 'area',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
          name: 'area',
          rules: 'required'
        },
        {
          domo_field: 'branded',
          domo_value: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded')),
          name: 'branded',
          ns: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded'), 'ns'),
          ns_order: 15,
          rules: 'required'
        },
        {
          belongsTo: ['audience', 'audiences'],
          form: 'audience_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="audience"
            fieldName="audience_id"
            form={rootStore.channel.model.crudStore.form}
            label="Audience"
            menuItems={rootStore.uiStore.menuItems('audiences', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('audience_id', event)}
          />),
          formOrder: 20,
          label: 'Audience',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          domo_field: 'audience',
          domo_value: (crudStore) => property(crudStore.storedData.audience, 'name'),
          grid: true,
          grid_order: 20,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.audiences,
          grid_value: (row) => property(row.audience, 'name'),
          id: 'audience',
          label: 'Audience',
          name: 'audience',
          ns: (crudStore) => abbrev(crudStore.storedData.audience),
          ns_order: 20,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.audience, 'name'),
          show_order: 20,
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 30,
          label: 'Campaign Type',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
          grid: true,
          grid_order: 30,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          grid_value: (row) => property(row.campaign_type, 'name'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          show: true,
          show_value: (row) => property(row.campaign_type, 'name'),
          show_order: 30,
        },
        {
          colSize: 10.5,
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.objective, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectives,
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          // ns: (crudStore) => abbrev(crudStore.storedData.objective),
          // ns_order: 40,
        },
        {
          belongsTo: ['objective', 'objectives'],
          form: 'objective_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            form={rootStore.channel.model.crudStore.form}
            label="Objective"
            menuItems={rootStore.uiStore.menuItems('objectives', 'makePrimeObjectiveOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 40,
          label: 'Objective',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.objective, 'name'),
          show_order: 40,
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          domo_field: 'ad_name',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          form: 'name',
          copyEditable: true,
          formField: (rootStore) => (
            <UccInputText
              core="name"
              fieldName="name"
              form={rootStore.channel.model.crudStore.form}
              label="Ad Description"
              onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
            />),
          formOrder: 50,
          grid: true,
          grid_order: 50,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          name: 'name',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          ns_order: 50,
          rules: 'required|string',
          show: true,
          show_value: (row) => row.name,
          show_order: 50,
          utaType: 'name',
        },  // no publisher for Astellas.
        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (<UccSelect
            core="tactic"
            fieldName="tactic_id"
            form={rootStore.channel.model.crudStore.form}
            label="Tactic"
            menuItems={rootStore.uiStore.menuItems('tactics', 'makePrimeChannelSpecificOption', 'channelTactics')}
            onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
          />),
          formOrder: 60,
          label: 'Tactic',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 9,
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 60,
          grid_value: (row) => property(row.tactic, 'name'),
          id: 'tactic',
          label: 'Tactic',
          name: 'tactic',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          show: true,
          show_value: (row) => property(row.tactic, 'name'),
          show_order: 60,
          rules: 'integer',
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.suppressionLabel(),
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          type: 'checkbox',
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 65,
          utaType: 'boolean',
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          form: 'display_ad_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} />),
          formOrder: 80,
          grid: true,
          grid_order: 80,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'display_ad_targetings_attributes',
          ns: (crudStore) => NameString.targetingString(crudStore),
          // ns_order: 80,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "display_ad_targetings"),
          show_order: 80,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'display_ad_targetings_attributes[].id',
        },
        {
          form: 'display_ad_targetings_attributes[].display_ad_id',
        },
        {
          form: 'display_ad_targetings_attributes[].targeting_id',
        },
        {
          form: 'display_ad_targetings_attributes[].extra',
        },
        {
          form: 'display_ad_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'display_ad_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'display_ad_targetings_attributes[].on',
        },  //no geo or language for astellas
        {
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={rootStore.uiStore.menuItems('genders', 'makePrimeChannelSpecificOption', 'channelGenders')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 90,
          label: 'Gender',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 9.5,
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          grid_order: 90,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 90,
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 90,
          rules: 'required|integer',
        },
        {
          belongsTo: ['device', 'devices'],
          form: 'device_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="device"
            fieldName="device_id"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            menuItems={rootStore.uiStore.menuItems('devices', 'makePrimeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('device_id', event)}
          />),
          formOrder: 100,
          label: 'Device',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 9.5,
          domo_field: 'device',
          domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
          grid: true,
          grid_order: 100,
          grid_value: (row) => property(row.device, 'name'),
          id: 'device',
          label: 'Device',
          name: 'device',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.devices,
          show: true,
          show_value: (row) => property(row.device, 'name'),
          show_order: 100,
          rules: 'integer',
        },
        {
          belongsTo: ['creative_type', 'creativeTypes'],
          form: 'creative_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="creative-type"
            fieldName="creative_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Creative Type"
            menuItems={rootStore.uiStore.menuItems('creativeTypes', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('creative_type_id', event)}
          />),
          formOrder: 110,
          label: 'Creative Type',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 12,
          domo_field: 'creative_type',
          domo_value: (crudStore) => property(crudStore.storedData.creative_type, 'name'),
          grid: true,
          grid_order: 110,
          grid_value: (row) => property(row.creative_type, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.creativeTypes,
          id: 'creative_type',
          label: 'Creative Type',
          name: 'creative_type',
          show: true,
          show_value: (row) => property(row.creative_type, 'name'),
          show_order: 110,
          rules: 'integer',
        },

        {
          belongsTo: ['size', 'sizes'],
          form: 'size_id',
          copyEditable: true,
          hideForm: true,
          formField: (rootStore) => (<UccSelect
            core="size"
            fieldName="size_id"
            form={rootStore.channel.model.crudStore.form}
            label="Size"
            menuItems={rootStore.uiStore.menuItems('sizes', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('size_id', event)}
          />),
          //form still in jsx
          label: 'Size',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'size',
          domo_value: (crudStore) => property(crudStore.storedData.size, 'name'),
          grid: true,
          grid_order: 150,
          grid_value: (row) => property(row.size, 'name'),
          id: 'size',
          label: 'Size',
          name: 'size',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.sizes,
          ns: (crudStore) => abbrev(crudStore.storedData.size),
          ns_order: 150,
          show: true,
          show_value: (row) => property(row.size, 'name'),
          show_order: 110,
          rules: 'required|integer',
        },
        {
          colSize: 15,
          domo_field: 'veeva_document_number',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.veeva_document_number),
          form: 'veeva_document_number',
          copyEditable: true,
          hideForm: true,
          formField: (rootStore) => (<UccInputText
            core="veeva_document_number"
            fieldName="veeva_document_number"
            form={rootStore.channel.model.crudStore.form}
            label="Veeva Document Number"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('veeva_document_number', event)}
          />),
          formOrder: 150,
          grid: true,
          grid_order: 150,
          grid_value: (row) => row.veeva_document_number,
          id: 'veeva_document_number',
          name: 'veeva_document_number',
          ns: (crudStore) => crudStore.storedData.veeva_document_number,
          label: 'Veeva Document Number',
          utaType: 'string',
          rules: 'required|string',
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.veeva_document_number,
          show_order: 140,
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 130, 130, 'dns'),
        dateField('end_date', 'End Date', 'blank', false, 140, 140, 'dns'),
      ),
    },

    //    _    _    _    _    _    _    _    _
    //   / \  / \  / \  / \  / \  / \  / \  / \
    //  ( C )( r )( e )( a )( t )( i )( v )( e )
    //   \_/  \_/  \_/  \_/  \_/  \_/  \_/  \_/

    {
      allFilterData: (apiStore) => apiStore['displayCreativesFilterOptions'],
      codename: 'DispCreat',
      controller: 'display_creative',
      crud: (rootStore) => rootStore.displayCreativesCrud,
      domoNsType: 'DisplayCreative',
      endpoint: 'display-creatives',
      filterOptionsCall: (apiStore) => (val) => apiStore['displayCreativeFilterOptions'] = val,
      form: (rootStore) => <GenericForm type="Creative" datePicker={2} numButtons={3} />,
      genericModelPromiseEndpoints: [
        'audiences',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-genders',
        'channel-objectives',
        'channel-tactics',
        'channel-targetings',
        'creative-types',
        'devices',
        'genders',
        'geos',
        'languages',
        'marketing-campaigns',
        'message-types',
        'objectives',
        'publishers',
        'sizes',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.displayCreativesCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Creatives',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'creatives',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'creative',
      tabDisabled: () => false,
      tabIndex: 2,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,

      columns: [
        {
          colSize: 11,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'OLD NSID/ID',
          name: 'id',
          ns: (row) => row.id,
          rules: 'integer|required',
          permListAndView: 80,
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 5,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 2,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'DisplayCreative',
          rules: 'required',
        },
        {
          default: 1,
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          form: 'channel_id',
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: () => 'hmm',
          rules: 'required',
          type: 'hidden',
          utaType: 'channel_id',
        },
        {
          colSize: 11.5,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'created_by',
          label: 'Created By',
          options: (apiStore) => apiStore.Users,
          id: 'created_by',
          grid: true,
          grid_order: 190,
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          colSize: 9,
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 8,
          label: 'Status',
          name: 'status_id',
          options: (apiStore) => apiStore.statuses,
          id: 'status_id',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          utaType: 'group',
          rules: 'required',
        },
        {
          name: 'company',
          ns: () => 'Astellas',
          ns_order: 10,
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },
        {
          domo_field: 'business_unit',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
          name: 'business_unit',
          rules: 'required'
        },
        {
          domo_field: 'area',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
          name: 'area',
          rules: 'required'
        },
        {
          domo_field: 'branded',
          domo_value: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded')),
          name: 'branded',
          ns: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded'), 'ns'),
          ns_order: 25,
          rules: 'required'
        },
        {
          belongsTo: ['audience', 'audiences'],
          form: 'audience_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="audience"
            fieldName="audience_id"

            form={rootStore.channel.model.crudStore.form}
            label="Audience"
            menuItems={rootStore.uiStore.menuItems('audiences', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('audience_id', event)}
          />),
          formOrder: 30,
          label: 'Audience',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          domo_field: 'audience',
          domo_value: (crudStore) => property(crudStore.storedData.audience, 'name'),
          grid: true,
          grid_order: 30,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.audiences,
          grid_value: (row) => property(row.audience, 'name'),
          id: 'audience',
          label: 'Audience',
          name: 'audience',
          ns: (crudStore) => abbrev(crudStore.storedData.audience),
          ns_order: 30,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.audience, 'name'),
          show_order: 30,
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 40,
          label: 'Campaign Type',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 13,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
          grid: true,
          grid_order: 40,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          grid_value: (row) => property(row.campaign_type, 'name'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',

        },
        {
          colSize: 10.5,
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 50,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectives,
          grid_value: (row) => property(row.objective, 'name'),
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          show: true,
          show_value: (row) => property(row.objective, 'name'),
          show_order: 50,
          rules: 'integer',
        },
        {
          belongsTo: ['objective', 'objectives'],
          form: 'objective_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            form={rootStore.channel.model.crudStore.form}
            label="Objective"
            menuItems={rootStore.uiStore.menuItems('objectives', 'makePrimeObjectiveOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 50,
          label: 'Objective',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          grid: true,
          grid_order: 60,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          name: 'name',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          // ns_order: 60,
          rules: 'required|string',
          show: true,
          show_value: (row) => row.name,
          show_order: 60,
          utaType: 'name',
        },
        {
          domo_field: 'ad_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 60,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Campaign Name',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 60,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.marketing_campaign, 'name'),
          show_order: 60,
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Name"
            menuItems={rootStore.uiStore.menuItems('marketingCampaigns', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 60,
          label: 'Campaign Name',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          belongsTo: ['message_type', 'messageTypes'],
          form: 'message_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="message-type"
            fieldName="message_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Primary Topic"
            menuItems={rootStore.uiStore.menuItems('messageTypes', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('message_type_id', event)}
          />),
          formOrder: 65,
          label: 'Primary Topic',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 11,
          domo_field: 'message_type',
          domo_value: (crudStore) => property(crudStore.storedData.message_type, 'name'),
          grid: true,
          grid_order: 65,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.messageTypes,
          grid_value: (row) => property(row.message_type, 'name'),
          id: 'message_type',
          label: 'Primary Topic',
          name: 'message_type',
          ns: (crudStore) => abbrev(crudStore.storedData.message_type),
          show: true,
          show_value: (row) => property(row.message_type, 'name'),
          show_order: 65,
          ns_order: 65,
        },
        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (
            <UccSelect
              core="tactic"
              fieldName="tactic_id"
              form={rootStore.channel.model.crudStore.form}
              label="Tactic"
              menuItems={rootStore.uiStore.menuItems('tactics', 'makePrimeChannelSpecificOption', 'channelTactics')}
              onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
            />),
          formOrder: 70,
          label: 'Tactic',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 9,
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 70,
          grid_value: (row) => property(row.tactic, 'name'),
          id: 'tactic',
          label: 'Tactic',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          name: 'tactic',
          show: true,
          show_value: (row) => property(row.tactic, 'name'),
          show_order: 70,
          rules: 'integer',
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.suppressionLabel(),
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          type: 'checkbox',
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 75,
          utaType: 'boolean',
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          form: 'display_creative_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} />),
          formOrder: 80,
          grid: true,
          grid_order: 80,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'display_creative_targetings_attributes',
          // ns: (crudStore) => NameString.targetingString(crudStore),
          // ns_order: 80,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "display_creative_targetings"),
          show_order: 80,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'display_creative_targetings_attributes[].id',
        },
        {
          form: 'display_creative_targetings_attributes[].display_ad_id',
        },
        {
          form: 'display_creative_targetings_attributes[].targeting_id',
        },
        {
          form: 'display_creative_targetings_attributes[].extra',
        },
        {
          form: 'display_creative_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'display_creative_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'display_creative_targetings_attributes[].on',
        },

        {
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={rootStore.uiStore.menuItems('genders', 'makePrimeChannelSpecificOption', 'channelGenders')}
            onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 90,
          label: 'Gender',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 9.5,
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          grid_order: 90,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 90,
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 90,
        },

        {
          belongsTo: ['device', 'devices'],
          copyEditable: true,
          form: 'device_id',
          formField: (rootStore) => (<UccSelect
            core="device"
            fieldName="device_id"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            menuItems={rootStore.uiStore.menuItems('devices', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('device_id', event)}
          />),
          formOrder: 120,
          label: 'Device',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 9.5,
          domo_field: 'device',
          domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
          grid: true,
          grid_order: 100,
          grid_value: (row) => property(row.device, 'name'),
          id: 'device',
          label: 'Device',
          name: 'device',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.devices,
          // ns: (crudStore) => abbrev(crudStore.storedData.device),
          // ns_order: 80,
          rules: 'integer',
          sortable: true,
          show: true,
          show_value: (row) => property(row.device, 'name'),
          show_order: 100,
        },


        {
          belongsTo: ['creative_type', 'creativeTypes'],
          form: 'creative_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="creative-type"
            fieldName="creative_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Creative Type"
            menuItems={rootStore.uiStore.menuItems('creativeTypes', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('creative_type_id', event)}
          />),
          formOrder: 110,
          label: 'Creative Type',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 12,
          domo_field: 'creative_type',
          domo_value: (crudStore) => property(crudStore.storedData.creative_type, 'name'),
          grid: true,
          grid_order: 110,
          grid_value: (row) => property(row.creative_type, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.creativeTypes,
          id: 'creative_type',
          label: 'Creative Type',
          name: 'creative_type',
          ns: (crudStore) => abbrev(crudStore.storedData.creative_type),
          ns_order: 110,
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.creative_type, 'name'),
          show_order: 110,
        },
        /// version

        {
          domo_field: 'version',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.version),
          form: 'version',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="version"
            fieldName="version"
            form={rootStore.channel.model.crudStore.form}
            label="Version"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('version', event)}
          />
          ),
          formOrder: 140,
          grid: true,
          grid_order: 140,
          grid_value: (row) => row.version,
          id: 'version',
          label: 'Version',
          filter: (dt, col) => inputTextFilter(dt, col),
          name: 'version',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.version),
          ns_order: 140,
          rules: 'string',
          show: true,
          show_value: (row) => row.version,
          show_order: 140,
          utaType: 'text',
        },


        {
          belongsTo: ['size', 'sizes'],
          form: 'size_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="size"
            fieldName="size_id"
            form={rootStore.channel.model.crudStore.form}
            label="Size"
            menuItems={rootStore.uiStore.menuItems('sizes', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('size_id', event)}
          />),
          formOrder: 145,
          label: 'Size',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'size',
          domo_value: (crudStore) => property(crudStore.storedData.size, 'name'),
          grid: true,
          grid_order: 145,
          grid_value: (row) => property(row.size, 'name'),
          id: 'size',
          label: 'Size',
          name: 'size',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.sizes,
          ns: (crudStore) => abbrev(crudStore.storedData.size),
          ns_order: 145,
          show: true,
          show_value: (row) => property(row.size, 'name'),
          show_order: 145,
          rules: 'required|integer',
        },

        {
          colSize: 15,
          domo_field: 'veeva_document_number',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.veeva_document_number),
          form: 'veeva_document_number',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="veeva_document_number"
            fieldName="veeva_document_number"
            form={rootStore.channel.model.crudStore.form}
            label="Veeva Document Number"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('veeva_document_number', event)}
          />),
          formOrder: 150,
          grid: true,
          grid_order: 150,
          grid_value: (row) => row.veeva_document_number,
          id: 'veeva_document_number',
          name: 'veeva_document_number',
          ns: (crudStore) => crudStore.storedData.veeva_document_number,
          label: 'Veeva Document Number',
          utaType: 'string',
          rules: 'required|string',
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.veeva_document_number,
          show_order: 140,
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', false, 200, false, 'S.', 200),
        dateField('end_date', 'End Date', 'blank', false, 210, false, 'E.', 210),
      ),
    },

    // ,---.          |
    // |---',---.,---.|__/ ,---.,---.,---.,---.
    // |    ,---||    |  \ ,---||   ||---'`---.
    // `    `---^`---'`   ``---^`---|`---'`---'
    //                          `---'
    {
      allFilterData: (apiStore) => apiStore['displayPackagesFilterOptions'],
      codename: 'DispPack',
      controller: 'display_package',
      crud: (rootStore) => rootStore.displayPackagesCrud,
      domoNsType: 'DisplayPackage',
      endpoint: 'display-packages',
      filterOptionsCall: (apiStore) => (val) => apiStore['displayPackagesFilterOptions'] = val,
      form: () => <GenericForm type="Package" datePicker={2} numButtons={3} />,
      genericModelPromiseEndpoints: [
        'audiences',
        'campaign-types',
        'campaign-type-channels',
        'creative-types',
        'publishers',
        'statuses',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.displayPackagesCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Packages',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'packages',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'package',
      tabDisabled: () => false,
      tabIndex: 3,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,

      columns: [
        {
          colSize: 11,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'OLD NSID/ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 5,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 2,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'DisplayPackage',
          rules: 'required',
        },
        {
          default: 1,
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          form: 'channel_id',
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: () => 'hmm',
          rules: 'required',
          type: 'hidden',
          utaType: 'channel_id',
        },
        {
          colSize: 11,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'created_by',
          label: 'Created By',
          options: (apiStore) => apiStore.Users,
          id: 'created_by',
          grid: true,
          grid_order: 190,
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          rules: 'required',
          utaType: 'user',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          colSize: 9,
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 6,
          label: 'Status',
          name: 'status_id',
          options: (apiStore) => apiStore.statuses,
          id: 'status_id',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          rules: 'required',
          utaType: 'group',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          rules: 'required',
          utaType: 'group',
        },
        {
          name: 'company',
          ns: () => 'Astellas',
          ns_order: 10,
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },
        {
          domo_field: 'business_unit',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
          name: 'business_unit',
          rules: 'required'
        },
        {
          domo_field: 'area',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
          name: 'area',
          rules: 'required'
        },
        {
          domo_field: 'branded',
          domo_value: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded')),
          name: 'branded',
          ns: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded'), 'ns'),
          ns_order: 25,
          rules: 'required'
        },
        {
          belongsTo: ['audience', 'audiences'],
          form: 'audience_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="audience"
            fieldName="audience_id"

            form={rootStore.channel.model.crudStore.form}
            label="Audience"
            menuItems={rootStore.uiStore.menuItems('audiences', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('audience_id', event)}
          />),
          formOrder: 30,
          label: 'Audience',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          domo_field: 'audience',
          domo_value: (crudStore) => property(crudStore.storedData.audience, 'name'),
          grid: true,
          grid_order: 30,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.audiences,
          grid_value: (row) => property(row.audience, 'name'),
          id: 'audience',
          label: 'Audience',
          name: 'audience',
          ns: (crudStore) => abbrev(crudStore.storedData.audience),
          ns_order: 40,
          show: true,
          show_value: (row) => property(row.audience, 'name'),
          show_order: 30,
          rules: 'required',
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 40,
          label: 'Campaign Type',
          rules: 'integer|required',
          utaType: 'fk',
        },
        {
          colSize: 13,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.campaign_type, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          ns_order: 30,
          show: true,
          show_value: (row) => property(row.campaign_type, 'name'),
          show_order: 40,
        },
        {
          colSize: 10.5,
          domo_field: 'publisher',
          domo_value: (crudStore) => property(crudStore.storedData.publisher, 'name'),
          grid: true,
          grid_order: 50,
          grid_value: (row) => property(row.publisher, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.publishers,
          id: 'publisher',
          label: 'Publisher',
          name: 'publisher',
          ns: (crudStore) => abbrev(crudStore.storedData.publisher),
          ns_order: 50,
        },
        {
          belongsTo: ['publisher', 'publishers'],
          form: 'publisher_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="publisher"
            fieldName="publisher_id"
            form={rootStore.channel.model.crudStore.form}
            label="Publisher"
            menuItems={rootStore.uiStore.menuItems('publishers', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('publisher_id', event)}
          />),
          formOrder: 50,
          label: 'Publisher',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.publisher, 'name'),
          show_order: 50,
          utaType: 'fk',
        },
        {
          colSize: 10.5,
          domo_field: 'ad_name',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          form: 'name',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="name"
            fieldName="name"
            form={rootStore.channel.model.crudStore.form}
            label="Package Description"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
          />),
          formOrder: 70,
          grid: true,
          grid_order: 70,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          name: 'name',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          ns_order: 70,
          rules: 'required|string',
          show: true,
          show_value: (row) => row.name,
          show_order: 70,
          utaType: 'name',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },

      ].concat(
        dateField('start_date', 'Start Date', 'blank', false, 80, 80, 'dns'),
        dateField('end_date', 'End Date', 'blank', false, 80, 80, 'dns'),
      ),
      // concat(() => )
    },
  ],
};

