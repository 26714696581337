import React from "react";

const PaidSocialIcon = (props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24.000000pt"
    height="24.000000pt"
    viewBox="0 0 300.000000 300.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
      fill= {props.fill} 
      stroke="none"

    >
      <path
        d="M192 2889 c-26 -13 -55 -39 -70 -62 l-27 -41 -3 -420 c-2 -275 1
    -434 7 -457 15 -49 45 -86 92 -109 35 -18 59 -20 264 -20 l225 0 143 -95 c78
    -52 152 -95 164 -95 37 0 53 36 53 117 l0 73 115 0 c128 0 173 14 214 66 40
    51 42 82 39 520 l-3 420 -27 41 c-16 24 -44 49 -70 62 -43 21 -55 21 -559 21
    -505 0 -515 0 -557 -21z m1063 -91 c54 -24 55 -34 55 -453 0 -420 -2 -435 -56
    -455 -14 -6 -84 -10 -156 -10 -159 0 -168 -5 -168 -87 l0 -53 -105 70 -104 70
    -225 0 c-124 0 -236 4 -250 10 -54 20 -56 35 -56 457 1 415 1 422 51 449 33
    18 974 20 1014 2z"
      />
      <path
        d="M683 2696 c-26 -22 -28 -31 -31 -108 -4 -77 -6 -85 -35 -115 -31 -33
    -32 -33 -127 -33 -61 0 -100 -4 -108 -12 -9 -9 -12 -73 -12 -224 0 -209 0
    -213 22 -228 19 -14 67 -16 326 -16 329 0 349 3 390 55 20 26 22 39 22 182 0
    117 -4 161 -15 183 -23 45 -53 60 -117 60 l-58 0 0 70 c0 74 -19 129 -56 161
    -62 54 -154 65 -201 25z m123 -100 c24 -20 28 -32 32 -90 4 -66 4 -66 -22 -66
    -25 0 -26 -3 -26 -50 l0 -49 118 -3 117 -3 0 -130 0 -130 -182 -3 -183 -2 0
    148 0 148 45 44 45 44 0 83 c0 93 8 101 56 59z m-246 -391 l0 -135 -45 0 -45
    0 0 135 0 135 45 0 45 0 0 -135z"
      />
      <path
        d="M1500 2670 l0 -50 271 0 c181 0 278 -4 292 -11 27 -14 67 -50 67 -60
    0 -4 -69 -9 -152 -11 -134 -3 -158 -7 -195 -26 -24 -12 -50 -33 -59 -47 -16
    -24 -21 -25 -120 -25 l-104 0 0 -50 0 -50 90 0 89 0 3 -352 c3 -325 5 -356 22
    -392 23 -45 96 -96 138 -96 l28 0 0 -76 c0 -138 28 -146 172 -49 54 36 100 65
    103 65 3 0 5 -73 5 -161 l0 -162 -48 46 c-83 77 -155 100 -242 77 -64 -17
    -134 -91 -148 -156 -11 -52 -4 -112 17 -154 7 -14 70 -82 138 -151 l126 -127
    -19 -41 -19 -40 -207 -1 -208 0 0 -55 0 -55 189 0 188 0 6 -83 c3 -46 15 -107
    25 -135 l20 -52 -501 0 c-560 0 -547 -1 -594 69 -41 62 -43 61 170 61 212 0
    251 7 302 51 61 54 66 85 63 350 l-3 236 -28 41 c-44 67 -85 82 -222 82 l-115
    0 0 73 c0 122 -35 144 -127 82 -29 -19 -55 -35 -58 -35 -3 0 -5 90 -5 200 l0
    200 -50 0 -50 0 0 -237 0 -238 -35 -22 c-33 -22 -43 -23 -260 -23 -202 0 -230
    -2 -263 -19 -93 -47 -102 -79 -102 -358 0 -200 3 -233 19 -268 22 -50 69 -91
    119 -105 21 -5 144 -10 280 -10 l242 0 0 -27 c1 -73 80 -168 159 -191 28 -9
    272 -12 888 -12 811 0 851 1 866 18 15 17 17 65 17 483 0 292 -4 487 -11 524
    -19 101 -49 164 -130 271 -44 57 -79 105 -79 108 0 3 76 6 168 8 168 3 169 3
    206 32 74 56 76 64 76 479 0 395 -2 410 -53 465 -51 55 -62 57 -343 60 l-262
    3 -17 39 c-23 51 -70 99 -120 121 -36 17 -71 19 -327 19 l-288 0 0 -50z m1277
    -264 l28 -24 3 -348 c2 -250 -1 -356 -9 -376 -24 -57 -28 -58 -334 -58 l-280
    0 -105 -70 -105 -70 -3 57 c-3 68 -17 83 -75 83 -41 0 -75 14 -94 39 -9 12
    -12 109 -13 379 l0 363 25 24 24 25 455 0 455 0 28 -24z m-369 -1023 c51 -65
    107 -147 125 -183 l32 -65 3 -472 3 -473 -239 0 c-238 0 -240 0 -254 22 -69
    109 -66 269 7 380 45 68 40 77 -119 238 -87 88 -147 156 -151 173 -17 65 36
    137 100 137 20 0 46 -5 58 -11 12 -6 104 -93 204 -192 l183 -182 37 38 38 37
    -93 93 -92 92 0 243 0 242 33 0 c31 0 41 -9 125 -117z m-1461 -385 c14 -16 35
    -18 150 -18 149 0 182 -9 202 -58 9 -20 11 -91 9 -236 l-3 -208 -28 -24 -28
    -24 -499 0 -499 0 -28 24 -28 24 -3 208 c-2 145 0 216 9 236 22 53 45 58 296
    59 l228 0 100 68 100 68 3 -51 c2 -28 10 -58 19 -68z"
      />
      <path
        d="M2045 2286 c-77 -34 -124 -114 -125 -211 0 -96 29 -135 200 -271 80
    -63 155 -117 167 -120 18 -5 40 7 95 50 271 207 292 233 292 346 -1 194 -199
    289 -346 165 l-30 -25 -37 30 c-21 16 -50 34 -65 40 -38 14 -115 12 -151 -4z
    m107 -95 c13 -5 45 -27 73 -50 27 -22 59 -41 71 -41 12 0 47 20 77 44 66 54
    94 63 136 42 54 -28 76 -102 49 -162 -7 -14 -68 -70 -137 -125 -107 -84 -128
    -96 -141 -85 -8 7 -62 50 -120 95 -137 107 -161 150 -125 225 15 30 58 65 82
    66 6 0 22 -4 35 -9z"
      />
      <path d="M280 800 l0 -50 330 0 330 0 0 50 0 50 -330 0 -330 0 0 -50z" />
      <path d="M1030 800 l0 -50 95 0 95 0 0 50 0 50 -95 0 -95 0 0 -50z" />
      <path d="M280 610 l0 -50 145 0 145 0 0 50 0 50 -145 0 -145 0 0 -50z" />
      <path d="M650 610 l0 -50 285 0 285 0 0 50 0 50 -285 0 -285 0 0 -50z" />
    </g>
  </svg>
);

export default PaidSocialIcon;
