import { paid_social } from './metadata/blue/paid-social.js';
import { email } from './metadata/blue/email.js'
import { paid_search } from './metadata/blue/paid-search';
import { display } from './metadata/blue/display';
import { programmatic } from './metadata/blue/programmatic';
import { user_management } from './metadata/blue/user-management';
import { attr_values } from './metadata/blue/attr-and-value';
import { domo_integration } from './metadata/blue/domo-integration';
import { facebook_integration } from './metadata/blue/facebook-integration';



var moment = require('moment');
const abbrev_regex = 'regex:/^\\w+$/';
const extra_regex = 'regex:/^[a-zA-Z0-9_]+$/';

export const blueMetadata = {
  // don't change codenames!
  channels: [
    {
      name: 'Affiliate',
      endpoint: 'affiliate',
      codename: 'affiliate',
      abbrev: 'affiliate',
      icon: 'Affiliate',
      disabled: true,
      showViewIcon: true,
      models: [
        {
          name: 'Affiliate Campaigns',
          singular: 'campaign',
          endpoint: 'affiliate-campaigns',
          permListAndView: 20,
          permCreateAndEdit: 20,
          permDelete: 20,
          route: 'campaigns',
        },
      ],
    },
  ].concat(paid_social, email, paid_search, display, programmatic, user_management, attr_values, domo_integration, facebook_integration),
};

