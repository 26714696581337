import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, disableSubmitButton, dateField, dateFilter, domoFields, formFields, getBrandedProperty, getInstanceUrl, generateFilterLabel, getModelUrl, getRelationId, getPlatformList, HeadedCamelCase, inputTextFilter, isDisabled, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');


export const paid_social = {
  abbrev: 'PaidSocial',
  codename: 'paidSocial',
  endpoint: 'paid-social',
  icon: 'PaidSocial',
  id: 1,
  name: 'Paid Social',
  showViewIcon: true,
  models: [
    //    ___   __   _  _  ____   __   __  ___  __ _  ____
    //   / __) / _\ ( \/ )(  _ \ / _\ (  )/ __)(  ( \/ ___)
    //  ( (__ /    \/ \/ \ ) __//    \ )(( (_ \/    /\___ \
    //   \___)\_/\_/\_)(_/(__)  \_/\_/(__)\___/\_)__)(____/

    {
      allFilterData: (apiStore) => apiStore['socialCampaignsFilterOptions'],
      codename: 'SocCamp',
      controller: 'social_campaign',
      domoNsType: 'SocialCampaign',
      endpoint: 'social-campaigns',
      filterOptionsCall: (apiStore) => (val) => apiStore['socialCampaignsFilterOptions'] = val,
      form: () => <GenericForm type="Campaign" datePicker={2} numButtons={3} />,
      genericModelPromiseEndpoints: [
        'ad-sets',
        'campaign-types',
        'campaign-type-channels',
        'campaign-type-objectives',
        'channel-objectives',
        'channel-platforms',
        'objectives',
        'platforms',
        'statuses',
        'users',
      ],
      hasChildren: true,
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.newCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Campaigns',
      permCreateAndEdit: 10,
      permDelete: 20,
      permListAndView: 20,
      permRequest: false,
      route: 'campaigns',
      send2domo: true,
      singular: 'campaign',
      showFormat: 'definitionList',
      tabData: (apiStore) => apiStore.genericTabPromiseResults(),
      tabPromiseEndpoints: (rootStore, brand_param) => {
        const allFilter = _.merge(
          brand_param,
          rootStore.paidSocial.SocAdSet.modelFilter(rootStore),
        );
        return [
          rootStore.newCampaignsCrud.getTotal(brand_param),
          rootStore.newAdsetsCrud.getTotal(allFilter, ''),
        ];
      },
      tabIndex: 0,
      tabLabel: (m) => {
        return m.crudStore.selectedRowIds.length > 0
          ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)`
          : m.name
      },
      tabDisabled: () => false,
      noDataText: (root) => '',
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      crud: (rootStore) => rootStore.newCampaignsCrud,
      columns: [

        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 10,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          show: true,
          show_order: 10,
          show_value: (row) => row.id,
          type: 'hidden',
          utaType: 'id',
        },

        {
          colSize: 6,
          // domo_field: 'nsid',
          // domo_value: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 20,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 1000,
          show: true,
          show_order: 1,
          show_value: (row) => row.namestring_id,
          utaType: 'namestring_id'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'SocialCampaign',
          label: 'ns_type',
          show: true,
          show_order: 30,
          show_value: (row) => 'SocialCampaign',
          rules: 'required',
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          filter: false,
          field: 'namestring',
          format: 'action',
          grid: true,
          grid_order: 40,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
        },
        {
          form: 'namestring_string',
          label: 'Namestring',
          name: 'namestring_string',
          formOrder: 50,
          rules: 'required',
          type: 'hidden',
          show: true,
          show_order: 50,
          show_value: (row) => row.namestring_string,
          utaType: 'string',
        },

        {
          colSize: 8,
          domo_field: 'status',
          domo_value: () => 'static',
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 60,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show: true,
          show_order: 60,
          show_value: (row) => row.status.name,
          utaType: 'fk',
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status Id',
          rules: 'required|integer',
          show: true,
          show_order: 70,
          show_value: (row) => row.status_id,
          type: 'hidden',
          utaType: 'status',
        },

        {
          label: 'Domo Push Date',
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
          show: false,
          show_order: 80,
          show_value: (row) => row.push_date,
        },

        {
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: (crudStore) => crudStore.props.rootStore.apiStore.currentBrand.enable_fb,
          grid_link: 'facebook-campaigns',
          grid_order: 90,
          grid_value: (row) => row.facebook_id,
          id: 'facebook_id',
          label: 'facebook_id',
          name: 'facebook_id',
          ns: (crudStore) => crudStore.storedData.facebook_id,
          permListAndView: 20,
          rules: 'integer',
          show: false,
          show_order: 90,
          show_value: (row) => row.facebook_id,
          utaType: 'string',
        },
        {
          grid: false,
          id: 'facebook_detail',
          label: 'facebook_detail',
          name: 'facebook_detail',
          ns: (crudStore) => crudStore.storedData.facebook_detail,
          rules: 'integer',
          show: false,
          show_order: 100,
          show_value: (row) => row.facebook_detail,
          utaType: 'string',
        },
        {
          grid: false,
          id: 'facebook_created_at',
          label: 'facebook_created_at',
          name: 'facebook_created_at',
          ns: (crudStore) => crudStore.storedData.facebook_created_at,
          rules: 'date',
          show: false,
          show_order: 110,
          show_value: (row) => row.facebook_created_at,
          utaType: 'date',
        },
        {
          label: 'Facebook Push Date',
          id: 'facebook_updated_at',
          name: 'facebook_updated_at',
          ns: (crudStore) => crudStore.storedData.facebook_updated_at,
          show: false,
          show_order: 120,
          show_value: (row) => row.facebook_updated_at,
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          form: 'group',
          label: 'Group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          ns_order: 130,
          show: true,
          show_order: 130,
          show_value: (row) => abbrev(row.group),
          utaType: 'group',
        },
        {
          form: 'group_id',
          label: 'Group Id',
          rules: 'required',
          show: true,
          show_value: (row) => row.group.id,
          show_order: 140,
          type: 'hidden',
          utaType: 'group',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          form: 'brand',
          label: 'Brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 150,
          show: true,
          show_order: 150,
          show_value: (row) => `${row.brand?.name} (${row.brand?.abbrev})`,
          utaType: 'brand',
        },
        {
          domo_field: 'brand_id',
          domo_value: (crudStore) => getRelationId(crudStore, 'brand'),
          form: 'brand_id',
          label: 'Brand Id',
          rules: 'required',
          type: 'hidden',
          show: true,
          show_value: (row) => row.brand?.id,
          show_order: 160,
          utaType: 'brand',
        },
        {
          domo_field: 'branded',
          domo_value: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded')),
          label: 'Branded',
          name: 'branded',
          ns: (crudStore) => getBrandedProperty(property(crudStore.storedData.brand, 'branded'), 'ns'),
          ns_order: 170,
          show: true,
          show_order: 170,
          show_value: (row) => row.brand.branded ? 'Branded (B)' : 'Unbranded (UB)',
          rules: 'required'
        },
        {
          domo_field: 'channel',
          domo_value: (crudStore) => crudStore.channel.abbrev,
          id: 'channel',
          form: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: (crudStore) => crudStore?.channel?.abbrev,
          ns_order: 180,
          show: true,
          show_value: (row) => 'Paid Social',
          show_order: 180,
          type: 'hidden',
          utaType: 'channel',
        },
        {
          domo_field: 'business_unit',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
          label: 'Business Unit',
          name: 'business_unit',
          show: true,
          show_order: 190,
          show_value: (row) => `${row.brand?.business_unit?.name} (id: ${row.brand?.business_unit?.id}, abbrev: ${row.brand?.business_unit?.abbrev})`,
          rules: 'required'
        },
        {
          domo_field: 'area',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
          name: 'area',
          label: 'Therapeutic Area',
          show: true,
          show_order: 200,
          show_value: (row) => `${row.brand?.area?.name} (id: ${row.brand?.area?.id}, abbrev: ${row.brand?.area?.abbrev})`,
          rules: 'required'
        },
        {
          colSize: 10,
          copyEditable: true,
          domo_field: 'campaign_name',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          form: 'name',
          formField: (rootStore) => (<UccInputText
            label="Description"
            core="name"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
            fieldName="name"
          />),
          formOrder: 210,
          grid: true,
          grid_order: 210,
          grid_value: (row) => row.name,
          grid_label: 'Description',
          filter_placeholder: 'Description',
          id: 'name',
          name: 'name',
          filter: (dt, col) => inputTextFilter(dt, col),
          label: 'Description',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          ns_order: 210,
          utaType: 'name',
          rules: 'string',
          show: true,
          show_order: 210,
          show_value: (row) => row.name,
        },
        {
          colSize: 12,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          grid: true,
          grid_order: 220,
          grid_value: (row) => property(row.campaign_type, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          // options: (apiStore) => apiStore.campaignTypes;
          filter_placeholder: 'Type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          ns_order: 220,
          options: (apiStore) => apiStore.campaignTypes,
          show: true,
          show_order: 220,
          show_value: (row) => `${property(row.campaign_type, "name")} (abbrev: ${row.campaign_type?.abbrev})`,
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
          copyEditable: true,
          form: 'campaign_type_id',
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            label={'Campaign Type'}
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 230,
          name: 'campaign_type_id',
          label: 'Campaign Type Id',
          rules: 'required|integer',
          show: true,
          show_order: 230,
          show_value: (row) => row.campaign_type_id,
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 240,
          grid_value: (row) => property(row.objective, 'name'),
          id: 'objective',
          label: 'Objective',
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'objective',
          ns: (crudStore) => abbrev(crudStore.storedData.objective),
          ns_order: 240,
          rules: 'required',
          options: (apiStore) => apiStore.objectives,
          show: true,
          show_order: 240,
          show_value: (row) => `${property(row.objective, "name")} (abbrev: ${row.objective?.abbrev})`,
        },
        {
          belongsTo: ['objective', 'objectives'],
          copyEditable: true,
          form: 'objective_id',
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            label={'Objective'}
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.makeObjectiveOptions()}

            // menuItems={rootStore.uiStore.makeObjectiveOptions()}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 250,
          label: 'Objective Id',
          rules: 'required|integer',
          show: true,
          show_order: 250,
          show_value: (row) => row.objective_id,
          utaType: 'fk',
        },
        {
          colSize: 10,
          copyEditable: true,
          domo_field: 'primary_kpi',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.primary_kpi),
          form: 'primary_kpi',
          formField: (rootStore) => (<UccInputText
            label="Primary KPI"
            core="primary_kpi"
            form={rootStore.channel.model.crudStore.form}
            onInput={(event) => rootStore.channel.model.crudStore.storeData('primary_kpi', event)}
            fieldName="primary_kpi"
          />),
          formOrder: 260,
          grid: true,
          grid_order: 260,
          grid_value: (row) => row.primary_kpi,
          grid_label: 'Primary KPI',
          filter_placeholder: 'Primary KPI',
          id: 'primary_kpi',
          name: 'primary_kpi',
          filter: (dt, col) => inputTextFilter(dt, col),
          label: 'Primary KPI',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.primary_kpi),
          ns_order: 260,
          utaType: 'name',
          rules: 'string',
          show: true,
          show_order: 260,
          show_value: (row) => row.primary_kpi,
        },

        {
          domo_field: 'platform',
          domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
          grid: true,
          grid_order: 270,
          grid_value: (row) => property(row.platform, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.platforms,
          id: 'platform',
          label: 'Platform',
          name: 'platform',
          ns: (crudStore) => abbrev(crudStore.storedData.platform),
          ns_order: 270,
          show: true,
          show_order: 270,
          show_value: (row) => `${property(row.platform, "name")} (abbrev: ${row.platform?.abbrev})`,
          rules: 'required',
        },
        {
          belongsTo: ['platform', 'platforms'],
          colSize: 8,
          copyEditable: true,
          form: 'platform_id',
          formField: (rootStore) => (<UccSelect
            core="platform"
            fieldName="platform_id"
            label="Platform"
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.menuItems('platforms', 'makePrimeChannelSpecificOption', 'channelPlatforms')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
          />),
          formOrder: 280,
          label: 'Platform',
          rules: 'required|integer',
          show: true,
          show_order: 280,
          show_value: (row) => row.platform_id,
          utaType: 'fk',
        },
        {
          colSize: 10,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid: true,
          grid_order: 400,
          grid_value: (row) => property(row.user, 'name'),
          id: 'created_by',
          label: 'Created By',
          name: 'created_by',
          ns: () => 'user',
          options: (apiStore) => apiStore.Users,
          rules: 'required',
          show: true,
          show_order: 290,
          show_value: (row) => `${property(row.user, 'name')} (id: ${row.user?.id}, email: ${row.user.email})`,
          utaType: 'user',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          show: true,
          show_order: 280,
          show_value: (row) => !!row.created_at ? moment(row.created_at).format('MM/DD/YYYY') : '',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', false, 310, 310, 'S.',),
        dateField('end_date', 'End Date', 'blank', false, 320, 320, 'E.'),
      ),
    },

    //    ___      _ _____      _
    //   / _ \    | /  ___|    | |
    //  / /_\ \ __| \ `--.___ | | _ ___
    //  |  _  |/ _` |`--. \/ _ \ __/ __|
    //  | | | | (_| /\__/ /  __/ |_\__ \
    //  \_| |_/\__,_\____/ \___|\__|___/

    {
      allFilterData: (apiStore) => apiStore['adSetsFilterOptions'],
      codename: 'SocAdSet',
      controller: 'social_ad_set',
      crud: (rootStore) => rootStore.newAdsetsCrud,
      domoNsType: 'SocialAdSet',
      filterOptionsCall: (apiStore) => (val) => apiStore['adSetsFilterOptions'] = val,
      endpoint: 'ad-sets',
      form: () => <GenericForm type="Adset" datePicker={2} numButtons={3} />,
      genericModelPromiseEndpoints: [
        'ad-locations',
        'ad-types',
        'ad-type-channels',
        'campaign-types',
        'channel-genders',
        'channel-platforms',
        'channel-tactics',
        'channel-targetings',
        'devices',
        'genders',
        'geos',
        'languages',
        'objectives',
        'platforms',
        'social-campaigns',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      hasChildren: true,
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.newAdsetsCrud.getFilterOptions({ brand_id: brand_id }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId, social_campaign_id: root.paidSocial.SocCamp.crudStore.selectedRowIds, }; },
      name: 'AdSets',
      noDataText: (r) => r.paidSocial.SocCamp.crudStore.selectedRowIds.length > 0 ? ' for the selected Campaign(s)' : '',
      parentModel: 'social_campaign',
      parentModelCode: 'SocCamp',
      permCreateAndEdit: 10,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'ad-sets',
      send2domo: true,
      singular: 'adset',
      showFormat: 'definitionList',
      tabData: (apiStore) => apiStore.genericTabPromiseResults(),
      tabPromiseEndpoints: (rootStore, brand_param) => {
        const allFilter = _.merge(
          brand_param,
          rootStore.paidSocial.SocAdSet.modelFilter(rootStore),
        );
        return [
          rootStore.newCampaignsCrud.getTotal(brand_param),
          rootStore.newAdsetsCrud.getTotal(allFilter, ''),
        ];
      },
      tabDisabled: (r) => r.apiStore.adsetTabDisabled ? true : false,
      tabIndex: 1,
      tabLabel: (m) => {
        return m.crudStore.selectedRowIds.length > 0 ?
          `${m.name} (${m.crudStore.selectedRowIds.length} selected)` :
          m.name;
      },
      updateCallback: (root) => root.channel.SocAd.crudStore.adsetOptions = [],
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 10,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          show: true,
          show_order: 20,
          show_value: (row) => row.id,
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 20,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 1000,
          show: true,
          show_order: 10,
          show_value: (row) => row.namestring_id,
          utaType: 'namestring_id'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'SocialAdSet',
          label: 'NS Type',
          rules: 'required',
          show: true,
          show_order: 30,
          show_value: (row) => 'SocialAdSet'
        },

        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 40,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          formOrder: 50,
          label: 'Namestring',
          name: 'namestring_string',
          rules: 'required',
          show: true,
          show_order: 50,
          show_value: (row) => row.namestring_string,
          type: 'hidden',
          utaType: 'string'

        },

        {
          domo_field: 'status',
          domo_value: () => 'static',
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 60,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          show: true,
          show_order: 60,
          show_value: (row) => `${row.status.name} (id: ${row.status.id})`,
          utaType: 'fk',
        },


        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          type: 'hidden',
          utaType: 'status',
        },

        {
          label: 'Domo Push Date',
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
          show: true,
          show_order: 80,
          show_value: (row) => row.push_date,

        },

        {
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: (crudStore) => crudStore.props.rootStore.apiStore.currentBrand.enable_fb,
          grid_link: 'facebook-adsets',
          grid_order: 90,
          grid_value: (row) => row.facebook_id,
          id: 'facebook_id',
          label: 'facebook_id',
          name: 'facebook_id',
          ns: (crudStore) => crudStore.storedData.facebook_id,
          permListAndView: 20,
          rules: 'integer',
          show: true,
          show_order: 90,
          show_value: (row) => row.enable_fb,
          utaType: 'string',
        },
        {
          grid: false,
          id: 'facebook_detail',
          label: 'facebook_detail',
          name: 'facebook_detail',
          ns: (crudStore) => crudStore.storedData.facebook_detail,
          rules: 'integer',
          show: true,
          show_order: 100,
          show_value: (row) => row.facebook_detail,
          utaType: 'string',
        },
        {
          grid: false,
          id: 'facebook_created_at',
          label: 'facebook_created_at',
          name: 'facebook_created_at',
          ns: (crudStore) => crudStore.storedData.facebook_created_at,
          rules: 'date',
          show: true,
          show_order: 110,
          show_value: (row) => row.facebook_created_at,
          utaType: 'date',
        },
        {
          label: 'Facebook Push Date',
          id: 'facebook_updated_at',
          name: 'facebook_updated_at',
          ns: (crudStore) => crudStore.storedData.facebook_updated_at,
          show: false,
          show_order: 120,
          show_value: (row) => row.facebook_updated_at,
        },

        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          label: 'Group',
          name: 'group',
          ns: () => 'group',
          rules: 'required',
          show: true,
          show_order: 130,
          show_value: (row) => `${row.social_campaign?.group?.name} (id: ${row.social_campaign?.group?.id} abbrev: ${abbrev(row.social_campaign?.group)})`,
          utaType: 'group',
        },

        // { group_id 140 },

        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          label: 'Brand',
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          ns_order: 140,
          show: true,
          show_order: 140,
          show_value: (row) => `${row.social_campaign?.brand?.name} (id: ${row.social_campaign?.brand?.id} abbrev: ${abbrev(row.social_campaign?.brand)})`,
          utaType: 'brand',
        },
        {
          copyEditable: false,
          domo_field: 'brand_id',
          domo_value: (crudStore) => getRelationId(crudStore, 'brand'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },

        {
          domo_field: 'branded',
          domo_value: (crudStore) => getBrandedProperty(property(crudStore.rootStore.apiStore.currentBrand, 'branded')),
          label: 'Branded',
          name: 'branded',
          ns: (crudStore) => getBrandedProperty(property(crudStore.rootStore.apiStore.currentBrand, 'branded'), 'ns'),
          ns_order: 150,
          rules: 'required',
          show: true,
          show_order: 150,
          show_value: (row) => row.brand?.branded ? 'B' : 'UB',

        },

        {
          domo_field: 'business_unit',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'business_unit'), 'name'),
          label: 'Business Unit',
          name: 'business_unit',
          rules: 'required',
          show: true,
          show_order: 160,
          show_value: (row) => `${row.social_campaign?.brand?.business_unit?.name} (id: ${row.social_campaign?.brand?.business_unit?.id}, abbrev: ${row.social_campaign?.brand?.business_unit?.abbrev})`,
        },
        {
          domo_field: 'area',
          domo_value: (crudStore) => property(property(crudStore.storedData.brand, 'area'), 'name'),
          label: 'Therapeutic Area',
          name: 'area',
          rules: 'required',
          show: true,
          show_order: 170,
          show_value: (row) => `${row.social_campaign?.brand?.area?.name} (id: ${row.social_campaign?.brand?.area?.id}, abbrev: ${row.social_campaign?.brand?.area?.abbrev})`,
        },
        {
          domo_field: 'channel',
          domo_value: () => 'PaidSocial',
          label: 'Channel',
          ns: () => 'hmm',
          name: 'channel',
          id: 'channel',
          rules: 'required',
          show: true,
          show_order: 180,
          show_value: (row) => 'PaidSocial',
          utaType: 'channel',
        },
        {
          belongsTo: ['social_campaign', 'socialCampaigns'],
          form: 'social_campaign_id',
          label: 'Campaign NSID',
          rules: 'required|integer',
          utaType: 'fk',
          copyEditable: false,
          formField: (rootStore) => (<UccSelect
            core="social_campaign"
            fieldName="social_campaign_id"
            label="Social Campaign"
            // disabled={true}
            disabled={rootStore.paidSocial?.SocCamp?.selectedRowIds?.length == 1 && rootStore.channel.model.crudStore.initialAction == 'bulk_create'}
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.channel.model.crudStore.makePrimeCampaignOptionsArray(_.sortBy(rootStore.apiStore.socialCampaigns, c => - c.namestring_id))}
            onChange={(event) => {
              disableSubmitButton(rootStore, event);
              rootStore.channel.model.crudStore.storeData('social_campaign_id', event);
              if (rootStore.channel.model.crudStore.firstPage || rootStore.channel.model.crudStore.storedData['unique_attribute'] != 'social_campaign_id') {
                rootStore.channel.model.crudStore.storeData('platform_id', property(property(rootStore.paidSocial.SocAdSet.crudStore.storedData['social_campaign'], 'platform'), 'id'));
              }
            }
            }
          />),
          formOrder: 1,
          show: true,
          show_order: 190,
          show_value: (row) => row.social_campaign?.namestring_id,
          subType: 'inherit',
        },
        {
          label: 'Social Campaign Namestring',
          name: 'social_campaign',
          ns: (crudStore) => 'PARENT',
          // show: true,
          // show_order: 200,
          // show_value: (row) => row.social_campaign?.namestring_string,
        },
        {
          colSize: 14,
          grid: true,
          grid_order: 210,
          grid_value: (row) => property(row.social_campaign, 'namestring_string'),
          grid_type: 'namestring',
          grid_label: 'Campaign Namestring',
          id: 'a.b.namestring_string',
          label: 'Campaign Namestring',
          name: 'social_campaign_namestring',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|integer',
          show: true,
          show_order: 210,
          show_value: (row) => row.social_campaign?.namestring_string,
          utaType: 'integer',
        },
        {
          colSize: 12,
          copyEditable: true,
          domo_field: 'campaign_name',
          domo_value: (crudStore) => property(crudStore.storedData.social_campaign, 'name'),
          grid: true,
          grid_order: 220,
          grid_value: (row) => `${property(row.social_campaign, 'name')} #${row.social_campaign.namestring_id}`,
          filter: (dt, col) => multiSelectFilter(dt, col),
          filter_label: (id, name) => generateFilterLabel(id, name),
          options: (apiStore) => apiStore.socialCampaigns,
          id: 'social_campaign',
          label: 'Campaign Descr',
          name: 'campaign_name',
          ns: () => 'PARENT',
          utaType: 'integer',
          show: true,
          show_value: (row) => `${property(row.social_campaign, 'name')}`,
          show_order: 220,
        },
        {
          colSize: 10,
          copyEditable: true,
          domo_field: 'adset_name',
          domo_value: (crudStore) => crudStore.storedData.name,
          filter_placeholder: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'name',
          formField: (rootStore) => (
            <UccInputText
              label="Description"
              core="campaign_name"
              form={rootStore.channel.model.crudStore.form}
              onInput={(event) => rootStore.channel.model.crudStore.storeData('name', event)}
              fieldName="name"
            />),
          formOrder: 230,
          grid: true,
          grid_order: 230,
          grid_value: (row) => row.name,
          id: 'name',
          label: 'Description',
          name: 'name',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          ns_order: 230,
          rules: 'string',
          show: true,
          show_value: (row) => row.name,
          show_order: 230,
          utaType: 'name',
        },
        {
          domo_field: 'campaign_type',
          domo_value: (crudStore) => parentAbbrev(crudStore.storedData.social_campaign, 'campaign_type'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => parentAbbrev(crudStore.storedData.social_campaign, 'campaign_type'),
          ns_order: 240,
          options: (apiStore) => apiStore.campaignTypes,
          show: true,
          show_order: 240,
          show_value: (row) => `${row.social_campaign?.campaign_type?.name} (id: ${row.social_campaign?.campaign_type?.id} , abbrev: ${row.social_campaign?.campaign_type?.abbrev})`,
          utaType: 'parent',
        },
        {
          domo_field: 'objective',
          domo_value: (crudStore) => parentAbbrev(crudStore.storedData.social_campaign, 'objective'),
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          ns: (crudStore) => parentAbbrev(crudStore.storedData.social_campaign, 'objective'),
          ns_order: 250,
          show: true,
          show_order: 250,
          show_value: (row) => `${row.social_campaign?.objective?.name} (id: ${row.social_campaign?.objective?.id} , abbrev: ${row.social_campaign?.objective?.abbrev})`,
          utaType: 'parent',
        },
        {
          domo_field: 'primary_kpi',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.social_campaign?.primary_kpi),
          id: 'primary_kpi',
          label: 'Primary KPI',
          name: 'primary_kpi',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.social_campaign?.primary_kpi),
          ns_order: 260,
          show: true,
          show_order: 260,
          show_value: (row) => `${row.social_campaign?.primary_kpi} (${HeadedCamelCase(row.social_campaign?.primary_kpi)})`,
          utaType: 'parent',
        },
        {
          colSize: 8,
          domo_field: 'ad_location',
          domo_value: (crudStore) => abbrev(crudStore.storedData.ad_location),
          grid: true,
          grid_order: 270,
          grid_value: (row) => property(row.ad_location, 'name'),
          id: 'ad_location',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.ad_locations,
          label: 'Ad Location',
          name: 'ad_location',
          ns: (crudStore) => abbrev(crudStore.storedData.ad_location),
          ns_order: 270,
          rules: 'required|integer',
          sortable: true,
          show: true,
          show_value: (row) => `${row.ad_location?.name} (id: ${row.ad_location.id} , abbrev: ${row.ad_location.abbrev})`,
          show_order: 270,
        },
        {
          belongsTo: ['ad_location', 'adLocations'],
          copyEditable: true,
          form: 'ad_location_id',
          formField: (rootStore) => (<UccSelect
            core="ad_location"
            fieldName="ad_location_id"
            form={rootStore.channel.model.crudStore.form}
            label="Ad Location"
            // menuItems={rootStore.apiStore.ad_locations.map((p) => ({ label: p.name, value: p.id }))}
            menuItems={rootStore.apiStore.adLocations.map((ad_location) => rootStore.uiStore.makePrimeRegularOption(ad_location))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('ad_location_id', event)}
          />),
          formOrder: 280,
          label: 'Ad Locations',
          rules: 'required|integer',
          show: false,
          utaType: 'fk',
        },
        {
          domo_field: 'platform',
          domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
          grid: true,
          default: (rootStore) => property(rootStore.paidSocial.SocCamp.crudStore.selectedRow, 'platform'),
          grid_order: 290,
          grid_value: (row) => property(row.platform, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.platforms,
          id: 'platform',
          label: 'Platform',
          name: 'platform',
          ns: (crudStore) => abbrev(crudStore.storedData.platform),
          ns_order: 290,
          rules: 'required',
          show: true,
          show_order: 290,
          show_value: (row) => `${row.platform?.name} (id: ${row.platform.id} , abbrev: ${row.platform.abbrev}, parent's platform: ${row.social_campaign?.platform?.name})`,
          utaType: 'platforms',
        },
        {
          belongsTo: ['platform', 'platforms'],
          copyEditable: (rootStore) => { return !isDisabled(getPlatformList(rootStore.paidSocial.SocAdSet.crudStore.selectedRows)) },
          // copyEditable: true,
          default: (rootStore) => property(property(rootStore.paidSocial.SocCamp.crudStore.selectedRow, 'platform'), 'id'),
          defaultType: 'platforms',
          form: 'platform_id',
          formField: (rootStore) => (<UccSelect
            core="platform"
            fieldName="platform_id"
            label="Platform"
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.makePlatformOptions(rootStore, ['bulk_edit', 'copy_edit'].includes(rootStore.paidSocial.SocAdSet.crudStore.toolbarAction) ? getPlatformList(rootStore.paidSocial.SocAdSet.crudStore.selectedRows) : property(rootStore.paidSocial.SocAdSet.crudStore.storedData['social_campaign'], 'platform'))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
            disabled={isDisabled(['bulk_edit', 'copy_edit'].includes(rootStore.paidSocial.SocAdSet.crudStore.toolbarAction) ? getPlatformList(rootStore.paidSocial.SocAdSet.crudStore.selectedRows) : property(rootStore.paidSocial.SocAdSet.crudStore.storedData['social_campaign'], 'platform'))}
          />),
          formOrder: 300,
          label: 'Platform',
          rules: 'required|integer',
          show: false,
          show_value: (row) => property(row.platform, 'name'),
          show_order: 300,
          utaType: 'fk',
        },

        {
          domo_field: 'device',
          domo_value: (crudStore) => abbrev(crudStore.storedData.device),
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid: true,
          grid_order: 310,
          grid_value: (row) => property(row.device, 'name'),
          id: 'device',
          label: 'Device',
          name: 'device',
          ns: (crudStore) => abbrev(crudStore.storedData.device),
          ns_order: 310,
          options: (apiStore) => apiStore.devices,
          rules: 'required|integer',
          sortable: true,
          show: true,
          show_value: (row) => `${row.device?.name} (id: ${row.device.id} , abbrev: ${row.device.abbrev})`,
          show_order: 310,
        },
        {
          belongsTo: ['device', 'devices'],
          copyEditable: true,
          form: 'device_id',
          formField: (rootStore) => (
            <UccSelect
              core="device"
              fieldName="device_id"
              form={rootStore.channel.model.crudStore.form}
              label="Device"
              menuItems={rootStore.uiStore.menuItems('devices', 'makePrimeRegularOption')}
              onChange={(event) => rootStore.channel.model.crudStore.storeData('device_id', event)}
            />),
          formOrder: 320,
          label: 'Device',
          // multiple: [],
          rules: 'required|integer',
          show: false,
          utaType: 'fk',
        },

        {
          colSize: 8,
          domo_field: 'geo',
          domo_value: (crudStore) => abbrev(crudStore.storedData.geo),
          grid: true,
          grid_order: 330,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          label: 'Geo',
          name: 'geo',
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          ns_order: 330,
          rules: 'required|integer',
          sortable: true,
          show: true,
          show_value: (row) => `${row.geo?.name} (id: ${row.geo.id} , abbrev: ${row.geo.abbrev})`,
          show_order: 330,
        },
        {
          belongsTo: ['geo', 'geos'],
          copyEditable: true,
          form: 'geo_id',
          formField: (rootStore) => (<UccSelect
            core="geo"
            fieldName="geo_id"
            form={rootStore.channel.model.crudStore.form}
            label="Geo"
            menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('geo_id', event)}
          />),
          formOrder: 340,
          label: 'Geos',
          rules: 'required|integer',
          show: false,
          utaType: 'fk',
        },

        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          grid_order: 350,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 350,
          rules: 'required|integer',
          show: true,
          show_value: (row) => `${row.gender?.name} (id: ${row.gender.id} , abbrev: ${row.gender.abbrev})`,
          show_order: 350,
        },
        {
          belongsTo: ['gender', 'genders'],
          copyEditable: true,
          form: 'gender_id',
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={rootStore.uiStore.menuItems('genders', 'makePrimeChannelSpecificOption', 'channelGenders')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />
          ),
          formOrder: 360,
          label: 'Gender',
          rules: 'required|integer',
          show: false,
          utaType: 'fk',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 370,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          grid_value: (row) => property(row.language, 'name'),
          id: 'language',
          label: 'Language',
          name: 'language',
          ns: (crudStore) => abbrev(crudStore.storedData.language),
          ns_order: 370,
          show: true,
          show_value: (row) => `${row.language?.name} (id: ${row.language.id} , abbrev: ${row.language.abbrev})`,
          show_order: 370,
        },
        {
          belongsTo: ['language', 'languages'],
          copyEditable: true,
          form: 'language_id',
          formField: (rootStore) => (<UccSelect
            core="language"
            fieldName="language_id"
            form={rootStore.channel.model.crudStore.form}
            label="Language"
            menuItems={rootStore.uiStore.menuItems('languages', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('language_id', event)}
          />),
          formOrder: 380,
          label: 'Language',
          rules: 'integer|required',
          show: false,
          utaType: 'fk',
        },

        //targeting fields

        {
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 390,
          grid_value: (row) => property(row.tactic, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          id: 'tactic',
          label: 'Tactic',
          name: 'tactic',
          ns: (crudStore) => abbrev(crudStore.storedData.tactic),
          ns_order: 390,
          rules: 'required',
          show: true,
          show_value: (row) => `${row.tactic?.name} (id: ${row.tactic.id} , abbrev: ${row.tactic.abbrev})`,
          show_order: 390,
        },

        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (
            <UccSelect
              core="tactic"
              fieldName="tactic_id"
              label="Tactic"
              form={rootStore.channel.model.crudStore.form}
              menuItems={rootStore.uiStore.menuItems('tactics', 'makePrimeChannelSpecificOption', 'channelTactics')}
              onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
            />),
          formOrder: 400,
          label: 'Tactic',
          rules: 'required|integer',
          show: false,
          utaType: 'fk',
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.suppressionLabel(),
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          ns: (crudStore) => crudStore.suppressionLabel(),
          ns_order: 410,
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 410,
          type: 'checkbox',
          utaType: 'boolean',
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          form: 'social_ad_set_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
          formOrder: 420,
          grid: true,
          grid_order: 420,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'social_ad_set_targetings_attributes',
          ns: (crudStore) => NameString.targetingString(crudStore),
          ns_order: 420,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "social_ad_set_targetings"),
          show_order: 420,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'social_ad_set_targetings_attributes[].id',
        },
        {
          form: 'social_ad_set_targetings_attributes[].social_ad_set_id',
        },
        {
          form: 'social_ad_set_targetings_attributes[].targeting_id',
        },
        {
          form: 'social_ad_set_targetings_attributes[].extra',
        },
        {
          form: 'social_ad_set_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'social_ad_set_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'social_ad_set_targetings_attributes[].on',
        },

        {
          colSize: 10,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          name: 'created_by',
          grid: true,
          grid_order: 440,
          id: 'created_by',
          label: 'Created By',
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          show: true,
          show_order: 440,
          show_value: (row) => `${property(row.user, 'name')} (id: ${row.user?.id}, email: ${row.user.email})`,
          options: (apiStore) => apiStore.Users,
        },
        {
          id: 'created_at',
          label: 'Created At',
          name: 'created_at',
          ns: (crudStore) => crudStore.storedData.created_at,
          rules: 'date',
          show: true,
          show_order: 450,
          show_value: (row) => !!row.created_at ? moment(row.created_at).format('MM/DD/YYYY') : '',
          utaType: 'date',
        },
      ].concat(
        dateField('start_date', 'Start Date', 'curherit', true, 460, 460, 'S.'),
        dateField('end_date', 'End Date', 'blankherit', false, 470, 470, 'E.'),
      ),
    },

    //    _____       .___
    //   /  _  \    __| _/______
    //  /  /_\  \  / __ |/  ___/
    // /    |    \/ /_/ |\___ \
    // \____|__  /\____ /____  >
    //         \/      \/    \/

    {
      allFilterData: (apiStore) => apiStore['adsFilterOptions'],
      codename: 'SocAd',
      controller: 'social_ad',
      crud: (rootStore) => rootStore.adsCrud,
      domoNsType: 'SocialAd',
      endpoint: 'ads',
      filterOptionsCall: (apiStore) => (val) => apiStore['adsFilterOptions'] = val,
      form: () => <GenericForm type="Ad" datePicker={2} numButtons={3} />,
      genericModelPromiseEndpoints: [
        'ad-sets',
        'ad-types',
        'ad-type-channels',
        'campaign-types',
        'channel-targetings',
        'devices',
        'genders',
        'geos',
        'languages',
        'marketing-campaigns',
        'objectives',
        'platforms',
        'social-campaigns',
        'statuses',
        'tactics',
        'tactic-targetings',
        'targetings',
        'users',
      ],
      modelPromiseEndpoints: (apiStore, brand_id, adset_param) => ([
        apiStore.newAdsCrud.getFilterOptions({ brand_id: brand_id, ...adset_param }, ''),
        apiStore.newAdsetsCrud.getAll({ brand_id: brand_id, ...adset_param }, ''),
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
      ]),
      grandParentModel: 'social_campaign',
      modelFilter: (root) => _.merge({ social_adset_id: root.paidSocial.SocAdSet.crudStore.selectedRowIds, }, { social_campaign_id: root.paidSocial.SocCamp.crudStore.selectedRowIds, }, { brand_id: root.apiStore.currentBrandId }),
      name: 'Ads',
      noDataText: (r) => r.paidSocial.SocAdSet.crudStore.selectedRowIds.length > 0 ? ' for the selected AdSet(s)' : '',
      parentModel: 'social_ad_set',
      parentModelCode: 'SocAdSet',
      permCreateAndEdit: 10,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'ads',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'ad',
      tabData: (apiStore) => apiStore.genericTabPromiseResults(),
      tabPromiseEndpoints: (rootStore, brand_param) => {
        const allFilter = _.merge(
          brand_param,
          rootStore.paidSocial.SocAdSet.modelFilter(rootStore),
        );
        return [
          rootStore.newCampaignsCrud.getTotal(brand_param),
          rootStore.newAdsetsCrud.getTotal(allFilter, ''),
        ];
      },
      tabDisabled: (r) => (r.apiStore.adTabDisabled) ? true : false,
      tabIndex: 2,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      targeting: 'parent',
      columns: [
        {
          colSize: 5,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 10,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          show: true,
          show_value: (row) => row.id,
          show_order: 10,
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 6,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 20,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 1000,
          show: true,
          show_value: (row) => row.namestring_id,
          show_order: 20,
          utaType: 'namestring_id'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'SocialAd',
          label: 'NS Type',
          rules: 'required',
          show: true,
          show_value: (row) => 'SocialAd',
          show_order: 30,
        },
        {
          colSize: 7,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 40,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
        },
        {
          form: 'namestring_string',
          formOrder: 50,
          label: 'Namestring',
          name: 'namestring_string',
          rules: 'required',
          show: true,
          show_value: (row) => row.namestring_string,
          show_order: 50,
          type: 'hidden',
          utaType: 'string',
        },
        {
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 60,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
          show: true,
          show_value: (row) => `${row.status.name} (id: ${row.status.id})`,
          show_order: 60,
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'utmstring',
          domo_value: (crudStore) => NameString.getAdUtmString(crudStore),
          form: 'utmstring_string',
          formOrder: 90,
          label: 'UTM String',
          name: 'utmstring_string',
          rules: 'required',
          show: true,
          show_value: (row) => row.utmstring_string,
          show_order: 90,
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'short_utm_string',
          domo_value: (crudStore) => NameString.getNewUtmString(crudStore),
          form: 'short_utm_string',
          formOrder: 110,
          label: 'Short UTM String',
          name: 'short_utm_string',
          rules: 'required',
          show: true,
          show_value: (row) => row.short_utm_string,
          show_order: 110,
          type: 'hidden',
          utaType: 'string'
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 120,
          id: 'push_date',
          label: 'Domo Push Date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
          show: true,
          show_value: (row) => row.push_date,
          show_order: 120,
        },

        // {
        //   filter: (dt, col) => inputTextFilter(dt, col),
        //   grid: (crudStore) => crudStore.props.rootStore.apiStore.currentBrand.enable_fb,
        //   grid_link: 'facebook-ads',
        //   grid_order: 130,
        //   grid_value: (row) => row.facebook_id,
        //   id: 'facebook_id',
        //   label: 'facebook_id',
        //   name: 'facebook_id',
        //   permListAndView: 20,
        //   rules: 'integer',
        //   utaType: 'string',
        //   ns: (crudStore) => crudStore.storedData.facebook_id,
        // },
        // {
        //   grid: false,
        //   id: 'facebook_detail',
        //   label: 'facebook_detail',
        //   name: 'facebook_detail',
        //   rules: 'integer',
        //   utaType: 'string',
        //   ns: (crudStore) => crudStore.storedData.facebook_detail,
        // },
        // {
        //   grid: false,
        //   id: 'facebook_created_at',
        //   label: 'facebook_created_at',
        //   name: 'facebook_created_at',
        //   rules: 'date',
        //   utaType: 'date',
        //   ns: (crudStore) => crudStore.storedData.facebook_created_at,
        // },
        // {
        //   label: 'Facebook Push Date',
        //   id: 'facebook_updated_at',
        //   name: 'facebook_updated_at',
        //   ns: (crudStore) => crudStore.storedData.facebook_updated_at,
        //   show: false,
        //   show_order: 170,
        //   show_value: (row) => row.facebook_updated_at,
        // },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          label: 'Group',
          name: 'group',
          ns: () => 'group',
          utaType: 'group',
          rules: 'required',
          show: true,
          show_value: (row) => `${row.social_ad_set.social_campaign.group.name} (id: ${row.social_ad_set.social_campaign.group.id}, abbrev: ${abbrev(row.social_ad_set.social_campaign.group)})`,
          show_order: 180,
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.brand, 'name'),
          name: 'brand',
          label: 'Brand',
          ns: (crudStore) => abbrev(crudStore.brand),
          ns_order: 190,
          utaType: 'brand',
          show: true,
          show_value: (row) => `${row.social_ad_set.social_campaign.brand.name} (id: ${row.social_ad_set.social_campaign.brand.id}, abbrev: ${abbrev(row.social_ad_set.social_campaign.brand)})`,
          show_order: 190,
        },
        {

          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.brand, 'id'),
          name: 'brand_id',
          ns: () => 'brand_id',
          utaType: 'brand',
          rules: 'required',
        },
        {
          domo_field: 'branded',
          domo_value: (crudStore) => getBrandedProperty(property(crudStore.rootStore.apiStore.currentBrand, 'branded')),
          label: 'Branded',
          name: 'branded',
          ns: (crudStore) => getBrandedProperty(property(crudStore.rootStore.apiStore.currentBrand, 'branded'), 'ns'),
          ns_order: 210,
          rules: 'required',
          show: true,
          show_value: (row) => row.social_ad_set?.social_campaign?.brand?.branded ? 'Branded (B)' : 'Unbranded (UB)',
          show_order: 210,
        },
        {
          domo_field: 'business_unit',
          domo_value: (crudStore) => property(property(crudStore.brand, 'business_unit'), 'name'),
          label: 'Business Unit',
          name: 'business_unit',
          rules: 'required',
          show: true,
          show_value: (row) => `${row.social_ad_set?.social_campaign?.brand?.business_unit.name} (id: ${row.social_ad_set?.social_campaign?.brand?.business_unit.id}, abbrev: ${row.social_ad_set?.social_campaign?.brand?.business_unit.abbrev})`,
          show_order: 220,
        },
        {
          domo_field: 'area',
          domo_value: (crudStore) => property(property(crudStore.brand, 'area'), 'name'),
          label: 'Therapeutic Area',
          name: 'area',
          rules: 'required',
          show: true,
          show_value: (row) => `${row.social_ad_set?.social_campaign?.brand?.area.name} (id: ${row.social_ad_set?.social_campaign?.brand?.area.id}, abbrev: ${row.social_ad_set?.social_campaign?.brand?.area.abbrev})`,
          show_order: 230,
        },
        {
          domo_field: 'channel',
          domo_value: (crudStore) => crudStore.channel.abbrev,
          id: 'channel',
          label: 'Channel',
          name: 'channel',
          ns: (crudStore) => crudStore.channel.abbrev,
          rules: 'required',
          utaType: 'channel',
          show: true,
          show_value: (row) => 'Paid Social',
          show_order: 240,
        },

        {
          colSize: 12,
          grid: true,
          grid_order: 250,
          grid_value: (row) => property(row.social_ad_set.social_campaign, 'namestring_string'),
          grid_type: 'namestring',
          grid_label: 'Camp. Namestring',
          grand_parent_field: true,
          id: 'a.b.namestring_string',
          label: 'Campaign Namestring',
          name: 'social_campaign_namestring',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|integer',
          show: true,
          show_value: (row) => row.social_ad_set?.social_campaign?.namestring_string,
          show_order: 250,
          utaType: 'integer',
        },

        {
          colSize: 9,
          copyEditable: true,
          domo_field: 'campaign_name',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'name'),
          form: 'campaign_name',
          grid: false,
          grid_order: 260,
          grid_value: (row) => `${property(row.social_ad_set.social_campaign, 'name')} #${property(row.social_ad_set.social_campaign, 'namestring_id')}`,
          grid_label: 'Campaign',
          filter_placeholder: 'Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          filter_label: (id, name) => generateFilterLabel(id, name),
          options: (apiStore) => apiStore.socialCampaigns,
          id: 'social_campaign_id',
          name: 'name',
          // filter: (dt, col) => inputTextFilter(dt, col),
          label: 'Campaign Description',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.name),
          // ns_order: 6,
          show: true,
          show_value: (row) => HeadedCamelCase(row.social_ad_set.social_campaign.name),
          show_order: 260,
          utaType: 'name',
          rules: 'string',
        },

        {
          colSize: 12,
          grid: true,
          grid_order: 270,
          grid_value: (row) => property(row.social_ad_set, 'namestring_string'),
          grid_type: 'namestring',
          grid_label: 'Adset Namestring',
          id: 'namestring_string',
          label: 'Adset Namestring',
          name: 'social_adset_namestring',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.social_ad_set, 'namestring_string'),
          show_order: 270,
          utaType: 'integer',
        },

        {
          belongsTo: ['social_ad_set', 'adSets'],
          form: 'social_ad_set_id',
          copyEditable: true,
          bulkCreatable: false,
          formField: (rootStore) => (<UccSelect
            core="social_ad_set"
            fieldName="social_ad_set_id"
            form={rootStore.channel.model.crudStore.form}
            label="Ad Set"

            menuItems={rootStore.channel.model.crudStore.makePrimeAdsetOptions(_.sortBy(rootStore.apiStore.adSets, as => - as.namestring_id))}
            onChange={(event) => {
              disableSubmitButton(rootStore, event)
              isDisabled(property(rootStore.paidSocial.SocCamp.crudStore.selectedRow, 'platform'))
              rootStore.channel.model.crudStore.storeData('social_ad_set_id', event)
            }
            }
          />),
          formOrder: 280,
          label: 'AdSet',
          rules: 'required|integer',
          show: true,
          show_value: (row) => `NSID: ${row.social_ad_set?.namestring_id}, Id: ${row.social_ad_set?.id}`,
          show_order: 280,
          subType: 'inherit',
          utaType: 'fk',
        },
        {
          domo_field: 'adset_name',
          domo_value: (crudStore) => HeadedCamelCase(property(property(crudStore.storedData, 'social_ad_set'), 'name')),
          label: 'Adset Description',
          show: true,
          show_value: (row) => row.social_ad_set?.name,
          show_order: 285,
        },

        {
          grid: false,
          grid_order: 290,
          grid_value: (row) => `${property(row.social_ad_set, 'name')} #${property(row.social_ad_set, 'namestring_id')}`,
          id: 'social_ad_set',
          label: 'Ad Set',
          name: 'social_ad_set',
          filter: (dt, col) => multiSelectFilter(dt, col),
          filter_label: (id, name) => generateFilterLabel(id, name),
          options: (apiStore) => apiStore.adSets,
          ns: (crudStore) => HeadedCamelCase(property(crudStore.storedData.social_ad_set, 'name')),
          // ns_order: 290,
          rules: 'required|integer',
          // show: true,
          // show_value: (row) => `${property(row.social_ad_set, 'name')} #${property(row.social_ad_set, 'id')}`,
          // show_order: 290,
          utaType: 'integer',
        },

        {
          colSize: 10,
          copyEditable: true,
          domo_field: 'ad_modifier',
          domo_value: (crudStore) => crudStore.storedData.name,
          filter_placeholder: 'Name',
          filter: (dt, col) => inputTextFilter(dt, col),
          form: 'creative_name',
          formField: (rootStore) => (
            <UccInputText
              label="Ad Description"
              core="creative_name"
              form={rootStore.channel.model.crudStore.form}
              onInput={(event) => rootStore.channel.model.crudStore.storeData('creative_name', event)}
              fieldName="creative_name"
            />),
          formOrder: 300,
          grid: true,
          grid_order: 300,
          grid_value: (row) => row.creative_name,
          id: 'creative_name',
          label: 'Ad Description',
          name: 'creative_name',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.creative_name),
          ns_order: 300,
          rules: 'string',
          show: true,
          show_value: (row) => row.creative_name,
          show_order: 300,
          utaType: 'name',
        },

        {
          copyEditable: true,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'campaign_type'), 'name'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'campaign_type')),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          ns_order: 320,
          rules: 'required',
          show: true,
          show_value: (row) => `${row.social_ad_set?.social_campaign?.campaign_type?.name} (id: ${row.social_ad_set?.social_campaign?.campaign_type?.id}, abbrev: ${row.social_ad_set?.social_campaign?.campaign_type?.abbrev})`,
          show_order: 320,
          utaType: 'grandParent',
        },
        {
          copyEditable: true,
          domo_field: 'objective',
          domo_value: (crudStore) => property(property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'objective'), 'name'),
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          ns: (crudStore) => abbrev(property(property(crudStore.storedData.social_ad_set, 'social_campaign'), 'objective')),
          ns_order: 330,
          rules: 'required',
          show: true,
          show_value: (row) => `${row.social_ad_set?.social_campaign?.objective?.name} (id: ${row.social_ad_set?.social_campaign?.objective?.id}, abbrev: ${row.social_ad_set?.social_campaign?.objective?.abbrev})`,
          show_order: 330,
          utaType: 'grandParent',
        },
        {
          domo_field: 'primary_kpi',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.social_ad_set.social_campaign.primary_kpi),
          id: 'primary_kpi',
          label: 'Primary KPI',
          name: 'primary_kpi',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.social_ad_set?.social_campaign?.primary_kpi),
          ns_order: 340,
          show: true,
          show_value: (row) => row.social_ad_set?.social_campaign?.primary_kpi,
          show_order: 340,
          utaType: 'parent',
        },

        {
          domo_field: 'platform',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'platform'), 'name'),
          id: 'platform',
          label: 'Platform',
          name: 'platform',
          ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'platform')),
          ns_order: 350,
          rules: 'required',
          show: true,
          show_value: (row) => `${row.social_ad_set?.platform?.name} (id: ${row.social_ad_set?.platform?.id}, abbrev: ${row.social_ad_set?.platform?.abbrev})`,
          show_order: 350,
          utaType: 'platforms',
        },
        {
          domo_field: 'ad_location',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'ad_location'), 'name'),
          id: 'ad_location',
          label: 'Ad Location',
          name: 'ad_location',
          ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'ad_location')),
          ns_order: 360,
          rules: 'required',
          show: true,
          show_value: (row) => `${row.social_ad_set?.ad_location?.name} (id: ${row.social_ad_set?.ad_location?.id}, abbrev: ${row.social_ad_set?.ad_location?.abbrev})`,
          show_order: 360,
          utaType: 'parent',
        },
        {
          domo_field: 'device',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'device'), 'name'),
          id: 'device',
          label: 'Device',
          name: 'device',
          ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'device')),
          ns_order: 370,
          rules: 'required',
          show: true,
          show_value: (row) => `${row.social_ad_set?.device?.name} (id: ${row.social_ad_set?.device?.id}, abbrev: ${row.social_ad_set?.device?.abbrev})`,
          show_order: 370,
          utaType: 'parent',
        },
        {
          domo_field: 'geo',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'geo'), 'name'),
          id: 'geo',
          name: 'geo',
          label: 'Geo',
          ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'geo')),
          ns_order: 380,
          rules: 'required',
          show: true,
          show_value: (row) => `${row.social_ad_set?.geo?.name} (id: ${row.social_ad_set?.geo?.id}, abbrev: ${row.social_ad_set?.geo?.abbrev})`,
          show_order: 380,
          utaType: 'parent',
        },

        {
          domo_field: 'language',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'language'), 'name'),
          id: 'language',
          label: 'Language',
          name: 'language',
          ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'language')),
          ns_order: 390,
          show: true,
          show_value: (row) => `${row.social_ad_set?.language?.name} (id: ${row.social_ad_set?.language?.id}, abbrev: ${row.social_ad_set?.language?.abbrev})`,
          show_order: 390,
          utaType: 'parent',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'gender'), 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'gender')),
          ns_order: 390,
          rules: 'required',
          show: true,
          show_value: (row) => `${row.social_ad_set?.gender?.name} (id: ${row.social_ad_set?.gender?.id}, abbrev: ${row.social_ad_set?.gender?.abbrev})`,
          show_order: 390,
          utaType: 'parent',
        },

        {
          domo_field: 'tactic',
          domo_value: (crudStore) => property(property(crudStore.storedData.social_ad_set, 'tactic'), 'name'),
          id: 'tactic',
          label: 'Tactic',
          name: 'tactic',
          ns: (crudStore) => abbrev(property(crudStore.storedData.social_ad_set, 'tactic')),
          ns_order: 400,
          rules: 'required',
          show: true,
          show_value: (row) => `${row.social_ad_set?.tactic?.name} (id: ${row.social_ad_set?.tactic?.id}, abbrev: ${row.social_ad_set?.tactic?.abbrev})`,
          show_order: 400,
          utaType: 'parent',
        },

        {
          domo_field: 'suppression',
          domo_value: (crudStore) => property(crudStore.storedData.social_ad_set, 'suppression') ? 'SupOn' : 'SupOff',
          id: 'suppression',
          labe: 'Supression',
          name: 'suppression',
          ns: (crudStore) => property(crudStore.storedData.social_ad_set, 'suppression') ? 'SupOn' : 'SupOff',
          ns_order: 410,
          show: true,
          show_value: (row) => row.social_ad_set?.suppression ? 'On' : 'Off',
          show_order: 410,
          utaType: 'parent',
        },
        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          id: 'targeting_ids',
          label: 'Targeting',
          name: 'targeting',
          ns: (crudStore) => NameString.targetingString(crudStore),
          ns_order: 420,
          rules: 'required',
          show: true,
          show_value: (row) => NameString.pivotShow(row.social_ad_set, "social_ad_set_targetings"),
          show_order: 420,
          utaType: 'targeting',
        },
        {
          colSize: 13,
          domo_field: 'ad_modifier',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 425,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Marketing Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 425,
          rules: 'required',
          show: true,
          show_value: (row) => `${property(row.marketing_campaign, 'name')} (id: ${row.marketing_campaign.id}, abbrev: ${row.marketing_campaign.abbrev})`,
          show_order: 425,
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Name"
            label="Marketing Campaign"
            menuItems={rootStore.uiStore.menuItems('marketingCampaigns', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}

          />),
          formOrder: 425,
          label: 'Campaign Name (aka Marketing Campaign)',
          rules: 'required|integer',
          utaType: 'fk',
        },

        {
          colSize: 7,
          domo_field: 'ad_type',
          domo_value: (crudStore) => property(crudStore.storedData.ad_type, 'name'),
          grid: true,
          grid_order: 430,
          grid_value: (row) => property(row.ad_type, 'name'),
          id: 'ad_type',
          label: 'Ad Type',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.adTypes,
          name: 'ad_type',
          ns: (crudStore) => abbrev(crudStore.storedData.ad_type),
          ns_order: 430,
          show: true,
          show_value: (row) => `${property(row.ad_type, 'name')} (id: ${row.ad_type?.id}, abbrev: ${row.ad_type?.abbrev})`,
          show_order: 430,
          rules: 'required',
        },
        {
          belongsTo: ['ad_type', 'adTypes'],
          form: 'ad_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="ad_type"
            fieldName="ad_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Ad Type"
            menuItems={rootStore.uiStore.menuItems('adTypes', 'makePrimeChannelSpecificOption', 'adTypeChannels')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('ad_type_id', event)}
          />),
          formOrder: 440,
          label: 'Ad Type',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          form: 'brand_url',
          copyEditable: true,
          formField: (rootStore) => (
            <UccInputText
              core="brand-url"
              fieldName="brand_url"
              form={rootStore.channel.model.crudStore.form}
              label="Brand Url"
              onInput={(event) => rootStore.channel.model.crudStore.storeData('brand_url', event)}
            />),
          formOrder: 450,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 450,
          grid_value: (row) => row.brand_url,
          id: 'brand_url',
          label: 'Brand Url',
          name: 'brand_url',
          ns: () => 'stored',
          rules: 'string',
          show: true,
          show_value: (row) => row.brand_url,
          show_order: 450,
          utaType: 'text',
        },
        {
          colSize: 7,
          grid: true,
          grid_order: 500,
          grid_value: (row) => row.created_at,
          id: 'created_at',
          label: 'Created At',
          name: 'created_at',
          ns: (crudStore) => crudStore.storedData.created_at,
          rules: 'date',
          show: true,
          show_value: (row) => !!row.created_at ? moment(row.created_at).format('MM/DD/YYYY') : '',
          show_order: 500,
          utaType: 'date',
        },
        {
          colSize: 7,
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          grid: true,
          grid_order: 510,
          grid_value: (row) => property(row.user, 'name'),
          id: 'created_by',
          label: 'Created By',
          name: 'created_by',
          ns: () => 'user',
          options: (apiStore) => apiStore.Users,
          rules: 'required',
          show: true,
          show_value: (row) => `${property(row.user, 'name')} (id: ${row.user?.id}, email: ${row.user.email})`,
          show_order: 510,
          utaType: 'user',
        },
      ].concat(
        dateField('start_date', 'Start Date', 'curherit', true, 460, 460, 'S.'),
        dateField('end_date', 'End Date', 'blankherit', false, 470, 470, 'E.'),
      ),
    },
  ],
};