import CrudAdminStore from '../../store/CrudAdminStore';
import CrudChildTabs from './CrudChildTabs';
import CrudIndex from './CrudIndex';
import CrudShow from './CrudShow';
import NameString from './../NameString';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import storeMetadata, { permRequest } from '../../store/StoreMetadata';
import BulkCreateForm from './forms/BulkCreateForm';



const styles = theme => ({
  h2: {
    margin: '20px 0px 17px',
    paddingLeft: '23px',
  },
  paper: {
    marginBottom: theme.spacing(10),
    padding: '10px 10px 10px 10px',
    borderRadius: '10px',
    marginBottom: '2px'
  },
});

@inject('rootStore')
@observer
class CrudAdmin extends React.Component {

  refetch = () => {
    const crudStore = this.props.rootStore.channel.model.crudStore;
    if (crudStore.refetchDisplayedRows) crudStore.fetchDisplayedRows(crudStore.refetchDisplayedRows);
    if (crudStore.refetchDisplayedRows) crudStore.fetchTotalRecords(crudStore.refetchDisplayedRows);
  }

  getHeader = (model) => {
    const cs = model.crudStore;
    switch (cs.initialAction) {
      case 'new':
        return `New ${Pluralize.singular(model.name)}`
        break;
      case 'edit':
        return `Edit ${Pluralize.singular(model.name)}`
        break;
      case 'bulk_create':
        switch (cs.firstPage) {
          case true:
            return `New Bulk ${model.name}`
            break;
          default:
            return cs.bulkCrudStores.length > 1 ? `New ${Pluralize(model.name)}` : `New ${Pluralize.singular(model.name)}`
            break;
        }
        break;
      default:
        return `Edit ${Pluralize.singular(model.name)}`
    }
  }

  componentDidMount = () => {
    //nothing here master..
  }

  render() {
    const {
      rootStore, classes
    } = this.props;
    const model = rootStore.channel.model;
    const crudStore = model.crudStore;
    const header = this.getHeader(model);
    switch (crudStore.action) {
      case 'index':
        this.refetch();

        if (model.permListAndView > rootStore.apiStore.currentUser.pll) return (<>Not Authorized</>);
        return (
          <CrudIndex model={model} />
        );
      case 'show':
        if (model.permListAndView > rootStore.apiStore.currentUser.pll) return (<>Not Authorized</>);

        return (
          <>
            <CrudShow model={model} />
            {model.childModelEndpoints &&
              <CrudChildTabs model={model} channel={this.props.rootStore.channel} />
            }
          </>
        )
      case 'bulk_create':
        return (
          <Paper className={classes.paper} elevation={6}>
            <Grid item>
              <Typography className={classes.h2} component="h2" variant="h2" >
                {header}
              </Typography>
              <BulkCreateForm type={Pluralize.singular(model.name)} />
            </Grid>
          </Paper>
        )
      case 'edit':
      case 'new':
      default:
        if (model.permCreateAndEdit > rootStore.apiStore.currentUser.pll && !permRequest(rootStore)) return (<>Not Authorized</>);

        return (
          <>
            <Paper className={classes.paper} elevation={6}>
              <Grid item>
                <Typography className={classes.h2} component="h2" variant="h2" >{header}</Typography>
                {model.form(rootStore)}
              </Grid>
            </Paper>
          </>
        )
    }
  }
}

CrudAdmin.wrappedComponent.propTypes = {};

export default withStyles(styles)(CrudAdmin);

