import React, { Component } from 'react';
import { Button } from 'primereact/button';
import './PrimeStyle.css';
import 'primeicons/primeicons.css';


class UccButton extends Component {
  buttonStyle = (option = 'submit') => {
    switch (option) {
      case 'submit':
        return {
          borderColor: '#61CC8F',
          backgroundColor: '#61CC8F',
          width: this.props.width,
          height: '30px'
        }
      case 'draft':
        return {
          borderColor: '#6C7CDC',
          backgroundColor: '#6C7CDC',
          width: this.props.width ? this.props.width : '150px',
          fontSize: '16',
          height: '30px'
        }
      case 'deny':
        return {
          borderColor: '#FF5656',
          backgroundColor: '#FF5656',
          width: this.props.width ? this.props.width : '150px',
          height: '30px'
        }
      case 'icon':
        return {
          borderColor: '#6C7CDC',
          backgroundColor: '#FFFFFF',
          width: 'fit-content',
          color: '#6C7CDC',
          fontSize: '16px',
          fontWeight: 800,
          fontStretch: 'Heavy',
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: 'normal',
          marginTop: '12px',
          borderRadius: '7px',
          minWidth: '185px',
          maxHeight: '40px',
          height: '40px'
        }
      default:
        return {
          borderColor: '#495057',
          backgroundColor: '#ffffff',
          color: '#496057',
          width: this.props.width,
          fontSize: '16px',
          height: '30px'
        }
    }
  }
  render() {
    const clsName = this.props.disable ? 'p-button' : `p-button ${this.props.className}`
    return <Button
      id={this.props.id}
      className={clsName}
      label={this.props.label}
      icon={this.props.icon}
      onClick={this.props.onClick}
      disabled={this.props.disable}
      style={this.buttonStyle(this.props.buttonType)}
      title={this.props.title}
      type={this.props.buttonType == "submit" ? "submit" : "button"} />;

  }
}

export default UccButton;




