
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { kebabCaseToCamel } from 'store/Api.js';
import UccToolbarButton from './UccToolbarButton';

@inject('rootStore')
@observer
class UccToolbarExport extends React.Component {
  constructor(props) {
    super(props);
    this.channel = this.props.rootStore.channel;
    this.model = this.channel.model;
  }

  exportClick = (event) => {
    this.props.model.crudStore.setCopyExportEl(event.currentTarget, 'export');
  };

  exportClose = (e, ding) => {
    const apiStore = this.props.rootStore.apiStore;
    const channel = this.props.rootStore.channel;
    const model = this.props.model;
    const nstype = typeof model.domoNsType == 'function' ? model.domoNsType(channel.codename) : model.domoNsType;

    switch (ding) {
      case 'selected':
        this.exportSelected > 0 ?
          this.channel.adminChannel ?
            apiStore.exportAdminCsv(model.endpoint, model.crudStore.selectedRowIds)
            : apiStore.exportNamestrings(apiStore.currentBrand.abbrev, channel.abbrev, nstype, apiStore.currentBrandId, model.crudStore.selectedRowIds)
          : alert('No created namestrings were selected');
        break;
      case 'created':
        this.createdToday > 0 ?
          this.channel.adminChannel ?
            null
            : apiStore.exportNamestrings(apiStore.currentBrand.abbrev, channel.abbrev, nstype, apiStore.currentBrandId, 'today')
          : alert('No namestrings have been created today');
        break;
      case 'all':
        this.totalCreated > 0 ?
          this.channel.adminChannel ?
            apiStore.exportAdminCsv(model.endpoint)
            : apiStore.exportNamestrings(apiStore.currentBrand.abbrev, channel.abbrev, nstype, apiStore.currentBrandId)
          : alert(`No ${model.singular} namestrings for ${apiStore.currentBrand.name} have been created yet`);
        break
    }
    model.crudStore.setCopyExportEl(null, 'export');
  };


  render() {

    const { rootStore, model, classes } = this.props;
    const numSelected = model.crudStore.displayedRows.length;
    const crudStore = model.crudStore;
    const channel = rootStore.channel;
    const id = 'toolbar_export';

    this.exportSelected = crudStore.displayedRows.map(r => {
      return crudStore.selectedRowIds.includes(r.id) ?
        r.status ?
          (r.status.name == 'Inactive' || r.status.name == 'Active') ? true : false
          : true
        : false;
    }).filter(Boolean).length;
    this.createdToday = channel.adminChannel ? null : isNaN(rootStore.apiStore.createdTodayCount) ? 0 : rootStore.apiStore.createdTodayCount
    this.totalCreated = channel.adminChannel ? rootStore.apiStore[kebabCaseToCamel(model.endpoint)]?.length || crudStore.displayedRows?.length : isNaN(rootStore.apiStore.totalCount) ? 0 : rootStore.apiStore.totalCount;

    const disabled = (numSelected == 0) ? true : false;

    return (
      <div>
        <UccToolbarButton disable={disabled} selectedRowIds={model.crudStore.selectedRowIds} model={model} label="Export" icon="pi pi-external-link" onClick={(e) => this.exportClick(e)} />
        <Menu id="simple-menu" anchorEl={crudStore.exportEl} open={crudStore.open} onClose={(e) => this.exportClose(e)} >
          <MenuItem onClick={(e) => this.exportClose(e, 'selected')} className={classes.parent}>Selected ({this.exportSelected})</MenuItem>
          {!channel.adminChannel && <MenuItem onClick={(e) => this.exportClose(e, 'created')} className={classes.parent}>Created Today ({this.createdToday})</MenuItem>}
          <MenuItem onClick={(e) => this.exportClose(e, 'all')} className={classes.parent}>All ({this.totalCreated})</MenuItem>
        </Menu>
      </div>
    );
  }
}


export default UccToolbarExport;
