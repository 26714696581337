import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer, inject } from 'mobx-react';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import AppTitle from './AppTitle';
import Toolbar from '@material-ui/core/Toolbar';

const styles = theme => ({


});

@inject('uiStore')
@observer
class NavigationSimple extends React.Component {

  render() {
    const { uiStore, classes } = this.props;
    return (
      <AppBar
        position="static"
        className={classNames(classes.appBar, { [classes.appBarShift]: uiStore.appDrawerOpen })}
      >
        <Toolbar>
          <AppTitle />
        </Toolbar>
      </AppBar>
    );
  }
}

NavigationSimple.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NavigationSimple);
