import _ from 'lodash';
import dvr from 'mobx-react-form/lib/validators/DVR';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MobxReactForm from 'mobx-react-form';
import NavigationSimple from '../Layout/NavigationSimple';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import routes from '../Layout/Routes';
import validatorjs from 'validatorjs';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { observable } from 'mobx';
import { observer, inject, Provider } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const BootstrapButton = withStyles({
  root: {
    fontStretch: 'Bold',
    fontWeight: 900,
  },
})(Button);

const plugins = {
  dvr: dvr(validatorjs),
};

const styles = (theme) => ({
  root: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    marginTop: theme.spacing(8),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '400px',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  error: {
    color: 'red',
    margin: 0
  },
  form: {
    // width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  textField: {
    width: '95%',
    margin: theme.spacing(1),
  },
  inputLabel: {
    padding: '0px 8px',
    background: '#fff'
  }

});

@inject('rootStore')
@observer
class PasswordChangeForm extends React.Component {
  @observable showPassword = false;
  constructor(props) {
    super(props);
    const { rootStore } = this.props

    const hooks = {
      onSuccess(form) {
        const info = rootStore.apiStore.changePassword(form);
      },
      onError(form) {
        // crudAdminStore.setValidationError(Object.values(form.errors()).filter(Boolean));
      },
    };
    rootStore.apiStore.alreadyChangedPassword = false;
    const fields = this.makeFields();
    this.form = new MobxReactForm({ fields }, { plugins, hooks })
  }

  makeFields = () => {
    return [
      {
        name: 'newPass',
        label: 'New Password',
        placeholder: 'Insert Password',
        rules: 'required|string|between:5,25',
        type: 'password'
      },
      {
        name: 'confirmPass',
        label: 'Confirm Password',
        rules: 'required|string|same:newPass',
        type: 'password'
      },
      {
        name: 'reset_password_token',
        type: 'hidden',
        value: new URL(window.location.href).searchParams.get('reset_password_token')
      },
    ];
  }


  changedAlready = () => {
    const apiStore = this.props.rootStore.apiStore;
    const uiStore = this.props.rootStore.uiStore;
    if (_.isEmpty(uiStore) || _.isEmpty(apiStore)) return false;
    if (apiStore.alreadyChangedPassword) {
      return true;
    } else {
      return false;
    }
  }

  onCancel(f) {
    f.props.rootStore.uiStore.router.goTo(
      routes.userProfile,
      {},
      f.props.rootStore.uiStore
    );
  }


  handleClickShowPassword = () => {
    this.showPassword = !this.showPassword;
    const type = this.showPassword ? 'text' : 'password';
    this.form.$('newPass').set('type', type);
    this.form.$('confirmPass').set('type', type);
  };

  render() {
    const showPassword = false;

    const { classes, rootStore } = this.props;
    const { apiStore } = rootStore;
    const { form } = this;
    const changedAlready = this.changedAlready();
    if (changedAlready) {
      return (
        <React.Fragment>
          <NavigationSimple />
          <div className={classes.root}>
            <Paper className={classes.paper} elevation={6}>
              <p>Password Changed</p>
              <a href="/app">Go to Home Page</a>
            </Paper>
          </div>
        </React.Fragment>
      )
    } else {
      return (
        <div>

          <NavigationSimple />
          <div className={classes.root}>
          <div className={classes.root}>
          <Typography component='h2' variant='h2'  className={classes.h2}>Update Password</Typography>
          </div>
            <Paper className={classes.paper} elevation={6}>
              <form className={classes.form} onSubmit={form.onSubmit}>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <input type="hidden" {...form.$('reset_password_token').bind()} />
                    <FormControl variant="outlined" className={classes.margin, classes.textField}>
                      <InputLabel className={classes.inputLabel} htmlFor="newPass">New Password</InputLabel>
                      <OutlinedInput
                        id="newPass"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={event => this.handleClickShowPassword(event)}
                            >
                              {this.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        {...form.$('newPass').bind()}

                      />
                      <div className={classes.error}>{form.$('newPass').error}</div>
                    </FormControl>



                    <FormControl variant="outlined" className={classes.margin, classes.textField}>
                      <InputLabel className={classes.inputLabel} htmlFor="confirmPass">Confirm Password</InputLabel>
                      <OutlinedInput
                        id="confirmPass"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={event => this.handleClickShowPassword(event)}
                            >
                              {this.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        {...form.$('confirmPass').bind()}

                      />
                      <div className={classes.error}>{form.$('confirmPass').error}</div>
                    </FormControl>
                    {!!apiStore.serverValidationErrors ? (
                      <div className={classes.error} id="validation-errors">
                        <List>
                          {
                            (apiStore.serverValidationErrors.map((er) => (
                              <ListItem button key={er}>
                                <ListItemText primary={er} />
                              </ListItem>
                            )))
                          }
                        </List>
                      </div>
                    ) : ('')}
                    <Grid container direction="row" justify="center" spacing={4}>
                      {/* <FormButtons form={form} onSubmit={form.onSubmit} onCancel={() => this.onCancel(this)} /> */}
                  
                      <BootstrapButton
                      variant="contained"
                      className={classes.button}
                      onClick={form.onSubmit} 
                      color="primary"
                    >
                      Update Password
                    </BootstrapButton>
                    </Grid> 
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </div>
        </div>
      );
    }
  }
}

PasswordChangeForm.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PasswordChangeForm);
