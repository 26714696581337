import React from "react";

const PaidSearchIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="24pt" height="24pt" version="1.1" viewBox="0 0 100 100">
    <path fill={props.fill} d="m50 18c-8.2617 0-15 6.7383-15 15s6.7383 15 15 15 15-6.7383 15-15-6.7383-15-15-15zm0 4c6.0977 0 11 4.9023 11 11s-4.9023 11-11 11-11-4.9023-11-11 4.9023-11 11-11zm-30 9c-6.0508 0-11 4.9492-11 11s4.9492 11 11 11 11-4.9492 11-11-4.9492-11-11-11zm60 0c-6.0508 0-11 4.9492-11 11s4.9492 11 11 11 11-4.9492 11-11-4.9492-11-11-11zm-60 4c3.8906 0 7 3.1094 7 7s-3.1094 7-7 7-7-3.1094-7-7 3.1094-7 7-7zm60 0c3.8906 0 7 3.1094 7 7s-3.1094 7-7 7-7-3.1094-7-7 3.1094-7 7-7zm-30 17c-7.0273 0-13.312 3.1523-17.531 8.0938-3.1094-2.5703-7.1055-4.0938-11.469-4.0938-9.9375 0-18 8.0508-18 17.969v2.0312c0 1.1055 0.89453 2 2 2h22v2c0 1.1055 0.89453 2 2 2h42c1.1055 0 2-0.89453 2-2v-2h22c1.1055 0 2-0.89453 2-2v-2.0312c0-9.918-8.0625-17.969-18-17.969-4.3438 0-8.332 1.5391-11.438 4.0938-4.2188-4.9531-10.527-8.0938-17.562-8.0938zm0 4c10.562 0 19 8.3906 19 18.906v3.0938h-38v-3.0938c0-10.516 8.4375-18.906 19-18.906zm-29 4c3.5234 0 6.6797 1.2617 9.125 3.375-1.8398 3.1367-2.9414 6.7617-3.0938 10.625h-20.031v-0.03125c0-7.7695 6.207-13.969 14-13.969zm58 0c7.793 0 14 6.1992 14 13.969v0.03125h-20.031c-0.15234-3.8516-1.2617-7.4609-3.0938-10.594 2.4453-2.1172 5.6016-3.4062 9.125-3.4062z"/>
</svg>
);

export default PaidSearchIcon;

