import React from 'react';
import routes from './Routes';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { Link } from 'mobx-router';
import ApiStore from '../../store/ApiStore';

const styles = theme => ({
  Breadcrumb: {
    height: '60px',
    position: 'relative',
    overflow: 'hidden',
    paddingTop: '20px',
    display: 'inline-block',
  },
  BreadCrumbColor: {
    color: '#6C7CDC'
  },
  Breadcrumbli: {
    padding: '5 5 5 5',
    position: 'relative',
    display: 'block',
    float: 'left',
    margin: '5 5 5 5',
    position: 'relative',
    color: 'black',
    listStyle: 'none',
    fontWeight: 900,
    fontSize: '16px'
  },
});

@inject('uiStore', 'apiStore')
@observer
class Breadcrumb extends React.Component {
  render() {
    const { breadcrumb, uiStore, classes } = this.props;
    const apiStore = uiStore.rootStore.apiStore;
    const routes = uiStore.rootStore.getRoutes();
    const routeParams = breadcrumb.routeParams ? breadcrumb.routeParams : {};

    const labelValue = _.isFunction(breadcrumb.label)
      ? breadcrumb.label()
      : ((breadcrumb.label == 'Brand' || breadcrumb.label == null) ? apiStore.brandLabel : breadcrumb.label);

    const id = `breadcrumb_${breadcrumb.label.split(' ').join('_')}`
    if (breadcrumb.viewName) {
      return (
        <li id={id} key={breadcrumb.label} className={classes.Breadcrumbli}>
          <Link
            view={routes[breadcrumb.viewName]}
            params={routeParams}
            store={uiStore}
            key={labelValue}
            color='inherit'
            className={classes.BreadCrumbColor}
          >
            {labelValue}
          </Link>
        </li>
      );
    } else {
      return (
        <li id={id} key={labelValue} className={classes.Breadcrumbli}>
          {labelValue}
        </li>
      );
    }
  }
}

export default withStyles(styles)(Breadcrumb);
