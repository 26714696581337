import React, { Component } from 'react';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

@inject('rootStore')
@observer

class UccToolTip extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { title, display, classes } = this.props;
    return (
      <Tooltip title={<span style={{ fontSize: 16 }}>{(typeof title == 'object' && title.type == "a") ? title.props.children : title}</span>} enterDelay={1000} placement="top-start" leaveDelay={200}>
        <div className="might-overflow">{title}</div>
      </Tooltip>
    );
  }
}

export default UccToolTip;
