import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Link } from 'mobx-router';
import './PrimeStyle.css';
import 'primeicons/primeicons.css';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


class UccAdminButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, subtitle, icon, view, params, store } = this.props;
    return (
      <div style={{ display: 'flex', float: 'left' }}>
        <Paper elevation={6} style={{ minWidth: '500px', maxWidth: '635px', height: '200px', borderRadius: '30px', boxShadow: ' 0 2px 4px 2px rgba(0, 0, 0, 0.5)' }}>
          <Grid item>
            <Link view={view} params={params} store={store} key='users' >
              <div className="body-container" style={{ paddingTop: '37px', marginRight: '30px' }}>
                <div style={{ float: 'left' }}><img src={icon} className="admin-icon" /></div>
                <div style={{ float: 'right', padding: '10px', color: 'black' }}><Typography component="h2" variant="h2" >{title}</Typography></div>
              </div>
              <div className="body-container">
                <div style={{ borderBottom: '2px solid #495057', minWidth: '400px', width: '500px' }} ></div>
                <Typography component="h3" variant="h3" style={{ color: '#6c7cdc', paddingTop: '30px' }} >{subtitle}</Typography>
              </div>
            </Link>
          </Grid>
        </Paper>
      </div>
    )
  }
}

export default UccAdminButton;

