import React from 'react';

import AppErrorBoundary from './AppErrorBoundary';
import CurrentPanel from './CurrentPanel';
import Navigation from './Navigation';
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: '100vh'
  },
  currentPanel: {
    flex: '1',
  },
});

class AppLayout extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <AppErrorBoundary>
        <main className={classes.appContainer}>
          <Navigation />
          <CurrentPanel className={classes.currentPanel} />
        </main>
      </AppErrorBoundary>
    );
  }
}

export default withStyles(styles)(AppLayout);
