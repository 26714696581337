import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'

import { displayPlacementModelMetadata, displayAdModelMetadata, displayCreativeModelMetadata } from './display-channel'

var moment = require('moment');

const abbrev_regex = 'regex:/^\\w+$/';
const extra_regex = 'regex:/^[a-zA-Z0-9_]+$/';

export const programmatic = {
  name: 'Programmatic',
  endpoint: 'programmatic',
  codename: 'programmatic',
  abbrev: 'Programmatic',
  icon: 'Programmatic',
  id: 5,
  showViewIcon: true,
  models: [
    {

      // ,------.                           ,-----.                                 
      // |  .--. ',--.--. ,---.  ,---.     '  .--./ ,--,--.,--,--,--. ,---.  ,---.  
      // |  '--' ||  .--'| .-. || .-. |    |  |    ' ,-.  ||        || .-. |(  .-'  
      // |  | --' |  |   ' '-' '' '-' '    '  '--'\\ '-'  ||  |  |  || '-' '.-'  `) 
      // `--'     `--'    `---' .`-  /      `-----' `--`--'`--`--`--'|  |-' `----'  
      //                        `---'                                `--'           

      allFilterData: (apiStore) => apiStore['programmaticCampaignsFilterOptions'],
      codename: 'ProgCamp',
      controller: 'programmatic_campaign',
      crud: (rootStore) => rootStore.programmaticCampaignsCrud,
      domoNsType: 'ProgrammaticCampaign',
      endpoint: 'programmatic-campaigns',
      filterOptionsCall: (apiStore) => (val) => apiStore['programmaticCampaignsFilterOptions'] = val, form: () => <GenericForm type="Campaign" datePicker={2} numButtons={3} />,
      genericModelPromiseEndpoints: ['channels', 'channel-genders', 'channel-platforms', 'brands', 'devices', 'display-types', 'genders', 'geos', 'groups', 'languages', 'marketing-campaigns', 'platforms', 'statuses'],
      modelPromiseEndpoints: (apiStore, brand_id) => ([

        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
        apiStore.programmaticCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Campaigns',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      route: 'campaigns',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'campaign',
      tabDisabled: () => false,
      tabIndex: 0,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 13,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 10,
          grid_type: 'namestring',
          filter: false,
          id: 'namestring',
          filter: false,
          filterField: 'namestring',
          label: 'Namestring',
          rules: 'required'
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'ProgrammaticCampaign',
          rules: 'required',
        },
        {
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          ns: () => 'hmm',
          name: 'channel',
          id: 'channel',
          utaType: 'channel',
          rules: 'required',
        },
        {
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 180,
          label: 'Created By',
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.createdBy
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 30,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          label: 'Group',
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          // ns_order: 10,
          rules: 'required',
          utaType: 'group',
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          label: 'Brand',
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          // ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },
        {
          belongsTo: ['platform', 'platforms'],
          form: 'platform_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="platform"
            fieldName="platform_id"
            label="Platform"
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.menuItems('platforms', 'makePrimeChannelSpecificOption', 'channelPlatforms')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
          />),
          formOrder: 35,
          label: 'Platform',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10,
          domo_field: 'platform',
          domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
          grid: true,
          grid_order: 35,
          grid_value: (row) => property(row.platform, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.platforms,
          id: 'platform',
          label: 'Platform',
          name: 'platform',
          ns: (crudStore) => abbrev(crudStore.storedData.platform),
          ns_order: 35,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.platform, 'name'),
          show_order: 35,

        },
        {
          colSize: 13,
          domo_field: 'campaign_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 40,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Marketing Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 40,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.marketing_campaign, 'name'),
          show_order: 40,
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Marketing Campaign"
            menuItems={rootStore.uiStore.menuItems('marketingCampaigns', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 40,
          label: 'Marketing Campaign',
          name: 'marketing_campaign_id',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          belongsTo: ['display_types', 'displayTypes'],
          form: 'display_type_ids',
          copyEditable: true,
          formField: (rootStore) => (<UccMultiSelect
            core="display-types"
            fieldName="display_type_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Display Types"
            menuItems={rootStore.apiStore.displayTypes.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('display_type_ids', event)}
          />),
          formOrder: 50,
          label: 'Display Type',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          domo_field: 'display_types',
          domo_value: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
          grid: true,
          grid_order: 50,
          grid_value: (row) => row.display_types.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.displayTypes,
          id: 'display_types',
          label: 'Display Types',
          name: 'display_types',
          multiple: [],
          ns: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
          ns_order: 50,
          rules: 'array',
          show: true,
          show_value: (row) => row.display_types.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },

        {
          belongsTo: ['device', 'devices'],
          form: 'device_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="device"
            fieldName="device_id"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            menuItems={rootStore.uiStore.menuItems('devices', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('device_id', event)}
          />),
          formOrder: 60,
          label: 'Device',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.device, 'name'),
          show_order: 60,
          utaType: 'fk',
        },
        {
          domo_field: 'device',
          domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
          grid: true,
          grid_order: 60,
          grid_value: (row) => property(row.device, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.devices,
          id: 'device',
          label: 'Device',
          name: 'device',
          // ns: (crudStore) => abbrev(crudStore.storedData.device),
          // ns_order: 60,
          rules: 'integer',
        },
        {
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          copyEditable: true,
          formField: (rootStore) => (
            <UccSelect
              core="geo"
              fieldName="geo_id"
              form={rootStore.channel.model.crudStore.form}
              label="Geo"
              menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeOption')}
              onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
            />
          ),
          formOrder: 70,
          label: 'Geo',
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 70,
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          grid_order: 70,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          label: 'Geo',
          name: 'geo',
          // ns: (crudStore) => abbrev(crudStore.storedData.geo),
          // ns_order: 70,
          rules: 'integer',
        },
        {
          colSize: 15,
          domo_field: 'region',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.region),
          form: 'region',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="region"
            fieldName="region"
            form={rootStore.channel.model.crudStore.form}
            label="Region"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
          />),
          formOrder: 80,
          grid: true,
          grid_order: 80,
          grid_value: (row) => row.region,
          id: 'region',
          name: 'region',
          // ns: (crudStore) => crudStore.storedData.region,
          // ns_order: 80,
          label: 'Region',
          utaType: 'string',
          rules: ['string', abbrev_regex],
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.region,
          show_order: 80,
        },
        {
          belongsTo: ['language', 'languages'],
          form: 'language_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="language"
            fieldName="language_id"
            form={rootStore.channel.model.crudStore.form}
            label="Language"
            menuItems={rootStore.uiStore.menuItems('languages', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('language_id', event)}
          />),
          formOrder: 90,
          label: 'Language',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 90,
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 90,
          grid_value: (row) => property(row.language, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          id: 'language',
          label: 'Language',
          name: 'language',
          // ns: (crudStore) => abbrev(crudStore.storedData.language),
          // ns_order: 90,
          rules: 'integer',
        },
        {
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={rootStore.uiStore.menuItems('genders', 'makePrimeChannelSpecificOption', 'channelGenders')}
            onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 100,
          label: 'Gender',
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 100,
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          grid_order: 100,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          // ns: (crudStore) => abbrev(crudStore.storedData.gender),
          // ns_order: 100,
          rules: 'integer',
        },

        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 110, 130, 'S.', 130),
        dateField('end_date', 'End Date', 'blank', false, 120, 140, 'E.', 140),
      ),
    },


    {
      //  ____  ____   __    ___        __  __  
      // (  _ \(  _ \ /  \  / __)      (  )/  \ 
      //  ) __/ )   /(  O )( (_ \       )((  O )
      // (__)  (__\_) \__/  \___/      (__)\__/ 

      allFilterData: (apiStore) => apiStore['programmaticInsertionOrdersFilterOptions'],
      codename: 'ProgIO',
      controller: 'programmatic_insertion_order',
      crud: (rootStore) => rootStore.programmaticInsertionOrdersCrud,
      domoNsType: 'ProgrammaticInsertionOrder',
      endpoint: 'programmatic-insertion-orders',
      filterOptionsCall: 'setProgrammaticInsertionOrdersFilterOptions',
      form: () => <GenericForm type="Insertion Order" datePicker={2} numButtons={3} />,
      genericModelPromiseEndpoints: ['campaign-types', 'campaign-type-channels', 'channels', 'channel-genders', 'channel-platforms', 'brands', 'devices', 'display-types', 'genders', 'geos', 'groups', 'languages', 'marketing-campaigns', 'optimization-goals', 'platforms', 'statuses'],
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
        apiStore.programmaticInsertionOrdersCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Insertion Orders',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      route: 'insertion-orders',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'insertion order',
      tabDisabled: () => false,
      tabIndex: 1,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 12,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 10,
          grid_type: 'namestring',
          filter: false,
          id: 'namestring',
          filter: false,
          filterField: 'namestring',
          label: 'Namestring',
          rules: 'required'
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'ProgrammaticInsertionOrder',
          rules: 'required',
        },
        {
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.channel),
          ns: () => 'hmm',
          name: 'channel',
          id: 'channel',
          utaType: 'channel',
          rules: 'required',
        },
        {
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 180,
          label: 'Created By',
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.createdBy
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 22,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          label: 'Group',
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          // ns_order: 10,
          rules: 'required',
          utaType: 'group',
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          label: 'Brand',
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          // ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },

        {
          belongsTo: ['platform', 'platforms'],
          form: 'platform_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="platform"
            fieldName="platform_id"
            label="Platform"
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.menuItems('platforms', 'makePrimeChannelSpecificOption', 'channelPlatforms')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
          />),
          formOrder: 20,
          label: 'Platform',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10,
          domo_field: 'platform',
          domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
          grid: true,
          grid_order: 20,
          grid_value: (row) => property(row.platform, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.platforms,
          id: 'platform',
          label: 'Platform',
          name: 'platform',
          ns: (crudStore) => abbrev(crudStore.storedData.platform),
          ns_order: 20,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.platform, 'name'),
          show_order: 20,

        },

        {
          colSize: 13,
          domo_field: 'campaign_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 25,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Marketing Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          // ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          // ns_order: 10,
          rules: '',
          show: true,
          show_value: (row) => property(row.marketing_campaign, 'name'),
          show_order: 25,
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Marketing Campaign"
            menuItems={rootStore.uiStore.menuItems('marketingCampaigns', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 25,
          label: 'Marketing Campaign',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          belongsTo: ['campaign_type', 'campaignTypes'],
          callback: (crudStore, thing, selected) => crudStore.clearObjective(crudStore, thing, selected),
          copyEditable: true,
          form: 'campaign_type_id',
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            label={'Campaign Type'}
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeChannelSpecificOption', 'campaignTypeChannels')}
            // menuItems={rootStore.apiStore.campaignTypes.map((ct) => rootStore.uiStore.makePrimeRegularOption(ct))}
            // menuItems={_.compact(rootStore.apiStore.campaignTypes.map((campaignType) => rootStore.uiStore.makePrimeSpecificCampaignTypeOption(campaignType)))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('campaign_type_id', event)}
          />),
          formOrder: 30,
          name: 'campaign_type_id',
          label: 'Campaign  Type',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 12,
          domo_field: 'campaign_type',
          domo_value: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          grid: true,
          grid_order: 30,
          grid_value: (row) => property(row.campaign_type, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          // options: (apiStore) => apiStore.campaignTypes;
          filter_placeholder: 'Type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          ns_order: 30,
          options: (apiStore) => apiStore.campaignTypes,
          show: true,
          show_value: (row) => property(row.campaign_type, "name"),
          show_order: 30,
        },
        {
          belongsTo: ['display_types', 'displayTypes'],
          form: 'display_type_ids',
          copyEditable: true,
          formField: (rootStore) => (<UccMultiSelect
            core="display-types"
            fieldName="display_type_ids"
            form={rootStore.channel.model.crudStore.form}
            label="Display Types"
            menuItems={rootStore.apiStore.displayTypes.map((p) => ({
              label: p.name,
              value: p.id,
            }))}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('display_type_ids', event)}
          />),
          formOrder: 50,
          label: 'Display Type',
          multiple: [],
          rules: 'required|array',
          utaType: 'array',
        },
        {
          domo_field: 'display_types',
          domo_value: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
          grid: true,
          grid_order: 50,
          grid_value: (row) => row.display_types.map(c => c.name).join(', '),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.displayTypes,
          id: 'display_types',
          label: 'Display Types',
          multiple: [],
          name: 'display_types',
          ns: (crudStore) => multiAbbrev(crudStore.storedData.display_types),
          ns_order: 50,
          rules: 'array',
          show: true,
          show_value: (row) => row.display_types.map(c => c.name).join(', '),
          show_order: 50,
          utaType: 'array',
          sortable: false
        },
        {
          belongsTo: ['optimization_goal', 'optimizationGoals'],
          form: 'optimization_goal_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="optimization-goal"
            fieldName="optimization_goal_id"
            form={rootStore.channel.model.crudStore.form}
            label="Optimization Goal"
            menuItems={rootStore.uiStore.menuItems('optimizationGoals', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('optimization_goal_id', event)}
          />),
          formOrder: 60,
          label: 'Optimization Goal',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.optimization_goal, 'name'),
          show_order: 60,
          utaType: 'fk',
        },
        {
          domo_field: 'optimization_goal',
          domo_value: (crudStore) => property(crudStore.storedData.optimization_goal, 'name'),
          grid: true,
          grid_order: 60,
          grid_value: (row) => property(row.optimization_goal, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.optimization_goals,
          id: 'optimization_goal',
          label: 'Optimization Goal',
          name: 'optimization_goal',
          ns: (crudStore) => abbrev(crudStore.storedData.optimization_goal),
          ns_order: 60,
          rules: 'required|integer',
        },

        {
          belongsTo: ['device', 'devices'],
          form: 'device_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="device"
            fieldName="device_id"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            menuItems={rootStore.uiStore.menuItems('devices', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('device_id', event)}
          />),
          formOrder: 65,
          label: 'Device',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.device, 'name'),
          show_order: 65,
          utaType: 'fk',
        },
        {
          domo_field: 'device',
          domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
          grid: true,
          grid_order: 65,
          grid_value: (row) => property(row.device, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.devices,
          id: 'device',
          label: 'Device',
          name: 'device',
          ns: (crudStore) => abbrev(crudStore.storedData.device),
          ns_order: 65,
          rules: 'integer',
        },
        {
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          copyEditable: true,
          formField: (rootStore) => (
            <UccSelect
              core="geo"
              fieldName="geo_id"
              form={rootStore.channel.model.crudStore.form}
              label="Geo"
              menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeOption')}
              onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
            />
          ),
          formOrder: 70,
          label: 'Geo',
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 70,
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          grid_order: 70,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          label: 'Geo',
          name: 'geo',
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          ns_order: 70,
          rules: 'integer',
        },
        {
          colSize: 15,
          domo_field: 'region',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.region),
          form: 'region',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="region"
            fieldName="region"
            form={rootStore.channel.model.crudStore.form}
            label="Region"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
          />),
          formOrder: 80,
          grid: true,
          grid_order: 80,
          grid_value: (row) => row.region,
          id: 'region',
          name: 'region',
          ns: (crudStore) => crudStore.storedData.region,
          ns_order: 80,
          label: 'Region',
          utaType: 'string',
          rules: ['string', abbrev_regex],
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.region,
          show_order: 80,
        },
        {
          belongsTo: ['language', 'languages'],
          form: 'language_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="language"
            fieldName="language_id"
            form={rootStore.channel.model.crudStore.form}
            label="Language"
            menuItems={rootStore.uiStore.menuItems('languages', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('language_id', event)}
          />),
          formOrder: 90,
          label: 'Language',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 90,
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 90,
          grid_value: (row) => property(row.language, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          id: 'language',
          label: 'Language',
          name: 'language',
          // ns: (crudStore) => abbrev(crudStore.storedData.language),
          // ns_order: 90,
          rules: 'integer',
        },
        {
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={rootStore.uiStore.menuItems('genders', 'makePrimeChannelSpecificOption', 'channelGenders')}
            onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 100,
          label: 'Gender',
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 100,
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          grid_order: 100,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 100,
          rules: 'integer',
        },

        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('start_date', 'Start Date', 'blank', true, 110, 130, 'dns', 130),
        dateField('end_date', 'End Date', 'blank', false, 120, 140, 'dns', 140),
      ),
    },

    {

      //    _   _   _   _     _   _   _   _     _   _   _   _   _  
      //   / \ / \ / \ / \   / \ / \ / \ / \   / \ / \ / \ / \ / \ 
      //  ( P | r | o | g ) ( L | i | n | e ) ( I | t | e | m | s )
      //   \_/ \_/ \_/ \_/   \_/ \_/ \_/ \_/   \_/ \_/ \_/ \_/ \_/ 

      allFilterData: (apiStore) => apiStore['programmaticLineItemsFilterOptions'],
      codename: 'ProgrammaticLineItem',
      controller: 'programmatic_line_item',
      crud: (rootStore) => rootStore.programmaticLineItemsCrud,
      domoNsType: 'ProgrammaticLineItem',
      dontDisplayDate: true,
      endpoint: 'programmatic-line-items',
      filterOptionsCall: 'setProgrammaticLineItemsFilterOptions',
      form: () => <GenericForm type="Line Item" numButtons={3} />,
      genericModelPromiseEndpoints: ['channels', 'channel-genders', 'channel-platforms', 'brands', 'devices', 'genders', 'geos', 'groups', 'languages', 'optimization-goals', 'platforms', 'statuses', 'tactics', 'targetings', 'channel-tactics', 'tactic-targetings', 'channel-targetings'],
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
        apiStore.programmaticLineItemsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Line Items',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      route: 'line-items',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'line item',
      tabDisabled: () => false,
      tabIndex: 2,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 13,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          colSize: 8,
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 10,
          grid_type: 'namestring',
          filter: false,
          id: 'namestring',
          filter: false,
          filterField: 'namestring',
          label: 'Namestring',
          rules: 'required'
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'ProgrammaticLineItem',
          rules: 'required',
        },
        {
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.storedData.channel),
          ns: () => 'hmm',
          name: 'channel',
          id: 'channel',
          utaType: 'channel',
          rules: 'required',
        },
        {
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          grid: true,
          grid_order: 180,
          label: 'Created By',
          grid_value: (row) => property(row.user, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'created_by',
          name: 'created_by',
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          options: (apiStore) => apiStore.createdBy
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 22,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          label: 'Group',
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          // ns_order: 10,
          rules: 'required',
          utaType: 'group',
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          label: 'Brand',
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          // ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },
        {
          belongsTo: ['platform', 'platforms'],
          form: 'platform_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="platform"
            fieldName="platform_id"
            label="Platform"
            form={rootStore.channel.model.crudStore.form}
            menuItems={rootStore.uiStore.menuItems('platforms', 'makePrimeChannelSpecificOption', 'channelPlatforms')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('platform_id', event)}
          />),
          formOrder: 50,
          label: 'Platform',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          colSize: 10,
          domo_field: 'platform',
          domo_value: (crudStore) => property(crudStore.storedData.platform, 'name'),
          grid: true,
          grid_order: 50,
          grid_value: (row) => property(row.platform, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.platforms,
          id: 'platform',
          label: 'Platform',
          name: 'platform',
          ns: (crudStore) => abbrev(crudStore.storedData.platform),
          ns_order: 50,
          rules: 'required',
          show: true,
          show_value: (row) => property(row.platform, 'name'),
          show_order: 50,
        },

        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (
            <UccSelect
              core="tactic"
              fieldName="tactic_id"
              form={rootStore.channel.model.crudStore.form}
              label="Tactic"
              menuItems={rootStore.uiStore.menuItems('tactics', 'makePrimeChannelSpecificOption', 'channelTactics')}
              onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
            />),
          formOrder: 60,
          label: 'Tactic',
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 60,
          grid_value: (row) => property(row.tactic, 'name'),
          id: 'tactic',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          label: 'Tactic',
          name: 'tactic',
          ns_order: 60,
          ns: (crudStore) => abbrev(crudStore.storedData.tactic),
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.tactic, 'name'),
          show_order: 60,
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.suppressionLabel(),
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          ns_order: 65,
          ns: (crudStore) => crudStore.storedData.suppression ? 'SupOn' : 'SupOff',
          type: 'checkbox',
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 65,
          utaType: 'boolean',
        },

        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          form: 'programmatic_line_item_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
          formOrder: 70,
          grid: true,
          grid_order: 70,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'programmatic_line_item_targetings_attributes',
          ns: (crudStore) => NameString.targetingString(crudStore),
          ns_order: 70,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "programmatic_line_item_targetings"),
          show_order: 70,
          sortable: false,
          utaType: 'targeting_array',
        },
        {
          form: 'programmatic_line_item_targetings_attributes[].id',
        },
        {
          form: 'programmatic_line_item_targetings_attributes[].programmatic_line_item_id',
        },
        {
          form: 'programmatic_line_item_targetings_attributes[].targeting_id',
        },
        {
          form: 'programmatic_line_item_targetings_attributes[].extra',
        },
        {
          form: 'programmatic_line_item_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'programmatic_line_item_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'programmatic_line_item_targetings_attributes[].on',
        },

        {
          belongsTo: ['optimization_goal', 'optimizationGoals'],
          form: 'optimization_goal_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="optimization-goal"
            fieldName="optimization_goal_id"
            form={rootStore.channel.model.crudStore.form}
            label="Optimization Goal"
            menuItems={rootStore.uiStore.menuItems('optimizationGoals', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('optimization_goal_id', event)}
          />),
          formOrder: 90,
          label: 'Optimization Goal',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.optimization_goal, 'name'),
          show_order: 90,
          utaType: 'fk',
        },
        {
          domo_field: 'goal',
          domo_value: (crudStore) => property(crudStore.storedData.optimization_goal, 'name'),
          grid: true,
          grid_order: 90,
          grid_value: (row) => property(row.optimization_goal, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.optimization_goals,
          id: 'optimization_goal',
          label: 'Optimization Goal',
          name: 'optimization_goal',
          ns: (crudStore) => abbrev(crudStore.storedData.optimization_goal),
          ns_order: 90,
          rules: 'required|integer',
        },

        {
          belongsTo: ['device', 'devices'],
          form: 'device_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="device"
            fieldName="device_id"
            form={rootStore.channel.model.crudStore.form}
            label="Device"
            menuItems={rootStore.uiStore.menuItems('devices', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('device_id', event)}
          />),
          formOrder: 100,
          label: 'Device',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.device, 'name'),
          show_order: 100,
          utaType: 'fk',
        },
        {
          domo_field: 'device',
          domo_value: (crudStore) => property(crudStore.storedData.device, 'name'),
          grid: true,
          grid_order: 100,
          grid_value: (row) => property(row.device, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.devices,
          id: 'device',
          label: 'Device',
          name: 'device',
          ns: (crudStore) => abbrev(crudStore.storedData.device),
          ns_order: 100,
          rules: 'integer',
        },
        {
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          copyEditable: true,
          formField: (rootStore) => (
            <UccSelect
              core="geo"
              fieldName="geo_id"
              form={rootStore.channel.model.crudStore.form}
              label="Geo"
              menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeOption')}
              onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
            />
          ),
          formOrder: 110,
          label: 'Geo',
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 110,
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          grid_order: 110,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          label: 'Geo',
          name: 'geo',
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          // ns_order: 110,
          rules: 'integer',
        },
        {
          colSize: 15,
          domo_field: 'region',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.region),
          form: 'region',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="region"
            fieldName="region"
            form={rootStore.channel.model.crudStore.form}
            label="Region"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('region', event)}
          />),
          formOrder: 120,
          grid: true,
          grid_order: 120,
          grid_value: (row) => row.region,
          id: 'region',
          name: 'region',
          ns: (crudStore) => crudStore.storedData.region,
          // ns_order: 80,
          label: 'Region',
          utaType: 'string',
          rules: ['string', abbrev_regex],
          filter: (dt, col) => inputTextFilter(dt, col),
          show: true,
          show_value: (row) => row.region,
          show_order: 120,
        },
        {
          belongsTo: ['language', 'languages'],
          form: 'language_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="language"
            fieldName="language_id"
            form={rootStore.channel.model.crudStore.form}
            label="Language"
            menuItems={rootStore.uiStore.menuItems('languages', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('language_id', event)}
          />),
          formOrder: 130,
          label: 'Language',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.language, 'name'),
          show_order: 130,
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'language',
          domo_value: (crudStore) => property(crudStore.storedData.language, 'name'),
          grid: true,
          grid_order: 130,
          grid_value: (row) => property(row.language, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.languages,
          id: 'language',
          label: 'Language',
          name: 'language',
          ns: (crudStore) => abbrev(crudStore.storedData.language),
          // ns_order: 130,
          rules: 'integer',
        },
        {
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={rootStore.uiStore.menuItems('genders', 'makePrimeChannelSpecificOption', 'channelGenders')}
            onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 140,
          label: 'Gender',
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 140,
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          grid_order: 140,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          ns_order: 140,
          rules: 'integer',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ]
    },
  ].concat(displayPlacementModelMetadata(5, 3))
    .concat(displayAdModelMetadata(5, 4))
    .concat(displayCreativeModelMetadata(5, 5))
};