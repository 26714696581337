import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import routes from '../Layout/Routes';
import { Link } from 'mobx-router';
import { observer, inject, Provider } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '../Layout/Breadcrumbs';

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    flexGrow: 1,
  },
});

@inject('rootStore')
@observer
class UserProfilePanel extends React.Component {
  constructor(props) {
    super(props);
    const { rootStore } = this.props;
  }

  render() {
    const { classes, rootStore } = this.props
    const { apiStore } = rootStore;
    return (
      <div className={classes.wrapper}>
        <div className="uccLeftColumn ">&nbsp;</div>
        <div className="uccCenterColumn">
          <Breadcrumbs />
          <div> This is the profile panel</div>
          <b> Name: </b>
          {rootStore.apiStore.currentUser.name}<br></br>
          <b> Email: </b>
          {rootStore.apiStore.currentUser.email}<br></br>
          <b> Group: </b>
          {rootStore.apiStore.currentUser.group.name}
          <br></br>
          <b> Role: </b>
          {rootStore.apiStore.currentUser.pl_name}
          <br></br>
          <Link view={routes.userPasswordEdit} store={rootStore.uiStore}>
            <div id='edit-password-link'>Edit Password</div>
          </Link>
        </div>
      </div>
    );
  };
}

UserProfilePanel.wrappedComponent.propTypes = {
  //  rootStore: PropTypes.instanceOf(RootStore).isRequired,
  // crudAdminStore: PropTypes.instanceOf(CrudAdminStore).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserProfilePanel);
