import ApiStore from '../../store/ApiStore';
import AppBar from '@material-ui/core/AppBar';
import AppTitle from './AppTitle';
import Home from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import Logo from '../../../../public/images/UCC_no_tagline.jpg';
import astellasLogo from '../../../../public/images/astellas_logo.jpeg';
import blueLogo from '../../../../public/images/blue_logo.png';

import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import RootStore from '../../store/RootStore';
import SessionMenu from './SessionMenu';
import Toolbar from '@material-ui/core/Toolbar';
import UiStore from '../../store/UiStore';
import classNames from 'classnames';
import constants from 'constants';
import routes from './Routes';
import { Link } from 'mobx-router';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import AdminAccordionNav from './AdminAccordionNav';
import BrandAccordionNav from './BrandAccordionNav';
import Divider from '@material-ui/core/Divider';


const styles = theme => ({
  appBar: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white'
  },
  appBarShift: {
    marginLeft: constants.DRAWER_WIDTH,
    width: `calc(100% - ${constants.DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
});

@inject('apiStore', 'uiStore', 'rootStore')
@observer
class Navigation extends React.Component {
  handleGoToHome = () => {
    const { apiStore, uiStore, rootStore } = this.props;
    // this.props.uiStore.toggleAppDrawer();
    uiStore.router.goTo(routes.home, {}, uiStore);
  };

  render() {
    const { uiStore, classes, rootStore } = this.props;
    const svgStyle = { height: '30px', width: '180px', marginLeft: '20px', marginTop: '9px' };
    const astellasLogoStyle = { height: '27px', width: '100px', marginRight: '14px' };
    const blueLogoStyle = { height: '0', width: '0', marginRight: '14px' };
    const instanceLogos = { astellasLogo, blueLogo };
    const instanceLogo = instanceLogos[rootStore.instance.name + 'Logo'];
    const instanceLogoStyle = rootStore.instance.name + 'Logo' == 'blueLogo' ? blueLogoStyle : astellasLogoStyle;

    return (
      <AppBar
        position="sticky"
        className={classNames(classes.appBar, { [classes.appBarShift]: uiStore.appDrawerOpen })}
      >
        <Toolbar disableGutters={!uiStore.drawerOpen}>
          <Link view={routes.home} store={uiStore}>
            <img src={Logo} style={svgStyle} onClick={this.handleGoToHome} alt={rootStore.title} />
          </Link>
          <Divider variant="middle" orientation="vertical" />
          {uiStore.router.currentPath.includes('/admin') ? <AdminAccordionNav /> : <BrandAccordionNav />}
          <AppTitle />
          <SessionMenu />
          <img src={instanceLogo} style={instanceLogoStyle} onClick={this.handleGoToHome} alt={rootStore.title} />
        </Toolbar>
      </AppBar>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

Navigation.wrappedComponent.propTypes = {
  uiStore: PropTypes.instanceOf(UiStore).isRequired,
};

export default withStyles(styles, { withTheme: true })(Navigation);
