
import AdminLeftNav from './AdminLeftNav';
import AdminOldMan from '../../../../public/images/admin-old-man.svg';
import Breadcrumbs from './Breadcrumbs';
import Iframe from 'react-iframe';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useState, useEffect } from 'react';
import RootStore from '../../store/RootStore';
import routes from './Routes';
import UiStore from '../../store/UiStore';
import { Button } from 'primereact/button';
import { Link } from 'mobx-router';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import AdminButton from '../PrimeReactComponent/UccAdminButton'

import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
  centered: {
    textAlign: 'center',
    width: '100%',
    clear: 'both',
    margin: '0 auto',
    paddingRight: '20px'
  },
  child: {
    paddingTop: '40px',
    textAlign: 'center',
    paddingBottom: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  parent: {
    color: '#495057',
    '&:hover ': {
      color: '#944DFF',
      backgroundColor: 'rgba(98, 0, 238, 0.08)',
      opacity: 1,
    },
    '&:focus': {
      color: '#rgba(98, 0, 238, 0.08)',
      fontWeight: 900,
    },
  },
  linkDecor: {
    textDecoration: 'none',
  },
  iconSpreader: {
    padding: '30px 0'
  },
});



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

@inject('apiStore', 'uiStore', 'rootStore')
@observer
class AdminPanel extends React.Component {
  constructor(props) {
    super(props)
  }
  @observable buttonList = this.props.rootStore.storeMetadata.channels.filter(c => c.adminChannel == true)
  @observable legacy_admin = this.props.rootStore.features.legacy_admin == true ? 1 : 0
  @observable pll = this.props.rootStore.apiStore.currentUser.pll < 60 ? 0 : 1
  @observable addButton = this.legacy_admin * this.pll;

  @observable buttonNumber = (this.buttonList.length) % 2


  legacyAdmin() {
    if (this.props.rootStore.apiStore.currentUser.pll < 60) return (<></>);
    if (this.props.rootStore.features.legacy_admin) {
      return (
        // <div className={this.buttonNumber == 0 ? "uccLeftCenterColumn" : ""} style={this.buttonNumber == 0 ? { float: 'left' } : {}} >
        <div className={this.props.classes.centered} >
          <div className={this.props.classes.child}  >
            <AdminButton icon={AdminOldMan} title="Legacy Admin" subtitle="Temporary access to Admin 1.0" href="./app/legacy-admin" view={routes.legacyAdmin} store={this.props.uiStore} key="legacyAdmin" />
          </div>
        </div>
        // </div>
      )
    }
  }

  buildButton = () => {
    return this.buttonList.map((item) => (
      <div className={this.buttonNumber == 0 ? "uccLeftCenterColumn" : ""} style={this.buttonNumber == 0 ? { float: 'left' } : {}} >
        <div className={this.props.classes.centered} >
          <div className={this.props.classes.child} >
            <AdminButton key={item.name} icon={item.icon} title={item.name} subtitle={item.subtitle} view={routes.adminChannelTabs} params={{ channel: item.endpoint, model: item.models[0].route }} store={this.props.uiStore} />
          </div>
        </div>
      </div>
    ))

  }

  render() {
    const { rootStore, uiStore, classes } = this.props;
    const topModel = rootStore.storeMetadata.channels.find(c => c.codename == 'attrValues').models[0].endpoint
    return (

      <div className="uccMainWrapper">
        {/* <div className="uccLeftColumn"><AdminLeftNav admin={false} /></div> */}
        <div className="uccCenterColumn">
          <Breadcrumbs />
          <div style={{ width: '80%', margin: 'auto' }} >
            {this.buildButton()}
            {this.legacyAdmin()}
          </div>
        </div>

        {/* <div className="uccRightColumn">&nbsp;</div> */}
      </div >
    );
  }
}

export default withStyles(styles)(AdminPanel);
