import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MobxReactForm from 'mobx-react-form';
import PropTypes from 'prop-types';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  root: {
    width: '100%',
    textAlign: 'center',
  },
  grid: {
  },
  button: {
    backgroundColor: "#00BF73",
    color: 'white',
    width: 135
  },
  cancel: {
    width: 135
  },
  saveDraft: {
    backgroundColor: "#475bd3",
    color: 'white',
  }
});

@inject('rootStore')
@observer
class AdFormButtons extends React.Component {
  render() {
    const { classes, form, onCancel } = this.props;
    return (
      <React.Fragment>
        <Grid item >
          <Button
            onClick={onCancel}
            variant="outlined"
            className={classes.cancel}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            className={classes.saveDraft}
            onClick={(e) => {
              form.$('status').set('value', 'draft');
              form.onSubmit(e);
            }}
          >
            Save Draft
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.button}
            onClick={(e) => {
              form.$('status').set('value', 'created');
              form.onSubmit(e);
            }}
          >
            Create
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

AdFormButtons.wrappedComponent.propTypes = {
  form: PropTypes.instanceOf(MobxReactForm).isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdFormButtons);
