import React from "react";
import StarIconBorder from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import IconButton from "@material-ui/core/IconButton";
import { observer, inject } from 'mobx-react';
import { withStyles } from "@material-ui/core/styles";


const styles = theme => ({
  hidden: {
    visibility: "hidden"
  },
  gold: {
    color: "gold"
  },
  display: {
    visibility: "visible"
  }
});

@inject("rootStore")
@observer
class FavoriteIcon extends React.Component {
  constructor(props) {
    super(props);
  }

  handleOnClick = () => {
    this.props.rootStore.addFavorite(this.props.brandId);
  };

  render() {
    const { rootStore, classes, brandId } = this.props;

    return (
      <IconButton
        component="span"
        onClick={event => this.handleOnClick()}
        key={brandId}
        className={this.props.className}
        fontSize={this.props.fontSize}
      >
        {rootStore.isFavoriteBrand(brandId) ? (
          <StarIcon className={classes.gold} />
        ) : (
            <StarIconBorder
              className={this.props.hoverState ? classes.display : classes.hidden}
            />
          )}
      </IconButton>
    );
  }
}
export default withStyles(styles)(FavoriteIcon);
