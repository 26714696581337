import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';

@inject('rootStore')
@observer
class UccErrorHandling extends Component {
  render() {
    const {
      rootStore,
      form,
      fieldName,
      callFrom,
      isError,
      justReturn,
      style
    } = this.props;
    if (justReturn) return null;
    const { channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const validationErrors = crudStore.validationErrors != [] ? true : false
    const displayCheck = (!this.props.isError && crudStore.storedData[fieldName] && crudStore.storedData[fieldName].toString().length > 0) ? true : false
    // const displayCheck = (crudStore.storedData[fieldName] && crudStore.storedData[fieldName] && crudStore.storedData[fieldName].toString().length > 0) ? true : false
    const isStartGreater = ((fieldName == 'end_date' || fieldName == 'start_date') && (crudStore.storedData['end_date'] != undefined && crudStore.storedData['end_date'].toString() != '0') && (crudStore.storedData['start_date'] > crudStore.storedData['end_date'])) ? false : true;
    // console.log('======== ')
    // console.log('fieldname ' + fieldName)

    // console.log('displayCheck ' + displayCheck)
    // console.log('callfrom ' + callFrom)

    // console.log('!this.props.isError ' + !this.props.isError)
    // console.log('isStartGreater ' + isStartGreater)
    // console.log('no validationErrors ' + validationErrors)
    // if (crudStore.storedData[fieldName]) { console.log('crudStore.storedData[fieldName].toString() ' + crudStore.storedData[fieldName].toString()) };
    // console.log('final', displayCheck && isStartGreater && validationErrors && crudStore.storedData[fieldName] && crudStore.storedData[fieldName].toString().length > 0)

    return (
      <div className="uccErrorHandling" style={this.props.style}>
        {(displayCheck &&
          isStartGreater &&
          validationErrors) &&
          crudStore.storedData[fieldName] && !crudStore.displayError &&
          crudStore.storedData[fieldName].toString().length > 0
          && (
            <i
              className="pi pi-check success"
              style={{ color: '#00cb09', marginLeft: '10px' }}
            ></i>
          )}
        {(crudStore.displayError || this.props.isError || !displayCheck && (!form ? this.props.callFrom : (form.$(fieldName).error == null ? 0 : form.$(fieldName).error)) || !isStartGreater) && (
          // {(crudStore.displayError || !displayCheck && (!form ? this.props.callFrom : (form.$(fieldName).error == null ? 0 : form.$(fieldName).error)) || !isStartGreater || form?.errors()[fieldName]) && (
          <i
            className="pi pi-exclamation-circle success"
            style={{ color: '#FF5656', marginLeft: '10px' }}
          ></i>
        )}
      </div>
    );
  }
}

export default UccErrorHandling;
