import { Button } from 'primereact/button';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { kebabCaseToCamel } from 'store/Api.js';
import UccToolbarButton from './UccToolbarButton';

@inject('rootStore')
@observer
class UccToolbarArchive extends React.Component {
  constructor(props) {
    super(props);
    this.channel = this.props.rootStore.channel;
    this.model = this.channel.model;
  }

  @observable archiveList = '';
  @action.bound

  getArchiveCount = (e) => {
    const model = this.props.model
    model.crudStore.setToolbarAction('Archive')
    const ac = model.crudStore.selectedRows;
    this.archiveList = ReactDOMServer.renderToStaticMarkup(<ul data-qa="data_archive_ul" style={{ textAlign: 'left' }}>{(_.uniq(ac).map((c) => (<li key={c.id}>{c.name}</li>)))}</ul>);
  }

  disableArchive = () => {
    const selectedRows = _.compact(this.props.model.crudStore.selectedRows);
    if (selectedRows.length < 1) return true;
    const hasStatus = this.props.rootStore.channel.hasStatus == undefined ? (this.props.rootStore.channel.model.hasStatus == undefined ? true : this.props.rootStore.channel.model.hasStatus) : this.props.rootStore.channel.hasStatus;
    if (!hasStatus) return true;

    return (selectedRows.filter(m => m.status.name == 'Archived').length > 0) ? true : false;
  }

  render() {
    const { rootStore, model, classes } = this.props;
    if (!_.isNumber(model.permArchive) || rootStore.apiStore.currentUser.pll < model.permArchive) return (<></>);

    const numSelected = model.crudStore.selectedRows.length;
    const crudStore = model.crudStore;
    const channel = rootStore.channel;
    const id = 'toolbar_archive';
    const disabled = (numSelected == 0) ? true : false;
    const archiveList = this.archiveList;
    return (
      <div>
        <UccToolbarButton disable={this.disableArchive()} selectedRowIds={model.crudStore.selectedRowIds} model={model} label="Archive" icon="pi pi-folder" id="toolbar_archive" onClick={(e) => {
          this.getArchiveCount(e);
          return crudStore.confirmAction(
            crudStore.onArchive,
            `<span data-qa = "data_delete" style="color:gray">Are You Sure?</span><div style="color:black;margin: 10px;"><nobr>You are about to archive the following</nobr> ${this.archiveList}</div>`,
            'Confirm',
          )
        }} />
      </div>
    );
  }
}


export default UccToolbarArchive;
