import { createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Avenir LT W01_35 Light1475496',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 16,
    fontWeight: 300,
    fontStretch: 'normal',
    letterSpacing: 'normal',
    color: '#475bd3',
    h1: {
      fontFamily: [
        'Avenir LT W01_95 Black1475556',
        'Roboto',
        'Helvetica',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: '20px',
      fontWeight: 900,
      fontStretch: 'Bold',
      letterSpacing: 'normal',
      color: '#000000'
    },
    h2: {
      fontFamily: [
        'Avenir LT W01_65 Medium1475532',
        'Roboto',
        'Helvetica',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 20,
      fontWeight: 500,
      fontStretch: 'normal',
      letterSpacing: 'normal',
      fontColor: '#828282',
    },
    h3: {
      fontFamily: [
        'Avenir LT W01_65 Medium1475532',
        'Roboto',
        'Helvetica',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 18,
      fontWeight: 500,
      fontStretch: 'normal',
      letterSpacing: 'normal',
      fontColor: '#828282',
    },
    h6: {
      fontFamily: [
        'Avenir LT W01_65 Medium1475532',
        'Roboto',
        'Helvetica',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 18,
      fontWeight: 300,
      fontStretch: 'normal',
      letterSpacing: 'normal',
      fontColor: '#828282',
    },
    body1: {
      fontFamily: [
        'Avenir LT W01_65 Medium1475532',
        'Roboto',
        'Helvetica',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 18,
      fontWeight: 300,
      fontStretch: 'normal',
      letterSpacing: 'normal',
    },
    body2: {
      fontFamily: [
        'Avenir LT W01_65 Medium1475532',
        'Roboto',
        'Helvetica',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 18,
      fontWeight: 300,
      fontStretch: 'normal',
      letterSpacing: 'normal',
      color: '#495057',
    },
    overline: {
      fontWeight: 300,
      fontStretch: 'normal',
      letterSpacing: 'normal',
      color: '#495057',
    },
    button: {
      fontFamily: [
        'Avenir LT W01_35 Light1475496',
        'Roboto',
        'Helvetica',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: 16,
      fontWeight: 300,
      fontStretch: 'normal',
      letterSpacing: 'normal',
    },
    grid: {
      justify: 'left',
    },
    variantMapping: {
      h1: 'h1',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#475bd3',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#00BF73',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#944DFF',
    },
    common: {
      main: '#828282',
      contrastText: '#828282',
    },
    active: {
      color: 'yellow',
    },
    height: '100%',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        color: '#FFFFFF',
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#ffffff',
        },
        MuiFormLabel: {
          root: {
            fontSize: '18px',
          },
        },

        MuiButton: {
          root: {
            fontStretch: 'bold',
            fontWeight: '900',
          },
        },
      },
    },
  },
});
