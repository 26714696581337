import React from 'react';
import UccDate from './UccDate';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';

@inject('rootStore')
@observer
class UccDoubleDatePicker extends React.Component {
  constructor(props) {
    super(props);
    const crudStore = this.props.rootStore.channel.model.crudStore;
  }
  render() {
    const {
      rootStore,
    } = this.props;
    const { channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    if (_.isEmpty(model.columns.filter(c => c.id == 'start_date')) || _.isEmpty(model.columns.filter(c => c.id == 'end_date'))) return null;
    return (
      <div className="container-date-2">
        <div className="calendarFirstHalf">
          <UccDate
            label="Start Date"
            core="start_date"
            form={crudStore.form}
            onInput={(event) => crudStore.storeData('start_date', event)}
            fieldName="start_date"
            onClear={(event) => crudStore.storeData('start_date', '')}
            numberofButton="2"
          />
        </div>
        <div className="calendarSecondHalf">
          <UccDate
            label="End Date"
            core="end_date"
            form={crudStore.form}
            onInput={(event) => crudStore.storeData('end_date', event)}
            fieldName="end_date"
            onClear={(event) => crudStore.storeData('end_date', '')}
            numberofButton="2"
          />
        </div>
      </div>
    );
  }
}

export default UccDoubleDatePicker;
