import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import dvr from 'mobx-react-form/lib/validators/DVR';
import FormButtons from './FormButtons';
import Grid from '@material-ui/core/Grid';
import NameString from './../../NameString';
import Pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import UccApproveDenyButton from '../../PrimeReactComponent/UccApproveDenyButtons';
import UccCheckBox from '../../PrimeReactComponent/UccCheckbox';
import UccChips from '../../PrimeReactComponent/UccChips';
import UccDoubleDatePicker from '../../PrimeReactComponent/UccDoubleDatePicker';
import UccInputText from '../../PrimeReactComponent/UccInputText';
import UccMultiSelect from '../../PrimeReactComponent/UccMultiSelect';
import UccSelect from '../../PrimeReactComponent/UccSelect';
import UccDoubleButton from '../../PrimeReactComponent/UccDoubleButton';
import UiStore from '../../../store/UiStore';
import validatorjs from 'validatorjs';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { between, permRequest, getButtonLabel } from '../../../store/StoreMetadata.js'


const plugins = {
  dvr: dvr(validatorjs),
};

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingLeft: '7px',
    paddingRight: '6px',
    flexGrow: 1,
  },
  tall: {
    height: 30,
  },
});

@inject('rootStore')
@observer
class AdminUserForm extends React.Component {
  @observable suggestedEndDate = '';

  constructor(props) {
    super(props);
    const crudStore = this.props.rootStore.channel.model.crudStore;
    const hooks = crudStore.hooks(crudStore);
    this.form = crudStore.form;
    this.props.rootStore.react = this;
  }

  componentDidMount = async () => {
    const { apiStore, channel } = this.props.rootStore;
    const crudStore = channel.model.crudStore;
    crudStore.formComponentDidMount();
    // if (crudStore.form && crudStore.form.fields.has('social_ad_set_id')) {
    //   channel.model.crudStore.storeData(
    //     'social_ad_set_id',
    //     channel.model.crudStore.form.$('social_ad_set_id').value,
    //   );
    // }
  };

  componentWillUnmount() {
    this.props.rootStore.uiStore.unmountComponent();
  }
  render() {
    const { classes, rootStore } = this.props;
    const { apiStore, channel, uiStore } = rootStore;
    const { form } = this;
    const model = channel.model;
    const crudStore = model.crudStore;
    const getLabel = getButtonLabel(rootStore)
    if (crudStore.loading) return (<div style={{ textAlign: 'center' }}><CircularProgress /></div>);

    return (
      <div className={classes.root}>
        <form className={classes.form} onSubmit={form.onSubmit}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12} sm={6}>
              {/* { ADSET } */}

              <UccInputText
                core="name"
                fieldName="name"
                form={this.form}
                label="Name"
                onInput={(event) => crudStore.storeData('name', event)}
              />
              <UccInputText
                core="email"
                fieldName="email"
                form={this.form}
                label="Email"
                onInput={(event) => crudStore.storeData('email', event)}
              />
              <UccSelect
                core="permission_level_id"
                fieldName="permission_level_id"
                form={rootStore.channel.model.crudStore.form}
                label="Permission Level"
                menuItems={apiStore.permissionLevels.filter(p => p.id != 1).filter(p => p.level <= apiStore.currentUser.pll).map((p) => ({
                  label: p.level_name,
                  value: `${p.id}`
                }))}
                onChange={(event) => rootStore.channel.model.crudStore.storeData('permission_level_id', event)}
              />

              <UccMultiSelect
                core="groups"
                fieldName="group_ids"
                form={crudStore.form}
                filter={true}
                label="Groups"
                menuItems={_.sortBy(apiStore.groups, ['name']).map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(event) => crudStore.storeData('group_ids', event)}
              />
              {crudStore.validationErrorsPrint()}
              <UccApproveDenyButton
                form={crudStore.form}
                onSubmit={crudStore.form.onSubmit}
                onCancel={crudStore.onCancel}
                label={getLabel}
              />
            </Grid>
          </Grid>
        </form>
        <div className={classes.tall}>&nbsp;</div>
      </div>
    );
  }
}

AdminUserForm.wrappedComponent.propTypes = {
  // apiStore: PropTypes.instanceOf(ApiStore).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminUserForm);
