import React from "react";
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    height: '30px',
    width: '30px',
    fill: props => props.color,
    '&:hover': {
      fill: '#5EABF8'
    }
  }
}

const AdminHomeIcon = (props) => {
  const { classes } = props;
  return (
    <svg className={classes.root} version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m84.375 71.875h-68.75c-1.7266 0-3.125-1.3984-3.125-3.125v-50c0-1.7266 1.3984-3.125 3.125-3.125h68.75c0.82812 0 1.625 0.32812 2.2109 0.91406 0.58594 0.58594 0.91406 1.3828 0.91406 2.2109v50c0 0.82812-0.32812 1.625-0.91406 2.2109-0.58594 0.58594-1.3828 0.91406-2.2109 0.91406zm-65.625-6.25h62.5v-43.75h-62.5z"/>
        <path d="m62.5 81.25h-25c-1.7266 0-3.125-1.3984-3.125-3.125s1.3984-3.125 3.125-3.125h25c1.7266 0 3.125 1.3984 3.125 3.125s-1.3984 3.125-3.125 3.125z"/>
        <path d="m50 59.375c-1.7266 0-3.125-1.3984-3.125-3.125v-15.625c0-1.7266 1.3984-3.125 3.125-3.125s3.125 1.3984 3.125 3.125v15.625c0 0.82812-0.32812 1.625-0.91406 2.2109-0.58594 0.58594-1.3828 0.91406-2.2109 0.91406z"/>
        <path d="m31.25 59.375c-1.7266 0-3.125-1.3984-3.125-3.125v-9.375c0-1.7266 1.3984-3.125 3.125-3.125s3.125 1.3984 3.125 3.125v9.375c0 0.82812-0.32812 1.625-0.91406 2.2109-0.58594 0.58594-1.3828 0.91406-2.2109 0.91406z"/>
        <path d="m68.75 59.375c-1.7266 0-3.125-1.3984-3.125-3.125v-25c0-1.7266 1.3984-3.125 3.125-3.125s3.125 1.3984 3.125 3.125v25c0 0.82812-0.32812 1.625-0.91406 2.2109-0.58594 0.58594-1.3828 0.91406-2.2109 0.91406z"/>
      </g>
    </svg>
  )};

export default withStyles(styles)(AdminHomeIcon);
