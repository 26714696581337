import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import jsp from '../../../lib/jsp';
import MenuItem from '@material-ui/core/MenuItem';
import MobxReactForm from 'mobx-react-form';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import UccErrorHandling from '../../PrimeReactComponent/UccErrorHandling';
import UccInputNumber from '../../PrimeReactComponent/UccInputNumber';
import UccInputText from '../../PrimeReactComponent/UccInputText';
import UccSelect from '../../PrimeReactComponent/UccSelect';
import { Checkbox } from 'primereact/checkbox';
import { hidden } from 'ansi-colors';
import { InputText } from 'primereact/inputtext';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  error: {
    color: 'red',
    margin: 0,
  },

  select: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  textFieldIndented: {
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(0),
    fontWeight: 300,
    fontFamily: 'Avenir LT W01_35 Light1475496',
    fontSize: 18,
    fontWeight: 300,
    fontStretch: 'normal',
    letterSpacing: 'normal',
  },
  targetingInputWidth: {
    width: '33px',
  },
  number: {
    width: theme.spacing(12),
  },
  targetingformControl: {
    display: 'flex',
    whiteSpace: 'nowrap',
    paddingLeft: theme.spacing(1),
    verticalAlign: 'middle',
    maxHeight: '80px',
    // paddingBottom: theme.spacing(0.5),
    // paddingTop:  theme.spacing(0.5),
  },
});

@inject('rootStore')
@observer
class TargetingField extends React.Component {

  constructor(props) {
    super(props);
    const { rootStore, form, targeting, storedData } = this.props;
    const { apiStore } = rootStore;
    this.crudStore = rootStore.channel.model.crudStore;
    this.form = rootStore.channel.model.crudStore.form;
    this.fieldName = `${props.rootStore.channel.model.controller}_targetings_attributes`;
    this.idField = `${props.rootStore.channel.model.controller}_id`;
    this.uberId = props.row_id;
  }

  @observable isError = false;

  componentDidMount = async () => {
    const rootStore = this.props.rootStore;
    this.setInitialRules(this.props.targeting);
    this.setInitialLabels(this.props.targeting);
    this.setIsError(this.props.targeting);
    if (['copy', 'edit'].includes(this.crudStore.action)) {
    }
  };

  componentDidUpdate = async () => {
    this.setIsError(this.props.targeting);
  };

  setInitialLabels = (targeting) => {
    const index = this.getFormIndex(targeting.id);
    if (index != -1 && !_.isEmpty(targeting.extra_field_type)) {
      this.form.$(this.fieldName).$(index).$('extra').set('label', `${targeting.name} extra`);
    }
  }

  setInitialRules = (targeting) => {
    const index = this.getFormIndex(targeting.id);
    if (index != -1) {
      this.setRequired(targeting, index)
    }
  }

  setRequired = (targeting, index, unset = false) => {
    const textOrNumber = ['text', 'number'];
    const rule = unset ? '' : 'required';
    if ((textOrNumber.includes(targeting.extra_field_type) && targeting.extra_required)) {
      this.form.$(this.fieldName).$(index).$('extra').set('rules', rule);
    }
  }

  getFormIndex = (targeting_id) => {
    return this.form.$(this.fieldName).values().findIndex(targAtt => targAtt.targeting_id == targeting_id)
  }

  newTargetingAttribute = (targetingId) => {
    return { changed: true, extra: null, [this.idField]: this.uberId, on: true, targeting_id: targetingId, _destroy: false }
  }

  crudStoreAttribute = (targetingId) => {
    return this.crudStore.storedData[this.fieldName].find(targAttr => targAttr.targeting_id == targetingId)
  }

  emptyAttribute = (targeting_id) => {
    return _.isEmpty(this.allInitiatedTargetingAttributes) || _.isEmpty(this.allInitiatedTargetingAttributes.find(data => data.targeting_id == targeting_id))
  }
  isChecked = (targetingId) => {
    return _.isEmpty(this.crudStoreAttribute(targetingId)) ? false : this.crudStoreAttribute(targetingId).on;
  };

  isDisabled = (targetingId) => {
    return !(this.crudStoreAttribute(targetingId) && this.crudStoreAttribute(targetingId).on);
  };

  isRequired = (val) => {
    return this.props.targeting.extra_required;
  };

  fieldType = (val) => {
    if (val == '') return 'hidden';
    if (val == 'select') return 'select';
    return val == 'number' ? 'number' : 'text';
  };

  label = (val) => {
    if (val == '') return 'hidden';
    return val == 'number' ? 'Days' : '';
  };

  menuItem = (item) => {
    return (
      { label: item[1], value: item[0].toString() }
    );
  };

  makeMenuItemsArray = () => {
    switch (this.props.targeting.id) {
      case 14:
        return [
          ['fb', 'Facebook'],
          ['inst', 'Instagram'],
          ['both', 'Both'],
        ];
    }
  };

  targetingAttributeChanged = (id, el) => {
    const targetingAttribute = this.emptyAttribute(id) ? this.newTargetingAttribute(id) : this.currentTargetingAttribute;
    const index = this.form.$(this.fieldName).values().findIndex(v => v.targeting_id == id);
    switch (index) {
      case -1:
        this.form.$(this.fieldName).add()
        const newIndex = this.form.$(this.fieldName).size - 1;
        this.form.$(this.fieldName).$(newIndex).set(targetingAttribute);
        if (!_.isEmpty(this.props.targeting.extra_field_type)) {
          this.form.$(this.fieldName).$(newIndex).$('extra').set('label', `${this.props.targeting.name} extra`)
        };
        this.setRequired(this.props.targeting, newIndex)
        this.crudStore.storedData[this.fieldName].push(targetingAttribute)
        break;
      default:
        this.form.$(this.fieldName).$(index).set({ '_destroy': !targetingAttribute._destroy, 'on': !targetingAttribute.on, 'changed': true })
        this.crudStore.storeTargetingAttributeData(id, '_destroy', !targetingAttribute._destroy);
        this.crudStore.storeTargetingAttributeData(id, 'on', !targetingAttribute.on);
        this.crudStore.storeTargetingAttributeData(id, 'changed', true);
        this.setRequired(this.props.targeting, index, !this.crudStoreAttribute(id).on);
    }
  };

  extraChanged = (name, el) => {
    const index = this.form.$(this.fieldName).values().findIndex(targAtt => targAtt.targeting_id == name)
    this.form.$(this.fieldName).$(index).$('extra').set('value', el.target.value)
    this.crudStoreAttribute(name).extra = el.target.value;

  };

  textFieldClasses = (ft) => {
    const classes = [];
    // classes.push('textFieldIndented');
    // classes.push('targetingInputWidth');
    // classes.push('two');
    return classes.join(' ');
  };

  getCorrectField = (checkCondition, iftrue, elsefalse) => {
    return checkCondition == 'text' ? iftrue : elsefalse;
  };

  setCurrentTargetingAttribute = (targeting) => {
    this.currentTargetingAttribute = this.emptyAttribute(targeting.id) ?
      {} :
      this.allInitiatedTargetingAttributes.find(dat => dat.targeting_id == targeting.id)
  }

  @action.bound
  setIsError(targeting_field) {
    if (!this.form) {
      this.isError = !this.crudStore.displayError;
      // } else if (this.getFormIndex(targeting.id) == -1) {
      //   isError = false;
    } else if (_.isEmpty(this.currentTargetingAttribute.extra) && this.crudStoreAttribute(targeting_field.id)?.on && targeting_field.extra_required) {
      this.isError = true;
    } else {
      this.isError = false;
    }
  }

  render() {
    const { classes, rootStore, form, targeting, row_id, index } = this.props;
    // console.log('rendering TargetingField');
    // console.log(targeting['abbrev']);
    this.allInitiatedTargetingAttributes = this.props.rootStore.channel.model.crudStore.storedData[this.fieldName]
    this.setCurrentTargetingAttribute(targeting);
    // console.log(`targeting_id: ${targeting.id}, name: ${targeting.name}, abbrev: ${targeting.abbrev}, extra_field_Type: ${targeting.extra_field_type}, extra required: ${targeting.extra_required} extra: ${this.extra}, stored: ${this.empty}`);
    const ft = targeting['extra_field_type'];
    const style = ft == 'text' ? 'width: 100' : 'width: 100';

    const keyFilter = this.props.keyfilter ?
      this.props.keyfilter : ft == 'number' ?
        'int' : ''

    // isError && !(form?.errors()[this.fieldName]
    return (
      <React.Fragment>
        <div className={classes.targetingformControl}>
          <Checkbox
            id={'chk_targeting_ids_' + targeting['abbrev']}
            // name="targeting_ids"
            name={this.fieldName[index].id}
            value={targeting['id']}
            checked={this.isChecked(targeting['id'])}
            onChange={(t) => this.targetingAttributeChanged(targeting.id, t)}
            style={ft == 'text' ? { marginTop: '28px' } : { marginTop: '0px' }}
          />

          {ft == 'text' ? (
            ''
          ) : (
            <label htmlFor={'chk_targeting_ids_' + targeting['abbrev']} className="targeting-label">
              {' '}
              {targeting['name']}{' '}
            </label>
          )}

          {(() => {
            switch (this.fieldType(ft)) {
              case 'hidden':
              case '':
              case null:
                return '';
              case 'xxselect':
                const menuItemArray = this.makeMenuItemsArray();
                const menuItems = menuItemArray.map((item) =>
                  this.menuItem(item),
                );
                return (
                  <React.Fragment>
                    <UccSelect
                      id={'targeting_ids_' + targeting['abbrev']}
                      fieldName={'targeting_ids_' + targeting['id']}
                      form={this.props.form}
                      value={form.$('targeting_ids_' + targeting['id']).value}
                      filter={false}
                      label={this.label(ft)}
                      disabled={this.isDisabled(targeting['id'])}
                      menuItems={menuItems}
                      onChange={(t) => this.extraChanged('targeting_ids_' + targeting['id'], t)}
                      className="select-targeting-width"
                    />
                  </React.Fragment>
                );
              case 'number':
                return (
                  <>
                    <FormControl id={`formControl-${this.props.core}`}>
                      <div className="container" style={{ width: '40px' }} >
                        <span className={"fieldset-text p-float-label uccItems"} style={this.props.callFrom == "days" ? { bottom: '8px', left: '20px' } : {}}>
                          <InputText
                            // label={ft == 'text' ? targeting['name'] : this.label(ft)}
                            type="text"
                            id={'targ_text_' + targeting['abbrev']}
                            value={this.currentTargetingAttribute.extra ? this.currentTargetingAttribute.extra : ''}
                            onChange={(t) => this.extraChanged(targeting.id, t)}
                            className={ft == 'text' ? 'targeting' : 'targeting-width'}
                            name={this.fieldName[index].extra}
                            disabled={this.isDisabled(targeting['id'])}
                            keyfilter="int"

                            // keyfilter={this.props.keyfilter}
                            width={12}
                          // style={isError ? { border: '' } : { border: '2px solid #FF5656' }}
                          />
                          <label htmlFor={`targ_text_${targeting['abbrev']}`}>{ft == 'text' ? targeting['name'] : this.label(ft)}</label>
                        </span>
                      </div>
                    </FormControl>
                    <UccErrorHandling form={this.props.form} fieldName={this.fieldName} isError={this.isError} justReturn={!this.currentTargetingAttribute.on} />
                  </>
                );
              default:
                return (
                  <>
                    <fieldset className={this.props.callFrom == "Days" ? "fieldset-textfield-targeting" : "fieldset-textfield"} id={`fieldset-${targeting['abbrev']}`} style={this.props.callFrom == "targeting" ? { paddingTop: '25px' } : {}} >
                      <FormControl id={`formControl-${this.props.core}`}>
                        <div className="container" style={{ width: '415px' }} >
                          <span className={"fieldset-text p-float-label uccItems"} style={this.props.callFrom == "days" ? { bottom: '8px' } : {}}>
                            <InputText
                              // label={ft == 'text' ? targeting['name'] : this.label(ft)}
                              type="text"
                              id={'targ_text_' + targeting['abbrev']}
                              value={this.currentTargetingAttribute.extra ? this.currentTargetingAttribute.extra : ''}
                              onChange={(t) => this.extraChanged(targeting.id, t)}
                              className={this.textFieldClasses()}
                              name={this.fieldName[index].extra}
                              disabled={this.isDisabled(targeting['id'])}
                              // keyfilter={this.props.keyfilter}
                              required={this.isRequired(targeting)}
                              // style={isError ? { border: '' } : { border: '2px solid #FF5656' }}
                              style={this.isError ? { border: '2px solid #FF5656' } : { border: '' }}
                            />
                            <label htmlFor={`targ_text_${targeting['abbrev']}`}>{ft == 'text' ? targeting['name'] : this.label(ft)}</label>
                          </span>
                          <UccErrorHandling form={this.props.form} fieldName={this.fieldName} isError={this.isError} justReturn={!this.currentTargetingAttribute.on} />
                        </div>
                      </FormControl>
                    </fieldset>
                  </>
                );
            }
          })()}
        </div>
      </React.Fragment >
    );
  }
}

TargetingField.wrappedComponent.propTypes = {
  targeting: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default withStyles(styles)(TargetingField);
