import React, { Component } from 'react';
import { Chips } from 'primereact/chips';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';
import UccErrorHandling from './UccErrorHandling';

@inject('rootStore')
@observer
class UccChips extends Component {
  render() {
    const {
      rootStore,
      form,
      fieldName,
      classes,
      label,
      menuItems,
      core,
      onClose,
      firstOptionLabel
    } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const finalFirstOptionLabel = _.isEmpty(this.props.firstOptionLabel) ? `Select ${label}` : this.props.firstOptionLabel

    const finalLabel = !form.$(fieldName).rules.includes('required') ? `(Optional)  ${label}` : label;
    return (

      <fieldset className="fieldset" id={`fieldset-${fieldName}`}>
        {/* {crudStore.storedData[fieldName] == null ? crudStore.storedData[fieldName] : "null"} */}
        <FormControl id={`formControl-${this.props.core}`}>
          <label>{finalLabel}</label>
          <div className="container">
            <Chips
              onChange={this.props.onChange}
              value={crudStore.storedData[fieldName]}
              filter={this.props.filter}>
            </Chips>


            {/* <div className="two">
                  { form.$(fieldName).rules.includes('required')&&crudStore.storedData[fieldName]  && <i className="pi pi-check success" style={{color:'#00cb09', float:'right'}}></i>}
                  {form.$(fieldName).error  &&!crudStore.storedData[fieldName] && <i className="pi pi-exclamation-circle success" style={{color:'red', float:'right'}}></i> }
                </div> */}
            <UccErrorHandling form={this.props.form} fieldName={fieldName} />
          </div>
        </FormControl>
      </fieldset>
    );
  }
}

export default UccChips;
