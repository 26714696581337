import CrudAdminStore from '../../store/CrudAdminStore';
import CrudChildTabs from './CrudChildTabs';
import CrudIndex from './CrudIndex';
import CrudShow from './CrudShow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  h2: {
    margin: '46px 0px 17px',
  },
  paper: {
    width: '100%',
  }
});

@inject('rootStore')
@observer
class CrudAdminChild extends React.Component {

  refetch = () => {
    const crudStore = this.props.rootStore.channel.model.crudStore
    if (crudStore.refetchDisplayedRows) crudStore.fetchDisplayedRows(crudStore.refetchDisplayedRows);
  }

  render() {
    const {
      rootStore, classes
    } = this.props;
    const {
      selectedRowIds,
    } = rootStore.channel.model.crudStore;
    const crudStore = this.props.rootStore.channel.model.crudStore;
    const model = this.props.rootStore.channel.model;
    const header = `${_.startCase(crudStore.action)} ${Pluralize.singular(model.name)}`
    switch (crudStore.action) {
      case 'index':
        this.refetch();
        return (
          <CrudIndex model={model} />
        );
      case 'show':
        return (
          <>
            <Paper className={classes.root} elevation={4}>
              <CrudShow />
            </Paper>
            {model.childModelEndpoints &&
              <CrudChildTabs model={model} />
            }
          </>
        )
      case 'edit':
      case 'new':
      default:
        return (
          <>
            <Grid item xs={12}>
              <Typography className={classes.h2} component="h2" variant="h2" >{header}</Typography>
            </Grid>
            <Paper className={classes.root} elevation={4}>
              {model.form(rootStore)}
            </Paper>
          </>
        )
    }
  }
}

CrudAdminChild.wrappedComponent.propTypes = {
  // crudAdminStore: PropTypes.instanceOf(CrudAdminStore).isRequired,
};

export default withStyles(styles)(CrudAdminChild);

