import React, { Component } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';
import UccErrorHandling from './UccErrorHandling';

@inject('rootStore')
@observer
class UccMultiSelect extends Component {

  render() {
    const {
      rootStore,
      core,
      form,
      fieldName,
      label,
      isBulkCreateSelection,
      disabled

    } = this.props;
    const { channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const finalDisabled = crudStore.multiselectDisabled && crudStore.multiselectDisabled[fieldName] ? true : disabled;
    const finalFirstOptionLabel = _.isEmpty(this.props.firstOptionLabel) ? `Select ${label}` : this.props.firstOptionLabel
    const isError = !form ? !crudStore.displayError : (!!(form.$(fieldName).error) && crudStore.storedData[fieldName].toString() === '' ? false : true)
    const finalLabel = !form ? label :
      !form.$(fieldName).rules.includes('required') && !label == ''
        ? `(Optional)  ${label}`
        : label;

    const options = isBulkCreateSelection && crudStore.storedData?.[core.replaceAll('-', '_')]?.map((i, index) => ({ label: i.name, value: i.id }));

    // const options = isBulkCreateSelection ? this.getOptions(model, core) : [];

    const value = isBulkCreateSelection ? options?.map(o => o.value) : crudStore.storedData[fieldName];

    return (

      <fieldset className="fieldset" id={`fieldset-${fieldName}`}>
        {/* {crudStore.storedData[fieldName] == null ? crudStore.storedData[fieldName] : "null"} */}
        <FormControl id={`formControl-${core}`}>
          <label className='label'>{finalLabel}</label>
          <div className="container">
            <MultiSelect
              disabled={finalDisabled}
              options={isBulkCreateSelection ? options : this.props.menuItems}
              onChange={this.props.onChange}
              placeholder={finalFirstOptionLabel}
              value={typeof (value) == 'number' ? [] : value}
              optionLabel="label"
              optionValue="value"
              filter={this.props.filter}
              className="uccItems"
              style={isError ? { width: '93%', border: '' } : { width: '93%', border: '2px solid #FF5656' }}
            />
            <UccErrorHandling
              form={this.props.form}
              fieldName={fieldName}
            />
          </div>
        </FormControl>
      </fieldset>
    );
  }
}

export default UccMultiSelect;
