import _ from 'lodash';
import ax from 'axios';
import { action, computed, observable } from 'mobx';
import CrudAdminStore from './CrudAdminStore';
import storeMetadata from './StoreMetadata';
import Model from './Model';
import rootStore from './RootStore';

class Instance {
  constructor(name) {
    this.metadata = storeMetadata(name)
    this.name = name;
  }
}

export default Instance;
