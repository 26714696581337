import React, { Component } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import './PrimeStyle.css';
import { InputNumber } from 'primereact/inputnumber';
import { withStyles } from '@material-ui/core/styles';
import { InputText } from 'primereact/inputtext';
import UccDoubleDatePicker from './UccDoubleDatePicker';
import UccInputText from './UccInputText';
import UccRadio from './UccRadio';
import UccSelect from './UccSelect';
import { copyEditDropDownList, getFormElement, disableSubmitButton, removeTrailingValue } from '../../store/StoreMetadata';
import UccDoubleButton from './UccDoubleButton';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import UccBulkNamestringModal from './UccBulkNamestringModal';
import UccBulkCalendar from './UccBulkCalendar';
import UccBulkForm from './UccBulkForm';
import UccErrorHandling from './UccErrorHandling';


@inject('rootStore')
@observer
class UccBulkMain extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { rootStore, form, label, classes, display, onCancel } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    const multiple = !_.isEmpty(model.crudStore.storedData['attribute_to_be_edited']) &&
      Array.isArray(model.columns.find(c => c.form == model.crudStore.storedData['attribute_to_be_edited'])['multiple']);
    return (
      <div style={{ marginBottom: '20px' }}>
        {!crudStore.firstPage && <div style={{ maxHeight: '500px' }}><UccBulkNamestringModal model={model} onCancel={onCancel} isMultiple={multiple} /></div>}
        {crudStore.firstPage &&
          <div data-qa="onCopy-edit-dialog" className={"p-grid p-fluid p-dialogbox"} style={{ paddingLeft: '30px', width: '565px', minHeight: '235px', paddingRight: '30px' }} >
            <UccBulkForm rootStore={this.props.rootStore} onChange={(event) => crudStore.setNumberOfCopies(event.target.value)} onCancel={onCancel} isMultiple={multiple} />
          </div>
        }
      </div>
    );
  }
}

export default UccBulkMain;