import AddIcon from '@material-ui/icons/AddRounded';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import RootStore from '../../store/RootStore';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles, lighten } from '@material-ui/core/styles';
import ApiStore from '../../store/ApiStore';
import UccButton from '../PrimeReactComponent/UccButton';
import UccClearFilter from '../PrimeReactComponent/UccClearFilter';
import UccToolbarButton from './UccToolbarButton';
import UccToolbarCopy from './UccToolbarCopy';
import UccToolbarCreate from './UccToolbarCreate';
import UccToolbarDelete from './UccToolbarDelete';
import UccToolbarEdit from './UccToolbarEdit';
import UccToolbarExport from './UccToolbarExport';
import UccToolbarArchive from './UccToolbarArchive';
import UccToolbarUnarchive from './UccToolbarUnarchive';
import UccToolbarProvision from './UccToolbarProvision';
import UccToolbarTestFacebook from './UccToolbarTestFacebook';
import routes from '../Layout/Routes';
import UCCDropDownNav from '../PrimeReactComponent/UCCDropDownNav';
import { between, permRequest } from '../../store/StoreMetadata.js'


const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    marginLeft: '24px',
    marginRight: '14px',
  },
  toolbarContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  disabledClass: {
    opacity: '49%',
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'right',
  },
  title: {
    flex: '0 0 auto',
  },
  dropdown: {
    display: 'inline',
    textAlign: 'left',
    width: '200px',
    height: '40px',
  }
});

@inject('rootStore', 'uiStore')
@observer
class CrudToolbar extends React.Component {

  disableEdit = () => {
    const model = this.props.model;
    const numSelected = model.crudStore.selectedRows.length;
    if (model.crudStore.rootStore.apiStore.currentUser.pll < model.permCreateAndEdit) return true;
    const predraftModels = [
      'SocCamp',
      'SocAdSet',
      'SocAd',
      'DispPlace',
      'DispAd',
      'DispCreat',
      'DispPack',
      'EmailCamp',
      'PaidSearchCamp',
      'PaidSearchAdGroup',
      'PaidSearchAdName',
      'ProgCamp',
      'ProgrammaticLineItem',
      'ProgIO'
    ];
    // return false;
    // debugger;
    if (!predraftModels.includes(model.codename)) {
      return (numSelected == 1) ? false : true;
    }
    if (model.crudStore.selectedRowIds.length == 0) return true;
    // if (this.props.model.crudStore.selectedRow && this.props.model.crudStore.selectedRow.status.name == 'Active')
    //   return true;
    // if (model.crudStore.selectedRows.filter(m => m.status.name == 'Active').length > 0) return true;

    return false;
  };

  dropdownNav = (channel, model) => {
    const { classes } = this.props;
    const opts = _.sortBy(channel.models.map((m) => { return { label: m.name, value: m } }), ['label']);
    return <Dropdown
      value={model}
      options={opts}
      onChange={(e) => this.props.uiStore.router.goTo(routes.adminChannelTabs, { channel: channel.endpoint, model: e.value.route }, e.value.crudStore)}
      className={classes.dropdown}
    />;
  }

  render() {
    const { classes, rootStore, selectedRowIds, model, uiStore } = this.props;
    if (model.crudStore.storedData['isChild']) return null;
    const channel = rootStore.channel;
    const crudStore = model.crudStore;

    const deleteCount = this.deleteCount;
    const numSelected = model.crudStore.selectedRowIds.length;
    const header = `${Pluralize.plural(model.name)} Grid`;

    const disabled = model.crudStore.selectedRowIds.length <= 0;
    const disabledClass = model.crudStore.selectedRowIds.length <= 0 ? this.props.classes.disabledClass : '';
    return (
      <React.Fragment>
        <div className={classes.toolbarContainer} data-qa="dropdown-nav">
          {channel.adminChannel ? <UCCDropDownNav /> : <h2 className="grid-title" >{header}</h2>}
          {!crudStore.storedData['isChild'] ? (<>
            <div className="new-button">
              <UccClearFilter rootStore={rootStore} />
            </div>
            <UccToolbarTestFacebook rootStore={rootStore} model={model} classes={classes} />
            <UccToolbarCreate rootStore={rootStore} model={model} classes={classes} />
            <UccToolbarProvision rootStore={rootStore} model={model} classes={classes} />

            {!model.noToolbar ? <Toolbar className={clsx(classes.root)}>
              <div className={classes.actions}>
                <UccToolbarEdit id="toolbar-edit" disable={this.disableEdit()} rootStore={rootStore} model={model} classes={classes} />
                <UccToolbarCopy rootStore={rootStore} model={model} classes={classes} />
                {<UccToolbarArchive model={model} />}
                {<UccToolbarUnarchive model={model} />}
                {!model.noExport && <UccToolbarExport rootStore={rootStore} model={model} classes={classes} />}
                <UccToolbarDelete model={model} />

              </div>
            </Toolbar> : <></>}
          </>) : (<></>)
          }
        </div>
      </React.Fragment >
    );
  }
}

CrudToolbar.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CrudToolbar);
