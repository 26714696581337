
import './PrimeStyle.css';
import NameString from '../NameString';
import Pluralize from 'pluralize';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import storeMetadata, { getInstanceUrl, getModelUrl, formFields, namestringFields, removeTrailingValue } from '../../store/StoreMetadata';
import UccButton from './UccButton';
import UccDialogBox from './UccDialogBox';
import UccDoubleButton from './UccDoubleButton';
import UccThreeButtons from './UccThreeButtons';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';

@inject('rootStore')
@observer
class UccBulkNamestringModal extends Component {

  @observable stringToDisplay = [];
  // const multiple = Array.isArray(model.columns.find(c => c.form == crudStore.storedData['attribute_to_be_edited'])['multiple'])
  constructor(props) {
    super(props);
    this.crudStore = this.props.rootStore.channel.model.crudStore;
    this.model = this.props.rootStore.channel.model;
    this.generateNamestring = this.generateNamestring.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSave = this.onSave.bind(this);
    this.clearHistory = this.clearHistory.bind(this);
    this.htmlDecode = this.htmlDecode.bind(this);
    this.submitDisabled = this.submitDisabled.bind(this);
    this.draftDisabled = this.draftDisabled.bind(this);
    this.saveDisabled = this.saveDisabled.bind(this);
  }


  onSubmit = (e, status = 'Inactive') => {
    const crudStore = this.props.rootStore.channel.model.crudStore;
    if (status == 'Inactive') crudStore.storeData('status_id', '4');
    if (crudStore.toolbarAction == 'bulk_edit') {
      if (crudStore.onBulkEditSubmit()) {
        crudStore.onCopy(crudStore.toolbarAction, status);
      }
    } else {
      crudStore.onCopyEditSubmit(status);
      crudStore.onCopy(crudStore.toolbarAction, status);
    }
    this.clearHistory();
  }

  onSave(e) {
    this.props.rootStore.channel.model.crudStore.storeData('status_id', '2');
    this.onSubmit(e, 'Draft');
  }

  clearHistory() {
    const crudStore = this.props.rootStore.channel.model.crudStore;
    crudStore.setFirstPage(true);
    crudStore.storedData["attribute_to_be_edited"] = 0;
    crudStore.storedData[this.props.rootStore.channel.model.crudStore.storedData["attribute_to_be_edited"]] = ''
    this.props.onCancel();
  }

  generateDisplayEditedString = (row, action) => {
    // this generates a confirmation message for bulk edit in Admin. 
    const model = this.props.model;
    const crudStore = model.crudStore.getBulkCrudStoreByRowId(row.id) ? model.crudStore.getBulkCrudStoreByRowId(row.id) : model.crudStore;
    const row_id = row.id;
    // const multiple = Array.isArray(model.columns.find(c => c.form == crudStore.storedData['attribute_to_be_edited'])['multiple']).
    const multiple = this.props.isMultiple;
    let column_selected = [];
    let selected_values = [];
    column_selected = removeTrailingValue(crudStore.storedData['attribute_to_be_edited'], '_');
    let final_string = (action == 'remove') ? `Remaining ${this.props.rootStore.uiStore.humanize(column_selected)}: ` : (action == 'replace') ? ` Setting ` : `Adding `;
    let preposition = (action == 'remove') ? ' in ' : (action == 'replace') ? ' for ' : ' to ';


    const originalAttrForCurrentRow = (typeof crudStore.storedData.attribute_to_be_edited_original_values == 'Array') ? crudStore.storedData.attribute_to_be_edited_original_values.find((a) => a[0] == row_id)[1] : crudStore.storedData.attribute_to_be_edited_original_values;
    const listOfOriginalAttribute = (typeof originalAttrForCurrentRow == 'Array') ? originalAttrForCurrentRow.map((a) => a) : originalAttrForCurrentRow;
    if (multiple) {
      selected_values = (action == 'add' && !!listOfOriginalAttribute) ? crudStore.storedData[column_selected].filter((a) => !listOfOriginalAttribute.includes(a.id)).map((c) => '&lt;strong&gt;' + c.name + '&lt;/strong&gt;') : crudStore.storedData[column_selected].map((c) => '&lt;strong&gt;' + c.name + '&lt;/strong&gt;');
      if (selected_values.length > 2) {
        const lastItem = selected_values.pop();
        final_string = final_string + ReactHtmlParser(selected_values.join(', ')) + ' and ' + ReactHtmlParser(lastItem);
      } else {
        final_string = final_string + ReactHtmlParser(selected_values.join(' and '));
      }
      final_string = final_string + preposition + row.name

    } else if (action == '') {
      // console.log('ok');
      final_string = 'The new ' + crudStore.storedData.attribute_to_be_edited + ' will be "' + crudStore.storedData[crudStore.storedData.attribute_to_be_edited] + '"'
      this.stringToDisplay = ReactHtmlParser(final_string);
      return;
    } else {
      if (crudStore.storedData[column_selected].id != listOfOriginalAttribute) {
        // todo: this is custom for permission levels because it uses level_name instead of name. 
        // maybe we can pass in wheter its name or level_name or some other value. 
        if (column_selected == 'permission_level') {
          selected_values.push('&lt;strong&gt;' + crudStore.storedData[column_selected].level_name + '&lt;/strong&gt;');
          final_string = 'Changing permission Level of ' + ReactHtmlParser('&lt;strong&gt;' + row.name + '&lt;/strong&gt;') + ' to ' + ReactHtmlParser(selected_values)
        } else {
          selected_values.push('&lt;strong&gt;' + (['Array', 'object'].includes(typeof crudStore.storedData[column_selected]) ? crudStore.storedData[column_selected].name : crudStore.storedData[column_selected]) + '&lt;/strong&gt;')
          final_string = `${column_selected} changed to ` + ReactHtmlParser(selected_values)
        }
      } else {
        selected_values = []
      }
    }
    if (action != '' && selected_values.length == 0) {
      final_string = 'Already present in ' + row.name
    }
    this.stringToDisplay.push(ReactHtmlParser(final_string));
  }


  generateNamestring = (bulkCrudStore) => {
    const model = this.props.model;
    const row_id = bulkCrudStore?.storedData?.id;
    let edited_column = [];
    edited_column.push(bulkCrudStore.storedData['attribute_to_be_edited']);
    // edited_column.push(bulkCrudStore.storedData['brand_id']);
    if (this.props.model.crudStore.storedData['keep_original_dates'] !== true) {
      edited_column.push('start_date');
      edited_column.push('end_date');
      edited_column.push('send_date');
    }


    this.stringToDisplay.push(
      <NameString
        store={bulkCrudStore}
        ret="string"
        type={this.props.model.name == 'Ads' ? 'ad' : ''}
        edited_column={edited_column}
      />
    );
    if (this.props.model.name == 'Ads') {
      this.utmPlatform = _.isEmpty(bulkCrudStore.storedData.social_ad_set) ?
        '' :
        ['SC', 'PIN'].includes(bulkCrudStore.storedData.social_ad_set.platform.abbrev) ?
          bulkCrudStore.storedData.social_ad_set.platform.name :
          '';
      this.utmString = NameString.getAdUtmString(bulkCrudStore, bulkCrudStore.storedData)
    };
  };

  saveDisabled = () => {
    if (!this.model.hasChildren) return true;
    if (this.model.parentModel && this.model.parentModel + "_id" == this.crudStore.storedData['attribute_to_be_edited']) {
      const parentStati = this.crudStore.bulkCrudStores.map(bcs => bcs.storedData[this.model.parentModel]?.status?.id);
      if (parentStati.filter(value => [5, 2].includes(value)).length > 0) {
        this.saveButtonMessage = "Save is disabled because the parent item is in draft state"
        return true;
      }
    }
    if (this.crudStore.initialAction == 'copy_edit') {
      return true;
    }
    return false;
  }

  submitDisabled = () => {
    const model = this.props.rootStore.channel.model;
    const crudStore = model.crudStore;

    if (this.model.hasChildren && crudStore.initialAction == 'bulk_edit') return true;

    if (model.parentModel != undefined && model.parentModel != "") {
      const parentStati = crudStore.bulkCrudStores.map(bcs => bcs.storedData[model.parentModel]?.status?.id);
      if (parentStati.filter(value => [5, 2].includes(value)).length > 0) {
        this.submitButtonMessage = "Submit is disabled because a parent item is still in draft state"
        return true;
      }
    }

    this.submitButtonMessage = "Save Items as Inactive"
    return false;
  }

  draftDisabled = () => {
    if (this.model.hasChildren && this.crudStore.initialAction == 'bulk_edit') return true;

    return false;
  }

  htmlDecode(input) {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  render() {
    const onCancel = this.props.onCancel;
    const channel = this.props.rootStore.channel;
    const isAdmin = this.props.rootStore.channel.adminChannel
    const actionAddRemove = this.props.model.crudStore.operationType;
    // const enableDraft = _.isEmpty(_.compact(this.props.model.crudStore.bulkCrudStores.map(bcs => bcs.storedData.status_id)).filter(stat => stat == 4))

    return (
      <div data-qa="dialog-namestring" style={{ minWidth: '300px', wordBreak: 'break-all' }}>
        {!isAdmin && this.props.model.crudStore.bulkCrudStores.map((a) => this.generateNamestring(a))}
        {isAdmin && this.props.model.crudStore.bulkCrudStores.map((a) => this.generateDisplayEditedString(a.selectedRow, actionAddRemove))}
        <ol style={{ marginRight: '25px', marginLeft: '15px', maxHeight: '350px', overflowY: 'auto' }}>
          {this.stringToDisplay.map((ns, index) => {
            return <li style={{ paddingBottom: '10px', wordWrap: 'break-word', paddingRight: '20px' }} id={index}>{ns}</li>
          })
          }
        </ol>

        {(!isAdmin) && <div style={{ margin: 'auto', alignContent: 'center', width: '776px' }}>
          <div className="root_2" >
            <div className="cancel_2">
              <UccButton
                buttonType=''
                label='cancel'
                onClick={(e) => this.clearHistory()}
                width='-webkit-fill-available'
              />
            </div>
            {!this.draftDisabled() && (<div className="predraft">
              <UccButton
                buttonType='draft'
                label="Save Draft"
                onClick={(e) => this.onSave(e)}
                width='-webkit-fill-available'
                disable={this.draftDisabled()}
                title={this.draftButtonMessage || 'Save as draft'}
              />
            </div>)}
            {!this.submitDisabled() && (<div className="submit_2">
              <UccButton
                buttonType='submit'
                disable={this.submitDisabled()}
                label="Submit"
                onClick={(e) => this.onSubmit(e)}
                width='-webkit-fill-available'
                title={this.submitButtonMessage || 'Submit'}
              />
            </div>)}
            {!this.saveDisabled() && (<div className="submit_3">
              <UccButton
                buttonType='save'
                disable={this.saveDisabled()}
                label="Save"
                onClick={(e) => this.onSubmit(e, 'keepStatuses')}
                width='-webkit-fill-available'
                title={this.saveButtonMessage || 'Save and keep current statuses'}
              />
            </div>)}
          </div>
        </div>}
        {(isAdmin) && <div style={{ margin: 'auto', alignContent: 'center' }}><UccDoubleButton title='Save' label='Save' onSubmit={(e) => this.onSubmit(e, 'keepStatuses')} onCancel={this.clearHistory} new_width='600px' new_paddingLeft='30px' cancelLeftPadding='0px' new_paddingTop='25px' new_margin='auto' /></div>}
      </div>
    );
  }
}

export default UccBulkNamestringModal;