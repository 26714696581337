import React, { Component } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import './PrimeStyle.css';
import { observer, inject } from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';

@inject('rootStore')
@observer
class UccSwitch extends Component {
  render() {
    const {
      rootStore,
      form,
      fieldName,
      classes,
      label,
      menuItems,
      core,
      onClose,
      firstOptionLabel
    } = this.props;
    const { apiStore, uiStore, channel } = rootStore;
    const model = channel.model;
    const crudStore = model.crudStore;
    return (
      <fieldset className="fieldset" id={`fieldset-${fieldName}`}>
        <FormControl id={`formControl-${this.props.core}`}>
          <div className="p-switch-container">
            <InputSwitch checked={this.props.checked} onChange={this.props.onChange} name={this.props.label} />
            <label className="p-switch-label">{this.props.label}</label>
          </div>
          <label className="p-helpertext">{this.props.checked ? "On" : "Off"}</label>
        </FormControl>
      </fieldset>
    );
  }
}

export default UccSwitch;
