import GenericForm from './../../../components/CrudAdmin/forms/GenericForm';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '../../../../../public/images/link-icon.png';
import Moment from 'react-moment';
import NameString from './../../../components/NameString';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import UccBrandChannelGroupDataTable from './../../../components/PrimeReactComponent/UccBrandChannelGroupDataTable';
import UccCheckBox from './../../../components/PrimeReactComponent/UccCheckbox';
import UccInputText from './../../../components/PrimeReactComponent/UccInputText';
import UccSelect from './../../../components/PrimeReactComponent/UccSelect';
import UccMultiSelect from './../../../components/PrimeReactComponent/UccMultiSelect';
import UtaTargeting from './../../../components/CrudAdmin/forms/FormElements/UtaTargeting';
import UccObjectiveModifier from './../../../components/PrimeReactComponent/UccObjectiveModifier';

import { abbrev, adGroupModifierNs, ad_id_value, between, channelSpecificOptions, childModelFilter, dateField, dateFilter, domoFields, formFields, getInstanceUrl, getModelUrl, HeadedCamelCase, inputTextFilter, multiAbbrev, multiSelectFilter, namestringFields, objectiveModifierNs, parentAbbrev, plusSigns, postgresDate, property, showFields, validDate, dropDownFilter } from '../../StoreMetadata.js'

var moment = require('moment');

export const email = {
  name: 'Email',
  endpoint: 'email',
  codename: 'email',
  abbrev: 'Email',
  icon: 'Email',
  id: 3,
  disabled: false,
  showViewIcon: true,
  models: [
    {
      allFilterData: (apiStore) => apiStore['emailCampaignsFilterOptions'],
      codename: 'EmailCamp',
      controller: 'email_campaign',
      crud: (rootStore) => rootStore.emailCampaignsCrud,
      domoNsType: 'EmailCampaign',
      endpoint: 'email-campaigns',
      filterOptionsCall: (apiStore) => (val) => apiStore['emailCampaignsFilterOptions'] = val,
      form: () => <GenericForm type="Email" datePicker={1} numButtons={3} />,
      genericModelPromiseEndpoints: [
        'ab-types',
        'campaign-types',
        'ad-types',
        'email-service-platforms',
        'geos',
        'campaign-type-objectives',
        'channel-objectives',
        'channel-platforms',
        'channel-tactics',
        'channel-targetings',
        'tactic-targetings',
        'objectives',
        'tactics',
        'targetings',
        'campaign-type-channels',
        'channel-genders',
        'genders',
        'ad-type-channels',
        'users',
        'audiences',
        'statuses',
        'test-types',
        'test-groups',
        'segments',
        'message-types',
        'marketing-campaigns',
        'waves',
        'send-types',
        'test-group-test-types',
        'email-types',
      ],
      modelPromiseEndpoints: (apiStore, brand_id) => ([
        apiStore.namestringsCrud.getExportCount({ model: apiStore.channel.model.domoNsType, brand_id: brand_id, channel: apiStore.channel.abbrev }, ''),
        apiStore.emailCampaignsCrud.getFilterOptions({ brand_id: brand_id }, ''),
      ]),
      modelFilter: (root) => { return { brand_id: root.apiStore.currentBrandId }; },
      name: 'Campaigns',
      nameField: 'name',
      noDataText: (root) => '',
      permCreateAndEdit: 20,
      permDelete: 20,
      permListAndView: 20,
      requiredData: (apiStore) => apiStore.genericModelPromiseResults(),
      permRequest: false,
      route: 'campaigns',
      send2domo: true,
      showFormat: 'definitionList',
      singular: 'campaign',
      tabDisabled: () => false,
      tabIndex: 0,
      tabLabel: (m) => m.crudStore.selectedRowIds.length > 0 ? `${m.name} (${m.crudStore.selectedRowIds.length} selected)` : m.name,
      columns: [
        {
          colSize: 6,
          domo_field: 'nsid',
          domo_value: (crudStore) => crudStore.storedData.id,
          form: 'id',
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_value: (row) => row.id,
          grid_order: 1,
          id: 'id',
          label: 'ID',
          name: 'id',
          ns: (row) => row.id,
          permListAndView: 80,
          rules: 'integer|required',
          type: 'hidden',
          utaType: 'id',
        },
        {
          colSize: 8,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_link: 'draft_edit',
          grid_order: 6,
          grid_value: (row) => row.namestring_id == null ? row.id : row.namestring_id,
          id: 'namestring_id',
          label: 'NSID',
          name: 'namestring_id',
          ns: (crudStore) => crudStore.storedData.namestring_id == null ? crudStore.storedData.id : crudStore.storedData.namestring_id,
          ns_order: 160,
          utaType: 'namestring_id'
        },
        {
          domo_field: 'namestring',
          domo_value: (crudStore) => _.map(_.filter(crudStore.nsFields, (f) => f.ns_order), (f) => f.ns(crudStore)).join('_'),
          format: 'action',
          grid: true,
          grid_order: 5,
          grid_type: 'namestring',
          id: 'namestring',
          label: 'Namestring',
          rules: 'required',
          colSize: 8
        },
        {
          form: 'namestring_string',
          name: 'namestring_string',
          formOrder: 66,
          rules: 'required',
          type: 'hidden',
          utaType: 'string'
        },
        {
          domo_field: 'ns_type',
          domo_value: () => 'EmailCampaign',
          rules: 'required',
        },

        {
          domo_field: 'channel',
          domo_value: (crudStore) => abbrev(crudStore.storedData.channel),
          ns: () => 'hmm',
          name: 'channel',
          id: 'channel',
          utaType: 'channel',
          rules: 'required',
        },
        {
          domo_field: 'created_by',
          domo_value: (crudStore) => property(crudStore.storedData.created_by, 'email'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          name: 'created_by',
          options: (apiStore) => apiStore.users,
          label: 'Created By',
          id: 'created_by',
          grid: true,
          grid_order: 190,
          grid_value: (row) => property(row.user, 'name'),
          ns: () => 'user',
          utaType: 'user',
          rules: 'required',
          colSize: 9
        },
        {
          belongsTo: ['status', 'statuses'],
          form: 'status_id',
          name: 'status_id',
          label: 'Status',
          rules: 'required|integer',
          utaType: 'status',
          type: 'hidden',
        },
        {
          domo_field: 'status',
          domo_value: (crudStore) => crudStore?.storedData?.status.name,
          // form: 'status_id',
          name: 'status_id',
          filter: (dt, col) => multiSelectFilter(dt, col),
          id: 'status_id',
          grid_value: (row) => property(row.status, 'name'),
          grid: true,
          grid_order: 9,
          label: 'Status',
          options: (apiStore) => apiStore.statuses,
          rules: 'required|integer',
          utaType: 'fk',
        },
        {
          form: 'group_id',
          type: 'hidden',
          utaType: 'group',
          rules: 'required',
        },
        {
          domo_field: 'group',
          domo_value: (crudStore) => abbrev(crudStore.storedData.group),
          name: 'group',
          ns: (crudStore) => abbrev(crudStore.storedData.group),
          utaType: 'group',
          rules: 'required',
        },
        {
          copyEditable: true,
          domo_field: 'brand_id',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'id'),
          form: 'brand_id',
          formField: (rootStore) => (<UccSelect
            core="brand_id"
            fieldName="brand_id"
            form={rootStore.channel.model.crudStore.form}
            label="Brand"
            menuItems={rootStore.uiStore.menuItems('brands', 'makePrimeRegularOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('brand_id', event)}
          />),
          formOrder: 25,
          hideForm: true,
          rules: 'required',
          type: 'hidden',
          utaType: 'brand',
        },
        {
          domo_field: 'brand',
          domo_value: (crudStore) => property(crudStore.storedData.brand, 'name'),
          name: 'brand',
          ns: (crudStore) => abbrev(crudStore.storedData.brand),
          // ns_order: 20,
          rules: 'required',
          utaType: 'brand',
        },
        {
          colSize: 13,
          domo_field: 'campaign_name',
          domo_value: (crudStore) => property(crudStore.storedData.marketing_campaign, 'name'),
          grid: true,
          grid_order: 15,
          grid_value: (row) => property(row.marketing_campaign, 'name'),
          id: 'marketing_campaign_id',
          label: 'Marketing Campaign',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.marketingCampaigns,
          name: 'marketing_campaign_id',
          ns: (crudStore) => abbrev(crudStore.storedData.marketing_campaign),
          ns_order: 15,
          rules: 'required',
          utaType: 'fk',
        },
        {
          belongsTo: ['marketing_campaign', 'marketingCampaigns'],
          form: 'marketing_campaign_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="marketing-campaign"
            fieldName="marketing_campaign_id"
            form={rootStore.channel.model.crudStore.form}
            label="Marketing Campaign"
            menuItems={rootStore.uiStore.menuItems('marketingCampaigns', 'makePrimeRegularOption')}
            onChange={(event) => rootStore.channel.model.crudStore.storeData('marketing_campaign_id', event)}
          />),
          formOrder: 2,
          label: 'Marketing Campaign',
          rules: 'required|integer',
          show: true,
          show_value: (row) => property(row.marketing_campaign, 'name'),
          show_order: 20,
          utaType: 'fk',
        },
        {
          colSize: 7,
          grid: true,
          grid_order: 20,
          grid_value: (row) => row.name,
          id: 'name',
          name: 'name',
          label: 'Description',
          filter_placeholder: 'Description',
          filter: (dt, col) => inputTextFilter(dt, col),
          rules: 'string',
          utaType: 'name',
          colSize: 9
        },

        {
          belongsTo: ['email_service_platform', 'emailServicePlatforms'],
          form: 'email_service_platform_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="email-service-platform"
            fieldName="email_service_platform_id"
            form={rootStore.channel.model.crudStore.form}
            label="Email Service Platform"
            menuItems={rootStore.uiStore.menuItems('emailServicePlatforms', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('email_service_platform_id', event)}
          />),
          formOrder: 30,
          label: 'Email Service Platform',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.email_service_platform, 'name'),
          show_order: 30,
          utaType: 'fk',
        },
        {
          colSize: 14,
          domo_field: 'platform',
          domo_value: (crudStore) => property(crudStore.storedData.email_service_platform, 'name'),
          grid: true,
          grid_order: 30,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.emailServicePlatforms,
          grid_value: (row) => property(row.email_service_platform, 'name'),
          id: 'email_service_platform',
          label: 'Email Service Platform',
          name: 'email_service_platform',
          ns: (crudStore) =>
            abbrev(crudStore.storedData.email_service_platform),
        },
        {
          colSize: 12,
          belongsTo: ['campaign_type', 'campaignTypes'],
          form: 'campaign_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="campaign-type"
            fieldName="campaign_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Campaign Type"
            menuItems={rootStore.uiStore.menuItems('campaignTypes', 'makePrimeSpecificCampaignTypeOption')}
            onChange={(event) => rootStore.channel.model.crudStore.setCampaignTypes(event)}
          />),
          formOrder: 40,
          label: 'Campaign Type',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'campaign_type',
          domo_value: (crudStore) => property(crudStore.storedData.campaign_type, 'name'),
          grid: true,
          grid_order: 40,
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.campaignTypes,
          grid_value: (row) => property(row.campaign_type, 'name'),
          id: 'campaign_type',
          label: 'Campaign Type',
          name: 'campaign_type',
          ns: (crudStore) => abbrev(crudStore.storedData.campaign_type),
          show: true,
          show_value: (row) => property(row.campaign_type, 'name'),
          show_order: 40,
          // ns_order: 30,
        },
        {
          domo_field: 'objective',
          domo_value: (crudStore) => property(crudStore.storedData.objective, 'name'),
          grid: true,
          grid_order: 50,
          grid_value: (row) => property(row.objective, 'name'),
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.objectives,
          id: 'objective',
          label: 'Objective',
          name: 'objective',
          ns: (crudStore) => abbrev(crudStore.storedData.objective),
        },
        {
          belongsTo: ['objective', 'objectives'],
          form: 'objective_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="objective"
            fieldName="objective_id"
            form={rootStore.channel.model.crudStore.form}
            label="Objective"
            menuItems={rootStore.uiStore.makeObjectiveOptions()}
            onChange={event => rootStore.channel.model.crudStore.storeData('objective_id', event)}
          />),
          formOrder: 50,
          label: 'Objective',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.objective, 'name'),
          show_order: 50,
          utaType: 'fk',
        },
        {
          belongsTo: ['tactic', 'tactics'],
          form: 'tactic_id',
          formField: (rootStore) => (
            <UccSelect
              core="tactic"
              fieldName="tactic_id"
              form={rootStore.channel.model.crudStore.form}
              label="Tactic"
              menuItems={rootStore.uiStore.menuItems('tactics', 'makePrimeChannelSpecificOption', 'channelTactics')}
              onChange={(event) => rootStore.channel.model.crudStore.pivotControlChanged('tactic', 'targeting', event)}
            />),
          formOrder: 60,
          label: 'Tactic',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'tactic',
          domo_value: (crudStore) => property(crudStore.storedData.tactic, 'name'),
          grid: true,
          grid_order: 60,
          grid_value: (row) => property(row.tactic, 'name'),
          id: 'tactic',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.tactics,
          label: 'Tactic',
          name: 'tactic',
          ns: (crudStore) => abbrev(crudStore.storedData.tactic),
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.tactic, 'name'),
          show_order: 60,
        },
        {
          domo_field: 'suppression',
          domo_value: (crudStore) => crudStore.suppressionLabel(),
          form: 'suppression',
          grid: false,
          id: 'suppression',
          label: 'Suppression',
          name: 'suppression',
          ns: (crudStore) => crudStore.suppressionLabel(),
          type: 'checkbox',
          show: true,
          show_value: (row) => row.suppression ? 'On' : 'Off',
          show_order: 65,
          utaType: 'boolean',
        },

        {
          domo_field: 'targeting',
          domo_value: (crudStore) => NameString.targetingString(crudStore),
          form: 'email_campaign_targetings_attributes',
          formField: (rootStore) => (<UtaTargeting form={rootStore.channel.model.crudStore.form} dependent="targeting" />),
          formOrder: 70,
          grid: true,
          grid_order: 80,
          id: 'targeting_ids',
          label: 'Targeting',
          multiple: [],
          name: 'email_campaign_targetings_attributes',
          // ns: (crudStore) => NameString.targetingString(crudStore),
          // ns_order: 80,
          show: true,
          show_value: (row) => NameString.pivotShow(row, "email_campaign_targetings"),
          show_order: 80,
          sortable: false,
          utaType: 'targeting_array',
        },

        {
          form: 'email_campaign_targetings_attributes[].id',
        },
        {
          form: 'email_campaign_targetings_attributes[].email_campaign_id',
        },
        {
          form: 'email_campaign_targetings_attributes[].targeting_id',
        },
        {
          form: 'email_campaign_targetings_attributes[].extra',
        },
        {
          form: 'email_campaign_targetings_attributes[]._destroy',
          default: 'false'
        },
        {
          form: 'email_campaign_targetings_attributes[].changed',
          default: 'false'
        },
        {
          form: 'email_campaign_targetings_attributes[].on',
        },

        {
          belongsTo: ['geo', 'geos'],
          form: 'geo_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="geo"
            fieldName="geo_id"
            form={rootStore.channel.model.crudStore.form}
            label="Geo"
            menuItems={rootStore.uiStore.menuItems('geos', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('geo_id', event)}
          />),
          formOrder: 80,
          label: 'Geo',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          colSize: 8,
          domo_field: 'geo',
          domo_value: (crudStore) => property(crudStore.storedData.geo, 'name'),
          grid: true,
          grid_order: 80,
          grid_value: (row) => property(row.geo, 'name'),
          id: 'geo',
          label: 'Geo',
          name: 'geo',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.geos,
          ns: (crudStore) => abbrev(crudStore.storedData.geo),
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.geo, 'name'),
          show_order: 80,
        },
        {
          belongsTo: ['gender', 'genders'],
          form: 'gender_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="gender"
            fieldName="gender_id"
            form={rootStore.channel.model.crudStore.form}
            label="Gender"
            menuItems={rootStore.uiStore.menuItems('genders', 'makePrimeChannelSpecificOption', 'channelGenders')}
            onChange={event => rootStore.channel.model.crudStore.storeData('gender_id', event)}
          />),
          formOrder: 90,
          label: 'Gender',
          rules: 'integer',
          utaType: 'fk',
        },
        {
          domo_field: 'gender',
          domo_value: (crudStore) => property(crudStore.storedData.gender, 'name'),
          grid: true,
          grid_order: 90,
          grid_value: (row) => property(row.gender, 'name'),
          id: 'gender',
          label: 'Gender',
          name: 'gender',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.genders,
          ns: (crudStore) => abbrev(crudStore.storedData.gender),
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.gender, 'name'),
          show_order: 90,
        },
        {
          belongsTo: ['ad_type', 'adTypes'],
          form: 'ad_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="ad-type"
            fieldName="ad_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="Creative Type"
            menuItems={rootStore.uiStore.menuItems('adTypes', 'makePrimeChannelSpecificOption', 'adTypeChannels')}
            onChange={event => rootStore.channel.model.crudStore.storeData('ad_type_id', event)}
          />),
          formOrder: 100,
          label: 'Creative Type',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.ad_type, 'name'),
          show_order: 100,
          utaType: 'fk',
        },
        {
          domo_field: 'ad_type',
          domo_value: (crudStore) => property(crudStore.storedData.ad_type, 'name'),
          grid: true,
          grid_order: 100,
          grid_value: (row) => property(row.ad_type, 'name'),
          id: 'ad_type',
          label: 'Creative Type',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.adTypes,
          name: 'ad_type',
          ns: (crudStore) => abbrev(crudStore.storedData.ad_type),
          colSize: 10
        },
        {
          belongsTo: ['ab_type', 'abTypes'],
          form: 'ab_type_id',
          copyEditable: true,
          formField: (rootStore) => (<UccSelect
            core="ab-type"
            fieldName="ab_type_id"
            form={rootStore.channel.model.crudStore.form}
            label="A - B Testing Type"
            menuItems={rootStore.uiStore.menuItems('abTypes', 'makePrimeOption')}
            onChange={event => rootStore.channel.model.crudStore.storeData('ab_type_id', event)}
          />),
          formOrder: 110,
          label: 'A - B Testing',
          rules: 'integer',
          show: true,
          show_value: (row) => property(row.ab_type, 'name'),
          show_order: 110,
          utaType: 'fk',
        },
        {
          colSize: 9,
          domo_field: 'ab_type',
          domo_value: (crudStore) => property(crudStore.storedData.ab_type, 'name'),
          grid: true,
          grid_order: 110,
          grid_value: (row) => property(row.ab_type, 'name'),
          id: 'ab_type',
          label: 'A - B Testing',
          filter: (dt, col) => multiSelectFilter(dt, col),
          options: (apiStore) => apiStore.abTypes,
          name: 'ab_type',
          ns: (crudStore) => abbrev(crudStore.storedData.ab_type),
          rules: 'integer',
        },
        {
          colSize: 9,
          domo_field: 'ab_version',
          domo_value: (crudStore) => HeadedCamelCase(crudStore.storedData.ab_version),
          form: 'ab_version',
          copyEditable: true,
          formField: (rootStore) => (<UccInputText
            core="ab_version"
            fieldName="ab_version"
            form={rootStore.channel.model.crudStore.form}
            label="A - B Testing Version"
            onInput={(event) => rootStore.channel.model.crudStore.storeData('ab_version', event)}
          />),
          formOrder: 120,
          filter: (dt, col) => inputTextFilter(dt, col),
          grid: true,
          grid_order: 120,
          grid_value: (row) => row.ab_version,
          id: 'ab_version',
          name: 'ab_version',
          label: 'A - B Version',
          ns: (crudStore) => HeadedCamelCase(crudStore.storedData.ab_version),
          utaType: 'string',
          rules: 'string',
          show: true,
          show_value: (row) => row.ab_version,
          show_order: 120,
        },
        {
          label: 'Created At',
          show_value: (row) => crudStore.storedData.created_at,
          show_order: 160,
          rules: 'required|integer',
        },
        {
          id: 'created_at',
          name: 'created_at',
          label: 'Created At',
          utaType: 'date',
          rules: 'date',
          ns: (crudStore) => crudStore.storedData.created_at,
        },
        {
          label: 'Domo Push Date',
          show_value: (row) => crudStore.storedData.push_date,
          show_order: 195,
          id: 'push_date',
          name: 'push_date',
          ns: (crudStore) => crudStore.storedData.push_date,
        },
      ].concat(
        dateField('send_date', 'Send Date', 'blank', true, 10, 10, '', 10),
      ),
    },
  ],
};