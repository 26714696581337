import Button from '@material-ui/core/Button';
import MobxReactForm from 'mobx-react-form';
import PropTypes from 'prop-types';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';

const BootstrapButton = withStyles({
  root: {
    fontStretch: 'Bold',
    fontWeight: 900,
  },
})(Button);
const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    display: 'inline-block',
    width: '90vh',
    maxWidth: 451,
  },
  button: {
    width: 135,
    height: 40,
  },
  cancel: {
    float: 'left',
    padding: '10',
  },
  savepreDraft: {
    display: 'inline-block',
    marginLeft: 15,
  },
  submit: {
    float: 'right',
    paddingLeft: '10',
    paddingRight: '0',
    marginLeft: 15
  },
});

@inject('rootStore')
@observer
class PredraftButtons extends React.Component {
  render() {
    const { classes, form, onCancel } = this.props;
    return (
      <React.Fragment >
        <div className={classes.root}>
          <div className={classes.cancel}>
            <BootstrapButton
              onClick={onCancel}
              className={classes.button}
              variant="outlined"
            >
              Cancel
            </BootstrapButton>
          </div>
          <div className={classes.savepreDraft}>
            <BootstrapButton
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={(e) => {
                form.$('status').set('value', 'draft');
                form.onSubmit(e);
              }}
            >
              Save Draft
            </BootstrapButton>
          </div>
          <div className={classes.submit}>
          <BootstrapButton
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={(e) => {
              form.$('status').set('value', 'created');
              form.onSubmit(e);
            }}
          >
            Create
          </BootstrapButton>   
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PredraftButtons.wrappedComponent.propTypes = {
  form: PropTypes.instanceOf(MobxReactForm).isRequired,
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PredraftButtons);
