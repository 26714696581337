import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import FormControl from '@material-ui/core/FormControl';
import UccErrorHandling from './UccErrorHandling';

class UccMin extends Component {
  render() {
    return (<div>this is an element</div>);
  };
}

export default UccMin;
