
import { Button } from 'primereact/button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import RootStore from '../../store/RootStore';
import Tooltip from '@material-ui/core/Tooltip';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import UccToolbarButton from './UccToolbarButton';

@inject('rootStore')
@observer
class UccToolbarCopy extends React.Component {
  constructor(props) {
    super(props);
    this.model = this.props.rootStore.channel.model;
  }

  exportClick = (event) => {
    this.props.model.crudStore.setCopyExportEl(event.currentTarget, 'copy_edit');
  };

  // renderBulkForm = (e, DialogMessage = 'copy') => {
  //   const model = this.props.model;
  //   model.crudStore.setToolbarAction(DialogMessage)
  //   model.crudStore.setInitialAction(DialogMessage)
  //   model.crudStore.setFirstPage(true);
  //   return (
  //     model.crudStore.confirmAction(
  //       model.crudStore.onDirectCopy,
  //       DialogMessage,
  //       'Confirm',
  //     )
  //   );
  // };


  onClick = (e, ding) => {
    const crudStore = this.props.model.crudStore;
    switch (ding) {
      case 'copy':
        crudStore.setToolbarAction('copy');
        crudStore.renderDirectCopyForm(e, ding);
        break;
      case 'copy_edit':
        crudStore.setToolbarAction('copy_edit');
        crudStore.renderBulkForm(e, ding);
        break
    }
    crudStore.setCopyExportEl(null, 'copy_edit');
  }

  render() {

    const { rootStore, model, classes, anchorEl } = this.props;
    const selectedRowIds = model.crudStore.selectedRowIds;
    const channel = rootStore.channel;
    const crudStore = model.crudStore;
    const deleteCount = this.deleteCount;
    const id = 'toolbar_copy-edit';

    return (
      channel.adminChannel ?
        (<></>) :
        (
          <>
            <UccToolbarButton id={id} disable={(model.crudStore.selectedRowIds.length <= 0) ? true : false} selectedRowIds={selectedRowIds} model={model} label="Copy" icon="pi pi-copy" onClick={(e) => this.exportClick(e)} />
            <Menu id="simple-menu-copy-edit" anchorEl={crudStore.copyEl} open={crudStore.open_copy} onClose={(e) => this.onClick(e)} >
              <MenuItem data-qa="Copy-list" onClick={(e) => this.onClick(e, 'copy')} className={classes.parent}>Copy</MenuItem>
              <MenuItem data-qa="Copy-Edit-list" onClick={(e) => this.onClick(e, 'copy_edit')} className={classes.parent}>Copy &#38; Edit</MenuItem>
            </Menu>
          </>)

    );
  }
}


export default UccToolbarCopy;
