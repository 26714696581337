
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from 'primereact/button';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import RootStore from '../../store/RootStore';
import Tooltip from '@material-ui/core/Tooltip';
// import { Tooltip } from 'primereact/tooltip';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles, lighten } from '@material-ui/core/styles';
import ApiStore from '../../store/ApiStore';

@inject('rootStore')
@observer
class UccToolbarButton extends React.Component {
  constructor(props) {
    super(props);
    this.model = this.props.rootStore.channel.model;
  }


  render() {
    const { numSelected, selectedRowIds, model, label, icon, disable, id } = this.props;
    const crudStore = model.crudStore;

    return (
      <div style={{ margin: '10px' }}>
        <Tooltip title={label} placement="top-start">
          <span>
            <Button
              id={id}
              icon={icon}
              className="p-button-rounded p-button-outlined"
              disabled={disable}
              onClick={this.props.onClick}
            >
            </Button>
          </span>
        </Tooltip>
      </div >
    );
  }
}


export default UccToolbarButton;
