import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import CrudTable from './CrudTable';
import CrudToolbar from './CrudToolbar';
import { observable } from 'mobx';

@inject('rootStore')
@observer
class CrudIndex extends React.Component {
  render() {
    const {
      rootStore,
      model,
      childIndex,
    } = this.props;
    return (
      <CrudTable model={model} childIndex={childIndex ? true : false}/>
    );
  }
}

export default CrudIndex;
